import http from './../../lib/http';
import api from './../../lib/api';

export const FINE_CATS_LOAD                 = 'FINE_CATS_LOAD';
export const FINE_CATS_UPDATE               = 'FINE_CATS_UPDATE';
export const FINE_CATS_CREATE               = 'FINE_CATS_CREATE';

export const FINE_CATS_LOAD_DATATABLE       = 'FINE_CATS_LOAD_DATATABLE';

export const FINE_CATS_LOAD_BY_FINE_CATS    = "FINE_CATS_LOAD_BY_FINE_CATS";

const FINE_CATS_CHANGE                      = 'FINE_CATS_CHANGE';
const FINE_CATS_LOADING_CHANGE              = 'FINE_CATS_LOADING_CHANGE';
const FINE_CATS_SAVING_CHANGE               = 'FINE_CATS_SAVING_CHANGE';
const FINE_CATS_ERRORS_CHANGE               = 'FINE_CATS_ERRORS_CHANGE';
const FINE_CATS_CREATE_CHANGE               = 'FINE_CATS_CREATE_CHANGE';

const FINE_CATS_CREATING_CHANGE             = 'FINE_CATS_CREATING_CHANGE';

const FINE_CATS_DATATABLE_CHANGE            = 'FINE_CATS_DATATABLE_CHANGE';
const FINE_CATS_DATATABLE_LOADING_CHANGE    = 'FINE_CATS_DATATABLE_LOADING_CHANGE';

const state = {
    fine_cats            : null,
    fine_cats_loading    : false,

    fine_cats_saving     : false,
    fine_cats_errors     : {},

    fine_cats_list_total    : 0,
    fine_cats_list           : null,
    fine_cats_list_loading   : false,

    fine_cats_creating           : false,
    fine_cats_creating_errors    : {},
    fine_cats_empty              : {
                                        id: 0,
                                        name: "",
                                        organization: "",
                                        is_work: true
                                    }
};

const actions = {
    [FINE_CATS_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(FINE_CATS_CHANGE, state.fine_cats_empty);
            return;
        }

        return api.load(
            'fine_cats',
            id,
            state,
            commit,
            FINE_CATS_LOADING_CHANGE,
            FINE_CATS_CHANGE
        );
    },
    [FINE_CATS_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update(
            'fine_cats',
            id,
            model,
            state,
            commit,
            FINE_CATS_SAVING_CHANGE,
            FINE_CATS_CHANGE,
            FINE_CATS_ERRORS_CHANGE
        );
    },

    [FINE_CATS_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create(
            'fine_cats.list',
            id,
            model,
            state,
            commit,
            FINE_CATS_SAVING_CHANGE,
            FINE_CATS_CREATE_CHANGE,
            FINE_CATS_ERRORS_CHANGE
        );
    },

    [FINE_CATS_LOAD_DATATABLE] ({ commit, state }, filter) {
        if (!filter) {
            filter = {
                is_work: true
            };
        }
        return api.list(
            'fine_cats.list',
            0,
            filter,
            state,
            commit,
            FINE_CATS_DATATABLE_LOADING_CHANGE,
            FINE_CATS_DATATABLE_CHANGE
        );
    },
    [FINE_CATS_LOAD_BY_FINE_CATS]({ commit, state }, id) {
      if (!id) {
        id = 0;
      }

      return api.list(
        "fine_cats.by_fine_cat",
        id,
        null,
        state,
        commit,
        FINE_CATS_DATATABLE_LOADING_CHANGE,
        FINE_CATS_DATATABLE_CHANGE
      );
    }
};

const mutations = {
    [FINE_CATS_DATATABLE_LOADING_CHANGE](state, data) {
        state.fine_cats_list_loading = data;
    },
    [FINE_CATS_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.fine_cats_list          = data.data;
            state.fine_cats_list_total    = data.total;
        } else {
            state.fine_cats_list          = [];
            state.fine_cats_list_total    = 0;
        }

    },
    [FINE_CATS_CHANGE](state, data) {
        state.fine_cats  = data;
        if (data) {
            if (state.fine_cats_list) {
                let list = [];
                state.fine_cats_list.forEach(fine_cats => {
                    if (fine_cats.id == data.id) {
                        list.push({...data});
                    }
                    else {
                        list.push(fine_cats);
                    }
                });

                state.fine_cats_list = list;
            }
        }
    },
    [FINE_CATS_LOADING_CHANGE](state, data) {
        state.fine_cats_loading = data;
    },
    [FINE_CATS_SAVING_CHANGE](state, data) {
        state.fine_cats_saving   = data;

    },
    [FINE_CATS_ERRORS_CHANGE](state, data) {
        state.fine_cats_errors   = data;
    },
    [FINE_CATS_CREATING_CHANGE](state, data) {
        state.fine_cats_creating = data;
    },
    [FINE_CATS_CREATE_CHANGE](state, data) {
        if (data) {
            if (state.fine_cats_list) {
                state.fine_cats_list.unshift({...data});
            }

        }
    }
};

export default {
    state,
    actions,
    mutations
}
