import http from './../../lib/http';
import api from './../../lib/api';


export const ADDRESS_LOAD_DATATABLE               = 'ADDRESS_LOAD_DATATABLE';
export const WAREHOUSE_LOAD_DATATABLE             = 'WAREHOUSE_LOAD_DATATABLE';

const ADDRESS_DATATABLE_LOADING_CHANGE            = 'ADDRESS_DATATABLE_LOADING_CHANGE';
const ADDRESS_DATATABLE_CHANGE                    = 'ADDRESS_DATATABLE_CHANGE';

const WAREHOUSE_DATATABLE_LOADING_CHANGE          = 'WAREHOUSE_DATATABLE_LOADING_CHANGE';
const WAREHOUSE_DATATABLE_CHANGE                  = 'WAREHOUSE_DATATABLE_CHANGE';

const state = {
    address_list            : [],
    address_loading         : false,    

    address_list_total      : 0,     

    warehouse_list          : [],
    warehouse_loading       : false,    

    warehouse_list_total    : 0,     
};

const actions = {       

    [ADDRESS_LOAD_DATATABLE] ({ commit, state }, data) {       
        let params  = data.params;
        let id  = data.id ? data.id : null;
        return api.list(
            'get_address.list',
            id,
            params,
            state,
            commit,
            ADDRESS_DATATABLE_LOADING_CHANGE,
            ADDRESS_DATATABLE_CHANGE
        );                
    },
    [WAREHOUSE_LOAD_DATATABLE] ({ commit, state }, data) {       
        let params  = data.params;
        let id  = data.id ? data.id : null;
        return api.list(
            'get_warehouse.list',
            id,
            params,
            state,
            commit,
            WAREHOUSE_DATATABLE_LOADING_CHANGE,
            WAREHOUSE_DATATABLE_CHANGE
        );                
    }


};

const mutations = {
    
    
    [ADDRESS_DATATABLE_LOADING_CHANGE](state, data) {
        state.address_loading = data;
    },
    [ADDRESS_DATATABLE_CHANGE](state, data) {        
        if (data) {
            state.address_list          = data.data;
            state.address_list_total    = data.total;
        } else {
            state.address_list          = [];
            state.address_list_total    = 0;
        }
    },

    [WAREHOUSE_DATATABLE_LOADING_CHANGE](state, data) {
        state.warehouse_loading = data;
    },
    [WAREHOUSE_DATATABLE_CHANGE](state, data) {
        console.log(data);
        if (data) {
            state.warehouse_list          = data.data;
            state.warehouse_list_total    = data.total;
        } else {
            state.warehouse_list          = [];
            state.warehouse_list_total    = 0;
        }

    },


};

export default {
    state,
    actions,
    mutations
}
