import http from './../../lib/http';
import api from './../../lib/api';

export const PRODUCT_LOAD                 = 'PRODUCT_LOAD';
export const PRODUCT_UPDATE               = 'PRODUCT_UPDATE';
export const PRODUCT_CREATE               = 'PRODUCT_CREATE';
export const PRODUCT_DELETE               = 'PRODUCT_DELETE';

export const PRODUCT_LOAD_DATATABLE       = 'PRODUCT_LOAD_DATATABLE';
export const PRODUCT_LOAD_DATATABLE_SUGGEST       = 'PRODUCT_LOAD_DATATABLE_SUGGEST';

const PRODUCT_CHANGE                      = 'PRODUCT_CHANGE';
const PRODUCT_LOADING_CHANGE              = 'PRODUCT_LOADING_CHANGE';
const PRODUCT_SAVING_CHANGE               = 'PRODUCT_SAVING_CHANGE';
const PRODUCT_ERRORS_CHANGE               = 'PRODUCT_ERRORS_CHANGE';
const PRODUCT_DELETE_CHANGE               = 'PRODUCT_DELETE_CHANGE';
const PRODUCT_CREATE_CHANGE               = 'PRODUCT_CREATE_CHANGE';

const PRODUCT_CREATING_CHANGE             = 'PRODUCT_CREATING_CHANGE';

const PRODUCT_DATATABLE_CHANGE            = 'PRODUCT_DATATABLE_CHANGE';
const PRODUCT_DATATABLE_LOADING_CHANGE    = 'PRODUCT_DATATABLE_LOADING_CHANGE';

export const PRODUCT_UPLOAD_IMG           = 'PRODUCT_UPLOAD_IMG';
export const PRODUCT_DELETE_IMG           = 'PRODUCT_DELETE_IMG';
export const PRODUCT_UPDATE_MAIN_IMG      = 'PRODUCT_UPDATE_MAIN_IMG';


const state = {
    product            : null, 
    product_loading    : false,

    product_saving     : false,
    product_errors     : {},

    product_list_total    : 0,
    product_list           : null,
    product_list_loading   : false,

    product_creating           : false,
    product_creating_errors    : {},
    product_empty              : {
                                    id: 0,
                                    name: "",
                                    code_product: "",
                                    uniqued_import_id: "",
                                    id_organization: 0,
                                    cat_id: 0,
                                    article: "",
                                    img: "",
                                    parent_project: "",
                                    parent_site: "",
                                    price_cost: 0,
                                    price_online: 0,
                                    price_prime: 0,
                                    weight: "",
                                    desc: "",
                                    script: "",
                                    basic_unit: "",
                                    nabor: 0,
                                    service: 0,
                                    complect: 0,
                                    basic_unit_seat: "",
                                    is_work: 0,
                                    organization_id: {value: "", text: "Не выбрано"},
                                    category_id: {value: "", text: "Не выбрано"}
                                }
};

const actions = {
    [PRODUCT_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(PRODUCT_CHANGE, state.product_empty);

            return;
        }

        return api.load(
            'product',
            id,
            state,
            commit,
            PRODUCT_LOADING_CHANGE,
            PRODUCT_CHANGE
        );
    },

    [PRODUCT_UPDATE] ({ commit, state }, {id, data}) {

        return api.create(
            'product',
            id,
            data,
            state,
            commit,
            PRODUCT_SAVING_CHANGE,
            PRODUCT_CHANGE,
            PRODUCT_ERRORS_CHANGE
        );
    },

    [PRODUCT_CREATE] ({ commit, state }, data) {
        let id      = data.id ? data.id : 0;

        return api.create(
            'product.list',
            id,
            data,
            state,
            commit,
            PRODUCT_SAVING_CHANGE,
            PRODUCT_CREATE_CHANGE,
            PRODUCT_ERRORS_CHANGE
        );
    },

    [PRODUCT_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'product',
            id,
            state,
            commit,
            PRODUCT_DELETE_CHANGE
        );
    },

    [PRODUCT_LOAD_DATATABLE] ({ commit, state }, data) {       
        let params  = data.params;
        let id  = data.id ? data.id : null;
        return api.list(
            'product.list',
            id,
            params,
            state,
            commit,
            PRODUCT_DATATABLE_LOADING_CHANGE,
            PRODUCT_DATATABLE_CHANGE
        );                
    },

    [PRODUCT_LOAD_DATATABLE_SUGGEST] ({ commit, state }, data) {       
        let params  = data.params;
        let id  = data.id ? data.id : null;
        return api.list(
            'product.suggest',
            id,
            params,
            state,
            commit,
            PRODUCT_DATATABLE_LOADING_CHANGE,
            PRODUCT_DATATABLE_CHANGE
        );                
    },

    [PRODUCT_UPLOAD_IMG] ({ commit, state }, data) {
        let id      = data.id;

        let formData = new FormData();
        data.product_images.forEach(function (image, i) {
            formData.append('product_images['+i+"]", image);
        });
        return api.create(
            'product.img',
            id,
            formData,
            state,
            commit,
            PRODUCT_SAVING_CHANGE,
            PRODUCT_CREATE_CHANGE,
            PRODUCT_ERRORS_CHANGE
        );
    },
    [PRODUCT_DELETE_IMG] ({ commit, state }, id) {
        if (!id) {
            id = 0
        }

        return api.delete(
            'product.img.delete',
            id,
            state,
            commit,
            PRODUCT_DELETE_CHANGE
        );
    },
    [PRODUCT_UPDATE_MAIN_IMG] ({ commit, state }, id) {
        let model   = '';

        return api.update(
            'product.img.set_main',
            id,
            model,
            state,
            commit,
            PRODUCT_SAVING_CHANGE,
            PRODUCT_CHANGE,
            PRODUCT_ERRORS_CHANGE
        );
    },
};

const mutations = {
    [PRODUCT_DATATABLE_LOADING_CHANGE](state, data) {
        state.product_list_loading = data;
    },
    [PRODUCT_DATATABLE_CHANGE](state, data) {

        if (data) {
            state.product_list          = data.data;
            state.product_list_total    = data.total;
        } else {
            state.product_list          = [];
            state.product_list_total    = 0;
        }

    },
    [PRODUCT_CHANGE](state, data) {
        state.product  = data;

        if (data) {
            if (state.product_list) {
                let list = [];
                state.product_list.forEach(product => {
                    if (product.id == data.id) {
                        list.push({...data});
                    }
                    else {
                        list.push(product);
                    }
                });

                state.product_list = list;
            }
        }
    },
    [PRODUCT_LOADING_CHANGE](state, data) {
        state.product_loading = data;
    },
    [PRODUCT_SAVING_CHANGE](state, data) {
        state.product_saving   = data;
        
    },
    [PRODUCT_ERRORS_CHANGE](state, data) {
        state.product_errors   = data;
    },
    [PRODUCT_CREATING_CHANGE](state, data) {
        state.product_creating = data;
    },
    [PRODUCT_DELETE_CHANGE](state, id) {
        if (id) {
            if (state.product_list) {

                let list = [];
                state.product_list.forEach(product => {
                    if (product.id !== id) {
                        list.push({...product});
                    }
                });

                state.product_list = list;
            }
        }
    },
    [PRODUCT_CREATE_CHANGE](state, data) {
        if (data) {
            if (state.product_list) {
                state.product_list.unshift({...data});
            }

        }
    }
};

export default {
    state,
    actions,
    mutations
}