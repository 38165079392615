import api from './../../lib/api';

export const CHAT_BOT_SETUP_LOAD                 = 'CHAT_BOT_SETUP_LOAD';
export const CHAT_BOT_SETUP_SAVE                 = 'CHAT_BOT_SETUP_SAVE';
export const CHAT_BOT_SETUP_LOAD_DATATABLE       = 'CHAT_BOT_SETUP_LOAD_DATATABLE';

const CHAT_BOT_SETUP_CHANGE                      = 'CHAT_BOT_SETUP_CHANGE';
export const CHAT_BOT_SETUP_LOADING_CHANGE       = 'CHAT_BOT_SETUP_LOADING_CHANGE';
const CHAT_BOT_SETUP_SAVING_CHANGE               = 'CHAT_BOT_SETUP_SAVING_CHANGE';
const CHAT_BOT_SETUP_ERRORS_CHANGE               = 'CHAT_BOT_SETUP_ERRORS_CHANGE';

const CHAT_BOT_SETUP_DATATABLE_CHANGE            = 'CHAT_BOT_SETUP_DATATABLE_CHANGE';
const CHAT_BOT_SETUP_DATATABLE_LOADING_CHANGE    = 'CHAT_BOT_SETUP_DATATABLE_LOADING_CHANGE';

// Группы
export const CHAT_BOT_SETUP_GROUP_LOAD_DATATABLE       = 'CHAT_BOT_SETUP_GROUP_LOAD_DATATABLE';
export const CHAT_BOT_SETUP_GROUP_LOAD                 = 'CHAT_BOT_SETUP_GROUP_LOAD';
export const CHAT_BOT_SETUP_GROUP_SAVE                 = 'CHAT_BOT_SETUP_GROUP_SAVE';
const CHAT_BOT_SETUP_GROUP_DATATABLE_CHANGE            = 'CHAT_BOT_SETUP_GROUP_DATATABLE_CHANGE';
const CHAT_BOT_SETUP_GROUP_DATATABLE_LOADING_CHANGE    = 'CHAT_BOT_SETUP_GROUP_DATATABLE_LOADING_CHANGE';
const CHAT_BOT_SETUP_GROUP_LOADING_CHANGE              = 'CHAT_BOT_SETUP_GROUP_LOADING_CHANGE';
const CHAT_BOT_SETUP_GROUP_CHANGE                      = 'CHAT_BOT_SETUP_GROUP_CHANGE';
const CHAT_BOT_SETUP_GROUP_SAVING_CHANGE               = 'CHAT_BOT_SETUP_GROUP_SAVING_CHANGE';
const CHAT_BOT_SETUP_GROUP_ERRORS_CHANGE               = 'CHAT_BOT_SETUP_GROUP_ERRORS_CHANGE';

export const CHAT_BOT_SETUP_GROUP_LIST_LOAD_DATATABLE      = 'CHAT_BOT_SETUP_GROUP_LIST_LOAD_DATATABLE';
const CHAT_BOT_SETUP_GROUP_LIST_DATATABLE_LOADING          = 'CHAT_BOT_SETUP_GROUP_LIST_DATATABLE_LOADING';
const CHAT_BOT_SETUP_GROUP_LIST_DATATABLE                  = 'CHAT_BOT_SETUP_GROUP_LIST_DATATABLE';

const state = {
  chat_bot_setup            : null,
  chat_bot_setup_loading    : false,

  chat_bot_setup_saving     : false,
  chat_bot_setup_errors     : {},

  chat_bot_setup_list_total    : 0,
  chat_bot_setup_list          : null,
  chat_bot_setup_list_loading  : false,

  chat_bot_setup_empty : {
    name: '',
    channel_type: null,
    is_work: false,
    parameters: {},
    geos: [],
  },
  ats_chat_bot_setups_empty : {
    info: null,
    ats_group_id: null,
    setups: [],
  },
  chat_bot_setup_parameters_empty: {
    api_key: '',
    phone: '',
    delay: 0,
    day_limit: 1000,
  },

  // Группы
  chat_bot_setup_group_saving     : false,
  chat_bot_setup_group_errors     : {},
  chat_bot_setup_group_empty : {
    title: '',
    parent_id: null,
    childs: [],
    chat_bot_setups: [],
    organizations: [],
    is_work: true
  },
  chat_bot_setup_group            : null,
  chat_bot_setup_group_loading    : false,
  chat_bot_setup_group_list_loading  : false,
  chat_bot_setup_group_list_total    : 0,
  chat_bot_setup_group_list          : null,
  chat_bot_setup_group_list_select            : null,
  chat_bot_setup_group_list_select_loading    : false,
};

// Дефолтные значения в параметрах аккаунтов
// Если добавится новый параметр, то при открытии настроек подставится дефолтное значение
const defaultParams = {
  // Для всех каналов
  common: state.chat_bot_setup_parameters_empty,
  byChannel: { // Индивидуально по типу канала
    '1': { // telegram
    },
    '2': { // 360dialog
    },
    '3': { // ultramsg
    },
    '4': { // vonage
    },
  },
};

/**
 * Добавит в объект object свойство property со значением defaultValue,
 * если такое свойство отсутствует в объекте
 *
 * @param  {Object} object
 * @param  {String} property
 * @param  {*}      defaultValue
 */
const propertyCheckOrSet = (object, property, defaultValue) => {
  if (object && !Object.prototype.hasOwnProperty.call(object, property)) {
    object[property] = defaultValue;
  }
};

const actions = {
  [CHAT_BOT_SETUP_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(CHAT_BOT_SETUP_CHANGE, _.cloneDeep(state.ats_chat_bot_setups_empty));
      return;
    }
    return api.load(
      'chat_bot_setup',
      id,
      state,
      commit,
      CHAT_BOT_SETUP_LOADING_CHANGE,
      CHAT_BOT_SETUP_CHANGE
    );
  },
  [CHAT_BOT_SETUP_SAVE] ({ commit, state }, data) {
    if (data.type !== 'new') {
      return api.update(
        'chat_bot_setup',
        data.id,
        data,
        state,
        commit,
        CHAT_BOT_SETUP_SAVING_CHANGE,
        CHAT_BOT_SETUP_CHANGE,
        CHAT_BOT_SETUP_ERRORS_CHANGE
      );
    } else {
      return api.create(
        'chat_bot_setup',
        data.id,
        data,
        state,
        commit,
        CHAT_BOT_SETUP_SAVING_CHANGE,
        CHAT_BOT_SETUP_CHANGE,
        CHAT_BOT_SETUP_ERRORS_CHANGE
      );
    }
  },
  [CHAT_BOT_SETUP_LOAD_DATATABLE] ({ commit, state }, data) {
    return api.list(
      'chat_bot_setups',
      0,
      data,
      state,
      commit,
      CHAT_BOT_SETUP_DATATABLE_LOADING_CHANGE,
      CHAT_BOT_SETUP_DATATABLE_CHANGE
    );
  },

  // Группы
  [CHAT_BOT_SETUP_GROUP_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(CHAT_BOT_SETUP_GROUP_CHANGE, _.cloneDeep(state.chat_bot_setup_group_empty));
      return;
    }
    return api.load(
      'chat_bot_setup_group',
      id,
      state,
      commit,
      CHAT_BOT_SETUP_GROUP_LOADING_CHANGE,
      CHAT_BOT_SETUP_GROUP_CHANGE,
      CHAT_BOT_SETUP_GROUP_ERRORS_CHANGE
    );
  },
  [CHAT_BOT_SETUP_GROUP_SAVE]({ commit, state }, data) {
      return api.create('chat_bot_setup_group', data.id, data.model, state, commit,
        CHAT_BOT_SETUP_GROUP_SAVING_CHANGE,
        CHAT_BOT_SETUP_GROUP_CHANGE,
        CHAT_BOT_SETUP_GROUP_ERRORS_CHANGE
      );
    },
  [CHAT_BOT_SETUP_GROUP_LOAD_DATATABLE] ({ commit, state }, data) {
    return api.list(
      'chat_bot_setup_groups',
      0,
      data,
      state,
      commit,
      CHAT_BOT_SETUP_GROUP_DATATABLE_LOADING_CHANGE,
      CHAT_BOT_SETUP_GROUP_DATATABLE_CHANGE
    );
  },
  [CHAT_BOT_SETUP_GROUP_LIST_LOAD_DATATABLE] ({ commit, state }, data) {
    return api.list(
      'chat_bot_setup_group_list_select',
      0,
      data,
      state,
      commit,
      CHAT_BOT_SETUP_GROUP_LIST_DATATABLE_LOADING,
      CHAT_BOT_SETUP_GROUP_LIST_DATATABLE
    );
  },
};

const mutations = {
  [CHAT_BOT_SETUP_DATATABLE_LOADING_CHANGE](state, data) {
    state.chat_bot_setup_list_loading = data;
  },
  [CHAT_BOT_SETUP_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.chat_bot_setup_list          = data.data;
      state.chat_bot_setup_list_total    = data.total;
    } else {
      state.chat_bot_setup_list          = [];
      state.chat_bot_setup_list_total    = 0;
    }
  },
  [CHAT_BOT_SETUP_CHANGE](state, data) {

    // Проверка обязательных параметров
    if (data) {
      for (let { channel_type, parameters } of data.setups) {

        // Для всех каналов
        for (let param in defaultParams.common) {
          propertyCheckOrSet(parameters, param, defaultParams.common[param]);
        }

        // Индивидуально по типу канала
        for (let param in defaultParams.byChannel[channel_type]) {
          propertyCheckOrSet(parameters, param, defaultParams.byChannel[channel_type][param]);
        }
      }
      state.chat_bot_setup = data;
    } else {
      state.chat_bot_setup = _.cloneDeep(state.ats_chat_bot_setups_empty);
    }
  },
  [CHAT_BOT_SETUP_LOADING_CHANGE](state, data) {
    state.chat_bot_setup_loading = data;
  },
  [CHAT_BOT_SETUP_SAVING_CHANGE](state, data) {
    state.chat_bot_setup_saving   = data;
  },
  [CHAT_BOT_SETUP_ERRORS_CHANGE](state, data) {
    state.chat_bot_setup_errors   = data;
  },

  // Группы
  [CHAT_BOT_SETUP_GROUP_ERRORS_CHANGE](state, data) {
    state.chat_bot_setup_group_errors   = data;
  },
  [CHAT_BOT_SETUP_GROUP_SAVING_CHANGE](state, data) {
    state.chat_bot_setup_group_saving   = data;
  },
  [CHAT_BOT_SETUP_GROUP_LOADING_CHANGE](state, data) {
    state.chat_bot_setup_group_loading = data;
  },
  [CHAT_BOT_SETUP_GROUP_CHANGE](state, data) {
    state.chat_bot_setup_group = data;
  },
  [CHAT_BOT_SETUP_GROUP_DATATABLE_LOADING_CHANGE](state, data) {
    state.chat_bot_setup_group_list_loading = data;
  },
  [CHAT_BOT_SETUP_GROUP_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.chat_bot_setup_group_list          = data.data;
      state.chat_bot_setup_group_list_total    = data.total;
    } else {
      state.chat_bot_setup_group_list          = [];
      state.chat_bot_setup_group_list_total    = 0;
    }
  },

  [CHAT_BOT_SETUP_GROUP_LIST_DATATABLE](state, data) {
    if (data) {
      state.chat_bot_setup_group_list_select          = data.data;
      state.chat_bot_setup_group_list_select_total    = data.total;
    } else {
      state.chat_bot_setup_group_list_select          = [];
      state.chat_bot_setup_group_list_select_total    = 0;
    }
  },
  [CHAT_BOT_SETUP_GROUP_LIST_DATATABLE_LOADING](state, data) {
    state.chat_bot_setup_group_list_select_loading = data;
  },
};

export default {
  state,
  actions,
  mutations
}
