import http from "./../../lib/http";
import api from "./../../lib/api";

export const SALES_REPORT_ORDERS_LOAD_DATATABLE     = "SALES_REPORT_ORDERS_LOAD_DATATABLE";
const SALES_REPORT_ORDERS_DATATABLE_LOADING_CHANGE  = "SALES_REPORT_ORDERS_DATATABLE_LOADING_CHANGE";
const SALES_REPORT_ORDERS_DATATABLE_CHANGE          = "SALES_REPORT_ORDERS_DATATABLE_CHANGE";



const state = {
  sales_report_orders_list_total     : 0,
  sales_report_orders_list           : null,
  sales_report_orders_loading        : false,
};

const actions = {
  [SALES_REPORT_ORDERS_LOAD_DATATABLE] ({ commit, state }, data) {       
        
        let params  = data.params;
        let id  = data.id ? data.id : null;
        
        return api.list(
            'sales_report.orders_list',
            id,
            params,
            state,
            commit,
            SALES_REPORT_ORDERS_DATATABLE_LOADING_CHANGE,
            SALES_REPORT_ORDERS_DATATABLE_CHANGE
        );                
    },
};

const mutations = {
  [SALES_REPORT_ORDERS_DATATABLE_LOADING_CHANGE](state, data) {
        state.sales_report_orders_loading = data;
    },
  [SALES_REPORT_ORDERS_DATATABLE_CHANGE](state, data) {
        if (data) {          
            state.sales_report_orders_list          = data.data;
            state.sales_report_orders_list_total    = data.total;
        } else {
            state.sales_report_orders_list          = [];
            state.sales_report_orders_list_total    = 0;
        }

    },
};

export default {
  state,
  actions,
  mutations
};
