import http from './../../lib/http';
import api from './../../lib/api';

export const CURRENCY_LIST                 = 'CURRENCY_LIST';

export const CURRENCY_LOAD                 = 'CURRENCY_LOAD';
export const CURRENCY_UPDATE               = 'CURRENCY_UPDATE';
export const CURRENCY_CREATE               = 'CURRENCY_CREATE';

export const CURRENCY_LOAD_DATATABLE       = 'CURRENCY_LOAD_DATATABLE';

export const CURRENCY_CHANGE               = 'CURRENCY_CHANGE';
const CURRENCY_LOADING_CHANGE              = 'CURRENCY_LOADING_CHANGE';
const CURRENCY_SAVING_CHANGE               = 'CURRENCY_SAVING_CHANGE';
const CURRENCY_ERRORS_CHANGE               = 'CURRENCY_ERRORS_CHANGE';

const CURRENCY_CREATING_CHANGE             = 'CURRENCY_CREATING_CHANGE';

const CURRENCY_DATATABLE_CHANGE            = 'CURRENCY_DATATABLE_CHANGE';
const CURRENCY_DATATABLE_LOADING_CHANGE    = 'CURRENCY_DATATABLE_LOADING_CHANGE';

export const CURRENCY_DELETE		  = 'CURRENCY_DELETE';
export const CURRENCY_DELETE_CHANGE  	  = 'CURRENCY_DELETE_CHANGE';

const state = {
    currency            : null,
    currency_loading    : false,
    currency_saving     : false,
    currency_errors     : {},

    currency_list_total     : 0, 
    currency_list           : null,
    currency_list_loading   : false,

    currency_creating           : false,
    currency_creating_errors    : {},
    currency_empty              : {
                                  id: 0,
                                  name: ""
                              }
};

const actions = {
    [CURRENCY_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(CURRENCY_CHANGE, state.currency_empty);

            return;
        }

        return api.load('currency', id, state, commit, CURRENCY_LOADING_CHANGE, CURRENCY_CHANGE);
    },

    [CURRENCY_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('currency', id, model, state, commit, CURRENCY_SAVING_CHANGE, CURRENCY_CHANGE, CURRENCY_ERRORS_CHANGE);
    },

    [CURRENCY_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('currency.list', id, model, state, commit, CURRENCY_CREATING_CHANGE, CURRENCY_CHANGE, CURRENCY_ERRORS_CHANGE);
    },

    [CURRENCY_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'currency.list', 
            id, 
            null,
            state, 
            commit, 
            CURRENCY_DATATABLE_LOADING_CHANGE, 
            CURRENCY_DATATABLE_CHANGE);
    },
    [CURRENCY_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'currency',
            id,
            state,
            commit,
            CURRENCY_DELETE_CHANGE
        );
    },
};

const mutations = {
    [CURRENCY_DATATABLE_LOADING_CHANGE](state, data) {
        state.currency_list_loading = data;
    },
    [CURRENCY_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.currency_list        = data.data;
            state.currency_list_total  = data.total;
        }
        else {
            state.currency_list        = [];
            state.currency_list_total  = 0;
        }
    },
    [CURRENCY_CHANGE](state, data) {
        state.currency  = data;
	state.currency_empty = {id:0, name:null};
    },
    [CURRENCY_LOADING_CHANGE](state, data) {
        state.currency_loading = data;
    },
    [CURRENCY_SAVING_CHANGE](state, data) {
        state.currency_saving   = data;
    },
    [CURRENCY_ERRORS_CHANGE](state, data) {
        state.currency_errors   = data;
    },
    [CURRENCY_CREATING_CHANGE](state, data) {
        state.currency_creating = data;
    },
    [CURRENCY_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.currency_list) {

                let list = [];
                state.currency_list.forEach(currency => {
                    if (currency.id !== id) {
                        list.push({...currency});
                    }
                });

                state.currency_list = list;
            }
        }
    },
};

export default {
    state,
    actions,
    mutations
}
