import axios from "axios";
import Vue from "vue";
import VueRouter from "./../routes.js";
import log from "./log.js";

import { USER_LOGOUT } from "./../store/modules/users";

let serialize = function(obj, prefix) {
  let str = [],
    p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];

      if (v !== null && typeof v === "object") {
        str.push(serialize(v, k));
      } else {
        if (v !== null) {
          str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
    }
  }

  return str.join("&");
};

const http = {
  token: null,
  store: null,

  setStore(store) {
    this.store = store;
  },
  setToken(token) {
    this.token = token;
  },

  request(method, url, params, options) {
    let config = {
      method: method,
      ...options
    };

    if (method == "get") {
      url = url + "?" + serialize(params);
    }

    if (method != "get") {
      config.data = params;
    }

    config.url = url;

    if (this.token) {
      config.headers = {
        Authorization: "bearer " + this.token
      };
    }

    let request = axios(config).catch(error => {

      if (error.response && error.response.status == 401) {
        this.store.dispatch(USER_LOGOUT);
        return false;
      }

      if (error.response && error.response.status == 403) {
        const tmpLang = this.store.state.users.auth_user.lang;
        const localGetTranslate = require(`./../langs/http/${tmpLang}.json`);
        Vue.notify({
          group: "app",
          type: "error",
          title: localGetTranslate.access_error,
          text: localGetTranslate.no_access_please,
          duration: 10000
        });
      }

      if (error.response && error.response.status == 404) {
        log.notFound(error.response)
        let match = VueRouter.app.$route.path
        if (error.response.config.url[error.response.config.url.length - 1] == '?') {
            match += '?'
        }
        let currPath = error.response.config.url.substring(error.response.config.url.length - match.length)
        if(match == currPath) {
            VueRouter.push('/404')
        }
      }

      if (error.response && error.response.data) {
        throw error.response.data;
      } else {
        throw error;
      }
    });
    return request;
  },

  get(url, params, options) {
    return this.request("get", url, params, options);
  },
  post(url, params, options) {
    return this.request("post", url, params, options);
  },
  put(url, params, options) {
    return this.request("put", url, params, options);
  },
  delete(url, params, options) {
    return this.request("delete", url, params, options);
  },
  cancel() {
    let cancelToken = axios.CancelToken;
    let source = cancelToken.source();

    return source;
  },
  all(promises) {
    let promise = axios.all(promises).then(
      axios.spread(function(acct, perms) {
        return true;
      })
    );

    return promise;
  },
  /**
   * Получение текущего выбранного языка системы (метод сделан для api.js)
   * @return {string} текущий выбранный язык системы
   * @author SeregaHelf
   */
  getLang() {
    return this.store.state.users.auth_user.lang;
  },
};

export default http;
