import http from './../../lib/http';
import api from './../../lib/api';
import VueRouter from "./../../routes.js";

export const FINES_LOAD_DATATABLE       = 'FINES_LOAD_DATATABLE';
const FINES_DATATABLE_CHANGE            = 'FINES_DATATABLE_CHANGE';
const FINES_DATATABLE_LOADING_CHANGE    = 'FINES_LOADING_CHANGE';

export const FINES_UPDATE               = 'FINES_UPDATE';
export const FINES_CREATE               = 'FINES_CREATE';

const FINES_SAVING_CHANGE               = 'FINES_SAVING_CHANGE';
const FINES_ERRORS_CHANGE               = 'FINES_ERRORS_CHANGE';
const FINES_CREATE_CHANGE               = 'FINES_CREATE_CHANGE';

export const FINES_LOAD_BY_FINE         = 'FINES_LOAD_BY_FINE';
const FINE_CHANGE                       = 'FINE_CHANGE';

export const BY_USERS_LOAD              = 'BY_USERS_LOAD';
const BY_USERS_LOADING_CHANGE           = 'BY_USERS_LOADING_CHANGE';
const BY_USERS_CHANGE                   = 'BY_USERS_CHANGE';

const BY_FINE_LOADING_CHANGE           = 'BY_FINE_LOADING_CHANGE';
const BY_FINE_CHANGE                   = 'BY_FINE_CHANGE';

const state = {
    fines            : null,
    fines_list_total    : 0,
    fines_list           : null,
    // fines_list_loading   : false,

    fines_saving     : false,
    fines_errors     : {},

    fines_creating           : false,
    fines_creating_errors    : {},
    fines_empty              : {
                                    id: 0,
                                    comment: {}
                                },
    by_users : null,
    by_users_loading:null,

    by_fine : null,
    by_fine_loading:null,
};

const actions = {
    [FINES_LOAD_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'fines.list',
            0,
            data,
            state,
            commit,
            FINES_DATATABLE_LOADING_CHANGE,
            FINES_DATATABLE_CHANGE
        );
    },
    [FINES_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update(
            'fines',
            id,
            model,
            state,
            commit,
            FINES_SAVING_CHANGE,
            FINE_CHANGE,
            FINES_ERRORS_CHANGE
        );
    },
    [FINES_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create(
            'fines.list',
            id,
            model,
            state,
            commit,
            FINES_SAVING_CHANGE,
            FINES_CREATE_CHANGE,
            FINES_ERRORS_CHANGE
        );
    },
    [FINES_LOAD_BY_FINE] ({ commit, state }, id) {
        if(!id) {
            commit(FINE_CHANGE, state.fines_empty);
            return;
        }
        return api.load(
            'fines',
            id,
            state,
            commit,
            BY_FINE_LOADING_CHANGE,
            BY_FINE_CHANGE
        );
    },
    [BY_USERS_LOAD] ({ commit, state }, data) {
        return api.list(
            'fines.by_users',
            0,
            data,
            state,
            commit,
            BY_USERS_LOADING_CHANGE,
            BY_USERS_CHANGE
        );
    }
};

const mutations = {
    [FINES_DATATABLE_LOADING_CHANGE](state, data) {
        state.fines_list_loading = data;
    },
    [FINES_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.fines_list          = data.data;
            state.fines_list_total    = data.total;
        } else {
            state.fines_list          = [];
            state.fines_list_total    = 0;
        }

    },
    [FINES_CREATE_CHANGE](state, data) {
        if (data) {
            VueRouter.push("/fines/" + data.id);
        }
    },
    [FINES_SAVING_CHANGE](state, data) {
        state.fines_saving   = data;
    },
    [FINES_ERRORS_CHANGE](state, data) {
        state.fines_errors   = data;
    },
    [FINE_CHANGE](state, data) {
        state.fine  = data;

        if (data) {
            if (state.fines_list) {
                let list = [];
                state.fines_list.forEach(fine => {
                    if (fine.id == data.id) {
                        list.push({...data});
                    }
                    else {
                        list.push(fine);
                    }
                });

                state.fines_list = list;
            }
        }
    },
    [BY_USERS_LOADING_CHANGE] (state, data) {
        state.by_users_loading = data;
    },
    [BY_USERS_CHANGE] (state, data) {
        if(data) {
            state.by_users = data.data;
        }
        else {
            state.by_users = [];
        }
    },
    [BY_FINE_LOADING_CHANGE] (state, data) {
        state.by_fine_loading = data;
    },
    [BY_FINE_CHANGE] (state, data) {
        if(data) {
            state.by_fine = data;
        }
        else {
            state.by_fine = [];
        }
    }
};

export default {
    state,
    actions,
    mutations
}
