import http from './../../lib/http';
import api from './../../lib/api';

export const TARIFF_SMS_ORGANIZATION_LOAD      = 'TARIFF_SMS_ORGANIZATION_LOAD';
export const TARIFF_SMS_ORGANIZATION_SAVE      = 'TARIFF_SMS_ORGANIZATION_SAVE';
export const TARIFF_SMS_ORGANIZATION_DATATABLE = 'TARIFF_SMS_ORGANIZATION_DATATABLE';
export const TARIFF_SMS_ORGANIZATION_SET_EMPTY = 'TARIFF_SMS_ORGANIZATION_SET_EMPTY';
export const TARIFF_SMS_PROVIDER_LOAD_DATATABLE = 'TARIFF_SMS_PROVIDER_LOAD_DATATABLE';

const TARIFF_SMS_ORGANIZATION_LOADING_CHANGE           = 'TARIFF_SMS_ORGANIZATION_LOADING_CHANGE';
const TARIFF_SMS_ORGANIZATION_CHANGE                   = 'TARIFF_SMS_ORGANIZATION_CHANGE';
const ERRORS_TARIFF_SMS_ORGANIZATION_LOAD              = 'ERRORS_TARIFF_SMS_ORGANIZATION_LOAD';
const LOADING                                      = 'LOADING';
const LOADED_UPDATE                                = 'LOADED_UPDATE';
const ERRORS_UPDATE                                = 'ERRORS_UPDATE';
const TARIFF_SMS_ORGANIZATION_DATATABLE_LOADING_CHANGE = 'TARIFF_SMS_ORGANIZATION_DATATABLE_LOADING_CHANGE';
const TARIFF_SMS_ORGANIZATION_DATATABLE_CHANGE         = 'TARIFF_SMS_ORGANIZATION_DATATABLE_CHANGE';
const SET_EMPTY                                    = 'SET_EMPTY';
const TARIFF_SMS_PROVIDER_DATATABLE_LOADING_CHANGE                                    = 'TARIFF_SMS_PROVIDER_DATATABLE_LOADING_CHANGE';
const TARIFF_SMS_PROVIDER_DATATABLE_CHANGE                                    = 'TARIFF_SMS_PROVIDER_DATATABLE_CHANGE';




const tariff_sms_empty = () => {
    return {
        info: null,
        commission: null,
        sms_provider: [],
        tariffs_sms: []
    }
}

const state = {
    tariff_sms                     : tariff_sms_empty(),
    tariff_sms_loading             : {},
    tariff_sms_errors              : {},
    tariff_sms_loading             : {},
    tariff_sms_list_loading        : false,
    tariff_sms_list                : null,
    tariff_sms_list_total          : 0,
    tariff_sms_provider_list       : {}
};

const actions = {
    [TARIFF_SMS_ORGANIZATION_LOAD] ({ commit, state }, id) {
        return api.load(
            'tariff_sms.organization',
            id,
            state,
            commit,
            TARIFF_SMS_ORGANIZATION_LOADING_CHANGE,
            TARIFF_SMS_ORGANIZATION_CHANGE,
            ERRORS_TARIFF_SMS_ORGANIZATION_LOAD
        );
    },
    [TARIFF_SMS_ORGANIZATION_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'tariff_sms.organization.list',
            0,
            data,
            state,
            commit,
            TARIFF_SMS_ORGANIZATION_DATATABLE_LOADING_CHANGE,
            TARIFF_SMS_ORGANIZATION_DATATABLE_CHANGE);
    },
    [TARIFF_SMS_ORGANIZATION_SAVE]({ commit, state }, data) {
        if (data.type != "new") {
            return api.update('tariff_sms.organization.update', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        } else {
            return api.create('tariff_sms.organization.create', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        }
    },
    [TARIFF_SMS_PROVIDER_LOAD_DATATABLE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }
        return api.list(
            'tariff_sms.sms_provider.list',
            id,
            null,
            state,
            commit,
            TARIFF_SMS_PROVIDER_DATATABLE_LOADING_CHANGE,
            TARIFF_SMS_PROVIDER_DATATABLE_CHANGE);
    },
    [TARIFF_SMS_ORGANIZATION_SET_EMPTY]({ commit, state }) {
        commit(SET_EMPTY);
    },
};

const mutations = {
    [TARIFF_SMS_ORGANIZATION_LOADING_CHANGE] (state, data) {
        state.tariff_sms_loading = data;
    },
    [TARIFF_SMS_ORGANIZATION_CHANGE] (state, data) {
        if(data){
            state.tariff_sms = data;
        }
    },
    [ERRORS_TARIFF_SMS_ORGANIZATION_LOAD](state, data) {
        state.tariff_sms_errors = data;
    },
    [LOADING](state, data) {
        state.tariff_sms_loading        = data;
    },
    [LOADED_UPDATE](state, data) {
        if (data) {
            state.tariff_sms_organization = data;
        }
    },
    [ERRORS_UPDATE](state, data) {
        state.tariff_sms_errors = data;
    },
    [TARIFF_SMS_ORGANIZATION_DATATABLE_LOADING_CHANGE](state, data) {
        state.tariff_sms_list_loading = data;
    },
    [TARIFF_SMS_ORGANIZATION_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.tariff_sms_list        = data.data;
            state.tariff_sms_list_total  = data.total;
        }
        else {
            state.tariff_sms_list        = [];
            state.tariff_sms_list_total  = 0;
        }
    },
    [TARIFF_SMS_PROVIDER_DATATABLE_LOADING_CHANGE] (state, data) {
        state.tariff_sms_provider_list_loading = data;
    },
    [TARIFF_SMS_PROVIDER_DATATABLE_CHANGE] (state, data) {
        if(data) {
            state.tariff_sms_provider_list        = data.data;
        }
        else {
            state.tariff_sms_provider_list        = [];
        }
    },
    [SET_EMPTY](state) {
        state.tariff_sms = tariff_sms_empty();
    },
};

export default {
    state,
    actions,
    mutations
}
