import http from "./../../lib/http";
import api from "./../../lib/api";

export const TEL_CHECK_CREATE   = "TEL_CHECK_CREATE";
const TEL_CHECK_CREATING_CHANGE = "TEL_CHECK_CREATING_CHANGE";
const TEL_CHECK_CHANGE          = "TEL_CHECK_CHANGE";
const TEL_CHECK_ERRORS_CHANGE   = "TEL_CHECK_ERRORS_CHANGE";

export const TEL_CHECK_LOAD          = "TEL_CHECK_LOAD";
const TEL_CHECK_LOAD_CHANGE          = "TEL_CHECK_LOAD_CHANGE";
const TEL_CHECK_LOAD_C               = "TEL_CHECK_LOAD_C";
const TEL_CHECK_LOAD_ERRORS_CHANGE   = "TEL_CHECK_LOAD_ERRORS_CHANGE";

export const REPORT_BY_CALLS_LOAD    = "REPORT_BY_CALLS_LOAD";
const REPORT_BY_CALLS_LOADING_CHANGE = "REPORT_BY_CALLS_LOADING_CHANGE";
const REPORT_BY_CALLS_CHANGE         = "REPORT_BY_CALLS_CHANGE";

export const GET_CALLS_STATE         = "GET_CALLS_STATE";

const state = {
  tel_check: null,
  tel_check_errors: {},
  tel_check_creating:false,
  tel_check_orders: null,
  tel_check_order_errors: {},
  tel_check_order_c:false,
  report_calls_list_total    : 0,
  report_calls           : null,
  report_calls_loading   : false
};

const actions = {
  [TEL_CHECK_CREATE]({ commit, state }, data) {
    let model = data.model;
    
    return api.create(
      "send_tel_check",
      0,
      model,
      state,
      commit,
      TEL_CHECK_CREATING_CHANGE,
      TEL_CHECK_CHANGE,
      TEL_CHECK_ERRORS_CHANGE
    );
  },
  [TEL_CHECK_LOAD]({ commit, state }, data) {
      return api.create(
        "tel_check_order",
        0,
        data,
        state,
        commit,
        TEL_CHECK_LOAD_C,
        TEL_CHECK_LOAD_CHANGE,
        TEL_CHECK_LOAD_ERRORS_CHANGE
      );
  },
  [REPORT_BY_CALLS_LOAD] ({ commit, state }, data) {       
      return api.list(
          'report_by_calls',
          null,
          data,
          state,
          commit,
          REPORT_BY_CALLS_LOADING_CHANGE,
          REPORT_BY_CALLS_CHANGE
      );                
  },
  [GET_CALLS_STATE] ({ commit, state }, data) {       
      return api.list(
          'get_calls_state',
          null,
          data,
          state,
          commit,
          REPORT_BY_CALLS_LOADING_CHANGE,
          REPORT_BY_CALLS_CHANGE
      );                
  }
};

const mutations = {
  [TEL_CHECK_CHANGE](state, data) {
    if(data) {
      state.tel_check        = data.data;
    }
    else {
      state.tel_check        = [];
    }
  },
  [TEL_CHECK_CREATING_CHANGE](state, data) {
    state.tel_check_creating = data;
  },
  [TEL_CHECK_ERRORS_CHANGE](state, data) {
    state.tel_check_errors = data;
  },
  [TEL_CHECK_LOAD_CHANGE](state, data) {
    if (data) {
      state.tel_check_orders = data.data;
    } else {
      state.tel_check_orders = [];
    }
  },
  [TEL_CHECK_LOAD_C](state, data) {
    state.tel_check_order_c = data;
  },
  [TEL_CHECK_LOAD_ERRORS_CHANGE](state, data) {
    state.tel_check_order_errors = data;
  },
  [REPORT_BY_CALLS_LOADING_CHANGE](state, data) {
      state.report_calls_loading = data;
  },
  [REPORT_BY_CALLS_CHANGE](state, data) {

      if (data) {
          state.report_calls          = data.data;
          state.report_calls_total    = data.total;
      } else {
          state.report_calls          = [];
          state.report_calls_total    = 0;
      }

  },
};

export default {
  state,
  actions,
  mutations
};
