import api from './../../lib/api';
import http from '@/lib/http';
import {
    createMark
} from '@/lib/dialog_mark';
import VueRouter from "./../../routes.js";

export const ROBOT_PROJECT_DB_LOAD_DATATABLE                     = 'ROBOT_PROJECT_DB_LOAD_DATATABLE';
export const ROBOT_PROJECT_LOAD       = 'ROBOT_PROJECT_LOAD';
export const ROBOT_PROJECT_SAVE       = 'ROBOT_PROJECT_SAVE';
export const ROBOT_PROJECT_FROM_DIALOGS = 'ROBOT_PROJECT_FROM_DIALOGS';
export const ROBOT_PROJECT_SET_EMPTY       = 'ROBOT_PROJECT_SET_EMPTY';
const ROBOT_PROJECT_DB_DATATABLE_LOADING_CHANGE = 'ROBOT_PROJECT_DB_DATATABLE_LOADING_CHANGE';
const ROBOT_PROJECT_DB_DATATABLE_CHANGE = 'ROBOT_PROJECT_DB_DATATABLE_CHANGE';
const ROBOT_PROJECT_LOADING_CHANGE = 'ROBOT_PROJECT_LOADING_CHANGE';
const ROBOT_PROJECT_CHANGE = 'ROBOT_PROJECT_CHANGE';
const ERRORS_ROBOT_PROJECT_LOAD = 'ERRORS_ROBOT_PROJECT_LOAD';
const SET_EMPTY_ROBOT_PROJECT = 'SET_EMPTY_ROBOT_PROJECT';
const ROBOT_PROJECT_LOADED_CREATE = 'ROBOT_PROJECT_LOADED_CREATE';
const ROBOT_PROJECT_LOADING = 'ROBOT_PROJECT_LOADING';
const ROBOT_PROJECT_LOADED = 'ROBOT_PROJECT_LOADED';
const ROBOT_PROJECT_ERRORS = 'ROBOT_PROJECT_ERRORS';


export const DIALOG_MARKUP_DB_LOAD_DATATABLE                     = 'DIALOG_MARKUP_DB_LOAD_DATATABLE';
export const DIALOG_MARKUP_LOAD                     = 'DIALOG_MARKUP_LOAD';
export const DIALOG_MARKUP_SCRIPT_LOAD = 'DIALOG_MARKUP_SCRIPT_LOAD';
export const DIALOG_MARKUP_UPDATE                     = 'DIALOG_MARKUP_UPDATE';
export const DIALOG_MARKUP_AUDIO_TO_TEXT            = 'DIALOG_MARKUP_AUDIO_TO_TEXT';
const DIALOG_MARKUP_DB_DATATABLE_LOADING_CHANGE = 'DIALOG_MARKUP_DB_DATATABLE_LOADING_CHANGE';
const DIALOG_MARKUP_DB_DATATABLE_CHANGE = 'DIALOG_MARKUP_DB_DATATABLE_CHANGE';
const DIALOG_MARKUP_LOADING = 'DIALOG_MARKUP_LOADING';
const DIALOG_MARKUP_SCRIPT_LOADED = 'DIALOG_MARKUP_SCRIPT_LOADED';
const DIALOG_MARKUP_LOADED = 'DIALOG_MARKUP_LOADED';
const DIALOG_MARKUP_ERRORS = 'DIALOG_MARKUP_ERRORS';
const robot_project_empty = () => {
    return {
        title: ""
    }
}
const state = {
    robot_project_list           : [],
    robot_project_list_loading   : false,
    robot_project_errors         : {},
    robot_project_loading        : {},
    robot_project                : robot_project_empty(),

    dialog_markup_script         : null,
    dialog_markup_list           : [],
    dialog_markup_list_loading   : false,
    dialog_markup_loading        : {},
    dialog_markup                : {},
};

const actions = {
    [ROBOT_PROJECT_DB_LOAD_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'robot.projects', 0, data, state, commit, ROBOT_PROJECT_DB_DATATABLE_LOADING_CHANGE, ROBOT_PROJECT_DB_DATATABLE_CHANGE
        );
    },
    [ROBOT_PROJECT_LOAD] ({commit, state}, id) {
        return api.load(
            'robot.project', id, state, commit, ROBOT_PROJECT_LOADING, ROBOT_PROJECT_LOADED, ROBOT_PROJECT_ERRORS
        );
    },
    [ROBOT_PROJECT_SAVE]({ commit, state }, data) {
        if (data.key != "new") {
            return api.create('robot.project.update', data.key, data.model, state, commit, ROBOT_PROJECT_LOADING, ROBOT_PROJECT_LOADED, ROBOT_PROJECT_ERRORS);
        } else {
            return api.create('robot.project.create', 0, data.model, state, commit, ROBOT_PROJECT_LOADING, ROBOT_PROJECT_LOADED_CREATE, ROBOT_PROJECT_ERRORS);
        }
    },
    [ROBOT_PROJECT_FROM_DIALOGS]({ commit, state }, data) {
        return api.create('robot.project.from_dialogs', 0, data, state, commit, ROBOT_PROJECT_LOADING, ROBOT_PROJECT_LOADED_CREATE, ROBOT_PROJECT_ERRORS);
    },
    [ROBOT_PROJECT_SET_EMPTY]({ commit }, data) {
        commit(SET_EMPTY_ROBOT_PROJECT);
    },



    [DIALOG_MARKUP_DB_LOAD_DATATABLE] ({ commit, state }, {id, data}) {
        return api.list(
            'robot.dialog_markups', id, data, state, commit, DIALOG_MARKUP_DB_DATATABLE_LOADING_CHANGE, DIALOG_MARKUP_DB_DATATABLE_CHANGE
        );
    },
    [DIALOG_MARKUP_LOAD] ({ commit, state }, id) {
        return api.load(
            'robot.dialog_markup', id, state, commit, DIALOG_MARKUP_LOADING, DIALOG_MARKUP_LOADED, DIALOG_MARKUP_ERRORS
        );
    },
    [DIALOG_MARKUP_UPDATE] ({ commit, state }, data) {
        return api.create('robot.dialog_markup.update', data.id, data.model, state, commit, DIALOG_MARKUP_LOADING, DIALOG_MARKUP_LOADED, DIALOG_MARKUP_ERRORS);
    },
    [DIALOG_MARKUP_AUDIO_TO_TEXT] ({ commit, state }, data) {
        let id = 0;
        if (data.id) {
            id = data.id;
        }
        return api.create('robot.dialog_markup.audio', id, data, state, commit, DIALOG_MARKUP_LOADING, DIALOG_MARKUP_LOADED, DIALOG_MARKUP_ERRORS);
    },
    [DIALOG_MARKUP_SCRIPT_LOAD] ({ commit, state }, id) {
        http.get(`${api.main_url}/api/v2/bot/script/${id}`, {
            thin: true,
        }).then(({ data }) => {
            commit(DIALOG_MARKUP_SCRIPT_LOADED, data);
        })
    },
};

const mutations = {
    [ROBOT_PROJECT_DB_DATATABLE_LOADING_CHANGE](state, data) {
        state.robot_project_list_loading = data;
    },
    [ROBOT_PROJECT_DB_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.robot_project_list        = data.data;
        } else {
            state.robot_project_list        = [];
        }
    },
    [SET_EMPTY_ROBOT_PROJECT](state) {
        state.robot_project = robot_project_empty();
    },
    [ROBOT_PROJECT_LOADING](state, data) {
        state.robot_project_loading        = data;
    },
    [ROBOT_PROJECT_LOADED_CREATE](state, data) {
        if (data && data.id) {
            state.robot_project = data;
            VueRouter.push({
                name: 'robot_project',
                params: {
                    id: data.id,
                },
            });
        }
    },
    [ROBOT_PROJECT_LOADED](state, data) {
        if (data) {
            state.robot_project = data;
        }
    },
    [ROBOT_PROJECT_ERRORS](state, data) {
        state.robot_project_errors = data;
    },

    [DIALOG_MARKUP_DB_DATATABLE_LOADING_CHANGE](state, data) {
        state.dialog_markup_list_loading = data;
    },
    [DIALOG_MARKUP_DB_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.dialog_markup_list        = data.data;
        } else {
            state.dialog_markup_list        = [];
        }
    },
    [DIALOG_MARKUP_LOADING](state, data) {
        state.dialog_markup_loading        = data;
    },
    [DIALOG_MARKUP_LOADED](state, data) {
        if (data) {
            if (data.markup && data.markup.markup) {
                const markup = [];
                
                data.markup.markup.forEach((item, i) => {
                    markup.push(createMark(item));
                });

                data.markup.markup = markup;
            }
            state.dialog_markup = data;
        }
    },
    [DIALOG_MARKUP_ERRORS](state, data) {
        state.dialog_markup_errors = data;
    },
    [DIALOG_MARKUP_SCRIPT_LOADED] (state, data) {
        state.dialog_markup_script = data;
    },
};

export default {
    state,
    actions,
    mutations
}
