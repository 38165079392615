// import http from "./../../lib/http";
import api from "./../../lib/api";

export const WHOLESALER_UNLOAD_LOAD = "WHOLESALER_UNLOAD_LOAD";
export const WHOLESALER_UNLOAD_UPDATE = "WHOLESALER_UNLOAD_UPDATE";
export const WHOLESALER_UNLOAD_CREATE = "WHOLESALER_UNLOAD_CREATE";
export const WHOLESALER_UNLOAD_NEW_CHANGE = "WHOLESALER_UNLOAD_NEW_CHANGE";

export const WHOLESALER_UNLOAD_LOAD_DATATABLE = "WHOLESALER_UNLOAD_LOAD_DATATABLE";

export const WHOLESALER_UNLOAD_CHANGE = "WHOLESALER_UNLOAD_CHANGE";
const WHOLESALER_UNLOAD_LOADING_CHANGE = "WHOLESALER_UNLOAD_LOADING_CHANGE";
const WHOLESALER_UNLOAD_SAVING_CHANGE = "WHOLESALER_UNLOAD_SAVING_CHANGE";
const WHOLESALER_UNLOAD_ERRORS_CHANGE = "WHOLESALER_UNLOAD_ERRORS_CHANGE";

const WHOLESALER_UNLOAD_CREATING_CHANGE = "WHOLESALER_UNLOAD_CREATING_CHANGE";

const WHOLESALER_UNLOAD_DATATABLE_CHANGE = "WHOLESALER_UNLOAD_DATATABLE_CHANGE";
const WHOLESALER_UNLOAD_DATATABLE_LOADING_CHANGE = "WHOLESALER_UNLOAD_DATATABLE_LOADING_CHANGE";

const state = {
    unload: {
        id: 0,
        name: null,
        comment: null,
        config: null,
        is_work: true
    },
    unload_loading: false,
    unload_saving: false,
    unload_errors: {},

    unload_list_total: 0,
    unload_list: null,
    unload_list_loading: false,

    unload_creating: false,
    unload_creating_errors: {}
};

const actions = {
    [WHOLESALER_UNLOAD_LOAD]({ commit, state }, id) {
        if (!id) {
            commit(WHOLESALER_UNLOAD_CHANGE, null);

            return;
        }

        return api.load(
            "wholesaler_unload",
            id,
            state,
            commit,
            WHOLESALER_UNLOAD_LOADING_CHANGE,
            WHOLESALER_UNLOAD_CHANGE
        );
    },

    [WHOLESALER_UNLOAD_UPDATE]({ commit, state }, data) {
        let id = data.id;
        let model = data.model;

        return api.update(
            "wholesaler_unload",
            id,
            model,
            state,
            commit,
            WHOLESALER_UNLOAD_SAVING_CHANGE,
            WHOLESALER_UNLOAD_CHANGE,
            WHOLESALER_UNLOAD_ERRORS_CHANGE
        );
    },

    [WHOLESALER_UNLOAD_CREATE]({ commit, state }, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create(
            "wholesaler_unload.list",
            id,
            model,
            state,
            commit,
            WHOLESALER_UNLOAD_CREATING_CHANGE,
            WHOLESALER_UNLOAD_ERRORS_CHANGE
        );
    },

    [WHOLESALER_UNLOAD_LOAD_DATATABLE]({ commit, state }, id) {
        if (!id) {
            id = 0;
        }

        return api.list(
            "wholesaler_unload.list",
            id,
            null,
            state,
            commit,
            WHOLESALER_UNLOAD_DATATABLE_LOADING_CHANGE,
            WHOLESALER_UNLOAD_DATATABLE_CHANGE
        );
    }
};

const mutations = {
    [WHOLESALER_UNLOAD_DATATABLE_LOADING_CHANGE](state, data) {
        state.unload_list_loading = data;
    },
    [WHOLESALER_UNLOAD_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.unload_list = data.data;
            state.unload_list_total = data.total;
        } else {
            state.unload_list = [];
            state.unload_list_total = 0;
        }
    },
    [WHOLESALER_UNLOAD_NEW_CHANGE](state) {
        state.unload = {
            id: 0,
            name: null,
            comment: null,
            config: null,
            is_work: true
        };
    },
    [WHOLESALER_UNLOAD_CHANGE](state, data) {
        if (data && data.data && data.data[0]) state.unload = data.data[0];
    },
    [WHOLESALER_UNLOAD_LOADING_CHANGE](state, data) {
        state.unload_loading = data;
    },
    [WHOLESALER_UNLOAD_SAVING_CHANGE](state, data) {
        state.unload_saving = data;
    },
    [WHOLESALER_UNLOAD_ERRORS_CHANGE](state, data) {
        state.unload_errors = data;
    },
    [WHOLESALER_UNLOAD_CREATING_CHANGE](state, data) {
        state.unload_creating = data;
    }
};

export default {
    state,
    actions,
    mutations
};
