
import http from "./../../lib/http";
import api from "./../../lib/api";
import VueRouter from "./../../routes.js";
import {ORDER_MANAGER_ID_CHANGE} from "./orders";

export const OPT_ORDER_CREATE = "OPT_ORDER_CREATE";
export const OPT_ORDER_LOAD = "OPT_ORDER_LOAD";
export const OPT_ORDER_SAVE = "OPT_ORDER_SAVE";

export const OPT_ORDER_LOAD_LIST = "OPT_ORDER_LOAD_LIST";

export const OPT_ORDER_MANAGER_ID_CHANGE = "OPT_ORDER_MANAGER_ID_CHANGE";
export const OPT_ORDER_CLIENT_ID_CHANGE = "OPT_ORDER_MANAGER_ID_CHANGE";

const OPT_ORDER_LIST_CHANGE = "OPT_ORDER_LIST_CHANGE";
const OPT_ORDER_LIST_LOADING_CHANGE = "OPT_ORDER_LIST_LOADING_CHANGE";

const OPT_ORDER_SAVING_CHANGE = "OPT_ORDER_SAVING_CHANGE";
const OPT_ORDER_CREATE_CHANGE = "OPT_ORDER_CREATE_CHANGE";

const OPT_ORDER_SAVING_CHANGE_M = "OPT_ORDER_SAVING_CHANGE_M";
const OPT_ORDER_CHANGE_M = "OPT_ORDER_CHANGE_M";
const OPT_ORDER_CHANGE_ERRORS_M = "OPT_ORDER_CHANGE_ERRORS_M";

const OPT_ORDER_SAVING_CHANGE_C = "OPT_ORDER_SAVING_CHANGE_C";
const OPT_ORDER_CHANGE_C = "OPT_ORDER_CHANGE_C";
const OPT_ORDER_CHANGE_ERRORS_C = "OPT_ORDER_CHANGE_ERRORS_C";

export const OPT_ORDER_CHANGE = "OPT_ORDER_CHANGE";
export const OPT_ORDER_CHANGE_ERRORS = "OPT_ORDER_CHANGE_ERRORS";
export const OPT_ORDER_DATATABLE_CHANGE = "OPT_ORDER_DATATABLE_CHANGE";

const state = {
    opt_order: null,
    errors: {},
    opt_order_m: null,
    errors_m: {},
    opt_order_c: null,
    errors_c: {},

    opt_order_list: null,
    opt_order_list_total: 0,
    opt_order_list_loading: false,
};

const actions = {
    [OPT_ORDER_CREATE]({ commit, state }, data) {
        let model = data;
        let id = data.id ? data.id : 0;

        return api.create(
            "wholesaler_orders",
            id,
            model,
            state,
            commit,
            OPT_ORDER_SAVING_CHANGE,
            OPT_ORDER_CREATE_CHANGE,
            OPT_ORDER_CHANGE_ERRORS
        );
    },

    [OPT_ORDER_LOAD]({ commit, state }, key) {
        commit(OPT_ORDER_CHANGE, null);
        let url = api.main_url + "/api/v2/optovichok/orders/" + key;

        return http.get(url).then(response => {
            if (response && response.data) {
                response.data.edit_status = {};
                response.data.name_status = {};
                response.data.help_name_status = {};
                response.data.statuses.forEach(function(item, i, arr) {
                    response.data.edit_status[item.type] = item.id;
                    response.data.name_status[item.parent_id] = item.name;
                    response.data.help_name_status[item.type] = item.parent_id;
                });
                commit(OPT_ORDER_CHANGE, response.data);
                return true;
            }
        });
    },

    [OPT_ORDER_SAVE]({ commit, state }, data) {
        commit(OPT_ORDER_CHANGE_ERRORS, {});
        let url = api.main_url + "/api/v2/optovichok/orders/" + data.order_key;

        return http
            .put(url, data)
            .then(response => {
                if (response && response.data) {
                    response.data.edit_status = {};
                    response.data.name_status = {};
                    response.data.help_name_status = {};
                    response.data.statuses.forEach(function(item, i, arr) {
                        response.data.edit_status[item.type] = item.id;
                        response.data.name_status[item.parent_id] = item.name;
                        response.data.help_name_status[item.type] = item.parent_id;
                    });
                    commit(OPT_ORDER_CHANGE, response.data);

                    return true;
                } else {
                    return false;
                }
            })
            .catch(error => {
                if (error.errors) {
                    commit(OPT_ORDER_CHANGE_ERRORS, error.errors);
                }

                return false;
            });
    },
    [OPT_ORDER_MANAGER_ID_CHANGE]({ commit, state }, data) {
        let key = data.key;
        let model = {'manager_id':data.manager_id};
        return api.update(
            "wholesaler_order.change_m",
            key,
            model,
            state,
            commit,
            OPT_ORDER_SAVING_CHANGE_M,
            OPT_ORDER_CHANGE_M,
            OPT_ORDER_CHANGE_ERRORS_M
        );
    },
    [OPT_ORDER_CLIENT_ID_CHANGE]({ commit, state }, data) {
        let key = data.key;
        let model = {'client_id':data.client_id};
        let request = api.update(
            "wholesaler_order.change_c",
            key,
            model,
            state,
            commit,
            OPT_ORDER_SAVING_CHANGE_M,
            OPT_ORDER_CHANGE_M,
            OPT_ORDER_CHANGE_ERRORS_M
        );

        return request;
    },
    [OPT_ORDER_LOAD_LIST]({ commit, state }, data) {
        api.list(
            "wholesaler_orders",
            null,
            data,
            state,
            commit,
            OPT_ORDER_LIST_LOADING_CHANGE,
            OPT_ORDER_LIST_CHANGE
        );
    }
};

const mutations = {
    [OPT_ORDER_CHANGE](state, data) {
        state.opt_order = data;
    },
    [OPT_ORDER_LIST_CHANGE](state, data) {
        if (data) {
            state.opt_order_list = data.data;
            state.opt_order_list.forEach(item => {
                item.created_at = new Date(item.created_at);
            });
            state.opt_order_list_total = data.total;
        } else {
            state.opt_order_list = [];
            state.opt_order_list_total = 0;
        }
    },
    [OPT_ORDER_LIST_LOADING_CHANGE](state, data) {
        state.opt_order_list_loading = data;
    },
    [OPT_ORDER_CHANGE_ERRORS](state, data) {
        state.errors = data;
    },
    [OPT_ORDER_CREATE_CHANGE](state, data) {
        if (data) {
            VueRouter.push("/wholesaler_orders/" + data.key);
        }
    },
    [OPT_ORDER_SAVING_CHANGE](state, data) {
        state.opt_order_saving = data;
    },
    [OPT_ORDER_SAVING_CHANGE_M](state, data) {
        state.opt_order_saving_m = data;
    },
    [OPT_ORDER_CHANGE_M](state, data) {
        state.opt_order_m = data;
    },
    [OPT_ORDER_CHANGE_ERRORS_M](state, data) {
        state.errors_m = data;
    },
    [OPT_ORDER_SAVING_CHANGE_C](state, data) {
        state.opt_order_saving_c = data;
    },
    [OPT_ORDER_CHANGE_C](state, data) {
        state.opt_order_c = data;
    },
    [OPT_ORDER_CHANGE_ERRORS_C](state, data) {
        state.errors_c = data;
    },
};

export default {
    state,
    actions,
    mutations
};
