import api from '../../lib/api';

export const RB_ACTION_CONSTRUCTOR_DASHBOARDS_LOAD = 'RB_ACTION_CONSTRUCTOR_DASHBOARDS_LOAD';
const RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADING = 'RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADING';
const RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADED = 'RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADED';
const RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOAD_ERRORS';

export const RB_ACTION_CONSTRUCTOR_DASHBOARD_LOAD = 'RB_ACTION_CONSTRUCTOR_DASHBOARD_LOAD';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADING = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADING';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADED = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADED';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOAD_ERRORS';

export const RB_ACTION_CONSTRUCTOR_DASHBOARD_STORE = 'RB_ACTION_CONSTRUCTOR_DASHBOARD_STORE';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADING = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADING';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADED = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADED';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_ERRORS';

export const RB_ACTION_CONSTRUCTOR_DASHBOARD_UPDATE = 'RB_ACTION_CONSTRUCTOR_DASHBOARD_UPDATE';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADING = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADING';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADED = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADED';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_ERRORS';

export const RB_ACTION_CONSTRUCTOR_DASHBOARD_DELETE = 'RB_ACTION_CONSTRUCTOR_DASHBOARD_DELETE';
const RB_MUTATION_CONSTRUCTOR_DASHBOARD_DELETE_LOADING = 'RB_ACTION_CONSTRUCTOR_DASHBOARD_DELETE_LOADING';

export const RB_MUTATION_CONSTRUCTOR_DASHBOARD_EMPTY = 'RB_MUTATION_CONSTRUCTOR_DASHBOARD_EMPTY';

const state = {
  dashboards: {
    items: [],
    total: 0,
    loading: false
  },
  dashboard: {
    name: "",
    description: "",
    reports: [],
    organizations: [],
  },
}

const actions = {
  /**
   * Загрузить отчеты
   * @param {Object} params 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_DASHBOARDS_LOAD]({commit, state}, params) {
    return api.list(
      'rb.constructor.dashboards.load',
      null,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADING,
      RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADED,
      RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить отчет
   * @param {Number} dashboardId 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_DASHBOARD_LOAD]({commit, state}, dashboardId) {
    return api.load(
      'rb.constructor.dashboards.dashboard.load',
      dashboardId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADING,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADED,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOAD_ERRORS
    ); 
  },
  /**
   * Создать отчет
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_DASHBOARD_STORE]({commit, state}) {
    let params = state.dashboard
    let reports = []

    params.reports.forEach(report => {
      reports.push(report.id)
    });
    params.reports = reports

    return api.create(
      'rb.constructor.dashboards.dashboard.store',
      null,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADING,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADED,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_ERRORS
    ); 
  },
  /**
   * Обновить отчет
   * @param {Number} dashboardId 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_DASHBOARD_UPDATE]({commit, state}, dashboardId) {
    let params = state.dashboard
    let reports = []

    params.reports.forEach(report => {
      reports.push(report.id)
    });
    params.reports = reports

    return api.update(
      'rb.constructor.dashboards.dashboard.update',
      dashboardId,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADING,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADED,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_ERRORS
    ); 
  },
  /**
   * Удалить отчет
   * @param {Number} dashboardId 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_DASHBOARD_DELETE]({commit, state}, dashboardId) {
    return api.delete(
      'rb.constructor.dashboards.dashboard.delete',
      dashboardId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_DASHBOARD_DELETE_LOADING
    ); 
  },
}

const mutations = {
  [RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADING](state, status) {
    state.dashboards.loading = status
  },
  [RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOADED](state, data) {
    if (data) {
      state.dashboards.items = data.data
      state.dashboards.total = data.total
    }
  },
  [RB_MUTATION_CONSTRUCTOR_DASHBOARDS_LOAD_ERRORS]() {},
  
  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOADED](state, data) {
    if (data) {
      let dashboard = data.data
      state.dashboard.name = dashboard.name
      state.dashboard.description = dashboard.description
      state.dashboard.reports = dashboard.reports
      state.dashboard.organizations = dashboard.organizations
    }
  },
  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_STORE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_UPDATE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_DELETE_LOADING]() {},

  [RB_MUTATION_CONSTRUCTOR_DASHBOARD_EMPTY](state) {
    state.dashboard.name = ""
    state.dashboard.description = ""
    state.dashboard.reports = []
    state.dashboard.organizations = []
  },
}

export default {
  state,
  actions,
  mutations,
}