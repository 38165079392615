import api from '../../lib/api';

export const RB_ACTION_CONSTRUCTOR_TEMPLATES_LOAD = 'RB_ACTION_CONSTRUCTOR_TEMPLATES_LOAD'
const RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADING = 'RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADING'
const RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADED = 'RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADED'
const RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOAD_ERRORS'

export const RB_ACTION_CONSTRUCTOR_TEMPLATE_LOAD = 'RB_ACTION_CONSTRUCTOR_TEMPLATE_LOAD'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADING = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADING'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADED = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADED'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOAD_ERRORS'

export const RB_ACTION_CONSTRUCTOR_TEMPLATE_STORE = 'RB_ACTION_CONSTRUCTOR_TEMPLATE_STORE'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADING = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADING'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADED = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADED'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_ERRORS'

export const RB_ACTION_CONSTRUCTOR_TEMPLATE_UPDATE = 'RB_ACTION_CONSTRUCTOR_TEMPLATE_UPDATE'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADING = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADING'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADED = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADED'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_ERRORS'

export const RB_ACTION_CONSTRUCTOR_TEMPLATE_DELETE = 'RB_ACTION_CONSTRUCTOR_TEMPLATE_DELETE';
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_DELETE_LOADING = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_DELETE_LOADING';

export const RB_ACTION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOAD = 'RB_ACTION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOAD'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADING = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADING'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADED = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADED'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOAD_ERRORS'

const state = {
  templates: {
    items: [],
    loading: false,
    total: 0,
    selectOptions: []
  },
  template: {
    name: "",
    description: "",
    source_id: null,
    groups: [],
    filters: [],
    functional_columns: [],
    columns: [],
    variables: [],
  },
  templateLoading: false,
  createdTemplateId: 0,
  gridColumns: []
}

const actions = {
  /**
   * Загрузить шаблоны
   * @param commit
   * @param state
   * @param {Object} params
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_TEMPLATES_LOAD]({commit, state}, params) {
    return api.list(
      'rb.constructor.templates.load',
      null,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADING,
      RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADED,
      RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить шаблон
   * @param commit
   * @param state
   * @param {Number} templateId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_TEMPLATE_LOAD]({commit, state}, templateId) {
    return api.load(
      'rb.constructor.templates.template.load',
      templateId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADING,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADED,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOAD_ERRORS
    ); 
  },
  /**
   * Создать шаблон
   * @param {Object} template
   * @returns
   * @param source
   */
  [RB_ACTION_CONSTRUCTOR_TEMPLATE_STORE]({commit, state}, {source}) {
    let params = state.template
    params.source_id = source.id;

    return api.create(
      'rb.constructor.templates.template.store',
      null,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADING,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADED,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_ERRORS
    ); 
  },
  /**
   * Обновить шаблон
   * @param {Object} template
   * @returns
   * @param templateId
   */
  [RB_ACTION_CONSTRUCTOR_TEMPLATE_UPDATE]({commit, state}, {templateId}) {
    let params = state.template
    delete params.source_id;

    let gridColumns = state.gridColumns.sort((a,b) => a.y > b.y ? 1 : -1)
    params.columns = []

    let position = 1

    gridColumns.forEach((element) => {
      params.columns.push({
        id: element.i,
        position: position++,
      })
    });

    return api.update(
      'rb.constructor.templates.template.update',
      templateId,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADING,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADED,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_ERRORS
    ); 
  },
  /**
   * Удалить шаблон
   * @param commit
   * @param state
   * @param {Number} templateId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_TEMPLATE_DELETE]({commit, state}, templateId) {
    return api.delete(
      'rb.constructor.templates.template.delete',
      templateId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_DELETE_LOADING
    ); 
  },
  /**
   * Загрузить колонки шаблона
   * @param commit
   * @param state
   * @param {Number} templateId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOAD]({commit, state}, templateId) {
    return api.load(
      'rb.constructor.templates.template.load',
      templateId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADING,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADED,
      RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOAD_ERRORS
    ); 
  },
}

const mutations = {
  [RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADING](state, status) {
    state.templates.loading = status
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOADED](state, data) {
    if (data) {
      state.templates.items = data.data
      state.templates.total = data.total

      state.templates.selectOptions = [
        { value: null, text: 'Выберите шаблон отчета' }
      ];

      data.data.forEach(template => {
        state.templates.selectOptions.push({
          'value': template.id,
          'text': template.name,
        });
      });
    }
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATES_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADING](state, status) {
    state.templateLoading = status
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOADED](state, data) {
    if (data) {
      let template = data.data

      state.template.name = template.name || "";
      state.template.description = template.description || "";
      state.template.groups = template.groups || [];
      state.template.filters = template.filters || [];
      state.template.functional_columns = template.functional_columns || [];
      state.template.source_id = template.source.id || null;
      state.template.variables = template.variables || [];

      if (template.columns) {
        state.gridColumns = []
        template.columns.forEach(column => {
          state.gridColumns.push({
            y: column.position,
            i: column.id,
            name: column.name,
            x: 0,
            w: 2,
            h: 5
          })
        });
      }
    }
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_LOADED](state, data) {
    if (data) {
      state.createdTemplateId = data.data.id
    }
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_STORE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_UPDATE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_DELETE_LOADING]() {},

  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOADED](state, data) {
    if (data) {
      let template = data.data
      if (template.columns) {
        state.gridColumns = []
        template.columns.forEach(column => {
          state.gridColumns.push({
            y: column.position,
            i: column.id,
            name: column.name,
            x: 0,
            w: 2,
            h: 5
          })
        });
      }
    }
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_COLUMNS_LOAD_ERRORS]() {},
}

export default {
  state,
  actions,
  mutations,
}