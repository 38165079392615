import http from './../../lib/http';
import api from './../../lib/api';

export const ENTITY_PARAMS_LIST                 = 'ENTITY_PARAMS_LIST';

export const ENTITY_PARAMS_LOAD                 = 'ENTITY_PARAMS_LOAD';
export const ENTITY_PARAMS_UPDATE               = 'ENTITY_PARAMS_UPDATE';
export const ENTITY_PARAMS_CREATE               = 'ENTITY_PARAMS_CREATE';

export const ENTITY_PARAMS_LOAD_DATATABLE       = 'ENTITY_PARAMS_LOAD_DATATABLE';

export const ENTITY_PARAMS_CHANGE               = 'ENTITY_PARAMS_CHANGE';

const ENTITY_PARAMS_LOADING_CHANGE              = 'ENTITY_PARAMS_LOADING_CHANGE';
const ENTITY_PARAMS_SAVING_CHANGE               = 'ENTITY_PARAMS_SAVING_CHANGE';
const ENTITY_PARAMS_ERRORS_CHANGE               = 'ENTITY_PARAMS_ERRORS_CHANGE';

const ENTITY_PARAMS_CREATING_CHANGE             = 'ENTITY_PARAMS_CREATING_CHANGE';

const ENTITY_PARAMS_DATATABLE_CHANGE            = 'ENTITY_PARAMS_DATATABLE_CHANGE';
const ENTITY_PARAMS_DATATABLE_LOADING_CHANGE    = 'ENTITY_PARAMS_DATATABLE_LOADING_CHANGE';

export const ENTITY_PARAMS_DELETE               = 'ENTITY_PARAMS_DELETE';
export const ENTITY_PARAMS_DELETE_CHANGE        = 'ENTITY_PARAMS_DELETE_CHANGE';

export const ENTITY_PARAMS_LOAD_BY_ENTITY       = 'ENTITY_PARAMS_LOAD_BY_ENTITY';
const ENTITY_PARAMS_LOAD_BY_ENTITY_CHANGE       = 'ENTITY_PARAMS_LOAD_BY_ENTITY_CHANGE';

export const ENTITY_PARAMS_LOAD_BY_ENTITY_ROLE  = 'ENTITY_PARAMS_LOAD_BY_ENTITY_ROLE';

export const ENTITY_PARAMS_LOAD_BY_ROLE_PERMITTED  = 'ENTITY_PARAMS_LOAD_BY_ROLE_PERMITTED';
const ENTITY_PARAMS_LOAD_BY_ROLE_PERMITTED_CHANGE = 'ENTITY_PARAMS_LOAD_BY_ROLE_PERMITTED_CHANGE';

const state = {
    entity_params            : null,
    entity_params_loading    : false,
    entity_params_saving     : false,
    entity_params_errors     : {},

    entity_params_list_total     : 0, 
    entity_params_list           : null,
    entity_params_list_loading   : false,

    entity_params_creating           : false,
    entity_params_creating_errors    : {},
    entity_params_empty              : {
                                        id: 0,
                                        entity_id: 0,
                                        parent_id: null,
                                        name: "",
                                        parameter: "",
                                    }
};

const actions = {
        [ENTITY_PARAMS_LOAD] ({ commit, state }, id) {
        if(!id) {
            state.entity_params_empty = {
                                  id: 0,
                                  entity_id: 0,
                                  parent_id: null,
                                  name: "",
                                  parameter: "",
                              };
            commit(ENTITY_PARAMS_CHANGE, state.entity_params_empty);

            return;
        }

        return api.load(
            'entity_params', 
            id, 
            state, 
            commit, 
            ENTITY_PARAMS_LOADING_CHANGE, 
            ENTITY_PARAMS_CHANGE
        );
    },

    [ENTITY_PARAMS_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update(
            'entity_params', 
            id, 
            model, 
            state, 
            commit, 
            ENTITY_PARAMS_SAVING_CHANGE,
            ENTITY_PARAMS_CHANGE, 
            ENTITY_PARAMS_ERRORS_CHANGE
        );
    },

    [ENTITY_PARAMS_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create(
            'entity_params.list', 
            id, 
            model, 
            state, 
            commit, 
            ENTITY_PARAMS_CREATING_CHANGE, 
            ENTITY_PARAMS_CHANGE, 
            ENTITY_PARAMS_ERRORS_CHANGE
        );
    },

    [ENTITY_PARAMS_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'entity_params.list', 
            id, 
            null,
            state, 
            commit, 
            ENTITY_PARAMS_DATATABLE_LOADING_CHANGE, 
            ENTITY_PARAMS_DATATABLE_CHANGE
        );
    },
    [ENTITY_PARAMS_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'entity_params',
            id,
            state,
            commit,
            ENTITY_PARAMS_DELETE_CHANGE
        );
    },
    [ENTITY_PARAMS_LOAD_BY_ENTITY] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'entity_params.by_entity', 
            id, 
            null,
            state, 
            commit, 
            ENTITY_PARAMS_LOADING_CHANGE, 
            ENTITY_PARAMS_LOAD_BY_ENTITY_CHANGE
        );
    },
    [ENTITY_PARAMS_LOAD_BY_ENTITY_ROLE] ({ commit, state }, data) {

        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create(
            'entity_params.by_entity_role', 
            id, 
            model,
            state, 
            commit, 
            ENTITY_PARAMS_LOADING_CHANGE, 
            ENTITY_PARAMS_LOAD_BY_ENTITY_CHANGE,
            ENTITY_PARAMS_ERRORS_CHANGE
        );       
    },
    [ENTITY_PARAMS_LOAD_BY_ROLE_PERMITTED] ({ commit, state }, data) {

        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create(
            'entity_params.by_role_permitted', 
            id, 
            model,
            state, 
            commit, 
            ENTITY_PARAMS_LOADING_CHANGE, 
            ENTITY_PARAMS_LOAD_BY_ROLE_PERMITTED_CHANGE,
            ENTITY_PARAMS_ERRORS_CHANGE
        );       
    },
    
};

const mutations = {
    [ENTITY_PARAMS_DATATABLE_LOADING_CHANGE](state, data) {
        state.entity_params_list_loading = data;
    },
    [ENTITY_PARAMS_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.entity_params_list   = data.data.map(
                function(item){
                    item.text=item.name; 
                    item.value=item.name; 
                    item.opened=false; 
                    return item;}
            );
            state.entity_params_list = treeify(data.data);
            state.entity_params_list_total  = data.total;
        }
        else {
            state.entity_params_list        = [];
            state.entity_params_list_total  = 0;
        }
    },
    [ENTITY_PARAMS_LOAD_BY_ENTITY_CHANGE](state, data) {

        if(data) {
            state.entity_params_list   = data.data.map(
                function(item){
                    item.text=item.name; 
                    item.value=item.name; 
                    item.opened=false; 
                    item.selected=false;
                    item.disabled=false;
                    return item;}
            );
            state.entity_params_list = treeify(state.entity_params_list);
            state.entity_params_total  = data.total;
        }
        else {
            state.entity_params_list        = [];
            state.entity_params_list_total  = 0;
        }
    },
    [ENTITY_PARAMS_LOAD_BY_ROLE_PERMITTED_CHANGE](state, data) {
        
        if(data) {
            state.entity_params_list = data.data;
            state.entity_params_total  = data.total;
        }
        else {
            state.entity_params_list        = [];
            state.entity_params_total  = 0;
        }
    },

    [ENTITY_PARAMS_CHANGE](state, data) {
        state.entity_params  = data;
    },
    [ENTITY_PARAMS_LOADING_CHANGE](state, data) {
        state.entity_params_loading = data;
    },
    [ENTITY_PARAMS_SAVING_CHANGE](state, data) {
        state.entity_params_saving   = data;
    },
    [ENTITY_PARAMS_CREATING_CHANGE](state, data) {
    state.entity_params_creating = data;
    },
    [ENTITY_PARAMS_ERRORS_CHANGE](state, data) {
        state.entity_params_errors   = data;
    },
    [ENTITY_PARAMS_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.entity_params_list) {

                let list = [];
                state.entity_params_list.forEach(entity_params => {
                    if (entity_params.id !== id) {
                        list.push({...entity_params});
                    }
                });

                state.entity_params_list = list;
            }
        }
    },

};

export default {
    state,
    actions,
    mutations
}

function treeify(list, idAttr, parentAttr, childrenAttr) {
    if (!idAttr) idAttr = 'id';
    if (!parentAttr) parentAttr = 'parent_id';
    if (!childrenAttr) childrenAttr = 'children';

    var treeList = [];
    var lookup = {};
    list.forEach(function(obj) {
        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
    });
    list.forEach(function(obj) {
        if (obj[parentAttr] != null) {

            if(lookup[obj[parentAttr]].selected)
                obj.selected = true;

            lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
            treeList.push(obj);
        }
    });

    return treeList;
};
