import api from './../../lib/api';

export const MESSENGER_ACCOUNT_LOAD                 = 'MESSENGER_ACCOUNT_LOAD';
export const MESSENGER_ACCOUNT_UPDATE               = 'MESSENGER_ACCOUNT_UPDATE';
export const MESSENGER_ACCOUNT_CREATE               = 'MESSENGER_ACCOUNT_CREATE';

export const MESSENGER_ACCOUNT_LOAD_DATATABLE       = 'MESSENGER_ACCOUNT_LOAD_DATATABLE';

const MESSENGER_ACCOUNT_CHANGE                      = 'MESSENGER_ACCOUNT_CHANGE';
const MESSENGER_ACCOUNT_LOADING_CHANGE              = 'MESSENGER_ACCOUNT_LOADING_CHANGE';
const MESSENGER_ACCOUNT_SAVING_CHANGE               = 'MESSENGER_ACCOUNT_SAVING_CHANGE';
const MESSENGER_ACCOUNT_ERRORS_CHANGE               = 'MESSENGER_ACCOUNT_ERRORS_CHANGE';
const MESSENGER_ACCOUNT_CREATE_CHANGE               = 'MESSENGER_ACCOUNT_CREATE_CHANGE';

const MESSENGER_ACCOUNT_DATATABLE_CHANGE            = 'MESSENGER_ACCOUNT_DATATABLE_CHANGE';
const MESSENGER_ACCOUNT_DATATABLE_LOADING_CHANGE    = 'MESSENGER_ACCOUNT_DATATABLE_LOADING_CHANGE';

const state = {
  messenger_account            : null,
  messenger_account_loading    : false,

  messenger_account_saving     : false,
  messenger_account_errors     : {},

  messenger_account_list_total   : 0,
  messenger_account_list         : null,
  messenger_account_list_loading : false,
  messenger_account_empty        : {
    id: 0,
    chat_bot_setup_id: null,
    organization_id: null,
  }
};

const actions = {
  [MESSENGER_ACCOUNT_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(MESSENGER_ACCOUNT_CHANGE, _.cloneDeep(state.messenger_account_empty));
      return;
    }
    return api.load(
      'messenger_account',
      id,
      state,
      commit,
      MESSENGER_ACCOUNT_LOADING_CHANGE,
      MESSENGER_ACCOUNT_CHANGE
    );
  },

  [MESSENGER_ACCOUNT_UPDATE] ({ commit, state }, {id, data}) {
    return api.update(
      'messenger_account',
      id,
      data,
      state,
      commit,
      MESSENGER_ACCOUNT_SAVING_CHANGE,
      MESSENGER_ACCOUNT_CHANGE,
      MESSENGER_ACCOUNT_ERRORS_CHANGE
    );
  },

  [MESSENGER_ACCOUNT_CREATE] ({ commit, state }, data) {
    return api.create(
      'messenger_accounts',
      0,
      data,
      state,
      commit,
      MESSENGER_ACCOUNT_SAVING_CHANGE,
      MESSENGER_ACCOUNT_CREATE_CHANGE,
      MESSENGER_ACCOUNT_ERRORS_CHANGE
    );
  },

  [MESSENGER_ACCOUNT_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'messenger_accounts',
      0,
      filter,
      state,
      commit,
      MESSENGER_ACCOUNT_DATATABLE_LOADING_CHANGE,
      MESSENGER_ACCOUNT_DATATABLE_CHANGE
    );
  },
};

const mutations = {
  [MESSENGER_ACCOUNT_DATATABLE_LOADING_CHANGE](state, data) {
    state.messenger_account_list_loading = data;
  },
  [MESSENGER_ACCOUNT_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.messenger_account_list          = data.data;
      state.messenger_account_list_total    = data.total;
    } else {
      state.messenger_account_list          = {};
      state.messenger_account_list_total    = 0;
    }
  },
  [MESSENGER_ACCOUNT_CHANGE](state, data) {
    state.messenger_account = data;
  },
  [MESSENGER_ACCOUNT_LOADING_CHANGE](state, data) {
    state.messenger_account_loading = data;
  },
  [MESSENGER_ACCOUNT_SAVING_CHANGE](state, data) {
    state.messenger_account_saving   = data;
  },
  [MESSENGER_ACCOUNT_ERRORS_CHANGE](state, data) {
    state.messenger_account_errors   = data;
  },
  [MESSENGER_ACCOUNT_CREATE_CHANGE](state, data) {
    state.messenger_account = data;
  },
};

export default {
  state,
  actions,
  mutations
}
