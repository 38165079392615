<template>
  <!-- <div id="page-wrapper"> -->
  <!-- <top-menu></top-menu> -->
  <!--<div class="container-fluid" style="margin-top: 25px;">-->
  <!-- <b-container fluid style="margin-top: 25px;"> -->
  <router-view v-if="user"></router-view>
<!-- <login v-else></login> -->
  <!-- </b-container> -->
  <!--</div>-->
  <!--<login v-if="!user"></login>-->
  <!--<div v-else>-->
  <!--<top-menu></top-menu>-->
  <!--<div class="container-fluid" style="margin-top: 25px;">-->
  <!--<router-view></router-view>-->
  <!--</div>-->
  <!---->
  <!--</div>-->
  <!-- <notifications group="app" v-if="is_notify"/> -->
  <!-- </div> -->
</template>

<script>
  // Для локализация DevExtreme
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import "devextreme-intl";
import ruMessages from "devextreme/localization/messages/ru.json";

import http from "./../../lib/http";

// import TopMenu from "./TopMenu";
// import Login from "./Login";

import {
  AUTH_USER_CHANGE,
  AUTH_TOKEN_CHANGE,
  USER_SET_TOKEN
} from "./../../store/modules/users";

import { mapState } from "vuex";

export default {
  data() {
    return {
      is_notify: false
    };
  },
  methods: {
    /**
     * Переводим с корня сайта на указанную старницу из наcтроек пользователя
     * @param  {Object} data
     * @author SeregaHelf
     */
    rotuePush(data) {
      if (document.location.pathname == '/') {
        if (this.can('menu.main.orders.view_list')) {
          this.$router.push({ path: '/orders' });
        } else if (data.mainlink && data.mainlink != '/') {
          this.$router.push({ path: data.mainlink });
        } else {
          this.$router.push({ path: '/profile' });
        }
      }
    },
  },
  // components: {
    // TopMenu,
    // Login,
  // },
  computed: {
    ...mapState({
      is_need_notify: state => state.users.is_need_notify,
      user: state => state.users.auth_user,
      token: state => state.users.auth_token
    })
  },
  watch: {
    user() {
      if (this.user) {
        if (this.$ls.get("users.auth_user") == null) {
          let pushUrl = '/';
          if (this.$ls.get('refererUrl')) {
            pushUrl = this.$ls.get('refererUrl');
            this.$ls.remove("refererUrl");
          } else if (this.user.mainlink && this.user.mainlink != '/') {
            pushUrl = this.user.mainlink;
          }

          this.$router.push(pushUrl);
        }

        this.$ls.set("users.auth_user", JSON.stringify({ ...this.user }));
        this.$ls.set("users.auth_token", this.token);
        locale(this.user.lang);
      } else {
        this.$ls.remove("users.auth_user");
        this.$ls.remove("users.auth_token");
        window.location.href = this.$login_main_url + "/login";
      }
    },
    is_need_notify() {
      this.is_notify = this.is_need_notify;
    }
  },
  created() {
    // Для локализации DevExtreme
    loadMessages(ruMessages);
    if(this.user)
      locale(this.user.lang);
    const urlParams = new URLSearchParams(window.location.search);
    const param_token = urlParams.get('token');

    if(param_token){
      this.$store.dispatch(USER_SET_TOKEN, param_token);
      this.$router.push({ query: null });
    }

    let user = JSON.parse(this.$ls.get("users.auth_user"));
    let token = this.$ls.get("users.auth_token");

    if (user && token) {
      this.$store.commit(AUTH_TOKEN_CHANGE, token);
      this.$store.commit(AUTH_USER_CHANGE, user);

      // если пользователь переходит в корень сайта, то перебрасываем его
      this.rotuePush(user);
    } else {
      let url = this.$main_url + "/api/v2/users/me";
      http.get(url).then(resp => {
        if (!resp) {
          window.location.href = this.$login_main_url + "/login";
        } else if (resp.data) {
          this.rotuePush(resp.data);
        }
      });
    }
  }
};
</script>
<style lang="scss">
/* Import Font Awesome Icons Set */
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";

/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";

// Import Main styles for this application
@import "@/assets/shared/sass/app.scss";

@import "~devextreme/dist/css/dx.common.css";
@import "~devextreme/dist/css/dx.light.css";

@import 'vue-json-pretty/lib/styles.css';

</style>
