import api from './../../lib/api';

export const PHRASE_CREATE          = 'PHRASE_CREATE';
export const PHRASE_DELETE          = 'PHRASE_DELETE';
export const PHRASE_LOAD_LIST       = 'PHRASE_LOAD_LIST';
export const PHRASE_UPDATE          = 'PHRASE_UPDATE';

const PHRASE_LIST_LOADING_CHANGE    = 'PHRASE_LIST_LOADING_CHANGE';
const PHRASE_LIST_CHANGE            = 'PHRASE_LIST_CHANGE';
const PHRASE_LOADING                = 'PHRASE_LOADING';
const PHRASE_LOADED                 = 'PHRASE_LOADED';
const PHRASE_ERRORS                 = 'PHRASE_ERRORS';
const PHRASE_DELETING               = 'PHRASE_DELETING';

const state = {
    phrase: {},
    phrase_list_loading: false,
    phrase_list: {},
    phrase_errors: {},
};

const actions = {
    [PHRASE_LOAD_LIST] ({ commit, state }, data) {
        return api.list(
            'phrases', 0, data, state, commit, PHRASE_LIST_LOADING_CHANGE, PHRASE_LIST_CHANGE
        );
    },
    async [PHRASE_CREATE]({ commit, state }, data) {
        const success = await api.create(
            'phrases',
            0,
            data,
            state,
            commit,
            PHRASE_LOADING,
            PHRASE_LOADED,
            PHRASE_ERRORS
        ).catch((error) => {
            return Promise.reject(state.phrase_errors);
        });

        if (success) {
            return Promise.resolve(state.phrase);
        }
    },
    [PHRASE_UPDATE]({ commit, state }, data) {
        return api.update(
            'phrase',
            data.id,
            data,
            state,
            commit,
            PHRASE_LOADING,
            PHRASE_LOADED,
            PHRASE_ERRORS
        );
    },
    [PHRASE_DELETE]({ commit, state }, id) {
        return api.delete(
            'phrase',
            id,
            state,
            commit,
            PHRASE_DELETING
        );
    },
};

const mutations = {
    [PHRASE_LIST_LOADING_CHANGE](state, data) {
        state.phrase_list_loading = data;
    },
    [PHRASE_LIST_CHANGE](state, data) {
        if(data) {
            state.phrase_list = data.data;
        } else {
            state.phrase_list = {};
        }
    },
    [PHRASE_LOADING](state, data) {
        state.phrase_loading = data;
    },
    [PHRASE_DELETING](state, id) {
        const index = state.phrase_list.data.findIndex((phrase) => phrase.id === id);
        if (index < 0) {
            return;
        }
        state.phrase_list.data.splice(index, 1);
    },
    [PHRASE_LOADED](state, data) {
        if (data) {
            state.phrase = data;
        }
    },
    [PHRASE_ERRORS](state, data) {
        state.phrase_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}
