import http from "./../../lib/http";
import api from "./../../lib/api";
import VueRouter from "./../../routes.js";
import Vue from "vue";
import lang from "../../lib/lang";

export const ORDER_CREATE = "ORDER_CREATE";
export const ORDER_LOAD = "ORDER_LOAD";
export const ORDER_SAVE = "ORDER_SAVE";
export const ORDER_UPDATE_PHONES = "ORDER_UPDATE_PHONES";
export const ORDER_GET_PHONES_BY_KEY = "ORDER_GET_PHONES_BY_KEY";

export const ORDER_LOAD_LIST = "ORDER_LOAD_LIST";
export const ORDER_LOAD_DOUBLE_LIST = "ORDER_LOAD_DOUBLE_LIST";

export const ORDER_MANAGER_ID_CHANGE = "ORDER_MANAGER_ID_CHANGE";
export const ORDER_BIND_SINGLE_PROJECT = "ORDER_BIND_SINGLE_PROJECT";
export const ORDER_SET_BP_DELAY = "ORDER_SET_BP_DELAY";
const ORDER_LIST_CHANGE = "ORDER_LIST_CHANGE";
const ORDER_LIST_LOADING_CHANGE = "ORDER_LIST_LOADING_CHANGE";
const ORDER_LOAD_DOUBLE_LIST_CHANGE = "ORDER_LOAD_DOUBLE_LIST_CHANGE";
const ORDER_CHANGE_DIAL_STEP = "ORDER_CHANGE_DIAL_STEP";

const ORDER_SAVING_CHANGE = "ORDER_SAVING_CHANGE";
const ORDER_CREATE_CHANGE = "ORDER_CREATE_CHANGE";

const ORDER_SAVING_CHANGE_M = "ORDER_SAVING_CHANGE_M";
const ORDER_CHANGE_M = "ORDER_CHANGE_M";
const ORDER_CHANGE_ERRORS_M = "ORDER_CHANGE_ERRORS_M";

//export const ORDER_LOAD_DATATABLE       = 'ORDER_LOAD_DATATABLE';

export const ORDER_CHANGE = "ORDER_CHANGE";
export const ORDER_PROFILE_CHANGE = "ORDER_PROFILE_CHANGE";
export const ORDER_UPDATE_PHONE_CHANGE = "ORDER_UPDATE_PHONE_CHANGE";
export const ORDER_CHANGE_ERRORS = "ORDER_CHANGE_ERRORS";
export const ORDER_DATATABLE_CHANGE = "ORDER_DATATABLE_CHANGE";

export const ORDER_REFUSE         = "ORDER_REFUSE";

const state = {
  order: null,
  order_p: null,
  order_p_c: null,
  errors: {},
  order_m: null,
  errors_m: {},

  order_double_list: null,
  order_list: null,
  order_list_total: 0,
  order_list_loading: false,
  order_refuse_saving: false
};

const actions = {
  [ORDER_CREATE]({ commit, state }, data) {
    let model = data;
    let id = data.id ? data.id : 0;

    return api.create(
      "order.list",
      id,
      model,
      state,
      commit,
      ORDER_SAVING_CHANGE,
      ORDER_CREATE_CHANGE,
      ORDER_CHANGE_ERRORS
    );
  },

  [ORDER_LOAD]({ commit, state }, key) {
    commit(ORDER_CHANGE, null);
    let url = api.main_url + "/api/v2/orders/" + key;

    return http.get(url).then(response => {
      if (response && response.data) {
        response.data.edit_status = {};
        response.data.name_status = {};
        response.data.help_name_status = {};
        response.data.statuses.forEach(function(item, i, arr) {
          response.data.edit_status[item.type] = item.id;
          response.data.name_status[item.parent_id] = item.name;
          response.data.help_name_status[item.type] = item.parent_id;
        });
        commit(ORDER_CHANGE, response.data);
        return true;
      }
    });
  },

  [ORDER_LOAD_DOUBLE_LIST]({ commit, state }, key) {
    return api.load(
      "order.doubles",
      key,
      state,
      commit,
      ORDER_LIST_LOADING_CHANGE,
      ORDER_LOAD_DOUBLE_LIST_CHANGE
    );
  },
  
  [ORDER_SAVE]({ commit, state }, data) {
    commit(ORDER_CHANGE_ERRORS, {});
    let url = api.main_url + "/api/v2/orders/" + data.order_key;

    return http
      .put(url, data)
      .then(response => {
        if (response && response.data) {
          response.data.edit_status = {};
          response.data.name_status = {};
          response.data.help_name_status = {};
          response.data.statuses.forEach(function(item, i, arr) {
            response.data.edit_status[item.type] = item.id;
            response.data.name_status[item.parent_id] = item.name;
            response.data.help_name_status[item.type] = item.parent_id;
          });
          commit(ORDER_CHANGE, response.data);

          return true;
        } else {
          return false;
        }
      })
      .catch(error => { 
        if (error.errors || error.message) {
          commit(ORDER_CHANGE_ERRORS, error.errors);
          Vue.notify({
            group: "app",
            type: "error",
            title: 'Заказ не сохранён!',
            text: api.errors_format(error.errors) + error.message,
            duration: 10000
          });
        }

        return false;
      });
  },
    [ORDER_UPDATE_PHONES]({commit, state}, data) {
        let id = data.id;
        return api.update(
            'order.update_phones', 
            id, 
            data, 
            state, 
            commit, 
            ORDER_SAVING_CHANGE, 
            ORDER_UPDATE_PHONE_CHANGE, 
            ORDER_CHANGE_ERRORS
        );
    },
    [ORDER_GET_PHONES_BY_KEY]({ commit, state }, key) {
        if (!key) {
            return;
        }
        return api.load(
            "order.get_phones_by_key",
            key,
            state,
            commit,
            ORDER_LIST_LOADING_CHANGE,
            ORDER_PROFILE_CHANGE
        );
    },
  [ORDER_BIND_SINGLE_PROJECT]({ commit, state }, data) {
    let url = api.main_url + "/api/v2/orders/bind_single_project/" + data.id;
    return http.put(url, data);
  },
  [ORDER_SET_BP_DELAY]({ commit, state }, data) {
    let url = api.main_url + "/api/v2/order/set_bp_delay/" + data.key;
    return http.put(url, data).then(response => {
        if (response && response.data) {
          commit(ORDER_CHANGE_DIAL_STEP, {
            dial_time:response.data.dial_time,
            current_action: response.data.current_action
          });
          return true;
        } else {
          return false;
        }
      })
      .catch(error => {
        if (error.errors || error.message) {
          commit(ORDER_CHANGE_ERRORS, error.errors);
          Vue.notify({
            group: "app",
            type: "error",
            title: 'Заказ не сохранён!',
            text: api.errors_format(error.errors) + error.message,
            duration: 10000
          });
        }
        return false;
      });
  },
  [ORDER_MANAGER_ID_CHANGE]({ commit, state }, data) {
    let key = data.key;
    let model = {'manager_id':data.manager_id};
    let request = api.update(
      "order.change_m",
      key,
      model,
      state,
      commit,
      ORDER_SAVING_CHANGE_M,
      ORDER_CHANGE_M,
      ORDER_CHANGE_ERRORS_M
    );

    return request;
  },
  [ORDER_LOAD_LIST]({ commit, state }, data) {
    return api.list(
      "order.list",
      null,
      data,
      state,
      commit,
      ORDER_LIST_LOADING_CHANGE,
      ORDER_LIST_CHANGE
    );
  },

  [ORDER_REFUSE]({ state }, id) {
    state.order_refuse_saving = true;
    let url = api.main_url + "/api/v2/orders/refuse_order/" + id;

    return http.post(url).then(response => {
      if (response.message) {
        Vue.notify({
          group: "app",
          type: "success",
          text: response.message,
          duration: 3000
        });
        state.order_refuse_saving = false;
        return true;
      }
    }).catch(error => {
      if (error.message) {
        Vue.notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: error.message,
          duration: 5000
        });
      }
      state.order_refuse_saving = false;
      return false;
    });
  },
};

const mutations = {
  [ORDER_CHANGE](state, data) {
    state.order = data;
  },
  [ORDER_CHANGE_DIAL_STEP](state, data) {
    // Обновление в объекте только нужных свойств.
    state.order = { ...state.order, ...data };
  },
  [ORDER_PROFILE_CHANGE](state, data) {
    state.order_p_c = data;
  },
  [ORDER_UPDATE_PHONE_CHANGE](state, data) {
    state.order_p = data;
  },
  [ORDER_LIST_CHANGE](state, data) {
    if (data) {
      state.order_list = data.data;
      state.order_list.forEach(item => {
        item.created_at = new Date(item.created_at);
      });
      state.order_list_total = data.total;
    } else {
      state.order_list = [];
      state.order_list_total = 0;
    }
  },
  [ORDER_LOAD_DOUBLE_LIST_CHANGE](state, data) {
    if (data) {
      state.order_double_list = data;
    } else {
      state.order_double_list = [];
    }
  },
  [ORDER_LIST_LOADING_CHANGE](state, data) {
    state.order_list_loading = data;
  },
  [ORDER_CHANGE_ERRORS](state, data) {
    state.errors = data;
  },
  [ORDER_CREATE_CHANGE](state, data) {
    if (data) {
      VueRouter.push("/orders/" + data.key);
    }
  },
  [ORDER_SAVING_CHANGE](state, data) {
    state.order_saving = data;
  },
  [ORDER_SAVING_CHANGE_M](state, data) {
    state.order_saving_m = data;
  },
  [ORDER_CHANGE_M](state, data) {
    state.order_m = data;
  },
  [ORDER_CHANGE_ERRORS_M](state, data) {
    state.errors_m = data;
  },
};

export default {
  state,
  actions,
  mutations
};
