import api from '@/lib/api';
import http from '@/lib/http';
import {
  IntentCustom,
  IntentFile,
  IntentPromoCode,
} from './blocks';

class IntentClass {
  className = '';
  itemClassName = '';
  brokenIntent = false;
  intents = {};

  constructor(data = {}) {
    if (_.isObject(data)) {
      _.each(data, (intentData) => {
        if (intentData.samples.length > 0 && intentData.samples[0].type === 'file') {
          this.addIntent(new IntentFile(intentData));
        } else if (intentData.samples.length > 0 && intentData.samples[0].type === 'promo_code') {
          this.addIntent(new IntentPromoCode(intentData));
        } else {
          this.addIntent(new IntentCustom(intentData));
        }
      });
    } else {
      console.log('New bot', 'Bot', 'Section');
    }
  }

  addIntent(intent) {
    this.intents[intent.getId()] = intent;
  }

  /**
   * Проверка на наличие сломанных интентов
   * @author SeregaHelf
   */
  checkIntents() {
    this.eachIntent((intent) => {
      if (_.has(this.intents, intent.id)) {
        if (this.intents[intent.id].broken === true) {
          this.brokenIntent = true;
          return false;
        }
      }
    });
  }

  unload() {
    return {
      brokenIntent: this.brokenIntent,
      intents: this.intents,
    };
  }
}

export default IntentClass;
