import api from './../../lib/api';

export const OWNER_LOAD         = "OWNER_LOAD";
export const OWNER_UPDATE       = "OWNER_UPDATE";
export const OWNER_CREATE       = "OWNER_CREATE";
export const OWNER_LIST_LOAD    = "OWNER_LIST_LOAD";
export const OWNER_GET_API_KEY = "OWNER_GET_API_KEY";

const OWNER_CHANGE              = "OWNER_CHANGE";
const OWNER_LOADING_CHANGE      = "OWNER_LOADING_CHANGE";
const OWNER_SAVING_CHANGE       = "OWNER_SAVING_CHANGE";
const OWNER_ERRORS_CHANGE       = "OWNER_ERRORS_CHANGE";
const OWNER_CREATE_CHANGE       = "OWNER_CREATE_CHANGE";
const OWNER_LIST_CHANGE         = "OWNER_LIST_CHANGE";
const OWNER_LIST_LOADING_CHANGE = "OWNER_LIST_LOADING_CHANGE";
const OWNER_API_KEY_CHANGE      = "OWNER_API_KEY_CHANGE";
const OWNER_API_KEY_LOADING_CHANGE = "OWNER_API_KEY_LOADING_CHANGE";

const state = {
    owner:                 null,

    owner_loading:         false,
    owner_saving:          false,

    owner_list:            [],
    owner_list_loading:    false,

    owner_errors:          {},

    owner_empty:           {
        id: 0,
        name: "",
        is_work: true,
        api_key: "",
        organizations: {id:0}
    },

    owner_api_key: null,
    owner_api_key_loading: null
};

const actions = {
    [OWNER_LIST_LOAD]({commit, state}, data) {
        let id = data.id;
        let params = data.params ? data.params : null;

        return api.list(
            'owners_by_company',
            id,
            params,
            state,
            commit,
            OWNER_LIST_LOADING_CHANGE,
            OWNER_LIST_CHANGE,
        );
    },

    [OWNER_LOAD] ({ state, commit }, id) {
        if (!id) {
            commit(OWNER_CHANGE, state.owner_empty);
            return;
        }
        return api.load(
            'owner',
            id,
            state,
            commit,
            OWNER_LOADING_CHANGE,
            OWNER_CHANGE
        )
    },

    [OWNER_UPDATE] ({ state, commit }, { id, data }) {

        return api.update(
            'owner',
            id,
            data,
            state,
            commit,
            OWNER_SAVING_CHANGE,
            OWNER_CHANGE,
            OWNER_ERRORS_CHANGE
        )
    },

    [OWNER_CREATE] ({ state, commit }, data) {

        return api.create(
            'owners',
            0,
            data,
            state,
            commit,
            OWNER_SAVING_CHANGE,
            OWNER_CREATE_CHANGE,
            OWNER_ERRORS_CHANGE
        )
    },
    [OWNER_GET_API_KEY]({ commit, state }, id) {

        let request = api.load(
            "owners.get_api_key",
            0,
            state,
            commit,
            OWNER_API_KEY_LOADING_CHANGE,
            OWNER_API_KEY_CHANGE
        );

        return request;
    }
};

const mutations = {
    [OWNER_CHANGE](state, data) {
        state.owner = data
    },
    [OWNER_LOADING_CHANGE](state, data) {
        state.owner_loading = data
    },
    [OWNER_SAVING_CHANGE](state, data) {
        state.owner_saving = data
    },
    [OWNER_ERRORS_CHANGE](state, data) {
        state.owner_errors = data
    },
    [OWNER_CREATE_CHANGE](state, data) {
        state.owner_creating = data
    },
    [OWNER_LIST_CHANGE](state, data) {
        if(data){
            state.owner_list = data.data ? data.data : data;
        }
    },
    [OWNER_LIST_LOADING_CHANGE](state, data) {
        state.owner_list_loading = data;
    },
    [OWNER_API_KEY_CHANGE](state, data) {
        state.owner_api_key = data;
    },
    [OWNER_API_KEY_LOADING_CHANGE](state, data) {
        state.owner_api_key_loading = data;
    },
};

export default {
    state,
    actions,
    mutations
}
