import http from './../../lib/http';
import api from './../../lib/api';

export const ROLE_GROUP_LIST                 = 'ROLE_GROUP_LIST';

export const ROLE_GROUP_LOAD                 = 'ROLE_GROUP_LOAD';
export const ROLE_GROUP_UPDATE               = 'ROLE_GROUP_UPDATE';
export const ROLE_GROUP_CREATE               = 'ROLE_GROUP_CREATE';

export const ROLE_GROUP_LOAD_DATATABLE       = 'ROLE_GROUP_LOAD_DATATABLE';

export const ROLE_GROUP_CHANGE               = 'ROLE_GROUP_CHANGE';
const ROLE_GROUP_LOADING_CHANGE              = 'ROLE_GROUP_LOADING_CHANGE';
const ROLE_GROUP_SAVING_CHANGE               = 'ROLE_GROUP_SAVING_CHANGE';
const ROLE_GROUP_ERRORS_CHANGE               = 'ROLE_GROUP_ERRORS_CHANGE';

const ROLE_GROUP_CREATING_CHANGE             = 'ROLE_GROUP_CREATING_CHANGE';

const ROLE_GROUP_DATATABLE_CHANGE            = 'ROLE_GROUP_DATATABLE_CHANGE';
const ROLE_GROUP_DATATABLE_LOADING_CHANGE    = 'ROLE_GROUP_DATATABLE_LOADING_CHANGE';

export const ROLE_GROUP_DELETE		         = 'ROLE_GROUP_DELETE';
export const ROLE_GROUP_DELETE_CHANGE  	     = 'ROLE_GROUP_DELETE_CHANGE';

export const ROLE_GROUP_SET_OWNER_STATUS_CHANGE = 'ROLE_GROUP_SET_OWNER_STATUS_CHANGE';

const state = {
    role_group            : null,
    role_group_loading    : false,
    role_group_saving     : false,
    role_group_errors     : {},

    role_group_list_total     : 0, 
    role_group_list           : null,
    role_group_list_loading   : false,

    role_group_creating           : false,
    role_group_creating_errors    : {},
    role_group_empty              : {
                                  id: 0,
                                  name: "",
                                  description: "",
                                  creator_organization_id: 0
                              }
};

const actions = {
    [ROLE_GROUP_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(ROLE_GROUP_CHANGE, state.role_group_empty);

            return;
        }

        return api.load('role_group', id, state, commit, ROLE_GROUP_LOADING_CHANGE, ROLE_GROUP_CHANGE);
    },

    [ROLE_GROUP_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('role_group', id, model, state, commit, ROLE_GROUP_SAVING_CHANGE, ROLE_GROUP_CHANGE, ROLE_GROUP_ERRORS_CHANGE);
    },

    [ROLE_GROUP_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('role_group.list', id, model, state, commit, ROLE_GROUP_CREATING_CHANGE, ROLE_GROUP_CHANGE, ROLE_GROUP_ERRORS_CHANGE);
    },

    [ROLE_GROUP_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'role_group.list', 
            id, 
            null,
            state, 
            commit, 
            ROLE_GROUP_DATATABLE_LOADING_CHANGE, 
            ROLE_GROUP_DATATABLE_CHANGE);
    },
    [ROLE_GROUP_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'role_group',
            id,
            state,
            commit,
            ROLE_GROUP_DELETE_CHANGE
        );
    },
};

const mutations = {
    [ROLE_GROUP_DATATABLE_LOADING_CHANGE](state, data) {
        state.role_group_list_loading = data;
    },
    [ROLE_GROUP_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.role_group_list        = data.data;
            state.role_group_list_total  = data.total;
        }
        else {
            state.role_group_list        = [];
            state.role_group_list_total  = 0;
        }
    },
    [ROLE_GROUP_CHANGE](state, data) {
        state.role_group  = data;
	    state.role_group_empty = {id:0, name:null};
    },
    [ROLE_GROUP_LOADING_CHANGE](state, data) {
        state.role_group_loading = data;
    },
    [ROLE_GROUP_SAVING_CHANGE](state, data) {
        state.role_group_saving   = data;
    },
    [ROLE_GROUP_ERRORS_CHANGE](state, data) {
        state.role_group_errors   = data;
    },
    [ROLE_GROUP_CREATING_CHANGE](state, data) {
        state.role_group_creating = data;
    },
    [ROLE_GROUP_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.role_group_list) {

                let list = [];
                state.role_group_list.forEach(role_group => {
                    if (role_group.id !== id) {
                        list.push({...role_group});
                    }
                });

                state.role_group_list = list;
            }
        }
    },
    [ROLE_GROUP_SET_OWNER_STATUS_CHANGE](state, data) {
        state.role_group = { ...state.role_group, owner_status: data };
    },
};

export default {
    state,
    actions,
    mutations
}
