import http from './../../lib/http';
import api from './../../lib/api';

export const PACKED_STANDARDS_LOAD                 = 'PACKED_STANDARDS_LOAD';
const PACKED_STANDARDS_LOADING_CHANGE              = 'PACKED_STANDARDS_LOADING_CHANGE';

export const PACKED_STANDARDS_UPDATE               = 'PACKED_STANDARDS_UPDATE';
export const PACKED_STANDARDS_CREATE               = 'PACKED_STANDARDS_CREATE';
const PACKED_STANDARDS_CHANGE                      = 'PACKED_STANDARDS_CHANGE';
const PACKED_STANDARDS_SAVING_CHANGE               = 'PACKED_STANDARDS_SAVING_CHANGE';
const PACKED_STANDARDS_ERRORS_CHANGE               = 'PACKED_STANDARDS_ERRORS_CHANGE';
const PACKED_STANDARDS_CREATE_CHANGE               = 'PACKED_STANDARDS_CREATE_CHANGE';

export const PACKED_STANDARDS_LOAD_DATATABLE       = 'PACKED_STANDARDS_LOAD_DATATABLE';
const PACKED_STANDARDS_DATATABLE_CHANGE            = 'PACKED_STANDARDS_DATATABLE_CHANGE';
const PACKED_STANDARDS_DATATABLE_LOADING_CHANGE    = 'PACKED_STANDARDS_DATATABLE_LOADING_CHANGE';

const state = {
    packed_standards            : null,
    packed_standards_loading    : false,

    packed_standards_saving     : false,
    packed_standards_errors     : {},

    packed_standards_list_total    : 0,
    packed_standards_list          : null,
    packed_standards_list_loading  : false,

    packed_standards_empty          : {
                                        id: 0,
                                        type: null,
                                        number: null,
                                        geo: "",
                                        organization_id: {value:""},
                                        is_work: true
                                    }
};

const actions = {
    [PACKED_STANDARDS_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update(
            'packed_standards',
            id,
            model,
            state,
            commit,
            PACKED_STANDARDS_SAVING_CHANGE,
            PACKED_STANDARDS_CHANGE,
            PACKED_STANDARDS_ERRORS_CHANGE
        );
    },
    [PACKED_STANDARDS_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create(
            'packed_standards.list',
            id,
            model,
            state,
            commit,
            PACKED_STANDARDS_SAVING_CHANGE,
            PACKED_STANDARDS_CREATE_CHANGE,
            PACKED_STANDARDS_ERRORS_CHANGE
        );
    },
    [PACKED_STANDARDS_LOAD_DATATABLE] ({ commit, state }, filter) {
        if (!filter) {
            filter = {
                is_work: true
            };
        }
        return api.list(
            'packed_standards.list',
            0,
            filter,
            state,
            commit,
            PACKED_STANDARDS_DATATABLE_LOADING_CHANGE,
            PACKED_STANDARDS_DATATABLE_CHANGE
        );
    },
    [PACKED_STANDARDS_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(PACKED_STANDARDS_CHANGE, state.packed_standards_empty);
            return;
        }

        return api.load(
            'packed_standards',
            id,
            state,
            commit,
            PACKED_STANDARDS_LOADING_CHANGE,
            PACKED_STANDARDS_CHANGE
        );
    }
};

const mutations = {
    [PACKED_STANDARDS_DATATABLE_LOADING_CHANGE](state, data) {
        state.packed_standards_list_loading = data;
    },
    [PACKED_STANDARDS_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.packed_standards_list          = data.data;
            state.packed_standards_list_total    = data.total;
        } else {
            state.packed_standards_list          = [];
            state.packed_standards_list_total    = 0;
        }
    },
    [PACKED_STANDARDS_CHANGE](state, data) {
        state.packed_standards  = data;
        if (data) {
            if (state.packed_standards_list) {
                let list = [];
                state.packed_standards_list.forEach(packed_standards => {
                    if (packed_standards.id == data.id) {
                        list.push({...data});
                    }
                    else {
                        list.push(packed_standards);
                    }
                });
                state.packed_standards_list = list;
            }
        }
    },
    [PACKED_STANDARDS_SAVING_CHANGE](state, data) {
        state.packed_standards_saving   = data;
    },
    [PACKED_STANDARDS_ERRORS_CHANGE](state, data) {
        state.packed_standards_errors   = data;
    },
    [PACKED_STANDARDS_CREATE_CHANGE](state, data) {
        if (data) {
            if (state.packed_standards_list) {
                state.packed_standards_list.unshift({...data});
            }
        }
    },
    [PACKED_STANDARDS_LOADING_CHANGE](state, data) {
        state.packed_standards_loading = data;
    }
};

export default {
    state,
    actions,
    mutations
}
