import {
  AccostAudio,
  AccostTts,
  AccostHss,
  AccostText,
  AccostDtxt,
  AccostTemplate,
  AccostImage,
  AccostVideo,
  AccostConditionAudio,
  ActionCallTransfer,
  ActionSendingSms,
  ActionHttpRequest,
  ActionFillOrder,
  IntentAny,
  IntentRef,
  ConditionOrderField,
  ConditionRepeat,
  ConditionTransitionIntents, ActionStay,
  ConditionTimeout,
} from './blocks';

const BLOCK_BOT = 'bot';
const BLOCK_USER = 'user';
const BLOCK_ACTION = 'action';
const BLOCK_CONDITION = 'condition';

class Screen {
  id = 0;
  tid = null;
  title = '';
  desc = '';
  color = '';
  accosts = [];
  intents = [];
  conditions = [];
  action = null;
  on_gibberish = null;
  status_id = null;
  is_global = false;
  can_interrupt = false;
  not_mute = false;

  constructor(title = 'New screen') {
    this.title = title;
  }

  get className() {
    return 'Screen';
  }

  addBlock(block, type, data) {
    if (block === BLOCK_BOT) {
      data.position = this.accosts.filter(item => item.start_type == data.start_type).length;
      switch (type) {
      case 'audio':
        this.accosts.push(new AccostAudio(data.id, data.file, data.data, data.text, data.start_type, data.position));
        break;
      case 'tts':
        this.accosts.push(new AccostTts(data.id, data.file, data.data, data.text, data.start_type, data.position));
        break;
      case 'text':
        this.accosts.push(new AccostText(data.id, data.text, data.start_type, data.position));
        break;
      case 'img':
        this.accosts.push(new AccostImage(data.id, data.text, data.file, data.data, data.start_type, data.position));
        break;
      case 'video':
        this.accosts.push(new AccostVideo(data.id, data.text, data.file, data.data, data.start_type, data.position));
        break;
      case 'wa_tpl':
        this.accosts.push(new AccostTemplate(data.id, data.text, data.file, data.data, data.start_type, data.position));
        break;
      case 'hss':
        this.accosts.push(new AccostHss(data.id, data.file, data.data, data.text, data.start_type, data.position));
        break;
      case 'dtxt':
        this.accosts.push(new AccostDtxt(data.id, data.text, data.position));
        break;
      case 'condition_audio':
        this.accosts.push(new AccostConditionAudio(data.id, data.file, data.text, data.start_type, data.position));
        break;
      default:
        throw `Unknown block type ${block}:${type}`;
      }
    } else if (block === BLOCK_USER) {
      switch (type) {
      case 'custom':
      case 'promo_code':
      case 'file':
        this.intents.push(new IntentRef({
          id: data.getId(),
          rid: data.rid,
          name: data.name,
          linked_screen_id: 0,
          linked_screen_tid: null,
          position: this.intents.length,
        }));
        break;
      case 'any':
        this.on_gibberish = new IntentAny(false);
        break;
      default:
        throw `Unknown block type ${block}:${type}`;
      }
    } else if (block === BLOCK_ACTION) {
      switch (type) {
      case 'call_transfer':
        this.action = new ActionCallTransfer({
          type,
          on_success_screen_id: null,
          on_failure_screen_id: null,
          payload: {...data.payload},
        });
        break;
      case 'sending_sms':
        this.action = new ActionSendingSms({
          type,
          on_success_screen_id: null,
          on_failure_screen_id: null,
          payload: {...data.payload},
        });
        break;
      case 'http_request':
        this.action = new ActionHttpRequest({
          type,
          on_success_screen_id: null,
          on_failure_screen_id: null,
          payload: {...data.payload},
        });
        break;
      case 'fill_order':
        this.action = new ActionFillOrder({
          type,
          on_success_screen_id: null,
          on_failure_screen_id: null,
          payload: {...data.payload},
        });
        break;
      case 'stay':
        this.action = new ActionStay({
          type,
          payload: {...data.payload},
        });
        break;
      default:
        this.action = {
          type,
          on_success_screen_id: null,
          on_failure_screen_id: null,
          payload: {...data},
        };
      }
    } else if (block === BLOCK_CONDITION) {
      data.position = this.conditions.length;
      switch (type) {
      case 'order_field':
        this.conditions.push(new ConditionOrderField(data));
        break;
      case 'repeat':
        this.conditions.push(new ConditionRepeat(data));
        break;
      case 'transition_intents':
        this.conditions.push(new ConditionTransitionIntents(data));
        break;
      case 'timeout':
          this.conditions.push(new ConditionTimeout(data));
          break;
      default:

      }
    }
  }

  load(data) {
    this.title = data.title;
    this.desc = data.desc;
    this.color = data.color;
    this.id = data.id;
    this.tid = data.tid;
    this.on_gibberish = new IntentAny(data.on_gibberish);
    this.status_id = data.status_id;
    this.is_global = data.is_global ? true : false;
    this.can_interrupt = data.can_interrupt ? true : false;
    this.not_mute = data.not_mute ? true : false;

    if (_.isArray(data.accosts)) {
      _.each(data.accosts, (accost) => {
        this.addBlock('bot', accost.type, accost);
      });
    }

    if (_.isArray(data.intents)) {
      this.intents = [];
      _.each(data.intents, (intent) => {
        this.intents.push(new IntentRef(intent));
      });
    }

    if (_.isArray(data.conditions)) {
      this.conditions = [];
      _.each(data.conditions, (condition) => {
        switch (condition.type) {
        case 'order_field':
          this.conditions.push(new ConditionOrderField(condition));
          break;
        case 'repeat':
          this.conditions.push(new ConditionRepeat(condition));
          break;
        case 'transition_intents':
          this.conditions.push(new ConditionTransitionIntents(condition));
          break;
        case 'timeout':
          this.conditions.push(new ConditionTimeout(condition));
          break;
        default:

        }
      });
    }

    if (_.isObject(data.action)) {
      switch (data.action.type) {
      case 'call_transfer':
        this.action = new ActionCallTransfer(data.action);
        break;
      case 'sending_sms':
        this.action = new ActionSendingSms(data.action);
        break;
      case 'http_request':
        this.action = new ActionHttpRequest(data.action);
        break;
      case 'fill_order':
        this.action = new ActionFillOrder(data.action);
        break;
      case 'stay':
        this.action = new ActionStay(data.action);
        break;
      default:
        this.action = data.action;
      }
    }

    return this;
  }

  unload() {
    const data = _.cloneDeep(this);
    delete data.parentIndex;
    delete data.index;

    if (data.intents) {
      data.intents.forEach((intent) => {
        delete intent.line;
      });
    }

    data.section = this.parentIndex;
    data.position = this.index;
    data.on_gibberish = this.on_gibberish.linked_screen_id === false ? null : this.on_gibberish.linked_screen_id;

    return data;
  }
}

export default Screen;
