import http from './../../lib/http';
import api from './../../lib/api';

export const CALL_CENTER_LIST                 = 'CALL_CENTER_LIST';

export const CALL_CENTER_LOAD                 = 'CALL_CENTER_LOAD';
export const CALL_CENTER_UPDATE               = 'CALL_CENTER_UPDATE';
export const CALL_CENTER_CREATE               = 'CALL_CENTER_CREATE';

export const CALL_CENTER_LOAD_DATATABLE       = 'CALL_CENTER_LOAD_DATATABLE';

export const CALL_CENTER_CHANGE               = 'CALL_CENTER_CHANGE';
const CALL_CENTER_LOADING_CHANGE              = 'CALL_CENTER_LOADING_CHANGE';
const CALL_CENTER_SAVING_CHANGE               = 'CALL_CENTER_SAVING_CHANGE';
const CALL_CENTER_ERRORS_CHANGE               = 'CALL_CENTER_ERRORS_CHANGE';

const CALL_CENTER_CREATING_CHANGE             = 'CALL_CENTER_CREATING_CHANGE';

const CALL_CENTER_DATATABLE_CHANGE            = 'CALL_CENTER_DATATABLE_CHANGE';
const CALL_CENTER_DATATABLE_LOADING_CHANGE    = 'CALL_CENTER_DATATABLE_LOADING_CHANGE';

export const CALL_CENTER_DELETE				  = 'CALL_CENTER_DELETE';
export const CALL_CENTER_DELETE_CHANGE  	  = 'CALL_CENTER_DELETE_CHANGE';

const state = {
    call_center            : null,
    call_center_loading    : false,
    call_center_saving     : false,
    call_center_errors     : {},

    call_center_list_total     : 0, 
    call_center_list           : null,
    call_center_list_loading   : false,

    call_center_creating           : false,
    call_center_creating_errors    : {},
    call_center_empty              : {
                                  id: 0,
                                  name: ""
                              }
};

const actions = {
    [CALL_CENTER_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(CALL_CENTER_CHANGE, state.call_center_empty);

            return;
        }

        return api.load('call_center', id, state, commit, CALL_CENTER_LOADING_CHANGE, CALL_CENTER_CHANGE);
    },

    [CALL_CENTER_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('call_center', id, model, state, commit, CALL_CENTER_SAVING_CHANGE, CALL_CENTER_CHANGE, CALL_CENTER_ERRORS_CHANGE);
    },

    [CALL_CENTER_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('call_center.list', id, model, state, commit, CALL_CENTER_CREATING_CHANGE, CALL_CENTER_CHANGE, CALL_CENTER_ERRORS_CHANGE);
    },

    [CALL_CENTER_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'call_center.list', 
            id, 
            null,
            state, 
            commit, 
            CALL_CENTER_DATATABLE_LOADING_CHANGE, 
            CALL_CENTER_DATATABLE_CHANGE);
    },
    [CALL_CENTER_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'call_center',
            id,
            state,
            commit,
            CALL_CENTER_DELETE_CHANGE
        );
    },
};

const mutations = {
    [CALL_CENTER_DATATABLE_LOADING_CHANGE](state, data) {
        state.call_center_list_loading = data;
    },
    [CALL_CENTER_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.call_center_list        = data.data;
            state.call_center_list_total  = data.total;
        }
        else {
            state.call_center_list        = [];
            state.call_center_list_total  = 0;
        }
    },
    [CALL_CENTER_CHANGE](state, data) {
        state.call_center  = data;
	state.call_center_empty = {id:0, name: null};
    },
    [CALL_CENTER_LOADING_CHANGE](state, data) {
        state.call_center_loading = data;
    },
    [CALL_CENTER_SAVING_CHANGE](state, data) {
        state.call_center_saving   = data;
    },
    [CALL_CENTER_ERRORS_CHANGE](state, data) {
        state.call_center_errors   = data;
    },
    [CALL_CENTER_CREATING_CHANGE](state, data) {
        state.call_center_creating = data;
    },
    [CALL_CENTER_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.call_center_list) {

                let list = [];
                state.call_center_list.forEach(call_center => {
                    if (call_center.id !== id) {
                        list.push({...call_center});
                    }
                });

                state.call_center_list = list;
            }
        }
    },
};

export default {
    state,
    actions,
    mutations
}
