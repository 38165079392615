import Container from './Container';
import Screen from './Screen';

class Section extends Container {
  constructor(title = 'New section') {
    super(title, 'Section', 'Screen');
  }

  add() {
    return this.push(new Screen());
  }

  get screens() {
    return this.items;
  }

  eachIntent(closure) {
    let next = true;

    _.each(this.screens, screen => {
      _.each(screen.intents, (intent, index) => {
        next = closure(intent, index, screen);
        return next;
      });

      return next;
    });
  }

  load(data) {
    _.each(data, screen => {
      this.add().load(screen);
    });
    return this;
  }

  screen(index) {
    return this.item(index);
  }
}

export default Section;
