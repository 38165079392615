import http from './../../lib/http';
import api from './../../lib/api';

export const ATS_GROUP_LOAD                     = 'ATS_GROUP_LOAD';
export const ATS_GROUP_CREATE                   = 'ATS_GROUP_CREATE';
export const ATS_GROUP_UPDATE                   = 'ATS_GROUP_UPDATE';
export const ATS_GROUP_DELETE                   = 'ATS_GROUP_DELETE';
export const ATS_GROUP_LIST                     = 'ATS_GROUP_LIST';
export const ATS_GROUP_LIST_TO_SELECT           = 'ATS_GROUP_LIST_TO_SELECT';
export const ATS_GROUP_LIST_BY_CONDITION        = 'ATS_GROUP_LIST_BY_CONDITION';
export const ATS_GROUPS_BY_ORGANIZATION         = 'ATS_GROUPS_BY_ORGANIZATION';
export const ATS_GROUP_GET_ORGANIZATIONS        = 'ATS_GROUP_GET_ORGANIZATIONS';
export const ATS_GROUP_ATTACH_ORGANIZATIONS     = 'ATS_GROUP_ATTACH_ORGANIZATIONS';


const ATS_GROUP_CHANGE                          = 'ATS_GROUP_CHANGE';
const ATS_GROUP_LOADING_CHANGE                  = 'ATS_GROUP_LOADING_CHANGE';
const ATS_GROUP_CREATING_CHANGE                 = 'ATS_GROUP_CREATING_CHANGE';
const ATS_GROUP_SAVING_CHANGE                   = 'ATS_GROUP_SAVING_CHANGE';
const ATS_GROUP_DELETE_CHANGE                   = 'ATS_GROUP_DELETE_CHANGE';
const ATS_GROUP_LIST_LOADING_CHANGE             = 'ATS_GROUP_LIST_LOADING_CHANGE';
const ATS_GROUP_LIST_CHANGE                     = 'ATS_GROUP_LIST_CHANGE';
const ATS_GROUP_LIST_TO_SELECT_LOADING_CHANGE   = 'ATS_GROUP_LIST_TO_SELECT_LOADING_CHANGE';
const ATS_GROUP_LIST_TO_SELECT_CHANGE           = 'ATS_GROUP_LIST_TO_SELECT_CHANGE';
const ATS_GROUP_LIST_BY_CONDITION_LOADING_CHANGE = 'ATS_GROUP_LIST_BY_CONDITION_LOADING_CHANGE';
const ATS_GROUP_LIST_BY_CONDITION_CHANGE         = 'ATS_GROUP_LIST_BY_CONDITION_CHANGE';
const ATS_GROUPS_BY_ORGANIZATION_LOADING_CHANGE = 'ATS_GROUPS_BY_ORGANIZATION_LOADING_CHANGE';
const ATS_GROUPS_BY_ORGANIZATION_CHANGE         = 'ATS_GROUPS_BY_ORGANIZATION_CHANGE';
const ATS_GROUP_ORGANIZATIONS_LOADING_CHANGE    = 'ATS_GROUP_ORGANIZATIONS_LOADING_CHANGE';
const ATS_GROUP_ORGANIZATIONS_CHANGE            = 'ATS_GROUP_ORGANIZATIONS_CHANGE';
const ATS_GROUP_ATTACH_ORGANIZATIONS_CHANGE     = 'ATS_GROUP_ATTACH_ORGANIZATIONS_CHANGE';


const ATS_GROUP_ERRORS_CHANGE                   = 'ATS_GROUP_ERRORS_CHANGE';

const state = {
    ats_group: null,
    ats_group_loading: false,
    ats_group_saving: false,
    ats_group_creating: false,
    ats_group_errors: {},

    ats_group_organizations_loading: false,
    ats_group_organizations: [],
    ats_group_organizations_total: 0,

    ats_group_attaching_organizations: false,

    ats_group_list: [],
    ats_group_list_total: 0,
    ats_group_list_loading: false,

    ats_group_list_by_condition: [],
    ats_group_list_by_condition_total: 0,
    ats_group_list_by_condition_loading: false,

    ats_groups_by_organization: [],
    ats_groups_by_organization_total: 0,
    ats_groups_by_organization_loading: false,
    ats_group_list_to_select: [],
    ats_group_list_to_select_total: false,
    ats_group_empty: {
        id: 0,
        name: "",
        description: null,
        is_work: true,
        ats_id: false,
        ats_name: "",
        organizations: []
    }
};

const actions = {
    [ATS_GROUP_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(ATS_GROUP_CHANGE, state.ats_group_empty);
            return;
        }

        return api.load('ats_groups', id, state, commit, ATS_GROUP_LOADING_CHANGE, ATS_GROUP_CHANGE);
    },
    [ATS_GROUP_CREATE] ({commit, state}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create('ats_groups.list', id, model, state, commit, ATS_GROUP_CREATING_CHANGE, ATS_GROUP_CHANGE, ATS_GROUP_ERRORS_CHANGE);
    },
    [ATS_GROUP_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update('ats_groups', id, model, state, commit, ATS_GROUP_SAVING_CHANGE, ATS_GROUP_CHANGE, ATS_GROUP_ERRORS_CHANGE);
    },
    [ATS_GROUP_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete('ats_groups', id, state, commit, ATS_GROUP_DELETE_CHANGE);
    },
    [ATS_GROUP_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = {
                is_work: true,
                take: 1000
            };
        }

        return api.list(
            'ats_groups.list',
            0,
            filter,
            state,
            commit,
            ATS_GROUP_LIST_LOADING_CHANGE,
            ATS_GROUP_LIST_CHANGE
        );
    },
    [ATS_GROUP_LIST_TO_SELECT] ({commit, state}, filter) {
        return api.list(
            'ats_groups.list_to_select',
            0,
            filter,
            state,
            commit,
            ATS_GROUP_LIST_TO_SELECT_LOADING_CHANGE,
            ATS_GROUP_LIST_TO_SELECT_CHANGE
        );
    },
    [ATS_GROUP_LIST_BY_CONDITION] ({commit, state}, data) {
        if (!data) {
            data = {
                filter: {
                    is_work: true,
                    take: 100,
                },
            };
        }

        return api.list(
            'ats_groups.get_list_by_condition',
            0,
            data,
            state,
            commit,
            ATS_GROUP_LIST_BY_CONDITION_LOADING_CHANGE,
            ATS_GROUP_LIST_BY_CONDITION_CHANGE
        );
    },
    [ATS_GROUPS_BY_ORGANIZATION] ({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let filter = {
            skip: data.skip,
            take: data.take,
        };

        return api.list(
            'ats_groups.by_organization',
            id,
            filter,
            state,
            commit,
            ATS_GROUPS_BY_ORGANIZATION_LOADING_CHANGE,
            ATS_GROUPS_BY_ORGANIZATION_CHANGE
        );
    },
    [ATS_GROUP_GET_ORGANIZATIONS] ({commit, state}, id) {
        return api.list(
            'ats_groups.get_organizations',
            id,
            null,
            state,
            commit,
            ATS_GROUP_ORGANIZATIONS_LOADING_CHANGE,
            ATS_GROUP_ORGANIZATIONS_CHANGE
        );
    },
    [ATS_GROUP_ATTACH_ORGANIZATIONS] ({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.create('ats_groups.attach_organizations', id, model, state, commit, ATS_GROUP_ATTACH_ORGANIZATIONS_CHANGE, ATS_GROUP_ORGANIZATIONS_CHANGE, ATS_GROUP_ERRORS_CHANGE);
    }
};

const mutations = {
    [ATS_GROUP_LIST_LOADING_CHANGE] (state, data) {
        state.ats_group_list_loading = data;
    },
    [ATS_GROUP_CREATING_CHANGE](state, data) {
        state.ats_group_creating = data;
    },
    [ATS_GROUP_SAVING_CHANGE](state, data) {
        state.ats_group_saving = data;
    },
    [ATS_GROUP_LIST_CHANGE] (state, data) {
        if(data) {
            state.ats_group_list        = data.data;
            state.ats_group_list_total  = data.total;
        }
        else {
            state.ats_group_list        = [];
            state.ats_group_list_total  = 0;
        }
    },

    [ATS_GROUP_LIST_TO_SELECT_LOADING_CHANGE] (state, data) {
        state.ats_group_list_to_select_loading = data;
    },
    [ATS_GROUP_LIST_TO_SELECT_CHANGE] (state, data) {
        if (data) {
            state.ats_group_list_to_select        = data.data;
            state.ats_group_list_to_select_total  = data.total;
        } else {
            state.ats_group_list_to_select        = [];
            state.ats_group_list_to_select_total  = 0;
        }
    },

    [ATS_GROUP_LIST_BY_CONDITION_LOADING_CHANGE] (state, data) {
        state.ats_group_list_by_condition_loading = data;
    },
    [ATS_GROUP_LIST_BY_CONDITION_CHANGE] (state, data) {
        if (data) {
            state.ats_group_list_by_condition = data.data;
            state.ats_group_list_by_condition_total = data.total;
        } else {
            state.ats_group_list_by_condition = [];
            state.ats_group_list_by_condition_total = 0;
        }
    },

    [ATS_GROUPS_BY_ORGANIZATION_LOADING_CHANGE] (state, data) {
        state.ats_groups_by_organization_loading = data;
    },
    [ATS_GROUPS_BY_ORGANIZATION_CHANGE] (state, data) {
        if(data) {
            state.ats_groups_by_organization        = data.data;
            state.ats_groups_by_organization_total  = data.total;
        }
        else {
            state.ats_groups_by_organization        = [];
            state.ats_groups_by_organization_total  = 0;
        }
    },
    [ATS_GROUP_LOADING_CHANGE] (state, data) {
        state.ats_group_loading = data;
    },
    [ATS_GROUP_CHANGE] (state, data) {
        if(data && typeof data === 'object'){
            state.ats_group_errors = {};
            state.ats_group = data;
            state.ats_group_empty = {
                id: 0,
                name: "",
                description: null,
                is_work: true,
                ats_id: false,
                ats_name: "",
                organizations: []
            }
        }
    },
    [ATS_GROUP_ORGANIZATIONS_LOADING_CHANGE] (state, data) {
        state.ats_group_organizations_loading = data;
    },
    [ATS_GROUP_ORGANIZATIONS_CHANGE] (state, data) {
        if (data) {
            state.ats_group_organizations = data.data;
            state.ats_group_organizations_total = data.total;
        } else {
            state.ats_group_organizations = []
            state.ats_group_organizations_total = 0;
        }
    },
    [ATS_GROUP_ATTACH_ORGANIZATIONS_CHANGE] (state, data) {
        state.ats_group_attaching_organizations = data;
    },
    [ATS_GROUP_ERRORS_CHANGE](state, data) {
        state.ats_group_errors = data;
    },
    [ATS_GROUP_DELETE_CHANGE](state, id) {
        if (id) {
            if (state.ats_group_list) {
                let list = [];
                state.ats_group_list.forEach(ats_group => {
                    if (ats_group.id !== id) {
                        list.push({...ats_group});
                    }
                });

                state.ats_group_list = list;
            }
        }
    }
};

export default {
    state,
    actions,
    mutations
}
