import api from './../../lib/api';

export const HP_BASE_LOAD                 = 'HP_BASE_LOAD';
const HP_BASE_LOADING_CHANGE              = 'HP_BASE_LOADING_CHANGE';

export const HP_BASE_UPDATE               = 'HP_BASE_UPDATE';
export const HP_BASE_CREATE               = 'HP_BASE_CREATE';
const HP_BASE_CHANGE                      = 'HP_BASE_CHANGE';
const HP_BASE_SAVING_CHANGE               = 'HP_BASE_SAVING_CHANGE';
const HP_BASE_ERRORS_CHANGE               = 'HP_BASE_ERRORS_CHANGE';
const HP_BASE_CREATE_CHANGE               = 'HP_BASE_CREATE_CHANGE';

export const HP_BASE_LOAD_DATATABLE       = 'HP_BASE_LOAD_DATATABLE';
const HP_BASE_DATATABLE_CHANGE            = 'HP_BASE_DATATABLE_CHANGE';
const HP_BASE_DATATABLE_LOADING_CHANGE    = 'HP_BASE_DATATABLE_LOADING_CHANGE';

const state = {
    hp_base            : null,
    hp_base_loading    : false,

    hp_base_saving     : false,
    hp_base_errors     : {},

    hp_base_list_total    : 0,
    hp_base_list          : null,
    hp_base_list_loading  : false,

    hp_base_empty          : {
                                id: 0,
                                name: "",
                                comment: ""
                            }
};

const actions = {
    [HP_BASE_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update(
            'hp_base',
            id,
            model,
            state,
            commit,
            HP_BASE_SAVING_CHANGE,
            HP_BASE_CHANGE,
            HP_BASE_ERRORS_CHANGE
        );
    },
    [HP_BASE_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create(
            'hp_base.list',
            id,
            model,
            state,
            commit,
            HP_BASE_SAVING_CHANGE,
            HP_BASE_CREATE_CHANGE,
            HP_BASE_ERRORS_CHANGE
        );
    },
    [HP_BASE_LOAD_DATATABLE] ({ commit, state }, filter) {
        if (!filter) {
            filter = {
                is_work: true
            };
        }
        return api.list(
            'hp_base.list',
            0,
            filter,
            state,
            commit,
            HP_BASE_DATATABLE_LOADING_CHANGE,
            HP_BASE_DATATABLE_CHANGE
        );
    },
    [HP_BASE_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(HP_BASE_CHANGE, state.hp_base_empty);
            return;
        }

        return api.load(
            'hp_base',
            id,
            state,
            commit,
            HP_BASE_LOADING_CHANGE,
            HP_BASE_CHANGE
        );
    }
};

const mutations = {
    [HP_BASE_DATATABLE_LOADING_CHANGE](state, data) {
        state.hp_base_list_loading = data;
    },
    [HP_BASE_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.hp_base_list          = data.data;
            state.hp_base_list_total    = data.total;
        } else {
            state.hp_base_list          = [];
            state.hp_base_list_total    = 0;
        }
    },
    [HP_BASE_CHANGE](state, data) {
        state.hp_base  = data;
        if (data) {
            if (state.hp_base_list) {
                let list = [];
                state.hp_base_list.forEach(hp_base => {
                    if (hp_base.id == data.id) {
                        list.push({...data});
                    }
                    else {
                        list.push(hp_base);
                    }
                });
                state.hp_base_list = list;
            }
        }
    },
    [HP_BASE_SAVING_CHANGE](state, data) {
        state.hp_base_saving   = data;
    },
    [HP_BASE_ERRORS_CHANGE](state, data) {
        state.hp_base_errors   = data;
    },
    [HP_BASE_CREATE_CHANGE](state, data) {
        if (data) {
            if (state.hp_base_list) {
                state.hp_base_list.unshift({...data});
            }
        }
    },
    [HP_BASE_LOADING_CHANGE](state, data) {
        state.hp_base_loading = data;
    }
};

export default {
    state,
    actions,
    mutations
}
