import api from './../../lib/api';

export const RECOGNITION_LANG_LIST            = 'RECOGNITION_LANG_LIST';
const RECOGNITION_LANG_LIST_LOADING_CHANGE    = 'RECOGNITION_LANG_LIST_LOADING_CHANGE';
const RECOGNITION_LANG_LIST_CHANGE            = 'RECOGNITION_LANG_LIST_CHANGE';

const state = {
    recognition_lang_list           : [],
    recognition_lang_list_loading   : false,
};

const actions = {
    [RECOGNITION_LANG_LIST] ({ commit, state }, data) {
        return api.list(
            'speech.list', 0, data, state, commit, RECOGNITION_LANG_LIST_LOADING_CHANGE, RECOGNITION_LANG_LIST_CHANGE
        );
    },
};

const mutations = {
    [RECOGNITION_LANG_LIST_LOADING_CHANGE](state, data) {
        state.recognition_lang_list_loading = data;
    },
    [RECOGNITION_LANG_LIST_CHANGE](state, data) {
        if(data) {
            state.recognition_lang_list        = data.data;
        } else {
            state.recognition_lang_list        = [];
        }
    },
};

export default {
    state,
    actions,
    mutations
}
