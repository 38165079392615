import http from "./../../lib/http";
import api from "./../../lib/api";

export const SMS_CHECK_CREATE   = "SMS_CHECK_CREATE";
const SMS_CHECK_CREATING_CHANGE = "SMS_CHECK_CREATING_CHANGE";
const SMS_CHECK_CHANGE          = "SMS_CHECK_CHANGE";
const SMS_CHECK_ERRORS_CHANGE   = "SMS_CHECK_ERRORS_CHANGE";

export const REPORT_BY_SMS_LOAD    = "REPORT_BY_SMS_LOAD";
const REPORT_BY_SMS_LOADING_CHANGE = "REPORT_BY_SMS_LOADING_CHANGE";
const REPORT_BY_SMS_CHANGE         = "REPORT_BY_SMS_CHANGE";

export const SMS_BY_PHONE_LOAD    = "SMS_BY_PHONE_LOAD";
const SMS_BY_PHONE_CHANGE         = "SMS_BY_PHONE_CHANGE";
const SMS_BY_PHONE_LOADING_CHANGE = "SMS_BY_PHONE_LOADING_CHANGE";

export const GET_SMS_STATE        = "GET_SMS_STATE";

const state = {
  sms_check: null,
  sms_check_errors: {},
  sms_check_creating:false,
  report_sms_list_total: 0,
  report_sms           : null,
  report_sms_loading   : false,
  sms_phone_loading    : 0,
  sms_phone            : null,
  sms_phone_total      : false

};

const actions = {
  [SMS_CHECK_CREATE]({ commit, state }, data) {
    let model = data.model;
    
    return api.create(
      "send_sms_check",
      0,
      model,
      state,
      commit,
      SMS_CHECK_CREATING_CHANGE,
      SMS_CHECK_CHANGE,
      SMS_CHECK_ERRORS_CHANGE
    );
    },
    [REPORT_BY_SMS_LOAD] ({ commit, state }, data) {       
          return api.list(
              'report_by_sms',
              null,
              data,
              state,
              commit,
              REPORT_BY_SMS_LOADING_CHANGE,
              REPORT_BY_SMS_CHANGE
        );                
    },
    [SMS_BY_PHONE_LOAD] ({ commit, state }, data) {       
          return api.list(
              'sms_by_phone',
              null,
              data,
              state,
              commit,
              SMS_BY_PHONE_LOADING_CHANGE,
              SMS_BY_PHONE_CHANGE
        );                
    },
    [GET_SMS_STATE] ({ commit, state }, data) {       
          return api.list(
              'get_sms_state',
              null,
              data,
              state,
              commit,
              REPORT_BY_SMS_LOADING_CHANGE,
              REPORT_BY_SMS_CHANGE
        );                
    }

};

const mutations = {
  [SMS_CHECK_CHANGE](state, data) {
    if(data) {
      state.sms_check        = data.data;
    }
    else {
      state.sms_check        = [];
    }
  },
  [SMS_CHECK_CREATING_CHANGE](state, data) {
    state.sms_check_creating = data;
  },
  [SMS_CHECK_ERRORS_CHANGE](state, data) {
    state.sms_check_errors = data;
  },
  [REPORT_BY_SMS_LOADING_CHANGE](state, data) {
      state.report_sms_loading = data;
  },
  [REPORT_BY_SMS_CHANGE](state, data) {

      if (data) {
          state.report_sms          = data.data;
          state.report_sms_total    = data.total;
      } else {
          state.report_sms          = [];
          state.report_sms_total    = 0;
      }
  },
  [SMS_BY_PHONE_LOADING_CHANGE](state, data) {
      state.sms_phone_loading = data;
  },
  [SMS_BY_PHONE_CHANGE](state, data) {

      if (data) {
          state.sms_phone          = data.data;
          state.sms_phone_total    = data.total;
      } else {
          state.sms_phone          = [];
          state.sms_phone_total    = 0;
      }
    }
};

export default {
  state,
  actions,
  mutations
};
