import api from './../../lib/api';

export const NLU_GROUP_CREATE = 'NLU_GROUP_CREATE';
export const NLU_GROUP_DELETE = 'NLU_GROUP_DELETE';
export const NLU_GROUP_LOAD_HISTORY = 'NLU_GROUP_LOAD_HISTORY';
export const NLU_GROUP_LOAD_LIST = 'NLU_GROUP_LOAD_LIST';
export const NLU_GROUP_LOAD_LIST_BY_FILTER = 'NLU_GROUP_LOAD_LIST_BY_FILTER';
export const NLU_GROUP_UPDATE = 'NLU_GROUP_UPDATE';

const NLU_GROUP_HISTORY_LOADING_CHANGE = 'NLU_GROUP_HISTORY_LOADING_CHANGE';
const NLU_GROUP_HISTORY_CHANGE = 'NLU_GROUP_HISTORY_CHANGE';
const NLU_GROUP_LIST_LOADING_CHANGE = 'NLU_GROUP_LIST_LOADING_CHANGE';
const NLU_GROUP_LIST_CHANGE = 'NLU_GROUP_LIST_CHANGE';
const NLU_GROUP_LIST_BY_FILTER_LOADING_CHANGE = 'NLU_GROUP_LIST_BY_FILTER_LOADING_CHANGE';
const NLU_GROUP_LIST_BY_FILTER_CHANGE = 'NLU_GROUP_LIST_BY_FILTER_CHANGE';
const NLU_GROUP_LOADING = 'NLU_GROUP_LOADING';
const NLU_GROUP_LOADED = 'NLU_GROUP_LOADED';
const NLU_GROUP_ERRORS = 'NLU_GROUP_ERRORS';
const NLU_GROUP_DELETING = 'NLU_GROUP_DELETING';

const state = {
    group: {},
    group_history_loading: false,
    group_history: [],
    group_history_total: 0,
    group_list_loading: false,
    group_list: [],
    group_list_by_filter_loading: false,
    group_list_by_filter: [],
    group_errors: {},
};

const actions = {
    [NLU_GROUP_LOAD_HISTORY] ({ commit, state }, data) {
        return api.list(
            'nlu.groups.history', 0, data, state, commit, NLU_GROUP_HISTORY_LOADING_CHANGE, NLU_GROUP_HISTORY_CHANGE
        );
    },
    [NLU_GROUP_LOAD_LIST] ({ commit, state }, data) {
        return api.list(
            'nlu.groups', 0, data, state, commit, NLU_GROUP_LIST_LOADING_CHANGE, NLU_GROUP_LIST_CHANGE
        );
    },
    [NLU_GROUP_LOAD_LIST_BY_FILTER] ({ commit, state }, data) {
        return api.list(
            'nlu.groups', 0, data, state, commit, NLU_GROUP_LIST_BY_FILTER_LOADING_CHANGE, NLU_GROUP_LIST_BY_FILTER_CHANGE
        );
    },
    async [NLU_GROUP_CREATE]({ commit, state }, data) {
        const success = await api.create(
            'nlu.groups',
            0,
            data,
            state,
            commit,
            NLU_GROUP_LOADING,
            NLU_GROUP_LOADED,
            NLU_GROUP_ERRORS
        ).catch((error) => {
            return Promise.reject(state.group_errors);
        });

        if (success) {
            return Promise.resolve(state.group);
        }
    },
    [NLU_GROUP_UPDATE]({ commit, state }, data) {
        return api.update(
            'nlu.group',
            data.id,
            data,
            state,
            commit,
            NLU_GROUP_LOADING,
            NLU_GROUP_LOADED,
            NLU_GROUP_ERRORS
        );
    },
    [NLU_GROUP_DELETE]({ commit, state }, id) {
        return api.delete(
            'nlu.group',
            id,
            state,
            commit,
            NLU_GROUP_DELETING
        );
    },
};

const mutations = {
    [NLU_GROUP_HISTORY_LOADING_CHANGE](state, data) {
        state.group_history_loading = data;
    },
    [NLU_GROUP_HISTORY_CHANGE](state, data) {
        if (data) {
            state.group_history = data.data;
            state.group_history_total = data.total;
        } else {
            state.group_history = [];
            state.group_history_total = 0;
        }
    },
    [NLU_GROUP_LIST_LOADING_CHANGE](state, data) {
        state.group_list_loading = data;
    },
    [NLU_GROUP_LIST_CHANGE](state, data) {
        if(data) {
            state.group_list = data.data;
        } else {
            state.group_list = [];
        }
    },
    [NLU_GROUP_LIST_BY_FILTER_LOADING_CHANGE](state, data) {
        state.group_list_by_filter_loading = data;
    },
    [NLU_GROUP_LIST_BY_FILTER_CHANGE](state, data) {
        if (data) {
            console.log(data, 'data');
            state.group_list_by_filter = data.data;
        } else {
            state.group_list_by_filter = [];
        }
    },
    [NLU_GROUP_LOADING](state, data) {
        state.group_loading = data;
    },
    [NLU_GROUP_DELETING](state, id) {
        const index = state.group_list.findIndex((group) => group.id === id);
        if (index < 0) {
            return;
        }
        state.group_list.splice(index, 1);
    },
    [NLU_GROUP_LOADED](state, data) {
        if (data) {
            state.group = data;

            const inList = state.group_list.find((group) => group.id === data.id);
            if (inList) {
                inList.title = data.title;
                inList.description = data.description;
            }
        }
    },
    [NLU_GROUP_ERRORS](state, data) {
        state.group_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}
