import http from './../../lib/http';
import api from './../../lib/api';

// Загрузить АТС с бэка по ID
export const TARIFF_ORGANIZATION_LOAD      = 'TARIFF_ORGANIZATION_LOAD';
export const TARIFF_ORGANIZATION_SAVE      = 'TARIFF_ORGANIZATION_SAVE';
export const TARIFF_ORGANIZATION_DATATABLE = 'TARIFF_ORGANIZATION_DATATABLE';
export const TARIFF_ORGANIZATION_SET_EMPTY = 'TARIFF_ORGANIZATION_SET_EMPTY';

export const TARIFF_SIP_LIST       = 'TARIFF_SIP_LIST';
export const TARIFF_ATS_USERS_LIST = 'TARIFF_ATS_USERS_LIST';

const TARIFF_ORGANIZATION_LOADING_CHANGE           = 'TARIFF_ORGANIZATION_LOADING_CHANGE';
const TARIFF_ORGANIZATION_CHANGE                   = 'TARIFF_ORGANIZATION_CHANGE';
const ERRORS_TARIFF_ORGANIZATION_LOAD              = 'ERRORS_TARIFF_ORGANIZATION_LOAD';
const LOADING                                      = 'LOADING';
const LOADED_UPDATE                                = 'LOADED_UPDATE';
const ERRORS_UPDATE                                = 'ERRORS_UPDATE';
const TARIFF_ORGANIZATION_DATATABLE_LOADING_CHANGE = 'TARIFF_ORGANIZATION_DATATABLE_LOADING_CHANGE';
const TARIFF_ORGANIZATION_DATATABLE_CHANGE         = 'TARIFF_ORGANIZATION_DATATABLE_CHANGE';
const SET_EMPTY                                    = 'SET_EMPTY';
const TARIFF_ATS_USER_LIST_LOADING_CHANGE          = 'TARIFF_ATS_USER_LIST_LOADING_CHANGE';
const TARIFF_ATS_USER_LIST_CHANGE                  = 'TARIFF_ATS_USER_LIST_CHANGE';
const TARIFF_SIP_LIST_LOADING_CHANGE               = 'TARIFF_SIP_LIST_LOADING_CHANGE';
const TARIFF_SIP_LIST_CHANGE                       = 'TARIFF_SIP_LIST_CHANGE';

const org_empty = () => {
    return {
        info: null,
        tariffs: []
    }
}

const state = {
    organization                     : org_empty(),
    organization_loading             : {},
    organization_errors              : {},
    loading                          : {},
    organization_list_loading        : false,
    organization_list                : null,
    organization_list_total          : 0,
    tariff_ats_user_list             : [],
    tariff_ats_user_list_total       : 0,
    tariff_ats_user_list_loading     : false,
    tariff_sip_list: [],
    tariff_sip_list_total: 0,
    tariff_sip_list_loading: false
};

const actions = {
    [TARIFF_ORGANIZATION_LOAD] ({ commit, state }, id) {
        return api.load(
            'tariff.organization',
            id,
            state,
            commit,
            TARIFF_ORGANIZATION_LOADING_CHANGE,
            TARIFF_ORGANIZATION_CHANGE,
            ERRORS_TARIFF_ORGANIZATION_LOAD
        );
    },
    [TARIFF_ORGANIZATION_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'tariff.organization.list', 
            0,
            data,
            state,
            commit,
            TARIFF_ORGANIZATION_DATATABLE_LOADING_CHANGE, 
            TARIFF_ORGANIZATION_DATATABLE_CHANGE);
    },
    [TARIFF_ORGANIZATION_SAVE]({ commit, state }, data) {
        if (data.type != "new") {
            return api.update('tariff.organization.update', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        } else {
            return api.create('tariff.organization.create', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        }
    },
    [TARIFF_ORGANIZATION_SET_EMPTY]({ commit, state }) {
        commit(SET_EMPTY);
    },

    [TARIFF_ATS_USERS_LIST] ({commit, state}, id) {
        return api.list(
            'tariff.ats_users.list',
            id,
            null,
            state,
            commit,
            TARIFF_ATS_USER_LIST_LOADING_CHANGE,
            TARIFF_ATS_USER_LIST_CHANGE
        );
    },
    [TARIFF_SIP_LIST] ({commit, state}, data) {
        let id;
        let params = null
        if(data && typeof data === 'object') {
            id = data.id
            params = data.params || null
        } else id = data

        return api.list(
            'tariff.sips.list',
            id,
            params,
            state,
            commit,
            TARIFF_SIP_LIST_LOADING_CHANGE,
            TARIFF_SIP_LIST_CHANGE
        );
    },
};

const mutations = {
    [TARIFF_ORGANIZATION_LOADING_CHANGE] (state, data) {
        state.organization_loading = data;
    },
    [TARIFF_ORGANIZATION_CHANGE] (state, data) {
        if(data){
            state.organization = data;
        }
    },
    [ERRORS_TARIFF_ORGANIZATION_LOAD](state, data) {
        state.organization_errors = data;
    },
    [LOADING](state, data) {
        state.loading        = data;
    },
    [LOADED_UPDATE](state, data) {
        if (data) {
            state.organization = data;
        }
    },
    [ERRORS_UPDATE](state, data) {
        state.organization_errors = data;
    },
    [TARIFF_ORGANIZATION_DATATABLE_LOADING_CHANGE](state, data) {
        state.organization_list_loading = data;
    },
    [TARIFF_ORGANIZATION_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.organization_list        = data.data;
            state.organization_list_total  = data.total;
        }
        else {
            state.organization_list        = [];
            state.organization_list_total  = 0;
        }
    },
    [SET_EMPTY](state) {
        state.organization = org_empty();
    },
    [TARIFF_ATS_USER_LIST_LOADING_CHANGE] (state, data) {
        state.tariff_ats_user_list_loading = data;
    },
    [TARIFF_ATS_USER_LIST_CHANGE] (state, data) {
        if(data) {
            state.tariff_ats_user_list        = data.data;
            state.tariff_ats_user_list_total  = data.total;
        }
        else {
            state.tariff_ats_user_list        = [];
            state.tariff_ats_user_list_total  = 0;
        }
    },
    [TARIFF_SIP_LIST_LOADING_CHANGE] (state, data) {
        state.tariff_sip_list_loading = data;
    },
    [TARIFF_SIP_LIST_CHANGE] (state, data) {
        if(data) {
            state.tariff_sip_list        = data.data;
            state.tariff_sip_list_total  = data.total;
        }
        else {
            state.tariff_sip_list        = [];
            state.tariff_sip_list_total  = 0;
        }
    }
};

export default {
    state,
    actions,
    mutations
}
