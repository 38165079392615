window.axios = require('axios');

window._ = require('lodash');

import Vue from 'vue';
window.Vue = Vue;

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage, {
    name: 'ls',
    bind: false
});


import { mapState } from 'vuex';
Vue.mixin({
    computed : {
        ...mapState({
            auth_user    : state => state.users.auth_user
        }),
        /**
         * По имени компонента и значению языка пользоателя подключает языковой файл.
         * Языковой файл должен лежать ./langs/Имя компонента/Языковое значение пользователя.json
         * @return array Массив с переводом
         */
        getTranslate() {
            let translate = {};
            try {
                translate = require(`./langs/${this.$options.name}/${this.auth_user.lang}.json`);
            } catch {
                return translate | {};
            }
            return translate;
        }
    },
    methods : {
        can(permission) {
            if(!this.auth_user || !this.auth_user.permission) {
                return false;
            }

            var item = "";

            for(var i=0; i<permission.split(".").length; i++){
                if(i==0){
                    item = permission.split(".")[i];
                }else{
                    item += "." + permission.split(".")[i];
                }

                if(this.auth_user.permission[item])
                    return true;
            };

            return false;
        },
        inputError (key) {
            if(this.errors[key]) {
                return this.errors[key].join(' ');
            }
            else {
                return '';
            }
        },
        inputState(key) {
            return this.errors[key] ? false : null;
        },
        dxInputState(field_name) {
            return {
                'border border-danger': this.inputState(field_name) === false
            };
        },
        dxInputStateV2(field_name) {
            return this.inputState(field_name) !== false;
        },
        /**
         * Возвращает бутстраповский variant соответствующий статусу пользователя АТС
         * @method sipStatusVariant
         * @param  {String}  status_name_en Статус пользователя АТС (английский вариант)
         * @param  {Boolean} option_in_call Приём входящих звонков. Брать из одноимённого поля пользователя АТС
         * @return {String}
         *
         * FIXME: Заменить этим методом всё аналоги получения статуса пользователя АТС
         */
        sipStatusVariant(status_name_en, option_in_call) {
            if (!!!status_name_en) {
                return "dark";
            }
            let status = status_name_en.toLowerCase()
            let oic = option_in_call
            if (status == "offline") {
                return "danger";
            }
            if (status == "speak") {
                return "warning";
            }
            if (!oic) {
                return "secondary";
            }
            if (status == "online") {
                return "success";
            }
            if (status == "ringing") {
                return "primary";
            }
        }
    }
});
