import http from './../../lib/http';
import api from './../../lib/api';


export const PERMISSION_LOAD        = 'PERMISSION_LOAD';
export const PERMISSION_UPDATE      = 'PERMISSION_UPDATE';
export const PERMISSION_CREATE      = 'PERMISSION_CREATE';
export const PERMISSION_LIST_LOAD   = 'PERMISSION_LIST_LOAD';
export const PERMISSION_MAX_LOAD    = 'PERMISSION_MAX_LOAD';

export const PERMISSION_CHANGE          = 'PERMISSION_CHANGE';
const PERMISSION_LOADING_CHANGE         = 'PERMISSION_LOADING_CHANGE';
const PERMISSION_SAVING_CHANGE          = 'PERMISSION_SAVING_CHANGE';
export const PERMISSION_ERRORS_CHANGE   = 'PERMISSION_ERRORS_CHANGE';

const PERMISSION_MAX_CHANGE             = 'PERMISSION_MAX_CHANGE';
const PERMISSION_MAX_LOADING_CHANGE     = 'PERMISSION_MAX_LOADING_CHANGE';

const PERMISSION_CREATE_CHANGE          = 'PERMISSION_CREATE_CHANGE';

const PERMISSION_LIST_CHANGE            = 'PERMISSION_LIST_CHANGE';
const PERMISSION_LIST_LOADING_CHANGE    = 'PERMISSION_LIST_LOADING_CHANGE';


const state = {
    organization_id         : 0,

    permission             : null,
    permission_loading     : false,
    permission_saving      : false,
    permission_errors      : {},

    permission_max              : null,
    permission_max_loading      : false,

    permission_list             : null,
    permission_list_loading     : false,
};


const actions = {
    [PERMISSION_LOAD]({ commit, state }, id){
        if(state.permission_list) {
            let ret = false;
            state.permission_list.forEach(perm => {
                if(perm.id == id) {
                    let newPerm = {
                        id  : id
                    };

                    let keys = ['api', 'order', 'orders_data', 'orders_fields'];
                    keys.forEach(key => {
                       newPerm[key] = {};
                       Object.keys(perm[key]).forEach(permKey => {
                           newPerm[key][permKey]    = {...perm[key][permKey]};
                       });
                    });

                    commit(PERMISSION_CHANGE, newPerm);
                    ret = true;
                }
            });

            if(ret) {
                return true;
            }
        }

        let request = api.load('permission',
            id,
            state,
            commit,
            PERMISSION_LOADING_CHANGE,
            PERMISSION_CHANGE
        );

        return request;
    },
    [PERMISSION_MAX_LOAD]({ commit, state }, organizationId){
        let request = api.load('permission.organization',
            organizationId,
            state,
            commit,
            PERMISSION_MAX_LOADING_CHANGE,
            PERMISSION_MAX_CHANGE
        );

        return request;
    },
    [PERMISSION_CREATE]({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;
        let request = api.create(
            'permission.organization',
            id,
            model,
            state,
            commit,
            PERMISSION_SAVING_CHANGE,
            PERMISSION_CREATE_CHANGE,
            PERMISSION_ERRORS_CHANGE
        );

        return request;
    },
    [PERMISSION_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;
        let request = api.update(
            'permission',
            id,
            model,
            state,
            commit,
            PERMISSION_SAVING_CHANGE,
            PERMISSION_CHANGE,
            PERMISSION_ERRORS_CHANGE
        );


        return request;
    },
    [PERMISSION_LIST_LOAD] ({ commit, state }, id) {
        return api.list('permission.list', id, null, state, commit, PERMISSION_LIST_LOADING_CHANGE, PERMISSION_LIST_CHANGE);
    }
};

const mutations = {
    [PERMISSION_CHANGE](state, data) {
        state.permission   = {...data};

        if(state.permission_list && data) {
            let list    = [];
            state.permission_list.forEach(perm => {
                if(perm.id == data.id) {
                    list.push({...data});
                }
                else {
                    list.push(perm);
                }
            });

            state.permission_list   = list;
        }
    },
    [PERMISSION_CREATE_CHANGE](state, data) {
        if(state.permission_list) {
            state.permission_list.push(data);
        }
    },
    [PERMISSION_LOADING_CHANGE](state, data) {
        state.permission_loading   = data;
    },
    [PERMISSION_SAVING_CHANGE](state, data) {
        state.permission_saving   = data;
    },
    [PERMISSION_ERRORS_CHANGE](state, data) {
        state.permission_errors    = data;
    },
    [PERMISSION_MAX_CHANGE](state, data) {
        state.permission_max    = data;
    },
    [PERMISSION_MAX_LOADING_CHANGE](state, data) {
        state.permission_max_loading    = data;
    },
    [PERMISSION_LIST_CHANGE](state, data) {
        if(data) {
            state.permission_list = data.data;
        }
        else {
            state.permission_list = [];
        }

    },
    [PERMISSION_LIST_LOADING_CHANGE](state, data) {
        state.permission_list_loading = data;
    }
};

export default {
    state,
    actions,
    mutations
}