import http from './../../lib/http';
import api from './../../lib/api';

export const ORDER_VALIDATOR_LIST = 'ORDER_VALIDATOR_LIST';
export const ORDER_VALIDATOR_LOAD = 'ORDER_VALIDATOR_LOAD';
export const ORDER_VALIDATOR_LOAD_BY_ORG = 'ORDER_VALIDATOR_LOAD_BY_ORG';
export const ORDER_VALIDATOR_CREATE = 'ORDER_VALIDATOR_CREATE';
export const ORDER_VALIDATOR_UPDATE = 'ORDER_VALIDATOR_UPDATE';
export const ORDER_VALIDATOR_DELETE = 'ORDER_VALIDATOR_DELETE';

const ORDER_VALIDATOR_LIST_LOADING_CHANGE = 'ORDER_VALIDATOR_LIST_LOADING_CHANGE';
const ORDER_VALIDATOR_LIST_CHANGE = 'ORDER_VALIDATOR_LIST_CHANGE';
const ORDER_VALIDATOR_LOADING_CHANGE = 'ORDER_VALIDATOR_LOADING_CHANGE';
const ORDER_VALIDATOR_CHANGE = 'ORDER_VALIDATOR_CHANGE';
const ORDER_VALIDATOR_CREATE_CHANGE = 'ORDER_VALIDATOR_CREATE_CHANGE';
const ORDER_VALIDATOR_UPDATE_CHANGE = 'ORDER_VALIDATOR_UPDATE_CHANGE';
const ORDER_VALIDATOR_DELETE_CHANGE = 'ORDER_VALIDATOR_DELETE_CHANGE';
const ORDER_VALIDATOR_ERRORS_CHANGE = 'ORDER_VALIDATOR_ERRORS_CHANGE';

const state = {
    order_validator: {
        id: 0,
        organization_id: 0,
        required: [],
        rules: {
            
        },
    },
    order_validator_loading: false,
    order_validator_saving: false,
    order_validator_creating: false,
    order_validator_errors: {},
    
    order_validator_list: [],
    order_validator_list_total: 0,
    order_validator_list_loading: false,
    
    order_validator_empty: {
        id: 0,
        organization_id: 0,
        required: [],
        rules: {
            
        },
    },
};

const actions = {
    [ORDER_VALIDATOR_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }
        
        return api.list(
            'order_validators.list',
            0,
            filter,
            state,
            commit,
            ORDER_VALIDATOR_LIST_LOADING_CHANGE,
            ORDER_VALIDATOR_LIST_CHANGE
        );
    },
    [ORDER_VALIDATOR_LOAD_BY_ORG] ({commit, state}, id) {
        if (!id) {
            commit(ORDER_VALIDATOR_CHANGE, state.order_validator_empty);
            return;
        }
        
        return api.load(
            'order_validators.by_org',
            id,
            state,
            commit,
            ORDER_VALIDATOR_LOADING_CHANGE,
            ORDER_VALIDATOR_CHANGE
        );
    },
    [ORDER_VALIDATOR_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(ORDER_VALIDATOR_CHANGE, state.order_validator_empty);
            return;
        }
        
        return api.load(
            'order_validators',
            id,
            state,
            commit,
            ORDER_VALIDATOR_LOADING_CHANGE,
            ORDER_VALIDATOR_CHANGE
        );
    },
    [ORDER_VALIDATOR_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'order_validators.list',
            id,
            model,
            state,
            commit,
            ORDER_VALIDATOR_CREATE_CHANGE,
            ORDER_VALIDATOR_CHANGE,
            ORDER_VALIDATOR_ERRORS_CHANGE);
    },
    [ORDER_VALIDATOR_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'order_validators',
            id,
            model,
            state,
            commit,
            ORDER_VALIDATOR_UPDATE_CHANGE,
            ORDER_VALIDATOR_CHANGE,
            ORDER_VALIDATOR_ERRORS_CHANGE);
    },
    [ORDER_VALIDATOR_DELETE] ({commit, state}, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'order_validators', 
            id, 
            state, 
            commit, 
            ORDER_VALIDATOR_DELETE_CHANGE
        );
    }
};

const mutations = {
    [ORDER_VALIDATOR_LIST_LOADING_CHANGE] (state, data) {
        state.order_validator_list_loading = data;
    },
    [ORDER_VALIDATOR_LIST_CHANGE] (state, data) {
        if(data) {
            state.order_validator_list        = data.data;
            state.order_validator_list_total  = data.total;
        }
        else {
            state.order_validator_list        = [];
            state.order_validator_list_total  = 0;
        }
    },
    [ORDER_VALIDATOR_LOADING_CHANGE] (state, data) {
        state.order_validator_loading = data;
    },
    [ORDER_VALIDATOR_CHANGE] (state, data) {
        state.order_validator_errors = {};
        if(data && typeof data === 'object'){
            state.order_validator.organization_id = data.organization_id;
            state.order_validator.id = data.id;
            state.order_validator.required = JSON.parse(data.required);
            state.order_validator.rules = JSON.parse(data.rules);
            state.order_validator_empty = {
                id: 0,
                organization_id: 0,
                required: [],
                rules: {
                    
                },
            }
        } else {
            state.order_validator_empty = {
                id: 0,
                organization_id: 0,
                required: [],
                rules: {
                    
                },
            }
            state.order_validator = {
                id: 0,
                organization_id: 0,
                required: [],
                rules: {
                    
                },
            }
        }
    },
    [ORDER_VALIDATOR_CREATE_CHANGE] (state, data) {
        state.order_validator_creating = data;
    },
    [ORDER_VALIDATOR_UPDATE_CHANGE] (state, data) {
        state.order_validator_saving = data;
    },
    [ORDER_VALIDATOR_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.order_validator_list) {
                let list = [];
                state.order_validator_list.forEach(prov => {
                    if (prov.id !== id) {
                        list.push({...prov});
                    }
                });

                state.order_validator_list = list;
            }
        }
    },
    [ORDER_VALIDATOR_ERRORS_CHANGE] (state, data) {
        state.order_validator_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}
