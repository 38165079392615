import api from './../../lib/api';

export const PAYMENT_CREATE = 'PAYMENT_CREATE';
const PAYMENT_ERRORS_CHANGE = 'PAYMENT_ERRORS_CHANGE';
const PAYMENT_CREATE_CHANGE = 'PAYMENT_CREATE_CHANGE';
const PAYMENT_CHANGE = 'PAYMENT_CHANGE';

const state = {
    kassa_com_errors: {},
    loading_payment: false,
    created_payment_data: null
}

const actions = {
    [PAYMENT_CREATE]({ commit, state }, data) {
        return api.create('kassa_com.create', 0, data, state, commit, PAYMENT_CREATE_CHANGE, PAYMENT_CHANGE, PAYMENT_ERRORS_CHANGE);
    }
}

const mutations = {
    [PAYMENT_CREATE_CHANGE](state, data) {
        state.loading_payment = data;
    },
    [PAYMENT_CHANGE](state, data) {
        state.created_payment_data = data;
    },
    [PAYMENT_ERRORS_CHANGE](state, data) {
        state.kassa_com_errors = data;
    },
}

export default {
    state,
    actions,
    mutations
}