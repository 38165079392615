import api from './../../lib/api';

export const POINT_WITH_GRADE_LOAD                 = 'POINT_WITH_GRADE_LOAD';
export const POINT_WITH_GRADE_UPDATE               = 'POINT_WITH_GRADE_UPDATE';
export const POINT_WITH_GRADE_CREATE               = 'POINT_WITH_GRADE_CREATE';

export const POINT_WITH_GRADE_LOAD_DATATABLE       = 'POINT_WITH_GRADE_LOAD_DATATABLE';

const POINT_WITH_GRADE_CHANGE                      = 'POINT_WITH_GRADE_CHANGE';
const POINT_WITH_GRADE_LOADING_CHANGE              = 'POINT_WITH_GRADE_LOADING_CHANGE';
const POINT_WITH_GRADE_SAVING_CHANGE               = 'POINT_WITH_GRADE_SAVING_CHANGE';
const POINT_WITH_GRADE_ERRORS_CHANGE               = 'POINT_WITH_GRADE_ERRORS_CHANGE';
const POINT_WITH_GRADE_CREATE_CHANGE               = 'POINT_WITH_GRADE_CREATE_CHANGE';

const POINT_WITH_GRADE_DATATABLE_CHANGE            = 'POINT_WITH_GRADE_DATATABLE_CHANGE';
const POINT_WITH_GRADE_DATATABLE_LOADING_CHANGE    = 'POINT_WITH_GRADE_DATATABLE_LOADING_CHANGE';

const state = {
  points_with_grade            : null,
  points_with_grade_loading    : false,

  points_with_grade_saving     : false,
  points_with_grade_errors     : {},

  points_with_grade_list_total    : 0,
  points_with_grade_list           : null,
  points_with_grade_list_loading   : false,

  points_with_grade_empty              : {
    id: 0,
    point_min: null,
    point_max: null,
    grade: null,
    competence_id: null,
    competence_group_id: null,
    level_type: 2,
  }
};

const actions = {
  [POINT_WITH_GRADE_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(POINT_WITH_GRADE_CHANGE, state.points_with_grade_empty);
      return;
    }
    return api.load(
      'points_with_grade',
      id,
      state,
      commit,
      POINT_WITH_GRADE_LOADING_CHANGE,
      POINT_WITH_GRADE_CHANGE
    );
  },

  [POINT_WITH_GRADE_UPDATE] ({ commit, state }, {id, data}) {
    return api.update(
      'points_with_grade',
      id,
      data,
      state,
      commit,
      POINT_WITH_GRADE_SAVING_CHANGE,
      POINT_WITH_GRADE_CHANGE,
      POINT_WITH_GRADE_ERRORS_CHANGE
    );
  },

  [POINT_WITH_GRADE_CREATE] ({ commit, state }, data) {
    return api.create(
      'points_with_grades',
      0,
      data,
      state,
      commit,
      POINT_WITH_GRADE_SAVING_CHANGE,
      POINT_WITH_GRADE_CREATE_CHANGE,
      POINT_WITH_GRADE_ERRORS_CHANGE
    );
  },

  [POINT_WITH_GRADE_LOAD_DATATABLE] ({ commit, state }, data) {
    let params  = data.params;
    let id  = data.id ? data.id : null;
    return api.list(
      'points_with_grades',
      id,
      params,
      state,
      commit,
      POINT_WITH_GRADE_DATATABLE_LOADING_CHANGE,
      POINT_WITH_GRADE_DATATABLE_CHANGE
    );
  },
};

const mutations = {
  [POINT_WITH_GRADE_DATATABLE_LOADING_CHANGE](state, data) {
    state.points_with_grade_list_loading = data;
  },
  [POINT_WITH_GRADE_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.points_with_grade_list          = data.data;
      state.points_with_grade_list_total    = data.total;
    } else {
      state.points_with_grade_list          = [];
      state.points_with_grade_list_total    = 0;
    }
  },
  [POINT_WITH_GRADE_CHANGE](state, data) {
    state.points_with_grade = data;
  },
  [POINT_WITH_GRADE_LOADING_CHANGE](state, data) {
    state.points_with_grade_loading = data;
  },
  [POINT_WITH_GRADE_SAVING_CHANGE](state, data) {
    state.points_with_grade_saving   = data;
  },
  [POINT_WITH_GRADE_ERRORS_CHANGE](state, data) {
    state.points_with_grade_errors   = data;
  },
  [POINT_WITH_GRADE_CREATE_CHANGE](state, data) {
    state.points_with_grade = data;
  },
};

export default {
  state,
  actions,
  mutations
}
