import api from './../../lib/api';

export const BPM_CASE_LOAD                 = 'BPM_CASE_LOAD';
export const BPM_CASE_UPDATE               = 'BPM_CASE_UPDATE';
export const BPM_CASE_CREATE               = 'BPM_CASE_CREATE';

export const BPM_CASE_LOAD_DATATABLE       = 'BPM_CASE_LOAD_DATATABLE';

const BPM_CASE_CHANGE                      = 'BPM_CASE_CHANGE';
const BPM_CASE_LOADING_CHANGE              = 'BPM_CASE_LOADING_CHANGE';
const BPM_CASE_SAVING_CHANGE               = 'BPM_CASE_SAVING_CHANGE';
const BPM_CASE_ERRORS_CHANGE               = 'BPM_CASE_ERRORS_CHANGE';
const BPM_CASE_CREATE_CHANGE               = 'BPM_CASE_CREATE_CHANGE';

const BPM_CASE_DATATABLE_CHANGE            = 'BPM_CASE_DATATABLE_CHANGE';
const BPM_CASE_DATATABLE_LOADING_CHANGE    = 'BPM_CASE_DATATABLE_LOADING_CHANGE';

const state = {
  bpm_case            : {},
  bpm_case_loading    : false,

  bpm_case_saving     : false,
  bpm_case_errors     : {},

  bpm_case_list_total   : 0,
  bpm_case_list         : {},
  bpm_case_list_loading : false,
  bpm_case_empty        : {
    id: '',
    name: '',
  },
};

const actions = {
  [BPM_CASE_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(BPM_CASE_CHANGE, _.cloneDeep(state.bpm_case_empty));
      return;
    }
    return api.load(
      'bpm_case',
      id,
      state,
      commit,
      BPM_CASE_LOADING_CHANGE,
      BPM_CASE_CHANGE
    );
  },

  [BPM_CASE_UPDATE] ({ commit, state }, {id, data}) {
    return api.update(
      'bpm_case',
      id,
      data,
      state,
      commit,
      BPM_CASE_SAVING_CHANGE,
      BPM_CASE_CHANGE,
      BPM_CASE_ERRORS_CHANGE
    );
  },

  [BPM_CASE_CREATE] ({ commit, state }, data) {
    return api.create(
      'bpm_cases',
      0,
      data,
      state,
      commit,
      BPM_CASE_SAVING_CHANGE,
      BPM_CASE_CREATE_CHANGE,
      BPM_CASE_ERRORS_CHANGE
    );
  },

  [BPM_CASE_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'bpm_cases',
      0,
      filter,
      state,
      commit,
      BPM_CASE_DATATABLE_LOADING_CHANGE,
      BPM_CASE_DATATABLE_CHANGE
    );
  },
};

const mutations = {
  [BPM_CASE_DATATABLE_LOADING_CHANGE](state, data) {
    state.bpm_case_list_loading = data;
  },
  [BPM_CASE_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.bpm_case_list          = data.data;
      state.bpm_case_list_total    = data.total;
    } else {
      state.bpm_case_list          = {};
      state.bpm_case_list_total    = 0;
    }
  },
  [BPM_CASE_CHANGE](state, data) {
    state.bpm_case = data;
  },
  [BPM_CASE_LOADING_CHANGE](state, data) {
    state.bpm_case_loading = data;
  },
  [BPM_CASE_SAVING_CHANGE](state, data) {
    state.bpm_case_saving   = data;
  },
  [BPM_CASE_ERRORS_CHANGE](state, data) {
    state.bpm_case_errors   = data;
  },
  [BPM_CASE_CREATE_CHANGE](state, data) {
    state.bpm_case = data;
  },
};

export default {
  state,
  actions,
  mutations
}
