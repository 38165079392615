<template>
    <multiselect
        v-model="selected"
        v-on:input="updateValue($event)"
        :options="options"
        :multiple="multiple"
        :searchable="true"
        :placeholder="getTranslate.enter_the_value"
        track-by="id"
        label="label"
        :loading="loading"
        :internal-search="false"

        :clear-on-select="false"
        :close-on-select="close_on_select"
        :options-limit="300"
        :limit="3"
        :limit-text="limitText"
        :max-height="600"
        :show-no-results="true"
        :hide-selected="false"

        select-label=""
        :deselect-label="getTranslate.delete"
        :selected-label="getTranslate.selected"
        @search-change="suggest"
    >

        <template slot="noResult">
            {{ getTranslate.nothing_found }}
        </template>

    </multiselect>
</template>

<script>
    import http from './../lib/http';

    export default {
        name: 'ModelInput',
        props: {
            type        : {},
            value       : {default: 0},
            refresh     : {default: 0},
            multiple    : {default : true},
            as_filter   : {default : false},
            filters     : {},
            terms     : {},
            keys      : {}
        },
        data: function () {
            return {
                selected        : [],
                options         : [],
                loading         : false,
                config          : {},
                close_on_select : false
            }
        },
        watch : {
            refresh() {
                // this.selected   = [];
                // this.options    = [];
                //     
                this.selected   = [];
                this.options    = [];           
                this.load();
                this.suggest(null);
            }
        },
        created : function () {
            if(!this.multiple) {
                this.close_on_select    = true;
            }

            let mainConfig  = {
                 project : {
                    url     : this.$main_url + '/api/v2/projects',
                    label   : 'title',
                    track   : 'id'
                },
                product : {
                    url     : this.$main_url + '/api/v2/products',
                    label   : 'name',
                    track   : 'id'
                },
                project_category : {
                    url     : this.$main_url + '/api/v2/project_category',
                    label   : 'name',
                    track   : 'id'
                }
            }


            this.config = mainConfig[this.type];
            this.load();
            this.suggest(null);
        },
        methods : {
            limitText (count) {
                return this.getTranslate.and_more + count;
            },
            suggest (query) {                
                let url     = null;
                let params  = {q : query};           
                
                if(query){
                    if(this.filters)params.filters = this.filters;
                    url = this.config.url + '/suggest';
                }else{
                    if(this.terms)params.filters = this.terms;
                    url = this.config.url;
                }


                

                this.loading    = true;
                http.get(url, params).then(response => {
                    this.loading    = false;
                    let options     = [];

                    if(response.data && response.data.data) {
                        response.data.data.forEach(d => {
                            let temp_data;
                            if(this.keys && this.keys.length > 0){
                                temp_data = [];
                                this.keys.forEach(function(key) {
                                  temp_data[key] = d[key];
                                });
                            }else{
                                temp_data = d[this.config.track];
                            }

                            let opt = {
                                label   : d[this.config.label],
                                id      : temp_data
                            };

                            options.push(opt);
                        });
                    }

                    this.options    = options;
                });
            },
            load() {
                let ids = [];
                
                if(this.as_filter) {
                    if(!this.value || !this.value.terms || !this.value.terms.length) {
                        return;
                    }

                    ids = [...this.value.terms];
                }
                else {
                    if(this.value) {
                        if(this.multiple) {
                            ids = [...this.value];
                        }
                        else {
                            ids = [this.value];
                        }
                    }
                }                


                let url     = this.config.url;
                let params  = {
                    filters : {
                        id : {
                            terms : ids
                        }
                    }
                };

                this.loading    = true;
                http.get(url, params).then(response => {
                    this.loading    = false;
                    let options     = [];
                    let selected    = [];

                    if(response.data && response.data.data) {
                        response.data.data.forEach(d => {
                            let temp_data;
                            if(this.keys && this.keys.length > 0){
                                temp_data = [];
                                this.keys.forEach(function(key) {
                                  temp_data[key] = d[key];
                                });
                            }else{
                                temp_data = d[this.config.track];
                            }
                            
                            let opt = {
                                label   : d[this.config.label],
                                id      : temp_data
                            };

                            options.push(opt);
                            selected.push(opt);
                        });
                    }

                    this.options    = options;
                    this.selected   = selected;
                });
            },
            updateValue : function (val) {
                let value;
                if(this.multiple) {
                    value   = [];
                    val.forEach(el => {
                        value.push(el.id);
                    });
                }
                else {
                    if(this.as_filter) {
                        value   = [val.id];
                    }
                    else {
                        value   = val.id;
                    }
                }


                if(this.as_filter) {
                    value   = {'terms' : value};
                }

                // this.$emit('input', {'terms' : value});
                this.$emit('input', value);
            }
        }

    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
