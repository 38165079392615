import api from './../../lib/api';

export const INDUSTRY_LOAD                 = 'INDUSTRY_LOAD';
export const INDUSTRY_UPDATE               = 'INDUSTRY_UPDATE';
export const INDUSTRY_CREATE               = 'INDUSTRY_CREATE';

export const INDUSTRY_LOAD_DATATABLE       = 'INDUSTRY_LOAD_DATATABLE';

const INDUSTRY_CHANGE                      = 'INDUSTRY_CHANGE';
const INDUSTRY_LOADING_CHANGE              = 'INDUSTRY_LOADING_CHANGE';
const INDUSTRY_SAVING_CHANGE               = 'INDUSTRY_SAVING_CHANGE';
const INDUSTRY_ERRORS_CHANGE               = 'INDUSTRY_ERRORS_CHANGE';
const INDUSTRY_CREATE_CHANGE               = 'INDUSTRY_CREATE_CHANGE';

const INDUSTRY_DATATABLE_CHANGE            = 'INDUSTRY_DATATABLE_CHANGE';
const INDUSTRY_DATATABLE_LOADING_CHANGE    = 'INDUSTRY_DATATABLE_LOADING_CHANGE';

const state = {
  industry            : null,
  industry_loading    : false,

  industry_saving     : false,
  industry_errors     : {},

  industry_list_total   : 0,
  industry_list         : null,
  industry_list_loading : false,
  industry_empty        : {
    id: 0,
    name: '',
    organizations: [],
  }
};

const actions = {
  [INDUSTRY_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(INDUSTRY_CHANGE, _.cloneDeep(state.industry_empty));
      return;
    }
    return api.load(
      'industry',
      id,
      state,
      commit,
      INDUSTRY_LOADING_CHANGE,
      INDUSTRY_CHANGE
    );
  },

  [INDUSTRY_UPDATE] ({ commit, state }, {id, data}) {
    return api.update(
      'industry',
      id,
      data,
      state,
      commit,
      INDUSTRY_SAVING_CHANGE,
      INDUSTRY_CHANGE,
      INDUSTRY_ERRORS_CHANGE
    );
  },

  [INDUSTRY_CREATE] ({ commit, state }, data) {
    return api.create(
      'industries',
      0,
      data,
      state,
      commit,
      INDUSTRY_SAVING_CHANGE,
      INDUSTRY_CREATE_CHANGE,
      INDUSTRY_ERRORS_CHANGE
    );
  },

  [INDUSTRY_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'industries',
      0,
      filter,
      state,
      commit,
      INDUSTRY_DATATABLE_LOADING_CHANGE,
      INDUSTRY_DATATABLE_CHANGE
    );
  },
};

const mutations = {
  [INDUSTRY_DATATABLE_LOADING_CHANGE](state, data) {
    state.industry_list_loading = data;
  },
  [INDUSTRY_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.industry_list          = data.data;
      state.industry_list_total    = data.total;
    } else {
      state.industry_list          = {};
      state.industry_list_total    = 0;
    }
  },
  [INDUSTRY_CHANGE](state, data) {
    state.industry = data;
  },
  [INDUSTRY_LOADING_CHANGE](state, data) {
    state.industry_loading = data;
  },
  [INDUSTRY_SAVING_CHANGE](state, data) {
    state.industry_saving   = data;
  },
  [INDUSTRY_ERRORS_CHANGE](state, data) {
    state.industry_errors   = data;
  },
  [INDUSTRY_CREATE_CHANGE](state, data) {
    state.industry = data;
  },
};

export default {
  state,
  actions,
  mutations
}
