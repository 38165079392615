import http from './../../lib/http';
import api from './../../lib/api';

export const REASON_FINES_LOAD                 = 'REASON_FINES_LOAD';
export const REASON_FINES_UPDATE               = 'REASON_FINES_UPDATE';
export const REASON_FINES_CREATE               = 'REASON_FINES_CREATE';

export const REASON_FINES_LOAD_DATATABLE       = 'REASON_FINES_LOAD_DATATABLE';

const REASON_FINES_CHANGE                      = 'REASON_FINES_CHANGE';
const REASON_FINES_LOADING_CHANGE              = 'REASON_FINES_LOADING_CHANGE';
const REASON_FINES_SAVING_CHANGE               = 'REASON_FINES_SAVING_CHANGE';
const REASON_FINES_ERRORS_CHANGE               = 'REASON_FINES_ERRORS_CHANGE';
const REASON_FINES_CREATE_CHANGE               = 'REASON_FINES_CREATE_CHANGE';

const REASON_FINES_CREATING_CHANGE             = 'REASON_FINES_CREATING_CHANGE';

const REASON_FINES_DATATABLE_CHANGE            = 'REASON_FINES_DATATABLE_CHANGE';
const REASON_FINES_DATATABLE_LOADING_CHANGE    = 'REASON_FINES_LOADING_CHANGE';

export const FINE_CATS_LOAD_BY_REASON_FINES    = "FINE_CATS_LOAD_BY_REASON_FINES";

const state = {
    reason_fines            : null,
    reason_fines_loading    : false,

    reason_fines_saving     : false,
    reason_fines_errors     : {},

    reason_fines_list_total    : 0,
    reason_fines_list           : null,
    reason_fines_list_loading   : false,

    reason_fines_creating           : false,
    reason_fines_creating_errors    : {},
    reason_fines_empty              : {
                                        id: 0,
                                        name: "",
                                        desc: "",
                                        fine_cat_id: "",
                                        price: "",
                                        organization: "",
                                        is_work: true
                                    }
};

const actions = {
    [REASON_FINES_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(REASON_FINES_CHANGE, state.reason_fines_empty);

            return;
        }

        return api.load(
            'reason_fines',
            id,
            state,
            commit,
            REASON_FINES_LOADING_CHANGE,
            REASON_FINES_CHANGE
        );
    },

    [REASON_FINES_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update(
            'reason_fines',
            id,
            model,
            state,
            commit,
            REASON_FINES_SAVING_CHANGE,
            REASON_FINES_CHANGE,
            REASON_FINES_ERRORS_CHANGE
        );
    },

    [REASON_FINES_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create(
            'reason_fines.list',
            id,
            model,
            state,
            commit,
            REASON_FINES_SAVING_CHANGE,
            REASON_FINES_CREATE_CHANGE,
            REASON_FINES_ERRORS_CHANGE
        );
    },

    [REASON_FINES_LOAD_DATATABLE] ({ commit, state }, filter) {
        if (!filter) {
            filter = {
                is_work: true
            };
        }
        return api.list(
            'reason_fines.list',
            0,
            filter,
            state,
            commit,
            REASON_FINES_DATATABLE_LOADING_CHANGE,
            REASON_FINES_DATATABLE_CHANGE
        );
    },
    [FINE_CATS_LOAD_BY_REASON_FINES]({ commit, state }, id) {
      if (!id) {
        id = 0;
      }

      return api.list(
        "reason_fines.by_reason_fines",
        id,
        null,
        state,
        commit,
        REASON_FINES_DATATABLE_LOADING_CHANGE,
        REASON_FINES_DATATABLE_CHANGE
      );
    }
};

const mutations = {
    [REASON_FINES_DATATABLE_LOADING_CHANGE](state, data) {
        state.reason_fines_list_loading = data;
    },
    [REASON_FINES_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.reason_fines_list          = data.data;
            state.reason_fines_list_total    = data.total;
        } else {
            state.reason_fines_list          = [];
            state.reason_fines_list_total    = 0;
        }

    },
    [REASON_FINES_CHANGE](state, data) {
        state.reason_fines  = data;
        if (data) {
            if (state.reason_fines_list) {
                let list = [];
                state.reason_fines_list.forEach(reason_fines => {
                    if (reason_fines.id == data.id) {
                        list.push({...data});
                    }
                    else {
                        list.push(reason_fines);
                    }
                });

                state.reason_fines_list = list;
            }
        }
    },
    [REASON_FINES_LOADING_CHANGE](state, data) {
        state.reason_fines_loading = data;
    },
    [REASON_FINES_SAVING_CHANGE](state, data) {
        state.reason_fines_saving   = data;

    },
    [REASON_FINES_ERRORS_CHANGE](state, data) {
        state.reason_fines_errors   = data;
    },
    [REASON_FINES_CREATING_CHANGE](state, data) {
        state.reason_fines_creating = data;
    },
    [REASON_FINES_CREATE_CHANGE](state, data) {
        if (data) {
            if (state.reason_fines_list) {
                state.reason_fines_list.unshift({...data});
            }

        }
    }
};

export default {
    state,
    actions,
    mutations
}
