import http from './../../lib/http';
import api from './../../lib/api';


export const BLACK_LIST_CREATE               = 'BLACK_LIST_CREATE';

const BLACK_LIST_ERRORS_CHANGE               = 'BLACK_LIST_ERRORS_CHANGE';

const BLACK_LIST_CREATING_CHANGE             = 'BLACK_LIST_CREATING_CHANGE';

export const BLACK_LIST_DELETE		         = 'BLACK_LIST_DELETE';
const BLACK_LIST_DELETE_CHANGE               = 'BLACK_LIST_DELETE_CHANGE';
const BLACK_LIST_CHANGE                      = 'BLACK_LIST_CHANGE';

const state = {
    black_list            : null,
    black_list_loading    : false,
    black_list_saving     : false,
    black_list_errors     : {},

    black_list_list_total     : 0, 
    black_list_list           : null,
    black_list_list_loading   : false,

    black_list_creating           : false,
    black_list_creating_errors    : {},
    black_list_empty              : {
                                  id: 0,
                                  phone: ""
                              }
};

const actions = {       

    [BLACK_LIST_CREATE] ({ commit, state }, data) {          
        
        return api.create(
            'black_list', 
            0, 
            data, 
            state, 
            commit, 
            BLACK_LIST_CREATING_CHANGE, 
            BLACK_LIST_CHANGE, 
            BLACK_LIST_ERRORS_CHANGE);
    },
    
    [BLACK_LIST_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'black_list.delete',
            id,
            state,
            commit,
            BLACK_LIST_DELETE_CHANGE
        );
    },


};

const mutations = {
    
    
    [BLACK_LIST_CHANGE](state, data) {
        state.black_list  = data;
    },        
    [BLACK_LIST_ERRORS_CHANGE](state, data) {
        state.black_list_errors   = data;
    },
    [BLACK_LIST_CREATING_CHANGE](state, data) {
        state.black_list_creating = data;
    },
    [BLACK_LIST_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.black_list_list) {

                let list = [];
                state.black_list_list.forEach(geo => {
                    if (black_list.id !== id) {
                        list.push({...black_list});
                    }
                });

                state.black_list_list = list;
            }
        }
    },


};

export default {
    state,
    actions,
    mutations
}
