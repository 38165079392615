import crypto from 'crypto';

const tid = function generateTemporaryId() {
  const now = Date.now().toString();
  const salt = Math.random().toString(36);
  const tid = crypto
    .createHash('md5')
    .update(`${now}${salt}`)
    .digest('hex')
    .toString();
  return tid;
};

/**
 * Авторизованный пользователь
 * @type {Object}
 */
const auth = JSON.parse(localStorage.getItem('users.auth_user', '{"lang":"en"}')) || {lang:'en'};

/**
 * Перевод надписей и сообщений
 * @type {Object}
 */
const getTranslate = require(`./../../langs/RobotBlocks/${auth.lang}.json`) || {};

/**
 * Варианты количества файлов в интенте "файл"
 * @type {Array}
 */
export const fileCounts = [
  'any',
  'single',
  'multiple',
];

/**
 * Допустимые типы файлов в интенте "файл"
 *
 * Должны соответствовать типам FT_XXX в рапторе (Raptor/Files/Mimes),
 * поэтому, если добавляем сюда, то добавляем и в рапторе
 *
 * А также, не забываем про определение типа в Raptor/Files/FileInfo@getFileType
 * @type {Array}
 */
export const fileTypes = [
  {
    type: 'image',
    icon: 'file-image',
    title: getTranslate.image,
  },
  {
    type: 'audio',
    icon: 'file-audio',
    title: getTranslate.audio,
  },
  {
    type: 'video',
    icon: 'file-video',
    title: getTranslate.video,
  },
  {
    type: 'archive',
    icon: 'file-archive',
    title: getTranslate.archive,
  },
  {
    type: 'text',
    icon: 'file-alt',
    title: getTranslate.text,
  },
  {
    type: 'word',
    icon: 'file-word',
    title: getTranslate.word,
  },
  {
    type: 'excel',
    icon: 'file-excel',
    title: getTranslate.excel,
  },
  {
    type: 'csv',
    icon: 'file-csv',
    title: getTranslate.csv,
  },
  {
    type: 'pdf',
    icon: 'file-pdf',
    title: getTranslate.pdf,
  },
  {
    type: 'font',
    icon: 'font',
    title: getTranslate.font,
  },
];

class Block {
  // Если кто-то подскажет как элегантнее получить имя класса, буду благодарен
  // this.constructor.name не работает в некоторых версиях браузеров
  // @axsmak
  // className = this.constructor.toString().match(/\w+/g)[1];
  className = 'Block';
}

class Accost extends Block {
  id = 0;
  className = 'Accost';
  text = {
    list: [],
    mod: 'queue',
  };
  type = '';
  start_type = 0;
  position = 0;

  constructor(id, text, start_type = 0, position = 0) {
    super();
    this.id = id;
    this.text = text;
    this.start_type = start_type;
    this.position = position;
  }
}

export class AccostAudio extends Accost {
  className = 'AccostAudio';
  type = 'audio';
  file = {
    list: [],
    mod: 'queue',
  };
  text = {
    list: [],
  };
  data = null;

  constructor(id = 0, file, data, text, start_type = 0, position = 0) {
    super('');
    this.id = id;
    this.file = file;
    this.data = data;
    this.text = text;
    this.start_type = start_type;
    this.position = position;
  }
}

/**
 * Accost TTS type
 * @type {String}
 */
export class AccostTts extends Accost {
  className = 'AccostTts';
  type = 'tts';
  provider = localStorage.getItem('robot.tts-provider') || 'yandex';
  lang = localStorage.getItem('robot.tts-lang') || 'en-US';
  voice = localStorage.getItem('robot.tts-voice') || null;
  emotion = localStorage.getItem('robot.tts-emotion') || null;
  file = '';
  data = null;
  text = '';

  constructor(id = 0, file = '', data = null, text = '', start_type = 0, position = 0) {
    super('');
    this.id = id;
    this.file = file;
    this.data = data;
    this.text = text;
    this.start_type = start_type;
    this.position = position;
  }
}

/**
 * Гибридный синтез
 */
export class AccostHss extends Accost {
  className = 'AccostHss';
  type = 'hss';
  file = {
    list: [],
    mod: 'queue',
  };
  text = {
    list: [],
  };
  data = [];

  constructor(id = 0, file, data, text, start_type = 0, position = 0) {
    super('');
    this.id = id;
    if (file) {
      this.file = file;
    }
    if (data) {
      this.data = data;
    }
    if (text) {
      this.text = text;
    }
    this.start_type = start_type;
    this.position = position;
  }
}

export class AccostText extends Accost {
  className = 'AccostText';
  type = 'text';
}

/**
 * Динамический текст
 */
export class AccostDtxt extends Accost {
  className = 'AccostDtxt';
  type = 'dtxt';
  text = {
    list: [],
    mod: 'queue',
  };

  constructor(id = 0, text, position = 0) {
    super('');
    this.id = id;
    this.position = position;
    if (text) {
      this.text = text;
    }
  }
}

/**
 * Условное аудио
 */
export class AccostConditionAudio extends Accost {
  className = 'AccostConditionAudio';
  type = 'condition_audio';
  file = {
    list: [],
    mod: 'queue',
    default_accost_is_next: false,
  };
  text = {
    list: [],
  };

  constructor(id = 0, file, text, start_type = 0, position = 0) {
    super('');
    this.id = id;
    if (file) {
      this.file = file;
    }
    if (text) {
      this.text = text;
    }
    this.start_type = start_type;
    this.position = position;
  }
}

export class AccostTemplate extends Accost {
  className = 'AccostTemplate';
  file = '';
  data = null;
  type = 'wa_tpl';

  constructor(id = 0, text = '', file = '', data = null, start_type = 0, position = 0) {
    super('');
    this.id = id;
    this.file = file;
    this.data = data;
    this.text = text;
    this.start_type = start_type;
    this.position = position;
  }
}

export class AccostImage extends Accost {
  className = 'AccostImage';
  file = {
    list: [],
    mod: 'queue',
  };
  text = {
    list: [],
  };
  data = [];
  type = 'img';

  constructor(id = 0, text = '', file = '', data = [], start_type = 0, position = 0) {
    super('');
    this.id = id;
    if (file) {
      this.file = file;
    }
    if (text) {
      this.text = text;
    }
    this.data = data;
    this.start_type = start_type;
    this.position = position;
  }
}

export class AccostVideo extends Accost {
  className = 'AccostVideo';
  file = {
    list: [],
    mod: 'queue',
  };
  text = {
    list: [],
  };
  data = [];
  type = 'video';

  constructor(id = 0, text = '', file = '', data = [], start_type = 0, position = 0) {
    super('');
    this.id = id;
    if (file) {
      this.file = file;
    }
    if (text) {
      this.text = text;
    }
    this.data = data;
    this.start_type = start_type;
    this.position = position;
  }
}

export class IntentSample {
  className = 'IntentSample';
  id = 0;
  tid = null;
  bot_intent_id = 0;
  type = 'template';
  text = '';
  position = 0;
  validated = null;
  timer = null;

  constructor({
    id = 0,
    bot_intent_id = 0,
    type = 'template',
    text = '',
    position = 0,
  } = {}) {
    this.id = id;
    this.bot_intent_id = bot_intent_id;
    this.type = type;
    this.text = text;
    this.position = position;

    if (id === 0) {
      this.tid = tid();
    }

    this.checkNow();
  }

  check() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.checkNow();
    }, 500);
    return this.validated;
  }

  checkNow() {
    if (this.text === '') {
      this.validated = null;
      return;
    }
    let regex;
    let fileParams;
    let regStr = this.text.trim();
    switch (this.type) {
      case 'nlu':
        this.validated = !/[^a-z0-9._-]/.test(this.text);
        break;
      case 'regex':
        try {
          regex = new RegExp(this.text);
          regex.test('');
          this.validated = true;
        } catch {
          this.validated = false;
        }
        break;
      case 'template':
        // Слэш удобнее чем черта при работе с кириллицей, но теперь меняем на черту (ИЛИ)
        regStr = regStr.replaceAll('/', '|');

        // Исправляем астериски стоящие вплотную к скобкам - они бессмысленны
        regStr = regStr.replaceAll('*(', '* (');
        regStr = regStr.replaceAll(')*', ') *');

        // Убираем двойные пробелы
        regStr = regStr.replaceAll(/\s{2,}/g, ' ');

        // Убираем пробелы рядом со скобками и рядом с ИЛИ
        regStr = regStr.replaceAll(/([(|])\s/g, '$1');
        regStr = regStr.replaceAll(/\s([)|])/g, '$1');

        // Шаблон начинается не с астериска >> интент в начале фразы
        regStr = regStr.replaceAll(/^([^*])/g, '^$1');

        // Шаблон заканчивается не на астериск >> интент в конце фразы
        regStr = regStr.replaceAll(/([^*])$/g, '$1$$');

        // Слово начинается не с астериска >> интент начинается на слово
        regStr = regStr.replaceAll(/(\(|\||\s)([^*(])/g, '$1\\b$2');

        // Слово заканчивается не на астериск >> интент заканчивается на слово
        regStr = regStr.replaceAll(/([^*)])(\)|\||\s)/g, '$1\\b$2');

        // Астериск отделённый пробелом >>
        regStr = regStr.replaceAll(/\*\s|\s\*/g, '.*');

        //Астериск в конце слова >> любое количество непробельных символов
        regStr = regStr.replaceAll(/([^.\s|(])\*/g, '$1\\S*');

        //Астериск в начале слова >> любое количество непробельных символов
        regStr = regStr.replaceAll(/\*([^.\s|()])/g, '\\S*$1');

        try {
          regex = new RegExp(regStr);
          regex.test('');
          this.validated = true;
        } catch {
          this.validated = false;
        }
        break;
      case 'match':
        this.validated = !/[^A-zА-я\s\d*#]/.test(this.text);
        break;
      case 'promo_code':
        this.validated = /^(?=.{4,32}$)([A-Z0-9]*[A-Z][A-Z0-9]*)$/.test(this.text);
        break;
      case 'file':
        try {
          fileParams = JSON.parse(this.text);
          if (
            _.isArray(fileParams.types)
            && fileParams.types.length > 0
            && _.indexOf(fileCounts, fileParams.count) >= 0
          ) {
            _.each(fileParams.types, (type) => {
              if (type !== 'any' && !_.find(fileTypes, { type })) {
                throw new Error(`Unknown file type '${type}'`);
              }
            });
            this.validated = true;
          } else {
            throw new Error('Wrong structure', fileParams);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error validation file-intent', e, "\n",this);
          this.validated = false;
        }
        break;
    }
  }
}

export class IntentAny extends Block {
  className = 'IntentAny';
  type = 'any';
  id = 0;
  rid = 0;
  name = getTranslate.any_other_phrase;
  linked_screen_id = null;
  position = 0;

  constructor(jump = null) {
    super();
    this.linked_screen_id = jump;
  }
}

export class IntentCustom extends Block {
  className = 'IntentCustom';
  type = 'custom';
  id = 0;
  bot_script_id = null;
  tid = null;
  name = '';
  priority = 1;
  broken = false;
  samples = [];
  tag_ids = [];

  constructor({ id = 0, bot_script_id = null, name = '', priority = 1, samples = [], tag_ids = []} = {}) {
    super();
    this.name = name;
    this.priority = priority;
    this.id = id;
    this.bot_script_id = bot_script_id;
    let pos = 0;
    _.each(samples, (sample) => {
      sample.position = pos;
      pos++;
      let tmpSample = new IntentSample(sample);
      if (tmpSample.validated === false) {
        this.broken = true;
      }
      this.samples.push(tmpSample);
    });
    if (id === 0) {
      this.tid = tid();
      this.samples.push(new IntentSample);
    }
    this.tag_ids = tag_ids;
  }

  get title() {
    return this.name || 'noname';
  }

  set title(title) {
    this.name = title;
  }

  get public() {
    return Number(this.bot_script_id) === 0;
  }

  set public(script_id) {
    if (script_id) {
      this.bot_script_id = script_id;
    } else {
      this.bot_script_id = null;
    }
  }

  getId(orTid = true) {
    if (this.id > 0 || orTid === false) {
      return this.id;
    }
    return this.tid;
  }
}


export class IntentFile extends IntentCustom {
  className = 'IntentFile';
  type = 'file';
  name = 'File from user';
  tid = null;

  constructor({ id = 0, bot_script_id = null, name = '', priority = 1, samples = [] } = {}) {
    super();
    this.samples = [];
    this.name = name;
    this.priority = priority;
    this.id = id;
    this.bot_script_id = bot_script_id;
    _.each(samples, (sample) => {
      this.samples.push(new IntentSample(sample));
    });
    if (id === 0) {
      this.tid = tid();
      this.samples.push(new IntentSample({
        type: 'file',
        text: JSON.stringify({
          types: [
            'any',
          ],
          count: 'any',
        }),
      }));
    }
  }
}

export class IntentPromoCode extends IntentCustom {
  className = 'IntentPromoCode';
  type = 'promo_code';
  id = 0;
  bot_script_id = null;
  tid = null;
  name = 'Promo code';
  priority = 1;
  samples = [];

  constructor({ id = 0, bot_script_id = null, name = '', priority = 1, samples = [] } = {}) {
    super();
    this.samples = [];
    this.name = name;
    this.priority = priority;
    this.id = id;
    this.bot_script_id = bot_script_id;
    _.each(samples, (sample) => {
      this.samples.push(new IntentSample(sample));
    });
    if (id === 0) {
      this.tid = tid();
      this.samples.push(new IntentSample({
        type: 'promo_code',
      }));
    }
  }
}

export class IntentRef extends Block {
  className = 'IntentRef';
  id = 0;
  rid = 0;
  name = '';
  linked_screen_id = null;
  position = 0;

  constructor({ id = 0, rid = 0, name = '', linked_screen_id = 0, position = 0 } = {}) {
    super();
    this.id = id;
    this.rid = rid;
    this.name = name;
    this.linked_screen_id = linked_screen_id;
    this.position = position;
    if (rid === 0) {
      this.rid = tid();
    }
  }
}

export class ActionDone extends Block {
  className = 'ActionDone';
  code = 0;
  id = 0;

  constructor() {
    super();
    this.id = tid();
  }
}

export class ActionCallTransfer extends Block {
  className = 'ActionCallTransfer';
  id = 0;
  payload = {
    type: 'queue', // queue / phone / robot
    queue: null,
    phone: null,
    robot: null,
    screen: null,
    speech: null,
  };

  constructor({
    id = 0,
    type = 'call_transfer',
    on_success_screen_id = null,
    on_failure_screen_id = null,
    payload = { type: 'queue', queue: null, phone: null, robot: null, screen: null, speech: null },
  } = {}) {
    super();
    this.type = type;
    this.on_success_screen_id = on_success_screen_id;
    this.on_failure_screen_id = on_failure_screen_id;
    this.payload = _.merge(this.payload, payload);
    this.id = id ? id : tid();
  }

  get value() {
    return this.payload[this.payload.type];
  }
}
/**
 * Класс конструктор действия Отправка SMS
 */
export class ActionSendingSms extends Block {
  className = 'ActionSendingSms';
  id = 0;
  payload = {
    type: 'sms_template',
    sms_template: null,
  };

  constructor({
    id = 0,
    type = 'sending_sms',
    on_success_screen_id = null,
    on_failure_screen_id = null,
    payload = { type: 'sms_template', sms_template: null },
  } = {}) {
    super();
    this.type = type;
    this.on_success_screen_id = on_success_screen_id;
    this.on_failure_screen_id = on_failure_screen_id;
    this.payload = payload;
    this.id = id ? id : tid();
  }

  get value() {
    return this.payload[this.payload.type];
  }
}

export class ActionStay extends Block {
  className = 'ActionStay';
  id = 0;
  payload = {
    remember_for_repeat: true,
    run_return_replicas: true,
  };

  constructor({
    id = 0,
    type = 'stay',
    payload = {
      remember_for_repeat: true,
      run_return_replicas: true,
    },
  } = {}) {
    super();
    this.id = id ? id : tid();
    this.type = type;
    this.payload = _.merge(this.payload, payload);
  }
}
/**
 * Класс конструктор действия Http-запрос (WebHook)
 */
export class ActionHttpRequest extends Block {
  className = 'ActionHttpRequest';
  payload = {
    type: 'http_request',
    http_method: 'GET',
    http_url: null,
    wait: false,
    max_timeout: 0,
  };
  id = 0;

  constructor({
    id = 0,
    type = 'http_request',
    on_success_screen_id = null,
    on_failure_screen_id = null,
    payload = {
      type: 'http_request',
      http_method: 'GET',
      http_url: null,
      wait: false,
      max_timeout: 0,
    },
  } = {}) {
    super();
    this.type = type;
    this.on_success_screen_id = on_success_screen_id;
    this.on_failure_screen_id = on_failure_screen_id;
    this.payload = payload;
    this.id = id ? id : tid();
  }
}

export class ActionFillOrder extends Block {
  className = 'ActionFillOrder';
  id = 0;
  payload = {
    type: 'plain',
    field: 'comment',
    post_field: '',
    prefix: '',
    postfix: '',
    value: '',
  };

  constructor({
    id = 0,
    type = 'fill_order',
    on_success_screen_id = null,
    on_failure_screen_id = null,
    payload,
  } = {}) {
    super();
    this.type = type;
    this.on_success_screen_id = on_success_screen_id;
    this.on_failure_screen_id = on_failure_screen_id;
    this.payload = payload ? payload : this.payload;
    this.id = id ? id : tid();
  }

  get value() {
    return this.payload[this.payload.type];
  }
}

export class Condition extends Block {
  className = 'Condition';
  id = 0;
  type = 'custom';
  left = {
    expression: '',
  };
  operator = '==';
  right = {
    expression: 'null',
  };
  on_true_screen_id = null;
  on_false_screen_id = null;
  position = 0;

  constructor(data = {}) {
    super();
    this.id = tid();
    if (data.id) {
      this.id = data.id;
    }
    if (data.position) {
      this.position = data.position;
    }
    if (data.left) {
      if (_.isString(data.left)) {
        this.left = JSON.parse(data.left);
      } else {
        this.left = data.left;
      }
    }
    if (data.operator) {
      this.operator = data.operator;
    }
    if (data.right) {
      if (_.isString(data.right)) {
        this.right = JSON.parse(data.right);
      } else {
        this.right = data.right;
      }
    }
    if (data.on_true_screen_id) {
      this.on_true_screen_id = data.on_true_screen_id;
    }
    if (data.on_false_screen_id) {
      this.on_false_screen_id = data.on_false_screen_id;
    }
  }
}

export class ConditionOrderField extends Condition {
  className = 'ConditionOrderField';
  type = 'order_field';

  constructor(data = {}) {
    super(data);

    if (!data.left) {
      this.left = {
        variable: '',
        index: null,
      };
    }

    if (!data.right) {
      this.right = {
        value: '',
        regex: false,
      };
    }

    if (!data.operator) {
      this.operator = '=';
    }
  }

  get value() {
    return JSON.stringify({
      right:    this.right,
      operator: this.operator,
      left:     this.left,
    });
  }

  set value(value) {
    const { right, operator, left } = JSON.parse(value);
    this.right    = right;
    this.operator = operator;
    this.left     = left;
  }
}

export class ConditionRepeat extends Condition {
  className = 'ConditionRepeat';
  type = 'repeat';
  left = {
    variable: 'REPEAT_IN_A_ROW',
  };
  operator = '>';
  right = {
    value: 0,
  };

  constructor(data = {}) {
    super(data);
    if (data.left) {
      if (_.isString(data.left)) {
        this.left = JSON.parse(data.left);
        if (_.isString(this.left)) {
          this.left = JSON.parse(this.left);
        }
      } else {
        this.left = data.left;
      }
    }
    if (data.operator) {
      this.operator = data.operator;
    }
    if (data.right) {
      if (_.isString(data.right)) {
        this.right = JSON.parse(data.right);
        if (_.isString(this.right)) {
          this.right = JSON.parse(this.right);
        }
      } else {
        this.right = data.right;
      }
    }
  }

  get value() {
    return this.right.value;
  }

  set value(value) {
    this.right = {
      value,
    }
  }
}

export {
  tid,
};

export class ConditionTransitionIntents extends ConditionRepeat {
  type = 'transition_intents';
  left = {
    variable: 'INTENTS_WORKED_IN_ROW',
  };

  constructor(data = {}) {
    super(data);
    if (data.left) {
      this.left = data.left;
    }
  }
}

export class ConditionTimeout extends Condition {
  className = 'ConditionTimeout';
  type = 'timeout';
  left = {
    variable: 'WAIT_SECONDS',
  };
  operator = '>';
  right = {
    value: 0,
    repeat: 0,
  };

  constructor(data = {}) {
    super(data);
    if (data.left) {
      if (_.isString(data.left)) {
        this.left = JSON.parse(data.left);
        if (_.isString(this.left)) {
          this.left = JSON.parse(this.left);
        }
      } else {
        this.left = data.left;
      }
    }
    if (data.operator) {
      this.operator = data.operator;
    }
    if (data.right) {
      if (_.isString(data.right)) {
        this.right = JSON.parse(data.right);
        if (_.isString(this.right)) {
          this.right = JSON.parse(this.right);
        }
      } else {
        this.right = data.right;
      }
    }
  }

  get value() {
    return this.right.value;
  }

  set value(value) {
    this.right = {
      value,
    }
  }
}
