import api from './../../lib/api';
import Vue from 'vue';

export const ATS_QUEUE_LIST = 'ATS_QUEUE_LIST';
export const ATS_QUEUE_IN_LIST = 'ATS_QUEUE_IN_LIST';
export const ATS_QUEUE_LOAD = 'ATS_QUEUE_LOAD';
export const ATS_QUEUE_CREATE = 'ATS_QUEUE_CREATE';
export const ATS_QUEUE_UPDATE = 'ATS_QUEUE_UPDATE';
export const ATS_QUEUE_DELETE = 'ATS_QUEUE_DELETE';
export const ATS_QUEUE_ATTACH_OPERATORS = 'ATS_QUEUE_ATTACH_OPERATORS';
export const ATS_QUEUE_ATTACH_TRUNKS = 'ATS_QUEUE_ATTACH_TRUNKS';
export const ATS_QUEUE_ATTACH_COMPANIES = 'ATS_QUEUE_ATTACH_COMPANIES';
export const ATS_QUEUES_BY_COMPANY = 'ATS_QUEUES_BY_COMPANY';
export const WEIGHT_ADJUSTING_LOG = 'WEIGHT_ADJUSTING_LOG';

export const ATS_QUEUE_LIST_FILTER = 'ATS_QUEUE_LIST_FILTER';

export const ATS_QUEUE_GROUP_LIST = 'ATS_QUEUE_GROUP_LIST';
export const ATS_QUEUE_GROUP_CREATE = 'ATS_QUEUE_GROUP_CREATE';
export const ATS_QUEUE_GROUP_LOAD = 'ATS_QUEUE_GROUP_LOAD';

const ATS_QUEUE_LIST_LOADING_CHANGE = 'ATS_QUEUE_LIST_LOADING_CHANGE';
const ATS_QUEUE_LIST_CHANGE = 'ATS_QUEUE_LIST_CHANGE';
const ATS_QUEUE_IN_LIST_CHANGE = 'ATS_QUEUE_IN_LIST_CHANGE';
const ATS_QUEUE_LOADING_CHANGE = 'ATS_QUEUE_LOADING_CHANGE';
const ATS_QUEUE_CHANGE = 'ATS_QUEUE_CHANGE';
const ATS_QUEUE_CREATE_CHANGE = 'ATS_QUEUE_CREATE_CHANGE';
const ATS_QUEUE_UPDATE_CHANGE = 'ATS_QUEUE_UPDATE_CHANGE';
const ATS_QUEUE_DELETE_CHANGE = 'ATS_QUEUE_DELETE_CHANGE';
const ATS_QUEUE_ERRORS_CHANGE = 'ATS_QUEUE_ERRORS_CHANGE';
const ATS_QUEUE_ATTACH_OPERATORS_CHANGE = 'ATS_QUEUE_ATTACH_OPERATORS_CHANGE';
const ATS_QUEUE_OPERATORS_CHANGE = 'ATS_QUEUE_OPERATORS_CHANGE';
const ATS_QUEUE_ATTACH_TRUNKS_CHANGE = 'ATS_QUEUE_ATTACH_TRUNKS_CHANGE';
const ATS_QUEUE_TRUNKS_CHANGE = 'ATS_QUEUE_TRUNKS_CHANGE';
const ATS_QUEUE_ATTACH_COMPANIES_CHANGE = 'ATS_QUEUE_ATTACH_COMPANIES_CHANGE';
const ATS_QUEUE_COMPANIES_CHANGE = 'ATS_QUEUE_COMPANIES_CHANGE';
const ATS_QUEUES_BY_COMPANY_LOADING_CHANGE = 'ATS_QUEUES_BY_COMPANY_LOADING_CHANGE';
const ATS_QUEUES_BY_COMPANY_CHANGE = 'ATS_QUEUES_BY_COMPANY_CHANGE';
const WEIGHT_ADJUSTING_LOG_LOADING = 'WEIGHT_ADJUSTING_LOG_LOADING';
const WEIGHT_ADJUSTING_LOG_LOADED = 'WEIGHT_ADJUSTING_LOG_LOADED';

const ATS_QUEUE_GROUP_LIST_LOADING_CHANGE = 'ATS_QUEUE_GROUP_LIST_LOADING_CHANGE';
const ATS_QUEUE_GROUP_LIST_CHANGE = 'ATS_QUEUE_GROUP_LIST_CHANGE';
const ATS_QUEUE_GROUP_CHANGE = 'ATS_QUEUE_GROUP_CHANGE';
const ATS_QUEUE_GROUP_CREATE_CHANGE = 'ATS_QUEUE_GROUP_CREATE_CHANGE';
const ATS_QUEUE_GROUP_ERRORS_CHANGE = 'ATS_QUEUE_GROUP_ERRORS_CHANGE';
const ATS_QUEUE_GROUP_SET_EMPTY = 'ATS_QUEUE_GROUP_SET_EMPTY';

const ATS_QUEUE_LIST_FILTER_CHANGE = 'ATS_QUEUE_LIST_FILTER_CHANGE';

const ats_queue_group_empty = () => {
    return {
        title: "",
        ats_group_id:null,
        operators:[],
        queues:[]
    }
}

const state = {
    ats_queue: null,
    ats_queue_loading: false,
    ats_queue_saving: false,
    ats_queue_creating: false,
    ats_queue_errors: {},

    ats_queues_by_company: [],
    ats_queues_by_company_total: 0,
    ats_queues_by_company_loading: false,

    ats_queue_list_filter: [],
    ats_queue_list_filter_total: 0,

    ats_queue_list: [],
    ats_queue_list_total: 0,
    ats_queue_in_list: [],
    ats_queue_in_list_total: 0,
    ats_queue_list_loading: false,

    attaching_operators: false,
    attaching_trunks: false,
    attaching_companies: false,
    weight_adjusting_log: [],
    weight_adjusting_log_loading: false,

    ats_queue_group_list: [],
    ats_queue_group_list_total: 0,
    ats_queue_group_list_loading: false,
    ats_queue_group_creating: false,
    ats_queue_group_errors: {},
    ats_queue_group: ats_queue_group_empty(),

    ats_queue_empty: {
        id: 0,
        type: "",
        name: "",
        comment: "",
        steps1: "{}",
        steps2: "{}",
        off_time1: "22:00:00",
        off_time2: "09:00:00",
        how_call: 0,
        strategy: "random",
        check_wbt: false,
        unload_id: null,
        organization_id: 0,
        ats_group_id: 0,
        caller_ids: null,
        operators: null,
        history: null,
        is_work: true,
        dsday: 0,
        avr: null,
        last_status: null,
        aimy_token: '',
        aimy_scene_id: '',
        script_id: null,
        weight_adjusting: false,
        target_load: 80,
        min_weight: 10,
        max_weight: 100,
        base_weight_check_time: '02:00:00',
        base_weight_check: null,
        power_divider: 1.0,
        low_turbo: false,
        high_turbo: false,
        speech: null,
        process_in_off_time: false,
        recall_is_congestion: false,
    },
};

const actions = {
    [ATS_QUEUE_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }

        return api.list(
            'ats_queues.list',
            0,
            filter,
            state,
            commit,
            ATS_QUEUE_LIST_LOADING_CHANGE,
            ATS_QUEUE_LIST_CHANGE
        );
    },
    [ATS_QUEUE_IN_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }

        return api.list(
            'ats_queues.in_list',
            0,
            filter,
            state,
            commit,
            ATS_QUEUE_LIST_LOADING_CHANGE,
            ATS_QUEUE_IN_LIST_CHANGE
        );
    },
    [ATS_QUEUE_LIST_FILTER] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }

        return api.list(
            'ats_queues.in_list_filter',
            0,
            filter,
            state,
            commit,
            ATS_QUEUE_LIST_LOADING_CHANGE,
            ATS_QUEUE_LIST_FILTER_CHANGE
        );
    },
    [ATS_QUEUES_BY_COMPANY] ({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let filter = {
            skip: data.skip,
            take: data.take,
        };

        return api.list(
            'ats_queues.by_company',
            id,
            filter,
            state,
            commit,
            ATS_QUEUES_BY_COMPANY_LOADING_CHANGE,
            ATS_QUEUES_BY_COMPANY_CHANGE
        );
    },
    [ATS_QUEUE_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(ATS_QUEUE_CHANGE, state.ats_queue_empty);
            return;
        }

        return api.load(
            'ats_queues',
            id,
            state,
            commit,
            ATS_QUEUE_LOADING_CHANGE,
            ATS_QUEUE_CHANGE
        );
    },
    [ATS_QUEUE_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'ats_queues.list',
            id,
            model,
            state,
            commit,
            ATS_QUEUE_CREATE_CHANGE,
            ATS_QUEUE_CHANGE,
            ATS_QUEUE_ERRORS_CHANGE);
    },
    [ATS_QUEUE_UPDATE]({commit, state}, data) {
        return api.create(
            'ats_queues',
            data.id,
            data.model,
            state,
            commit,
            ATS_QUEUE_UPDATE_CHANGE,
            ATS_QUEUE_CHANGE,
            ATS_QUEUE_ERRORS_CHANGE);
    },
    [ATS_QUEUE_DELETE] ({commit, state}, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'ats_queues',
            id,
            state,
            commit,
            ATS_QUEUE_DELETE_CHANGE
        );
    },
    [ATS_QUEUE_ATTACH_OPERATORS] ({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'ats_queues.attach_operators',
            id,
            model,
            state,
            commit,
            ATS_QUEUE_ATTACH_OPERATORS_CHANGE,
            ATS_QUEUE_OPERATORS_CHANGE,
            ATS_QUEUE_ERRORS_CHANGE);
    },
    [ATS_QUEUE_ATTACH_TRUNKS] ({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'ats_queues.attach_trunks',
            id,
            model,
            state,
            commit,
            ATS_QUEUE_ATTACH_TRUNKS_CHANGE,
            ATS_QUEUE_TRUNKS_CHANGE,
            ATS_QUEUE_ERRORS_CHANGE);
    },
    [ATS_QUEUE_ATTACH_COMPANIES] ({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'ats_queues.attach_companies',
            id,
            model,
            state,
            commit,
            ATS_QUEUE_ATTACH_COMPANIES_CHANGE,
            ATS_QUEUE_COMPANIES_CHANGE,
            ATS_QUEUE_ERRORS_CHANGE);
    },
    [WEIGHT_ADJUSTING_LOG] ({ commit, state }, { id, period, page }) {
        return api.list(
            'ats_queues.weight_adjusting_log',
            id,
            { period, page },
            state,
            commit,
            WEIGHT_ADJUSTING_LOG_LOADING,
            WEIGHT_ADJUSTING_LOG_LOADED
        );
    },

    // Группы очередей
    [ATS_QUEUE_GROUP_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }

        return api.list(
            'ats_queue_groups.list',
            0,
            filter,
            state,
            commit,
            ATS_QUEUE_GROUP_LIST_LOADING_CHANGE,
            ATS_QUEUE_GROUP_LIST_CHANGE
        );
    },
    [ATS_QUEUE_GROUP_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        if(id == 0){
            return api.create(
                'ats_queue_groups.create',
                0,
                data,
                state,
                commit,
                ATS_QUEUE_GROUP_CREATE_CHANGE,
                ATS_QUEUE_GROUP_CHANGE,
                ATS_QUEUE_GROUP_ERRORS_CHANGE);
        }else{
            return api.create(
                'ats_queue_groups.update',
                id,
                data,
                state,
                commit,
                ATS_QUEUE_GROUP_CREATE_CHANGE,
                ATS_QUEUE_GROUP_CHANGE,
                ATS_QUEUE_GROUP_ERRORS_CHANGE);
        }
    },
    [ATS_QUEUE_GROUP_LOAD] ({commit, state}, id) {
        if(id == 0){
            commit(ATS_QUEUE_GROUP_SET_EMPTY);
            return;
        }
        return api.load(
            'ats_queue_groups.load',
            id,
            state,
            commit,
            ATS_QUEUE_GROUP_CREATE_CHANGE,
            ATS_QUEUE_GROUP_CHANGE
        );
    }
};

const mutations = {
    [ATS_QUEUE_LIST_LOADING_CHANGE] (state, data) {
        state.ats_queue_list_loading = data;
    },
    [ATS_QUEUE_LIST_CHANGE] (state, data) {
        if(data) {
            state.ats_queue_list        = data.data;
            state.ats_queue_list_total  = data.total;
        }
        else {
            state.ats_queue_list        = [];
            state.ats_queue_list_total  = 0;
        }
    },
    [ATS_QUEUE_IN_LIST_CHANGE] (state, data) {
        if(data) {
            state.ats_queue_in_list        = data.data;
            state.ats_queue_in_list_total  = data.total;
        }
        else {
            state.ats_queue_in_list        = [];
            state.ats_queue_in_list_total  = 0;
        }
    },
    [ATS_QUEUE_LIST_FILTER_CHANGE] (state, data) {
        if(data) {
            state.ats_queue_list_filter        = data.data;
            state.ats_queue_list_filter_total  = data.total;
        }
        else {
            state.ats_queue_list_filter        = [];
            state.ats_queue_list_filter_total  = 0;
        }
    },
    [ATS_QUEUES_BY_COMPANY_LOADING_CHANGE] (state, data) {
        state.ats_queues_by_company_loading = data;
    },
    [ATS_QUEUES_BY_COMPANY_CHANGE] (state, data) {
        if(data) {
            state.ats_queues_by_company        = data.data;
            state.ats_queues_by_company_total  = data.total;
        }
        else {
            state.ats_queues_by_company        = [];
            state.ats_queues_by_company_total  = 0;
        }
    },
    [ATS_QUEUE_LOADING_CHANGE] (state, data) {
        state.ats_queue_loading = data;
    },
    [ATS_QUEUE_CHANGE] (state, data) {
        state.ats_queue_errors = {};
        if(data && typeof data === 'object'){
            state.ats_queue = _.cloneDeep(data);
        }
    },
    [ATS_QUEUE_CREATE_CHANGE] (state, data) {
        state.ats_queue_creating = data;
    },
    [ATS_QUEUE_UPDATE_CHANGE] (state, data) {
        state.ats_queue_saving = data;
    },
    [ATS_QUEUE_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.ats_queue_list) {
                let list = [];
                state.ats_queue_list.forEach(prov => {
                    if (prov.id !== id) {
                        list.push({...prov});
                    }
                });

                state.ats_queue_list = list;
            }
        }
    },
    [ATS_QUEUE_ATTACH_OPERATORS_CHANGE] (state, data) {
        state.attaching_operators = data;
    },
    [ATS_QUEUE_OPERATORS_CHANGE] (state, data) {
        if (data) {
            state.ats_queue.operators = data.operators
        } else {
            state.ats_queue.operators = []
        }
    },
    [ATS_QUEUE_ATTACH_TRUNKS_CHANGE] (state, data) {
        state.attaching_trunks = data;
    },
    [ATS_QUEUE_ATTACH_COMPANIES_CHANGE] (state, data) {
        state.attaching_companies = data;
    },
    [ATS_QUEUE_TRUNKS_CHANGE] (state, data) {
        if (data) {
            state.ats_queue.caller_ids = data.data
            if (data.errors) {
                Vue.notify({
                    group: "app",
                    type: "warn",
                    title: data.message,
                    text: errors_format(data.errors),
                    duration: 10000
                });
            }
        } else {
            state.ats_queue.caller_ids = []
        }
    },
    [ATS_QUEUE_COMPANIES_CHANGE] (state, data) {
        if (data) {
            state.ats_queue.organizations = data.organizations
        } else {
            state.ats_queue.organizations = []
        }
    },
    [ATS_QUEUE_ERRORS_CHANGE] (state, data) {
        state.ats_queue_errors = data;
    },
    [WEIGHT_ADJUSTING_LOG_LOADING] (state, data) {
        state.weight_adjusting_log_loading = data;
    },
    [WEIGHT_ADJUSTING_LOG_LOADED] (state, data) {
        if (data && _.isArray(data.data)) {
            state.weight_adjusting_log = data.data;
        } else {
            state.weight_adjusting_log = [];
        }
    },
    // Группы очередей
    [ATS_QUEUE_GROUP_LIST_LOADING_CHANGE] (state, data) {
        state.ats_queue_group_list_loading = data;
    },
    [ATS_QUEUE_GROUP_LIST_CHANGE] (state, data) {
        if(data) {
            state.ats_queue_group_list        = data.data;
            state.ats_queue_group_list_total  = data.total;
        }
        else {
            state.ats_queue_group_list        = [];
            state.ats_queue_group_list_total  = 0;
        }
    },
    [ATS_QUEUE_GROUP_CHANGE] (state, data) {
        state.ats_queue_group_errors = {};
        if(data && typeof data === 'object'){
            state.ats_queue_group = _.cloneDeep(data);
        }
    },
    [ATS_QUEUE_GROUP_CREATE_CHANGE] (state, data) {
        state.ats_queue_group_creating = data;
    },
    [ATS_QUEUE_GROUP_ERRORS_CHANGE] (state, data) {
        state.ats_queue_group_errors = data;
    },
    [ATS_QUEUE_GROUP_SET_EMPTY](state, data) {
        state.ats_queue_group = ats_queue_group_empty();
    }
};

let errors_format = function(items) {
  let result = "";
  let i = 1;
  for (var key in items) {
    result += i + ") " + items[key] + "<br/>";
    i++;
  }

  return result;
};

export default {
    state,
    actions,
    mutations
}
