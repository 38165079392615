export const SET_TABLE = 'SET_TABLE';
export const GET_TABLE = 'GET_TABLE';
export const DELETE_TABLE = 'DELETE_TABLE';

const SET_TABLE_CHANGE = 'SET_TABLE_CHANGE';
const GET_TABLE_CHANGE = 'GET_TABLE_CHANGE';

const state = {
    dx_tables : [],
};

const actions = {
    [SET_TABLE] ({ commit, state }, data) {

        if(typeof(localStorage["dx_tables"]) == "undefined"){  
            localStorage["dx_tables"] = "{}";
        }
        let ls = JSON.parse(localStorage["dx_tables"]);
        
        if(typeof(ls[data.model])== "undefined"){
            ls[data.model]=[];
        };

        ls[data.model]=data.item;
            
        localStorage["dx_tables"] = JSON.stringify(ls);

        commit(SET_TABLE_CHANGE, ls);

    },
    [GET_TABLE] ({ commit, state }, data) {

        if(typeof(localStorage["dx_tables"]) == "undefined"){  
            localStorage["dx_tables"] = "{}";
        }
        let result =JSON.parse(localStorage["dx_tables"]);
        commit(GET_TABLE_CHANGE, result);
        return true;
    },
    [DELETE_TABLE] ({ commit, state }, data) {

        let ls = JSON.parse(localStorage["dx_tables"]);
        
        ls[data.model].splice(data.index, 1);

        localStorage["dx_tables"] = JSON.stringify(ls);

        commit(SET_TABLE_CHANGE, ls);
    }
};

const mutations = {
    [SET_TABLE_CHANGE](state, data) {
        state.dx_tables = data;
    },
    [GET_TABLE_CHANGE](state, data) {
        state.dx_tables = data;
    }
};

export default {
    state,
    actions,
    mutations
}
