import http from './../../lib/http';
import api from './../../lib/api';

export const ROLE_LIST                 = 'ROLE_LIST';

export const ROLE_LOAD                 = 'ROLE_LOAD';
export const ROLE_UPDATE               = 'ROLE_UPDATE';
export const ROLE_CREATE               = 'ROLE_CREATE';

export const ROLE_LOAD_DATATABLE       = 'ROLE_LOAD_DATATABLE';
export const ROLE_LOAD_BY_GROUP        = 'ROLE_LOAD_BY_GROUP';

export const ROLE_CHANGE               = 'ROLE_CHANGE';
const ROLE_LOADING_CHANGE              = 'ROLE_LOADING_CHANGE';
const ROLE_SAVING_CHANGE               = 'ROLE_SAVING_CHANGE';
const ROLE_ERRORS_CHANGE               = 'ROLE_ERRORS_CHANGE';

const ROLE_CREATING_CHANGE             = 'ROLE_CREATING_CHANGE';

const ROLE_DATATABLE_CHANGE            = 'ROLE_DATATABLE_CHANGE';
const ROLE_DATATABLE_LOADING_CHANGE    = 'ROLE_DATATABLE_LOADING_CHANGE';

export const ROLE_BY_GROUP_CHANGE     = 'ROLE_BY_GROUP_CHANGE';
const ROLE_BY_GROUP_LOADING_CHANGE    = 'ROLE_BY_GROUP_LOADING_CHANGE';

export const ROLE_DELETE		       = 'ROLE_DELETE';
export const ROLE_DELETE_CHANGE  	   = 'ROLE_DELETE_CHANGE';

export const ROLE_ATTACH_GEO = 'ROLE_ATTACH_GEO';
export const ROLE_ATTACH_GEO_CHANGE = 'ROLE_ATTACH_GEO_CHANGE';

export const ROLE_LOAD_GEO = 'ROLE_LOAD_GEO';
const ROLE_LOAD_GEO_CHANGE = 'ROLE_LOAD_GEO_CHANGE';

export const ROLE_ATTACH_ENTITY_PARAMS = 'ROLE_ATTACH_ENTITY_PARAMS';
const ROLE_ATTACH_ENTITY_PARAMS_CHANGE = 'ROLE_ATTACH_ENTITY_PARAMS_CHANGE';

export const ROLE_ATTACH_STATUS = 'ROLE_ATTACH_STATUS';
const ROLE_ATTACH_STATUS_CHANGE = 'ROLE_ATTACH_STATUS_CHANGE';

export const ROLE_ATTACH_ALL_CHILD_STATUS = 'ROLE_ATTACH_ALL_CHILD_STATUS';
const ROLE_ATTACH_ALL_CHILD_STATUS_CHANGE = 'ROLE_ATTACH_ALL_CHILD_STATUS_CHANGE';

export const ROLE_ATTACH_ORGANIZATIONS_PROJECTS = 'ROLE_ATTACH_ORGANIZATIONS_PROJECTS';
const ROLE_ATTACH_ORGANIZATIONS_PROJECTS_CHANGE = 'ROLE_ATTACH_ORGANIZATIONS_PROJECTS_CHANGE';

export const ROLE_LOAD_ORGANIZATIONS_PROJECTS = 'ROLE_LOAD_ORGANIZATIONS_PROJECTS';
const ROLE_LOAD_ORGANIZATIONS_PROJECTS_CHANGE = 'ROLE_LOAD_ORGANIZATIONS_PROJECTS_CHANGE';

export const ROLE_LOAD_GROUP_BY_ACCESS = 'ROLE_LOAD_GROUP_BY_ACCESS';
const ROLE_GROUP_BY_ACCESS_CHANGE = 'ROLE_GROUP_BY_ACCESS_CHANGE';

export const ROLE_LOAD_BY_ACCESS = 'ROLE_LOAD_BY_ACCESS';
export const ROLE_BY_ACCESS_CHANGE = 'ROLE_BY_ACCESS_CHANGE';

export const ROLE_BY_ACCESS_FOR_LIST = 'ROLE_BY_ACCESS_FOR_LIST';
export const ROLE_BY_ACCESS_FOR_LIST_CHANGE = 'ROLE_BY_ACCESS_FOR_LIST_CHANGE';

export const ROLE_COPY_SETTINGS = 'ROLE_COPY_SETTINGS';

export const ROLE_SET_OWNER_STATUS_CHANGE = 'ROLE_SET_OWNER_STATUS_CHANGE';

export const LOGOUT_BY_ROLE = 'LOGOUT_BY_ROLE';

export const ROLE_ADD_PERMISSIONS = 'ROLE_ADD_PERMISSIONS';

export const ROLE_LOAD_PERMISSIONS = 'ROLE_LOAD_PERMISSIONS';
const ROLE_PERMISSIONS_CHANGE = 'ROLE_PERMISSIONS_CHANGE';

const ROLE_CHANGE_LOGOUT = 'ROLE_CHANGE_LOGOUT';


const state = {
    role            : null,
    role_loading    : false,
    role_saving     : false,
    role_errors     : {},

    role_list_total     : 0,
    role_list           : null,
    role_list_loading   : false,

    role_creating           : false,
    role_creating_errors    : {},
    role_empty              : {
                                  id: 0,
                                  name: "",
                                  description: "",
                                  creator_organization_id: 0
                              },
    role_ticket_view  : {
        role_id : 0,
        organizations : [],
        projects : [],
        geos : [],
        is_deduct_organization : 0,
        is_deduct_project : 0,
        is_deduct_geo : 0
    },
    group_list_by_access : [],
    role_list_by_access : [],
    role_by_access_for_list : [],
    role_attached_entity_params: null,
    role_attach_result : null,
    permission_filter : null
};

const actions = {

    [LOGOUT_BY_ROLE] ({ commit, state }, id) {
        return api.load('user.logout_by_role', id, state, commit, ROLE_LOADING_CHANGE, ROLE_CHANGE_LOGOUT);
    },
    [ROLE_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(ROLE_CHANGE, state.role_empty);
            return;
        }

        return api.load('role', id, state, commit, ROLE_LOADING_CHANGE, ROLE_CHANGE);
    },

    [ROLE_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('role', id, model, state, commit, ROLE_SAVING_CHANGE, ROLE_CHANGE, ROLE_ERRORS_CHANGE);
    },

    [ROLE_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create('role.list', id, model, state, commit, ROLE_SAVING_CHANGE, ROLE_CHANGE, ROLE_ERRORS_CHANGE);
    },

    [ROLE_ATTACH_GEO] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create('role.attach_geo', id, model, state, commit, ROLE_LOADING_CHANGE, ROLE_ATTACH_GEO_CHANGE, ROLE_ERRORS_CHANGE);
    },

    [ROLE_ATTACH_ENTITY_PARAMS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create('role.attach_params', id, model, state, commit, ROLE_LOADING_CHANGE, ROLE_ATTACH_ENTITY_PARAMS_CHANGE, ROLE_ERRORS_CHANGE);
    },


    [ROLE_ATTACH_STATUS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create('role.attach_status', id, model, state, commit, ROLE_LOADING_CHANGE, ROLE_ATTACH_STATUS_CHANGE, ROLE_ERRORS_CHANGE);
    },

    [ROLE_ATTACH_ALL_CHILD_STATUS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create('role.attach_all_child_status', id, model, state, commit, ROLE_LOADING_CHANGE, ROLE_ATTACH_ALL_CHILD_STATUS_CHANGE, ROLE_ERRORS_CHANGE);
    },

    [ROLE_ATTACH_ORGANIZATIONS_PROJECTS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create('role.attach_organizations_projects', id, model, state, commit, ROLE_LOADING_CHANGE, ROLE_ATTACH_ORGANIZATIONS_PROJECTS_CHANGE, ROLE_ERRORS_CHANGE);
    },

    [ROLE_LOAD_GEO] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'role.geo',
            id,
            null,
            state,
            commit,
            ROLE_DATATABLE_LOADING_CHANGE,
            ROLE_LOAD_GEO_CHANGE);
    },

    [ROLE_LOAD_ORGANIZATIONS_PROJECTS] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'role.organizations_projects',
            id,
            null,
            state,
            commit,
            ROLE_DATATABLE_LOADING_CHANGE,
            ROLE_LOAD_ORGANIZATIONS_PROJECTS_CHANGE);
    },
    [ROLE_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'role.list',
            id,
            null,
            state,
            commit,
            ROLE_DATATABLE_LOADING_CHANGE,
            ROLE_DATATABLE_CHANGE);
    },

    [ROLE_LOAD_BY_GROUP] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'role.by_group',
            id,
            null,
            state,
            commit,
            ROLE_BY_GROUP_LOADING_CHANGE,
            ROLE_BY_GROUP_CHANGE);
    },

    [ROLE_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'role',
            id,
            state,
            commit,
            ROLE_DELETE_CHANGE
        );
    },

    [ROLE_LOAD_GROUP_BY_ACCESS] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.list(
            'role.groups_by_access',
            id,
            null,
            state,
            commit,
            ROLE_BY_GROUP_LOADING_CHANGE,
            ROLE_GROUP_BY_ACCESS_CHANGE);
    },

    [ROLE_LOAD_BY_ACCESS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create(
            'role.by_access',
            id,
            model,
            state,
            commit,
            ROLE_BY_GROUP_LOADING_CHANGE,
            ROLE_BY_ACCESS_CHANGE,
            ROLE_ERRORS_CHANGE);
    },
    // получение списка ролей для селектов
    [ROLE_BY_ACCESS_FOR_LIST] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create(
            'role.by_access_for_list',
            id,
            model,
            state,
            commit,
            ROLE_BY_GROUP_LOADING_CHANGE,
            ROLE_BY_ACCESS_FOR_LIST_CHANGE,
            ROLE_ERRORS_CHANGE
        );
    },

    [ROLE_COPY_SETTINGS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;

        return api.create('role.copy_settings', id, model, state, commit,ROLE_CREATING_CHANGE, ROLE_CHANGE, ROLE_ERRORS_CHANGE);
    },

    [ROLE_ADD_PERMISSIONS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;
        return api.create(
            'role.add_permissions',
            id,
            model,
            state,
            commit,
            ROLE_DATATABLE_LOADING_CHANGE,
            ROLE_ERRORS_CHANGE,
            ROLE_ERRORS_CHANGE);

    },

    [ROLE_LOAD_PERMISSIONS] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        state.permission_filter = null;

        return api.list(
            'role.get_permissions',
            id,
            null,
            state,
            commit,
            ROLE_DATATABLE_LOADING_CHANGE,
            ROLE_PERMISSIONS_CHANGE);
    },

};

const mutations = {
    [ROLE_BY_GROUP_LOADING_CHANGE](state, data) {
        state.role_list_loading = data;
    },
    [ROLE_DATATABLE_LOADING_CHANGE](state, data) {
        state.role_list_loading = data;
    },
    [ROLE_LOAD_ORGANIZATIONS_PROJECTS_CHANGE](state, data) {
        if(data){
            state.role_ticket_view.role_id = data.data.role_id;
            state.role_ticket_view.organizations = data.data.organizations;
            state.role_ticket_view.projects = data.data.projects;
            state.role_ticket_view.is_deduct_organization = data.data.is_deduct_organization;
            state.role_ticket_view.is_deduct_project = data.data.is_deduct_project;
        }else{
            state.role_ticket_view.role_id = 0;
            state.role_ticket_view.organizations = [];
            state.role_ticket_view.projects = [];
            state.role_ticket_view.is_deduct_organization = false;
            state.role_ticket_view.is_deduct_project = false;
        }
    },
    [ROLE_LOAD_GEO_CHANGE](state, data) {
        if(data){
          state.role_ticket_view.role_id = data.data.role_id;
          state.role_ticket_view.geos = data.data.geos;
          state.role_ticket_view.is_deduct_geo = data.data.is_deduct_geo;
        }else{
          state.role_ticket_view.role_id = 0;
          state.role_ticket_view.geos = [];
          state.role_ticket_view.is_deduct_geo = false;
        }
    },
    [ROLE_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.role_list        = data.data;
            state.role_list_total  = data.total;
        }
        else {
            state.role_list        = [];
            state.role_list_total  = 0;
        }
    },
    [ROLE_BY_GROUP_CHANGE](state, data) {

        if(data) {
            state.role_list        = data.data;
            state.role_list_total  = data.total;
        }
        else {
            state.role_list        = [];
            state.role_list_total  = 0;
        }
    },
    [ROLE_GROUP_BY_ACCESS_CHANGE](state, data) {
        if(data) {
            state.group_list_by_access = data.data;
        }
        else {
            state.group_list_by_access = [];
        }
    },
    [ROLE_BY_ACCESS_CHANGE](state, data) {
        if(data) {
            state.role_list_by_access = data.data;
        }
        else {
            state.role_list_by_access = [];
        }
    },
    [ROLE_BY_ACCESS_FOR_LIST_CHANGE](state, data) {
        if (data) {
            state.role_by_access_for_list = data.data;
        } else {
            state.role_by_access_for_list = [];
        }
    },
    [ROLE_CHANGE_LOGOUT](state, data) {
        state.role_loading = false;
    },
    [ROLE_CHANGE](state, data) {
        if(data && typeof data === 'object'){
            state.role  = data;
            state.role_empty = {id:0, name:null};
        }
    },
    [ROLE_LOADING_CHANGE](state, data) {
        state.role_loading = data;
    },
    [ROLE_SAVING_CHANGE](state, data) {
        state.role_saving   = data;

    },
    [ROLE_ERRORS_CHANGE](state, data) {
        state.role_errors   = data;
    },
    [ROLE_CREATING_CHANGE](state, data) {
        state.role_creating = data;
    },
    [ROLE_ATTACH_ENTITY_PARAMS_CHANGE](state, data) {
        state.role_attach_result = data;
    },
    [ROLE_ATTACH_STATUS_CHANGE](state, data) {
        state.role_attach_result = data;
    },
    [ROLE_ATTACH_ALL_CHILD_STATUS_CHANGE](state, data) {
        state.role_attach_result = data;
    },
    [ROLE_ATTACH_ORGANIZATIONS_PROJECTS_CHANGE](state, data) {
        state.role_attach_result = data;
    },
    [ROLE_ATTACH_GEO_CHANGE](state, data) {
        state.role_attach_result = data;
    },
    [ROLE_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.role_list) {

                let list = [];
                state.role_list.forEach(role => {
                    if (role.id !== id) {
                        list.push({...role});
                    }
                });

                state.role_list = list;
            }
        }
    },
    [ROLE_SET_OWNER_STATUS_CHANGE](state, data) {
        state.role.onwer_status = data;
    },
    [ROLE_PERMISSIONS_CHANGE](state, data) {
        if(data && typeof data.data === 'object'){
            state.permission_filter = JSON.parse(data.data.permissions);
        };
    },
};

export default {
    state,
    actions,
    mutations
}
