import api from './../../lib/api';

export const CLIENT_LOAD   = "CLIENT_LOAD";
export const CLIENT_UPDATE = "CLIENT_UPDATE";
export const CLIENT_CREATE = "CLIENT_CREATE";
export const CLIENT_DELETE = "CLIENT_DELETE";

const CLIENT_CHANGE         = "CLIENT_CHANGE";
const CLIENT_LOADING_CHANGE = "CLIENT_LOADING_CHANGE";
const CLIENT_SAVING_CHANGE  = "CLIENT_SAVING_CHANGE";
const CLIENT_ERRORS_CHANGE  = "CLIENT_ERRORS_CHANGE";
const CLIENT_DELETE_CHANGE  = "CLIENT_DELETE_CHANGE";
const CLIENT_CREATE_CHANGE  = "CLIENT_CREATE_CHANGE";

const state = {
    client:                 null,

    client_loading:         false,
    client_saving:          false,

    client_errors:          {},

    client_empty:           {
                                id: 0,
                                client_name: "",
                                phone: "",
                                iin: "",
                                type: { id: 2 },
                                advert_source: {id:null},
                                organizations: {id:0}
                            },
};

const actions = {
    [CLIENT_LOAD] ({ state, commit }, id) {
        if (!id) {
            commit(CLIENT_CHANGE, state.client_empty);
            return;
        }
        return api.load(
            'wholesale_client',
            id,
            state,
            commit,
            CLIENT_LOADING_CHANGE,
            CLIENT_CHANGE
        )
    },

    [CLIENT_UPDATE] ({ state, commit }, { id, data }) {

        return api.update(
            'wholesale_client',
            id,
            data,
            state,
            commit,
            CLIENT_SAVING_CHANGE,
            CLIENT_CHANGE,
            CLIENT_ERRORS_CHANGE
        )
    },

    [CLIENT_CREATE] ({ state, commit }, data) {

        return api.create(
            'wholesale_clients',
            0,
            data,
            state,
            commit,
            CLIENT_SAVING_CHANGE,
            CLIENT_CREATE_CHANGE,
            CLIENT_ERRORS_CHANGE
        )
    },

    [CLIENT_DELETE] ({ state, commit }, id) {

        return api.delete(
            'wholesale_client',
            id,
            state,
            commit,
            CLIENT_DELETE_CHANGE
        )
    },
};

const mutations = {
    [CLIENT_CHANGE](state, data) {
        state.client = data
    },
    [CLIENT_LOADING_CHANGE](state, data) {
        state.client_loading = data
    },
    [CLIENT_SAVING_CHANGE](state, data) {
        state.client_saving = data
    },
    [CLIENT_ERRORS_CHANGE](state, data) {
        state.client_errors = data
    },
    [CLIENT_CREATE_CHANGE](state, data) {
        state.client_creating = data
    },
    [CLIENT_DELETE_CHANGE](state, data) {
        console.log('QQ',data)
    },
};

export default {
    state,
    actions,
    mutations
}