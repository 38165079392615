import api from './../../lib/api';
import VueRouter from "./../../routes.js";
import moment from "moment"

export const DECISION_TEMPLATE_LOAD                 = 'DECISION_TEMPLATE_LOAD';
export const DECISION_TEMPLATE_UPDATE               = 'DECISION_TEMPLATE_UPDATE';
export const DECISION_TEMPLATE_CREATE               = 'DECISION_TEMPLATE_CREATE';

export const DECISION_TEMPLATE_LOAD_DATATABLE       = 'DECISION_TEMPLATE_LOAD_DATATABLE';

export const GET_BOT_SCRIPTS_LOAD_SOLUTION          = 'GET_BOT_SCRIPTS_LOAD_SOLUTION';
export const SAVE_BOT_SCRIPTS_LOAD_SOLUTION         = 'SAVE_BOT_SCRIPTS_LOAD_SOLUTION';

export const GET_SETUP_CAMPAIGN_THROUGH_CAMPAIGN_LOAD = 'GET_SETUP_CAMPAIGN_THROUGH_CAMPAIGN_LOAD';
export const COPY_SETUP_BOT_SCRIPTS_IN_NEW_CAMPAIGN   = 'COPY_SETUP_BOT_SCRIPTS_IN_NEW_CAMPAIGN';

const DECISION_TEMPLATE_CHANGE                      = 'DECISION_TEMPLATE_CHANGE';
const DECISION_TEMPLATE_LOADING_CHANGE              = 'DECISION_TEMPLATE_LOADING_CHANGE';
const DECISION_TEMPLATE_SAVING_CHANGE               = 'DECISION_TEMPLATE_SAVING_CHANGE';
const DECISION_TEMPLATE_ERRORS_CHANGE               = 'DECISION_TEMPLATE_ERRORS_CHANGE';
const DECISION_TEMPLATE_CREATE_CHANGE               = 'DECISION_TEMPLATE_CREATE_CHANGE';

const DECISION_TEMPLATE_DATATABLE_CHANGE            = 'DECISION_TEMPLATE_DATATABLE_CHANGE';
const DECISION_TEMPLATE_DATATABLE_LOADING_CHANGE    = 'DECISION_TEMPLATE_DATATABLE_LOADING_CHANGE';
// для клиентской части
export const SOLUTION_LOAD                          = 'SOLUTION_LOAD';
export const SOLUTION_LOAD_DATATABLE                = 'SOLUTION_LOAD_DATATABLE';

const SOLUTION_CHANGE                               = 'SOLUTION_CHANGE';
const SOLUTION_LOADING_CHANGE                       = 'SOLUTION_LOADING_CHANGE';
const SOLUTION_DATATABLE_CHANGE                     = 'SOLUTION_DATATABLE_CHANGE';
const SOLUTION_DATATABLE_LOADING_CHANGE             = 'SOLUTION_DATATABLE_LOADING_CHANGE';

const GET_BOT_SCRIPTS_LOADING_CHANGE_SOLUTION       = 'GET_BOT_SCRIPTS_LOADING_CHANGE_SOLUTION';
const GET_BOT_SCRIPTS_CHANGE_SOLUTION               = 'GET_BOT_SCRIPTS_CHANGE_SOLUTION';
const SAVE_BOT_SCRIPT_SAVING_CHANGE_SOLUTION        = 'SAVE_BOT_SCRIPT_SAVING_CHANGE_SOLUTION';
const SAVE_BOT_SCRIPT_CHANGE_SOLUTION               = 'SAVE_BOT_SCRIPT_CHANGE_SOLUTION';
const SAVE_BOT_SCRIPT_ERRORS_CHANGE_SOLUTION        = 'SAVE_BOT_SCRIPT_ERRORS_CHANGE_SOLUTION';

const state = {
  // для админской части
  decision_template            : null,
  decision_template_loading    : false,

  decision_template_saving     : false,
  decision_template_errors     : {},

  decision_template_list_total   : 0,
  decision_template_list         : null,
  decision_template_list_loading : false,
  decision_template_empty        : {
    name: '',
    description: '',
    img: '',
    lnk_video: '',
    organization_ids: [],
    tags:[],
    type: null,
    industry_id: null,
    bpm_bp_id: null,
    bot_script_id: null,
    is_work: false,
    is_public: false,
    dashboards: [],
    reports: [],
  },
// для клиентской части
  solution              : null,
  solution_loading      : false,
  solution_list_total   : 0,
  solution_list         : null,
  solution_list_loading : false,

  get_bot_scripts_solution            : {},
  get_bot_scripts_loading_solution    : false,
  solution_wa_tpl_templates           : [],

  save_bot_script_solution            : {},
  save_bot_script_saving_solution     : false,
  save_bot_script_errors_solution     : {},
};

const getters = {
  solution_wa_tpl_templates: state => {
    if (state.get_bot_scripts_solution && state.get_bot_scripts_solution.tpl_templates) {
      return state.get_bot_scripts_solution.tpl_templates;
    } else {
      return [];
    }
  }
};

const actions = {
  [DECISION_TEMPLATE_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(DECISION_TEMPLATE_CHANGE, _.cloneDeep(state.decision_template_empty));
      return;
    }
    return api.load(
      'decision_template',
      id,
      state,
      commit,
      DECISION_TEMPLATE_LOADING_CHANGE,
      DECISION_TEMPLATE_CHANGE
    );
  },

  [DECISION_TEMPLATE_UPDATE] ({ commit, state }, {id, data}) {
    return api.create(
      'decision_template',
      id,
      data,
      state,
      commit,
      DECISION_TEMPLATE_SAVING_CHANGE,
      DECISION_TEMPLATE_CHANGE,
      DECISION_TEMPLATE_ERRORS_CHANGE
    );
  },

  [DECISION_TEMPLATE_CREATE] ({ commit, state }, data) {
    return api.create(
      'decision_templates',
      0,
      data,
      state,
      commit,
      DECISION_TEMPLATE_SAVING_CHANGE,
      DECISION_TEMPLATE_CREATE_CHANGE,
      DECISION_TEMPLATE_ERRORS_CHANGE
    );
  },

  [DECISION_TEMPLATE_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'decision_templates',
      0,
      filter,
      state,
      commit,
      DECISION_TEMPLATE_DATATABLE_LOADING_CHANGE,
      DECISION_TEMPLATE_DATATABLE_CHANGE
    );
  },

  [SOLUTION_LOAD] ({ commit, state }, id) {
    if (!id) {
      return false;
    }
    return api.load(
      'solution',
      id,
      state,
      commit,
      SOLUTION_LOADING_CHANGE,
      SOLUTION_CHANGE
    );
  },
  [SOLUTION_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'solutions',
      0,
      filter,
      state,
      commit,
      SOLUTION_DATATABLE_LOADING_CHANGE,
      SOLUTION_DATATABLE_CHANGE
    );
  },

  // получение списка сценариев ботов с и их экранов для редактирования через Кампанию
  [GET_BOT_SCRIPTS_LOAD_SOLUTION] ({ commit, state }, id) {
    if (!id) {
      return;
    }
    return api.load(
      'solution_get_bot_scripts',
      id,
      state,
      commit,
      GET_BOT_SCRIPTS_LOADING_CHANGE_SOLUTION,
      GET_BOT_SCRIPTS_CHANGE_SOLUTION
    );
  },

  [SAVE_BOT_SCRIPTS_LOAD_SOLUTION] ({ commit, state }, {id, data}) {
    return api.update(
      'solution_create_setups_bot_scripts',
      id,
      data,
      state,
      commit,
      SAVE_BOT_SCRIPT_SAVING_CHANGE_SOLUTION,
      SAVE_BOT_SCRIPT_CHANGE_SOLUTION,
      SAVE_BOT_SCRIPT_ERRORS_CHANGE_SOLUTION
    );
  },
  // для копирования настроек Кампании через шаблон решения
  // получение данных настроек
  [GET_SETUP_CAMPAIGN_THROUGH_CAMPAIGN_LOAD] ({ commit, state }, bot_campaign_id) {
    if (!bot_campaign_id) {
      return;
    }
    return api.load(
      'get_setup_through_campaign',
      bot_campaign_id,
      state,
      commit,
      GET_BOT_SCRIPTS_LOADING_CHANGE_SOLUTION,
      GET_BOT_SCRIPTS_CHANGE_SOLUTION
    );
  },
  // создание новой Кампании, и сохранение настроек
  [COPY_SETUP_BOT_SCRIPTS_IN_NEW_CAMPAIGN] ({ commit, state }, {bot_campaign_id, data}) {
    return api.update(
      'copy_setup_in_new_campaign',
      bot_campaign_id,
      data,
      state,
      commit,
      SAVE_BOT_SCRIPT_SAVING_CHANGE_SOLUTION,
      SAVE_BOT_SCRIPT_CHANGE_SOLUTION,
      SAVE_BOT_SCRIPT_ERRORS_CHANGE_SOLUTION
    );
  },
};

const mutations = {
  [DECISION_TEMPLATE_DATATABLE_LOADING_CHANGE](state, data) {
    state.decision_template_list_loading = data;
  },
  [DECISION_TEMPLATE_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.decision_template_list          = data.data;
      state.decision_template_list_total    = data.total;
    } else {
      state.decision_template_list          = {};
      state.decision_template_list_total    = 0;
    }
  },
  [DECISION_TEMPLATE_CHANGE](state, data) {
    state.decision_template = data;
  },
  [DECISION_TEMPLATE_LOADING_CHANGE](state, data) {
    state.decision_template_loading = data;
  },
  [DECISION_TEMPLATE_SAVING_CHANGE](state, data) {
    state.decision_template_saving   = data;
  },
  [DECISION_TEMPLATE_ERRORS_CHANGE](state, data) {
    state.decision_template_errors   = data;
  },
  [DECISION_TEMPLATE_CREATE_CHANGE](state, data) {
    state.decision_template = data;
    if (data) {
      VueRouter.push(`/bpm/decision_templates/${data.id}`);
    }
  },
  // для клиентской части
  [SOLUTION_CHANGE](state, data) {
    state.solution = data;
  },
  [SOLUTION_LOADING_CHANGE](state, data) {
    state.solution_loading = data;
  },
  [SOLUTION_DATATABLE_LOADING_CHANGE](state, data) {
    state.solution_list_loading = data;
  },
  [SOLUTION_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.solution_list          = data.data;
      state.solution_list_total    = data.total;
    } else {
      state.solution_list          = {};
      state.solution_list_total    = 0;
    }
  },
  // Получение списка скриптов бота с их данными для настройки
  [GET_BOT_SCRIPTS_LOADING_CHANGE_SOLUTION](state, data) {
    state.get_bot_scripts_loading_solution = data;
  },
  [GET_BOT_SCRIPTS_CHANGE_SOLUTION](state, data) {
    state.get_bot_scripts_solution = data;
  },
  [SAVE_BOT_SCRIPT_CHANGE_SOLUTION](state, data) {
    if (data && data.id) {
      state.get_bot_scripts_solution = data;

      if (data.status && data.status === 'ok') {
        // если создание завершилось без ошибок
        VueRouter.push({
          name: 'campaign',
          params: {
            id: data.id,
          },
        });
      } else {
        VueRouter.push({
          name: 'campaign_options',
          params: {
            id: data.id,
          },
        });
      }
    }
  },
  [SAVE_BOT_SCRIPT_SAVING_CHANGE_SOLUTION](state, data) {
    state.save_bot_script_saving_solution   = data;
  },
  [SAVE_BOT_SCRIPT_ERRORS_CHANGE_SOLUTION](state, data) {
    state.save_bot_script_errors_solution   = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
