import api from './../../lib/api';

export const BOT_CAMPAIGN_LOAD                 = 'BOT_CAMPAIGN_LOAD';
export const BOT_CAMPAIGN_UPDATE               = 'BOT_CAMPAIGN_UPDATE';
export const BOT_CAMPAIGN_CREATE               = 'BOT_CAMPAIGN_CREATE';

export const BOT_CAMPAIGN_LOAD_DATATABLE       = 'BOT_CAMPAIGN_LOAD_DATATABLE';

export const CAMPAIGN_DB_FILE_LOAD_DATATABLE   = 'CAMPAIGN_DB_FILE_LOAD_DATATABLE';

export const GET_BOT_SCRIPTS_LOAD_DATATABLE    = 'GET_BOT_SCRIPTS_LOAD_DATATABLE';
export const SAVE_BOT_SCRIPTS_LOAD_DATATABLE   = 'SAVE_BOT_SCRIPTS_LOAD_DATATABLE';

export const BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_LIST    = 'BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_LIST';
export const BOT_CAMPAIGN_GET_STAT    = 'BOT_CAMPAIGN_GET_STAT';

export const BOT_CAMPAIGN_EXCLUDE_CONTACT_BY_LIST     = 'BOT_CAMPAIGN_EXCLUDE_CONTACT_BY_LIST';
export const BOT_CAMPAIGN_EXCLUDE_CONTACT_BY_FILE     = 'BOT_CAMPAIGN_EXCLUDE_CONTACT_BY_FILE';

export const BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_FILTER    = 'BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_FILTER';

export const BOT_CAMPAIGN_UPDATE_LOAD_TYPE    = 'BOT_CAMPAIGN_UPDATE_LOAD_TYPE';

export const BOT_CAMPAIGN_ELEMENTS_LOAD        = 'BOT_CAMPAIGN_ELEMENTS_LOAD';

const BOT_CAMPAIGN_CHANGE                      = 'BOT_CAMPAIGN_CHANGE';
const BOT_CAMPAIGN_LOADING_CHANGE              = 'BOT_CAMPAIGN_LOADING_CHANGE';
const BOT_CAMPAIGN_SAVING_CHANGE               = 'BOT_CAMPAIGN_SAVING_CHANGE';
const BOT_CAMPAIGN_ERRORS_CHANGE               = 'BOT_CAMPAIGN_ERRORS_CHANGE';
const BOT_CAMPAIGN_CREATE_CHANGE               = 'BOT_CAMPAIGN_CREATE_CHANGE';

const BOT_CAMPAIGN_DATATABLE_CHANGE            = 'BOT_CAMPAIGN_DATATABLE_CHANGE';
const BOT_CAMPAIGN_DATATABLE_LOADING_CHANGE    = 'BOT_CAMPAIGN_DATATABLE_LOADING_CHANGE';

const CAMPAIGN_DB_FILE_DATATABLE_LOADING_CHANGE = 'CAMPAIGN_DB_FILE_DATATABLE_LOADING_CHANGE';
const CAMPAIGN_DB_FILE_DATATABLE_CHANGE         = 'CAMPAIGN_DB_FILE_DATATABLE_CHANGE';

const GET_BOT_SCRIPTS_LOADING_CHANGE            = 'GET_BOT_SCRIPTS_LOADING_CHANGE';
const GET_BOT_SCRIPTS_CHANGE                    = 'GET_BOT_SCRIPTS_CHANGE';
const SAVE_BOT_SCRIPT_CHANGE                    = 'SAVE_BOT_SCRIPT_CHANGE';
const SAVE_BOT_SCRIPT_SAVING_CHANGE             = 'SAVE_BOT_SCRIPT_SAVING_CHANGE';
const SAVE_BOT_SCRIPT_ERRORS_CHANGE             = 'SAVE_BOT_SCRIPT_ERRORS_CHANGE';

const CAMPAIGN_STAT_DATATABLE_LOADING_CHANGE             = 'CAMPAIGN_STAT_DATATABLE_LOADING_CHANGE';
const CAMPAIGN_STAT_DATATABLE_CHANGE             = 'CAMPAIGN_STAT_DATATABLE_CHANGE';

const BOT_CAMPAIGN_UPDATE_LOAD_TYPE_SAVING_CHANGE             = 'BOT_CAMPAIGN_UPDATE_LOAD_TYPE_SAVING_CHANGE';
const BOT_CAMPAIGN_UPDATE_LOAD_TYPE_CHANGE             = 'BOT_CAMPAIGN_UPDATE_LOAD_TYPE_CHANGE';
const BOT_CAMPAIGN_UPDATE_LOAD_TYPE_ERRORS_CHANGE             = 'BOT_CAMPAIGN_UPDATE_LOAD_TYPE_ERRORS_CHANGE';

const BOT_CAMPAIGN_ELEMENTS_LOADING_CHANGE      = 'BOT_CAMPAIGN_ELEMENTS_LOADING_CHANGE';
const BOT_CAMPAIGN_ELEMENTS_CHANGE              = 'BOT_CAMPAIGN_ELEMENTS_CHANGE';

const state = {
  bot_campaign            : null,
  bot_campaign_loading    : false,

  bot_campaign_elements        : [],
  bot_campaign_elements_loading: false,

  bot_campaign_saving     : false,
  bot_campaign_errors     : {},

  bot_campaign_list_total   : 0,
  bot_campaign_list         : null,
  bot_campaign_list_loading : false,
  bot_campaign_empty        : {
    id: 0,
    name: '',
    organizations: [],
  },
  bot_campaign_stat_loading : false,
  bot_campaign_stat:[
    [],[],[],[],[]
  ],

  campaign_db_file_list  : {},
  campaign_db_file_list_loading : false,

  get_bot_scripts            : {},
  get_bot_scripts_loading    : false,
  campaign_wa_tpl_templates  : [],

  save_bot_script            : {},
  save_bot_script_saving     : false,
  save_bot_script_errors     : {},
  segment_list     : [],
};

const getters = {
  campaign_wa_tpl_templates: state => {
    if (state.get_bot_scripts && state.get_bot_scripts.tpl_templates) {
      return state.get_bot_scripts.tpl_templates;
    } else {
      return [];
    }
  }
};

const actions = {
  [BOT_CAMPAIGN_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(BOT_CAMPAIGN_CHANGE, _.cloneDeep(state.bot_campaign_empty));
      return;
    }
    return api.load(
      'bot_campaign',
      id,
      state,
      commit,
      BOT_CAMPAIGN_LOADING_CHANGE,
      BOT_CAMPAIGN_CHANGE
    );
  },
  [BOT_CAMPAIGN_ELEMENTS_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(BOT_CAMPAIGN_ELEMENTS_CHANGE, []);
      return;
    }

    return api.load(
      'bot_campaign.elements',
      id,
      state,
      commit,
      BOT_CAMPAIGN_ELEMENTS_LOADING_CHANGE,
      BOT_CAMPAIGN_ELEMENTS_CHANGE
    );
  },

  [BOT_CAMPAIGN_UPDATE] ({ commit, state }, {id, data}) {
    return api.update(
      'bot_campaign',
      id,
      data,
      state,
      commit,
      BOT_CAMPAIGN_SAVING_CHANGE,
      BOT_CAMPAIGN_CHANGE,
      BOT_CAMPAIGN_ERRORS_CHANGE
    );
  },

  [BOT_CAMPAIGN_CREATE] ({ commit, state }, data) {
    return api.create(
      'bot_campaigns',
      0,
      data,
      state,
      commit,
      BOT_CAMPAIGN_SAVING_CHANGE,
      BOT_CAMPAIGN_CREATE_CHANGE,
      BOT_CAMPAIGN_ERRORS_CHANGE
    );
  },

  [BOT_CAMPAIGN_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'bot_campaigns',
      0,
      filter,
      state,
      commit,
      BOT_CAMPAIGN_DATATABLE_LOADING_CHANGE,
      BOT_CAMPAIGN_DATATABLE_CHANGE
    );
  },
  // получение списка загруженных файлов баз контрагентов для Кампании
  [CAMPAIGN_DB_FILE_LOAD_DATATABLE] ({ commit, state }, data) {
    return api.list(
      'get_bpm_db_load_list',
      data.id,
      data.filter,
      state,
      commit,
      CAMPAIGN_DB_FILE_DATATABLE_LOADING_CHANGE,
      CAMPAIGN_DB_FILE_DATATABLE_CHANGE
    );
  },

  // Добавление контактов в кампании вручную
  [BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_LIST]({ commit, state }, data) {
      return api.create('bot_campaigns.create_contrahen_by_list', 0, data, state, commit, BOT_CAMPAIGN_SAVING_CHANGE, BOT_CAMPAIGN_CHANGE, BOT_CAMPAIGN_ERRORS_CHANGE);
  },
  // Добавление контактов в кампании через фильтр
  [BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_FILTER]({ commit, state }, data) {
      return api.create('bot_campaigns.create_contrahen_by_filter', 0, data, state, commit, BOT_CAMPAIGN_SAVING_CHANGE, BOT_CAMPAIGN_CHANGE, BOT_CAMPAIGN_ERRORS_CHANGE);
  },
  // Исключение списка контактов из обработки кампании
  [BOT_CAMPAIGN_EXCLUDE_CONTACT_BY_LIST] ({ commit, state }, data) {
      return api.create('bot_campaigns.exclude_contact_by_list', 0, data, state, commit, BOT_CAMPAIGN_SAVING_CHANGE, BOT_CAMPAIGN_CHANGE, BOT_CAMPAIGN_ERRORS_CHANGE);
  },
  // Исключение контактов через файл из обработки кампании
  [BOT_CAMPAIGN_EXCLUDE_CONTACT_BY_FILE] ({ commit, state }, data) {
      return api.create('bot_campaigns.exclude_contact_by_file', 0, data, state, commit, BOT_CAMPAIGN_SAVING_CHANGE, BOT_CAMPAIGN_CHANGE, BOT_CAMPAIGN_ERRORS_CHANGE);
  },

  // Получение статистики по контрагентам кампании
  [BOT_CAMPAIGN_GET_STAT] ({ commit, state }, data) {
    return api.list(
            'bot_campaigns.get_stat',
            data.id,
            data,
            state,
            commit,
            CAMPAIGN_STAT_DATATABLE_LOADING_CHANGE,
            CAMPAIGN_STAT_DATATABLE_CHANGE);
    },

  // получение списка сценариев ботов с и их экранов для редактирования через Кампанию
  [GET_BOT_SCRIPTS_LOAD_DATATABLE] ({ commit, state }, id) {
    if (!id) {
      return;
    }
    return api.load(
      'get_bot_scripts',
      id,
      state,
      commit,
      GET_BOT_SCRIPTS_LOADING_CHANGE,
      GET_BOT_SCRIPTS_CHANGE
    );
  },
  [SAVE_BOT_SCRIPTS_LOAD_DATATABLE] ({ commit, state }, {id, data}) {
    return api.update(
      'save_bot_script',
      id,
      data,
      state,
      commit,
      SAVE_BOT_SCRIPT_SAVING_CHANGE,
      SAVE_BOT_SCRIPT_CHANGE,
      SAVE_BOT_SCRIPT_ERRORS_CHANGE
    );
  },
  [BOT_CAMPAIGN_UPDATE_LOAD_TYPE] ({ commit, state }, {id, data}) {
    return api.update(
      'bot_campaigns.update_load_type',
      id,
      data,
      state,
      commit,
      BOT_CAMPAIGN_UPDATE_LOAD_TYPE_SAVING_CHANGE,
      BOT_CAMPAIGN_UPDATE_LOAD_TYPE_CHANGE,
      BOT_CAMPAIGN_UPDATE_LOAD_TYPE_ERRORS_CHANGE
    );
  },
};

const mutations = {
  [BOT_CAMPAIGN_DATATABLE_LOADING_CHANGE](state, data) {
    state.bot_campaign_list_loading = data;
  },
  [BOT_CAMPAIGN_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.bot_campaign_list          = data.data;
      state.bot_campaign_list_total    = data.total;
    } else {
      state.bot_campaign_list          = {};
      state.bot_campaign_list_total    = 0;
    }
  },
  [BOT_CAMPAIGN_CHANGE](state, data) {
    state.bot_campaign = data;
  },
  [BOT_CAMPAIGN_LOADING_CHANGE](state, data) {
    state.bot_campaign_loading = data;
  },
  [BOT_CAMPAIGN_ELEMENTS_CHANGE](state, data) {
    if (data?.data) {
      state.bot_campaign_elements = data.data;
    } else {
      state.bot_campaign_elements = [];
    }
  },
  [BOT_CAMPAIGN_ELEMENTS_LOADING_CHANGE](state, data) {
    state.bot_campaign_elements_loading = data;
  },
  [BOT_CAMPAIGN_SAVING_CHANGE](state, data) {
    state.bot_campaign_saving   = data;
  },
  [BOT_CAMPAIGN_ERRORS_CHANGE](state, data) {
    state.bot_campaign_errors   = data;
  },
  [BOT_CAMPAIGN_CREATE_CHANGE](state, data) {
    state.bot_campaign = data;
  },

  [CAMPAIGN_STAT_DATATABLE_LOADING_CHANGE](state, data) {
    state.bot_campaign_stat_loading = data;
  },
  [CAMPAIGN_STAT_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.bot_campaign_stat = { ...state.bot_campaign_stat, ...data.data };
    }
  },

  [CAMPAIGN_DB_FILE_DATATABLE_LOADING_CHANGE](state, data) {
    state.bot_campaign_list_loading = data;
  },
  [CAMPAIGN_DB_FILE_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.campaign_db_file_list          = data.data;
      state.campaign_db_file_list_loading  = data.total;
    } else {
      state.campaign_db_file_list          = {};
      state.campaign_db_file_list_loading  = 0;
    }
  },
  // Получение списка скриптов робота с их данными для настройки
  [GET_BOT_SCRIPTS_LOADING_CHANGE](state, data) {
    state.get_bot_scripts_loading = data;
  },
  [GET_BOT_SCRIPTS_CHANGE](state, data) {
    state.get_bot_scripts = data;
  },
  [SAVE_BOT_SCRIPT_CHANGE](state, data) {
    state.save_bot_script = data;
  },
  [SAVE_BOT_SCRIPT_SAVING_CHANGE](state, data) {
    state.save_bot_script_saving   = data;
  },
  [SAVE_BOT_SCRIPT_ERRORS_CHANGE](state, data) {
    state.save_bot_script_errors   = data;
  },
  [BOT_CAMPAIGN_UPDATE_LOAD_TYPE_SAVING_CHANGE](state, data) {
    return;
  },
  [BOT_CAMPAIGN_UPDATE_LOAD_TYPE_CHANGE](state, data) {
    return;
  },
  [BOT_CAMPAIGN_UPDATE_LOAD_TYPE_ERRORS_CHANGE](state, data) {
    return;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
