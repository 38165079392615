import http from './../../lib/http';
import api from './../../lib/api';

export const TRAFFIC_LIST                 = 'TRAFFIC_LIST';

export const TRAFFIC_LOAD                 = 'TRAFFIC_LOAD';
export const TRAFFIC_UPDATE               = 'TRAFFIC_UPDATE';
export const TRAFFIC_CREATE               = 'TRAFFIC_CREATE';

export const TRAFFIC_LOAD_DATATABLE       = 'TRAFFIC_LOAD_DATATABLE';

export const TRAFFIC_CHANGE               = 'TRAFFIC_CHANGE';

const TRAFFIC_LOADING_CHANGE              = 'TRAFFIC_LOADING_CHANGE';
const TRAFFIC_SAVING_CHANGE               = 'TRAFFIC_SAVING_CHANGE';
const TRAFFIC_ERRORS_CHANGE               = 'TRAFFIC_ERRORS_CHANGE';

const TRAFFIC_CREATING_CHANGE             = 'TRAFFIC_CREATING_CHANGE';

const TRAFFIC_DATATABLE_CHANGE            = 'TRAFFIC_DATATABLE_CHANGE';
const TRAFFIC_DATATABLE_LOADING_CHANGE    = 'TRAFFIC_DATATABLE_LOADING_CHANGE';

export const TRAFFIC_DELETE		  = 'TRAFFIC_DELETE';
export const TRAFFIC_DELETE_CHANGE  	  = 'TRAFFIC_DELETE_CHANGE';

const state = {
    traffic            : null,
    traffic_loading    : false,
    traffic_saving     : false,
    traffic_errors     : {},

    traffic_list_total     : 0, 
    traffic_list           : null,
    traffic_list_loading   : false,

    traffic_creating           : false,
    traffic_creating_errors    : {},
    traffic_empty              : {
                                  id: 0,
                                  name: "",
                                  code: ""
                              }
};

const actions = {
    [TRAFFIC_LOAD] ({ commit, state }, id) {
        if(!id) {
            state.traffic_empty = {
                                  id: 0,
                                  name: "",
                                  code: ""
                              };
            commit(TRAFFIC_CHANGE, state.traffic_empty);

            return;
        }

        return api.load('traffic', id, state, commit, TRAFFIC_LOADING_CHANGE, TRAFFIC_CHANGE);
    },

    [TRAFFIC_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('traffic', id, model, state, commit, TRAFFIC_SAVING_CHANGE, TRAFFIC_CHANGE, TRAFFIC_ERRORS_CHANGE);
    },

    [TRAFFIC_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('traffic.list', id, model, state, commit, TRAFFIC_CREATING_CHANGE, TRAFFIC_CHANGE, TRAFFIC_ERRORS_CHANGE);
    },

    [TRAFFIC_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'traffic.list', 
            id, 
            null,
            state, 
            commit, 
            TRAFFIC_DATATABLE_LOADING_CHANGE, 
            TRAFFIC_DATATABLE_CHANGE);
    },
    [TRAFFIC_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'traffic',
            id,
            state,
            commit,
            TRAFFIC_DELETE_CHANGE
        );
    },

};

const mutations = {
    [TRAFFIC_DATATABLE_LOADING_CHANGE](state, data) {
        state.traffic_list_loading = data;
    },
    [TRAFFIC_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.traffic_list        = data.data;
            state.traffic_list_total  = data.total;
        }
        else {
            state.traffic_list        = [];
            state.traffic_list_total  = 0;
        }
    },
    [TRAFFIC_CHANGE](state, data) {
 	state.traffic  = data;
    },
    [TRAFFIC_LOADING_CHANGE](state, data) {
        state.traffic_loading = data;
    },
    [TRAFFIC_SAVING_CHANGE](state, data) {
        state.traffic_saving   = data;
    },
    [TRAFFIC_CREATING_CHANGE](state, data) {
	state.traffic_creating = data;
    },
    [TRAFFIC_ERRORS_CHANGE](state, data) {
        state.traffic_errors   = data;
    },
    [TRAFFIC_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.traffic_list) {

                let list = [];
                state.traffic_list.forEach(traffic => {
                    if (traffic.id !== id) {
                        list.push({...traffic});
                    }
                });

                state.traffic_list = list;
            }
        }
    },

};

export default {
    state,
    actions,
    mutations
}
