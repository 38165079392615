export const FILTER_LIST = 'FILTER_LIST';

export const SET_FILTER = 'SET_FILTER';
export const GET_FILTER = 'GET_FILTER';
export const DELETE_FILTER = 'DELETE_FILTER';

const SET_FILTER_CHANGE = 'SET_FILTER_CHANGE';
const GET_FILTER_CHANGE = 'GET_FILTER_CHANGE';

const state = {
    filter_list : [],
};

const actions = {
    [SET_FILTER] ({ commit, state }, data) {

        if(typeof(localStorage["filters"]) == "undefined"){  
            localStorage["filters"] = "{}";
        }
        let ls = JSON.parse(localStorage["filters"]);
        
        if(typeof(ls[data.model])== "undefined"){
            ls[data.model]=[];
        };

        ls[data.model].push(data.item);
            
        localStorage["filters"] = JSON.stringify(ls);

        commit(SET_FILTER_CHANGE, ls);

    },
    [GET_FILTER] ({ commit, state }, data) {

        if(typeof(localStorage["filters"]) == "undefined"){  
            localStorage["filters"] = "{}";
        }
        let result =JSON.parse(localStorage["filters"]);

        commit(GET_FILTER_CHANGE, result);
    },
    [DELETE_FILTER] ({ commit, state }, data) {

        let ls = JSON.parse(localStorage["filters"]);
        
        ls[data.model].splice(data.index, 1);

        localStorage["filters"] = JSON.stringify(ls);

        commit(SET_FILTER_CHANGE, ls);
    }
};

const mutations = {
    [SET_FILTER_CHANGE](state, data) {
        state.filter_list = data;
    },
    [GET_FILTER_CHANGE](state, data) {
        state.filter_list = data;
    }
};

export default {
    state,
    actions,
    mutations
}
