import api from './../../lib/api';

export const COMPETENCE_CAT_LOAD                 = 'COMPETENCE_CAT_LOAD';
export const COMPETENCE_CAT_UPDATE               = 'COMPETENCE_CAT_UPDATE';
export const COMPETENCE_CAT_CREATE               = 'COMPETENCE_CAT_CREATE';

export const COMPETENCE_CAT_LOAD_DATATABLE       = 'COMPETENCE_CAT_LOAD_DATATABLE';

const COMPETENCE_CAT_CHANGE                      = 'COMPETENCE_CAT_CHANGE';
const COMPETENCE_CAT_LOADING_CHANGE              = 'COMPETENCE_CAT_LOADING_CHANGE';
const COMPETENCE_CAT_SAVING_CHANGE               = 'COMPETENCE_CAT_SAVING_CHANGE';
const COMPETENCE_CAT_ERRORS_CHANGE               = 'COMPETENCE_CAT_ERRORS_CHANGE';
const COMPETENCE_CAT_CREATE_CHANGE               = 'COMPETENCE_CAT_CREATE_CHANGE';

const COMPETENCE_CAT_CREATING_CHANGE             = 'COMPETENCE_CAT_CREATING_CHANGE';

const COMPETENCE_CAT_DATATABLE_CHANGE            = 'COMPETENCE_CAT_DATATABLE_CHANGE';
const COMPETENCE_CAT_DATATABLE_LOADING_CHANGE    = 'COMPETENCE_CAT_DATATABLE_LOADING_CHANGE';

const COMPETENCE_CAT_LIST_CHANGE                 = 'COMPETENCE_CAT_LIST_CHANGE';
const COMPETENCE_CAT_LIST_LOADING_CHANGE         = 'COMPETENCE_CAT_LIST_LOADING_CHANGE';

const state = {
    competence_cat            : null,
    competence_cat_loading    : false,

    competence_cat_saving     : false,
    competence_cat_errors     : {},

    competence_cat_list_total    : 0,
    competence_cat_list           : null,
    competence_cat_list_loading   : false,

    competence_cat_creating           : false,
    competence_cat_creating_errors    : {},
    competence_cat_empty              : {
        id: 0,
        name: "",
        organization_id: "",
        is_work: true,
        organizations: {id:0}
    }
};

const actions = {
    [COMPETENCE_CAT_LOAD] ({ commit, state }, id) {
        if (!id) {
            commit(COMPETENCE_CAT_CHANGE, state.competence_cat_empty);
            return;
        }
        return api.load(
            'competence_cat',
            id,
            state,
            commit,
            COMPETENCE_CAT_LOADING_CHANGE,
            COMPETENCE_CAT_CHANGE
        )
    },

    [COMPETENCE_CAT_UPDATE] ({ commit, state }, {id, data}) {

        return api.update(
            'competence_cat',
            id,
            data,
            state,
            commit,
            COMPETENCE_CAT_SAVING_CHANGE,
            COMPETENCE_CAT_CHANGE,
            COMPETENCE_CAT_ERRORS_CHANGE
        );
    },

    [COMPETENCE_CAT_CREATE] ({ commit, state }, data) {
        let id = data.id ? data.id : 0;

        return api.create(
            'competence_cats',
            id,
            data,
            state,
            commit,
            COMPETENCE_CAT_SAVING_CHANGE,
            COMPETENCE_CAT_CREATE_CHANGE,
            COMPETENCE_CAT_ERRORS_CHANGE
        );
    },

    [COMPETENCE_CAT_LOAD_DATATABLE] ({ commit, state }, data) {
        let params  = data.params;
        let id  = data.id ? data.id : null;
        return api.list(
            'competence_cats',
            id,
            params,
            state,
            commit,
            COMPETENCE_CAT_DATATABLE_LOADING_CHANGE,
            COMPETENCE_CAT_DATATABLE_CHANGE
        );
    },
};

const mutations = {
    [COMPETENCE_CAT_DATATABLE_LOADING_CHANGE](state, data) {
        state.competence_cat_list_loading = data;
    },
    [COMPETENCE_CAT_DATATABLE_CHANGE](state, data) {

        if (data) {
            state.competence_cat_list          = data.data;
            state.competence_cat_list_total    = data.total;
        } else {
            state.competence_cat_list          = [];
            state.competence_cat_list_total    = 0;
        }

    },
    [COMPETENCE_CAT_CHANGE](state, data) {
        state.competence_cat = data;
    },
    [COMPETENCE_CAT_LOADING_CHANGE](state, data) {
        state.competence_cat_loading = data;
    },
    [COMPETENCE_CAT_SAVING_CHANGE](state, data) {
        state.competence_cat_saving   = data;
    },
    [COMPETENCE_CAT_ERRORS_CHANGE](state, data) {
        state.competence_cat_errors   = data;
    },
    [COMPETENCE_CAT_CREATING_CHANGE](state, data) {
        state.competence_cat_creating = data;
    },
    [COMPETENCE_CAT_CREATE_CHANGE](state, data) {
        state.competence_cat = data;
    },
    [COMPETENCE_CAT_LIST_CHANGE](state, data) {
        if(data){
            state.competence_cat_list = data.data ? data.data : data;
        }
    },
    [COMPETENCE_CAT_LIST_LOADING_CHANGE](state, data) {
        state.competence_cat_list_loading = data;
    },
};

export default {
    state,
    actions,
    mutations
}