import api from './../../lib/api';

export const GRADE_OPTION_LOAD                 = 'GRADE_OPTION_LOAD';
export const GRADE_OPTION_UPDATE               = 'GRADE_OPTION_UPDATE';
export const GRADE_OPTION_CREATE               = 'GRADE_OPTION_CREATE';

export const GRADE_OPTION_LOAD_DATATABLE       = 'GRADE_OPTION_LOAD_DATATABLE';

const GRADE_OPTION_CHANGE                      = 'GRADE_OPTION_CHANGE';
const GRADE_OPTION_LOADING_CHANGE              = 'GRADE_OPTION_LOADING_CHANGE';
const GRADE_OPTION_SAVING_CHANGE               = 'GRADE_OPTION_SAVING_CHANGE';
const GRADE_OPTION_ERRORS_CHANGE               = 'GRADE_OPTION_ERRORS_CHANGE';
const GRADE_OPTION_CREATE_CHANGE               = 'GRADE_OPTION_CREATE_CHANGE';

const GRADE_OPTION_DATATABLE_CHANGE            = 'GRADE_OPTION_DATATABLE_CHANGE';
const GRADE_OPTION_DATATABLE_LOADING_CHANGE    = 'GRADE_OPTION_DATATABLE_LOADING_CHANGE';

const state = {
  grade_option            : null,
  grade_option_loading    : false,

  grade_option_saving     : false,
  grade_option_errors     : {},

  grade_option_list_total    : 0,
  grade_option_list           : null,
  grade_option_list_loading   : false,

  grade_option_empty              : {
    id: 0,
    name: '',
    json_params: [null],
    competence_id: '',
    competence_group_id: '',
    type_calc: 'point_grade',
    is_dell: false,
  }
};

const actions = {
  [GRADE_OPTION_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(GRADE_OPTION_CHANGE, state.grade_option_empty);
      return;
    }
    return api.load(
      'grade_option',
      id,
      state,
      commit,
      GRADE_OPTION_LOADING_CHANGE,
      GRADE_OPTION_CHANGE
    );
  },

  [GRADE_OPTION_UPDATE] ({ commit, state }, {id, data}) {
    return api.update(
      'grade_option',
      id,
      data,
      state,
      commit,
      GRADE_OPTION_SAVING_CHANGE,
      GRADE_OPTION_CHANGE,
      GRADE_OPTION_ERRORS_CHANGE
    );
  },

  [GRADE_OPTION_CREATE] ({ commit, state }, data) {
    return api.create(
      'grade_options',
      0,
      data,
      state,
      commit,
      GRADE_OPTION_SAVING_CHANGE,
      GRADE_OPTION_CREATE_CHANGE,
      GRADE_OPTION_ERRORS_CHANGE
    );
  },

  [GRADE_OPTION_LOAD_DATATABLE] ({ commit, state }, data) {
    let params  = data.params;
    let id  = data.id ? data.id : null;
    return api.list(
      'grade_options',
      id,
      params,
      state,
      commit,
      GRADE_OPTION_DATATABLE_LOADING_CHANGE,
      GRADE_OPTION_DATATABLE_CHANGE
    );
  },
};

const mutations = {
  [GRADE_OPTION_DATATABLE_LOADING_CHANGE](state, data) {
    state.grade_option_list_loading = data;
  },
  [GRADE_OPTION_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.grade_option_list          = data.data;
      state.grade_option_list_total    = data.total;
    } else {
      state.grade_option_list          = [];
      state.grade_option_list_total    = 0;
    }
  },
  [GRADE_OPTION_CHANGE](state, data) {
    if (data && typeof data.json_params === 'string') {
      data.json_params = JSON.parse(data.json_params);
    }
    state.grade_option = data;
  },
  [GRADE_OPTION_LOADING_CHANGE](state, data) {
    state.grade_option_loading = data;
  },
  [GRADE_OPTION_SAVING_CHANGE](state, data) {
    state.grade_option_saving   = data;
  },
  [GRADE_OPTION_ERRORS_CHANGE](state, data) {
    state.grade_option_errors   = data;
  },
  [GRADE_OPTION_CREATE_CHANGE](state, data) {
    if (data && typeof data.json_params === 'string') {
      data.json_params = JSON.parse(data.json_params);
    }
    state.grade_option = data;
  },
};

export default {
  state,
  actions,
  mutations
}
