import http from './../../lib/http';
import api from './../../lib/api';

export const ATS_QUEUE_ACTION_LIST = 'ATS_QUEUE_ACTION_LIST';
export const ATS_QUEUE_ACTION_LOAD = 'ATS_QUEUE_ACTION_LOAD';
export const ATS_QUEUE_ACTION_CREATE = 'ATS_QUEUE_ACTION_CREATE';
export const ATS_QUEUE_ACTION_UPDATE = 'ATS_QUEUE_ACTION_UPDATE';
export const ATS_QUEUE_ACTION_DELETE = 'ATS_QUEUE_ACTION_DELETE';

const ATS_QUEUE_ACTION_LIST_LOADING_CHANGE = 'ATS_QUEUE_ACTION_LIST_LOADING_CHANGE';
const ATS_QUEUE_ACTION_LIST_CHANGE = 'ATS_QUEUE_ACTION_LIST_CHANGE';
const ATS_QUEUE_ACTION_LOADING_CHANGE = 'ATS_QUEUE_ACTION_LOADING_CHANGE';
const ATS_QUEUE_ACTION_CHANGE = 'ATS_QUEUE_ACTION_CHANGE';
const ATS_QUEUE_ACTION_CREATE_CHANGE = 'ATS_QUEUE_ACTION_CREATE_CHANGE';
const ATS_QUEUE_ACTION_UPDATE_CHANGE = 'ATS_QUEUE_ACTION_UPDATE_CHANGE';
const ATS_QUEUE_ACTION_DELETE_CHANGE = 'ATS_QUEUE_ACTION_DELETE_CHANGE';
const ATS_QUEUE_ACTION_ERRORS_CHANGE = 'ATS_QUEUE_ACTION_ERRORS_CHANGE';

const state = {
    ats_queue_action: null,
    ats_queue_action_loading: false,
    ats_queue_action_saving: false,
    ats_queue_action_creating: false,
    ats_queue_action_errors: {},
    
    ats_queue_action_list: [],
    ats_queue_action_list_total: 0,
    ats_queue_action_list_loading: false,
    
    ats_queue_action_empty: {
        id: 0,
        ats_queue_id: 0,
        event_type: "",
        event_param: "",
        action: "",
        action_value: 0,
        avr: "",
        avr_after: "",
    }
};

const actions = {
    [ATS_QUEUE_ACTION_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }
        
        return api.list(
            'ats_queue_actions.list',
            0,
            filter,
            state,
            commit,
            ATS_QUEUE_ACTION_LIST_LOADING_CHANGE,
            ATS_QUEUE_ACTION_LIST_CHANGE
        );
    },
    [ATS_QUEUE_ACTION_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(ATS_QUEUE_ACTION_CHANGE, state.ats_queue_action_empty);
            return;
        }
        
        return api.load(
            'ats_queue_actions',
            id,
            state,
            commit,
            ATS_QUEUE_ACTION_LOADING_CHANGE,
            ATS_QUEUE_ACTION_CHANGE
        );
    },
    [ATS_QUEUE_ACTION_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'ats_queue_actions.list',
            id,
            model,
            state,
            commit,
            ATS_QUEUE_ACTION_CREATE_CHANGE,
            ATS_QUEUE_ACTION_CHANGE,
            ATS_QUEUE_ACTION_ERRORS_CHANGE);
    },
    [ATS_QUEUE_ACTION_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.create(
            'ats_queue_actions',
            id,
            model,
            state,
            commit,
            ATS_QUEUE_ACTION_UPDATE_CHANGE,
            ATS_QUEUE_ACTION_CHANGE,
            ATS_QUEUE_ACTION_ERRORS_CHANGE);
    },
    [ATS_QUEUE_ACTION_DELETE] ({commit, state}, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'ats_queue_actions', 
            id, 
            state, 
            commit, 
            ATS_QUEUE_ACTION_DELETE_CHANGE
        );
    }
};

const mutations = {
    [ATS_QUEUE_ACTION_LIST_LOADING_CHANGE] (state, data) {
        state.ats_queue_action_list_loading = data;
    },
    [ATS_QUEUE_ACTION_LIST_CHANGE] (state, data) {
        if(data) {
            state.ats_queue_action_list        = data.data;
            state.ats_queue_action_list_total  = data.total;
        }
        else {
            state.ats_queue_action_list        = [];
            state.ats_queue_action_list_total  = 0;
        }
    },
    [ATS_QUEUE_ACTION_LOADING_CHANGE] (state, data) {
        state.ats_queue_action_loading = data;
    },
    [ATS_QUEUE_ACTION_CHANGE] (state, data) {
        state.ats_queue_action_errors = {};
        if(data && typeof data === 'object'){
            state.ats_queue_action = data;
            state.ats_queue_action_empty = {
                id: 0,
                ats_queue_id: 0,
                event_type: "",
                event_param: "",
                action: "",
                action_value: 0,
                avr: "",
                avr_after: "",
            }
        }
    },
    [ATS_QUEUE_ACTION_CREATE_CHANGE] (state, data) {
        state.ats_queue_action_creating = data;
    },
    [ATS_QUEUE_ACTION_UPDATE_CHANGE] (state, data) {
        state.ats_queue_action_saving = data;
    },
    [ATS_QUEUE_ACTION_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.ats_queue_action_list) {
                let list = [];
                state.ats_queue_action_list.forEach(prov => {
                    if (prov.id !== id) {
                        list.push({...prov});
                    }
                });

                state.ats_queue_action_list = list;
            }
        }
    },
    [ATS_QUEUE_ACTION_ERRORS_CHANGE] (state, data) {
        state.ats_queue_action_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}