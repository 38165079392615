// import http from "./../../lib/http";
import api from "./../../lib/api";

export const COPY_LOAD = "COPY_LOAD";
export const COPY_UPDATE = "COPY_UPDATE";
export const COPY_CREATE = "COPY_CREATE";
export const COPY_NEW_CHANGE = "COPY_NEW_CHANGE";
export const COPY_INFO_LOAD = "COPY_INFO_LOAD";

export const COPY_LOAD_DATATABLE = "COPY_LOAD_DATATABLE";

export const COPY_CHANGE = "COPY_CHANGE";
const COPY_LOADING_CHANGE = "COPY_LOADING_CHANGE";
const COPY_SAVING_CHANGE = "COPY_SAVING_CHANGE";
const COPY_ERRORS_CHANGE = "COPY_ERRORS_CHANGE";

const COPY_CREATING_CHANGE = "COPY_CREATING_CHANGE";

const COPY_DATATABLE_CHANGE = "COPY_DATATABLE_CHANGE";
const COPY_DATATABLE_LOADING_CHANGE = "COPY_DATATABLE_LOADING_CHANGE";
const COPY_INFO_LOADING_CHANGE = "COPY_INFO_LOADING_CHANGE";
const COPY_INFO_CHANGE = "COPY_INFO_CHANGE";

const state = {
  copy: {
    id: 0,
    name: null,
    comment: null,
    config: null,
    filter_comment:null,
    type:null,
    is_work: true,
    is_copy_user_form: false,
    organization_id: null,
    project_id: null,
    script_id: null,
    is_segment: false,
    hp_base_id: null,
    bpm_db:null,
    bpm_bp:null
  },
  copy_loading: false,
  copy_info_loading: false,
  copy_info: null,
  copy_saving: false,
  copy_errors: {},

  copy_list_total: 0,
  copy_list: null,
  copy_list_loading: false,

  copy_creating: false,
  copy_creating_errors: {}
};

const actions = {
  [COPY_LOAD]({ commit, state }, id) {
    if (!id) {
      commit(COPY_CHANGE, null);
      return;
    }

    return api.load(
      "copy",
      id,
      state,
      commit,
      COPY_LOADING_CHANGE,
      COPY_CHANGE
    );
  },

  [COPY_INFO_LOAD]({ commit, state }, id) {
    commit(COPY_INFO_CHANGE, null);
    return api.load(
      "copy.info",
      id,
      state,
      commit,
      COPY_INFO_LOADING_CHANGE,
      COPY_INFO_CHANGE
    );
  },

  [COPY_UPDATE]({ commit, state }, data) {
    let id = data.id;
    let model = data.model;
    
    return api.update(
      "copy",
      id,
      model,
      state,
      commit,
      COPY_SAVING_CHANGE,
      COPY_CHANGE,
      COPY_ERRORS_CHANGE
    );
  },

  [COPY_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "copy.list",
      id,
      model,
      state,
      commit,
      COPY_CREATING_CHANGE,
      COPY_ERRORS_CHANGE
    );
  },

  [COPY_LOAD_DATATABLE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "copy.list",
      id,
      null,
      state,
      commit,
      COPY_DATATABLE_LOADING_CHANGE,
      COPY_DATATABLE_CHANGE
    );
  }
};

const mutations = {
  [COPY_DATATABLE_LOADING_CHANGE](state, data) {
    state.copy_list_loading = data;
  },
  [COPY_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.copy_list = data.data;
      state.copy_list_total = data.total;
    } else {
      state.copy_list = [];
      state.copy_list_total = 0;
    }
  },
  [COPY_NEW_CHANGE](state) {
    state.copy = {
      id: 0,
      name: null,
      comment: null,
      config: null,
      filter_comment:null,
      type: "API",
      is_work: false,
      is_copy_user_form: false,
      organization_id: 1,
      project_id: null,
      script_id: null,
      hp_base_id: null,
      bpm_db:null,
      bpm_bp:null
    };
  },
  [COPY_CHANGE](state, data) {
    if (data && data.data && data.data[0]) state.copy = data.data[0];
  },
  [COPY_INFO_CHANGE](state, data) {
    if(data === null) state.copy_info = null;
    if (data && data.data) state.copy_info = data.data;
  },
  [COPY_LOADING_CHANGE](state, data) {
    state.copy_loading = data;
  },
  [COPY_INFO_LOADING_CHANGE](state, data) {
    state.copy_info_loading = data;
  },
  [COPY_SAVING_CHANGE](state, data) {
    state.copy_saving = data;
  },
  [COPY_ERRORS_CHANGE](state, data) {
    state.copy_errors = data;
  },
  [COPY_CREATING_CHANGE](state, data) {
    state.copy_creating = data;
  }
};

export default {
  state,
  actions,
  mutations
};
