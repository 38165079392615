import http from "./../../lib/http";
import api from "./../../lib/api";

export const USER_LOAD = "USER_LOAD";
export const USER_SAVE = "USER_SAVE";
export const USER_AUTH = "USER_AUTH";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_CHANGE = "USER_CHANGE";
const USER_LOADING_CHANGE = "USER_LOADING_CHANGE";
const USER_ERRORS_CHANGE = "USER_ERRORS_CHANGE";
const USER_SAVING_CHANGE = "USER_SAVING_CHANGE";

export const USER_PASSWORD_UPDATE = "USER_PASSWORD_UPDATE";
const USER_PASSWORD_ERROR_CHANGE  = "USER_PASSWORD_ERROR_CHANGE";
const USER_PASSWORD_SAVING_CHANGE = "USER_PASSWORD_SAVING_CHANGE";
const USER_PASSWORD_CHANGE        = "USER_PASSWORD_CHANGE";

export const USER_LOAD_LIST = "USERS_LOAD_LIST";
const USER_LIST_CHANGE = "USER_LIST_CHANGE";
const USER_LIST_LOADING_CHANGE = "USER_LIST_LOADING_CHANGE";

export const USER_LOAD_SEARCH_LIST = "USER_LOAD_SEARCH_LIST";
const USER_SEARCH_LIST_CHANGE = "USER_SEARCH_LIST_CHANGE";
const USER_SEARCH_LIST_LOADING_CHANGE = "USER_SEARCH_LIST_LOADING_CHANGE";

export const AUTH_TOKEN_CHANGE = "AUTH_TOKEN_CHANGE";
const AUTH_TOKEN_LOADING = "AUTH_TOKEN_LOADING";
const AUTH_TOKEN_ERRORS = "AUTH_TOKEN_ERRORS";
export const AUTH_USER_CHANGE = "AUTH_USER_CHANGE";
const AUTH_USER_LOADING_CHANGE = "AUTH_USER_LOADING_CHANGE";
const AUTH_USER_ERRORS_CHANGE = "AUTH_USER_ERRORS_CHANGE";

export const USER_QUERY_CHANGE = "QUERY_CHANGE";
export const USER_SET_NOTIFY_STATUS = "USER_SET_NOTIFY_STATUS";

export const USER_NOTIFY_CHANGE = "USER_NOTIFY_CHANGE";

export const USER_SET_SHOW_IS_WORK = "USER_SET_SHOW_IS_WORK";
const USER_SET_SHOW_IS_WORK_CHANGE = "USER_SET_SHOW_IS_WORK_CHANGE";

export const USER_SET_TOKEN = "USER_SET_TOKEN";
export const USER_NEED_NEW_PASSWORD_CHANGE = "USER_NEED_NEW_PASSWORD_CHANGE";
export const USER_SET_OFFER_ACCEPTED = "USER_SET_OFFER_ACCEPTED";


const state = {
  user: null,
  user_loading: false,
  user_saving: false,
  user_errors: {},

  user_list: null,
  user_list_loading: null,
  user_list_total: null,

  user_search_list: [],
  user_search_list_total: null,

  auth_token: null,
  auth_token_loading: null,
  auth_user: null,
  auth_user_loading: false,
  auth_user_errors: false,
  query: "",

  is_need_notify: false,

  user_password: null,
  user_password_saving: false,
  user_password_errors: {}
};

const actions = {
  [USER_LOAD_SEARCH_LIST]({ commit, state }, data) {
    let params = data.params;
    let id = data.id;
    let request = api.list(
      "user.search",
      id,
      params,
      state,
      commit,
      USER_SEARCH_LIST_LOADING_CHANGE,
      USER_SEARCH_LIST_CHANGE
    );

    return request;
  },
  [USER_LOAD_LIST]({ commit, state }, data) {
    let params = data.params;
    let id = data.id;
    let request = api.list(
      "user.list",
      id,
      params,
      state,
      commit,
      USER_LIST_LOADING_CHANGE,
      USER_LIST_CHANGE
    );

    return request;
  },
  [USER_LOAD]({ commit, state }, id) {
    if (id == 0) {
      commit(USER_CHANGE, { id: 0 });
      commit(USER_LOADING_CHANGE, false);
      return;
    }
    commit(USER_CHANGE, null);
    commit(USER_LOADING_CHANGE, true);

    /*         let url     = '/api/v2/users/' + id;

        return http.get(url).then(response => {
            commit(USER_LOADING_CHANGE, false);

            if(response && response.data) {
                commit(USER_CHANGE, response.data);
            }
        });
 */
    return api.load(
      "user",
      id,
      state,
      commit,
      USER_LOADING_CHANGE,
      USER_CHANGE
    );
  },
  [USER_SAVE]({ commit, state }, data) {
    let id = state.user.id;
    let model = data;

    return api.update(
      "user",
      id,
      model,
      state,
      commit,
      USER_SAVING_CHANGE,
      USER_CHANGE,
      USER_ERRORS_CHANGE
    );
  },
  [USER_SET_SHOW_IS_WORK]({ commit, state }, data) {
    let id = data.id;
    let model = data.params;

    return api.update(
      "set_show_is_work",
      id,
      model,
      state,
      commit,
      USER_SAVING_CHANGE,
      USER_SET_SHOW_IS_WORK_CHANGE,
      USER_ERRORS_CHANGE
    );
  },
  [USER_AUTH]({ commit, state }, data) {
    let url = api.main_url + "/api/v2/users/auth";

    commit(AUTH_TOKEN_LOADING, true);
    commit(AUTH_USER_ERRORS_CHANGE, false);

    let promise = http
      .post(url, data)
      .then(response => {
        commit(AUTH_TOKEN_LOADING, false);
        if (response && response.data) {
          return response.data.token;
        }
      })
      .catch(error => {
        commit(AUTH_USER_ERRORS_CHANGE, true);

        commit(AUTH_TOKEN_LOADING, false);

        return false;
      })
      .then(token => {
        if (token) {
          commit(AUTH_TOKEN_CHANGE, token);
          commit(AUTH_USER_LOADING_CHANGE, true);

          return http.get(api.main_url + "/api/v2/users/me");
        }

        return false;
      })
      .then(response => {
        commit(AUTH_USER_LOADING_CHANGE, true);

        if (response && response.data) {
          commit(AUTH_USER_CHANGE, response.data);
        }
      });
  },
  [USER_SET_TOKEN]({ commit, state }, token) {
    commit(AUTH_TOKEN_LOADING, true);
    commit(AUTH_USER_ERRORS_CHANGE, false);

    commit(AUTH_TOKEN_CHANGE, token);
    commit(AUTH_USER_LOADING_CHANGE, true);
    http.get(api.main_url + "/api/v2/users/me").then(response => {
        commit(AUTH_USER_LOADING_CHANGE, true);

        if (response && response.data) {
          commit(AUTH_USER_CHANGE, response.data);
        }
      })
  },
  [USER_LOGOUT]({ commit, state }, data) {
    commit(AUTH_TOKEN_CHANGE, null);
    commit(AUTH_USER_CHANGE, null);
  },
  [USER_PASSWORD_UPDATE] ({commit, state}, data) {
    let id = data.id;
    let model = data.model;
    return api.update(
        'user_password',
        id,
        model,
        state,
        commit,
        USER_PASSWORD_SAVING_CHANGE,
        USER_PASSWORD_CHANGE,
        USER_PASSWORD_ERROR_CHANGE,
    );
  },
};

const mutations = {
  [USER_SEARCH_LIST_LOADING_CHANGE](state, data) {
    state.user_search_list_loading = data;
  },
  [USER_SEARCH_LIST_CHANGE](state, data) {
    if (data) {
      state.user_search_list = data.data;
      state.user_search_list_total = data.total;
    } else {
      state.user_search_list = [];
      state.user_search_list_total = 0;
    }
  },
  [USER_LIST_LOADING_CHANGE](state, data) {
    state.user_list_loading = data;
  },
  [USER_LIST_CHANGE](state, data) {
    if (data) {
      state.user_list = data.data;
      state.user_list_total = data.total;
    } else {
      state.user_list = [];
      state.user_list_total = 0;
    }
  },
  [USER_CHANGE](state, data) {    
    if (data != null) {          
      if(data.id==0){
          data.mainlink='/',
          data.is_work=true
      };
      state.user = data.data;
      if (data.data === undefined) state.user = data;
    }
  },
  [USER_LOADING_CHANGE](state, data) {
    state.user_loading = data;
  },
  [USER_SAVING_CHANGE](state, data) {
    state.user_saving = data;
  },
  [USER_ERRORS_CHANGE](state, data) {
    state.user_errors = data;
  },
  [AUTH_USER_CHANGE](state, data) {
    state.auth_user = data;

    if (data) {
      if (data.role[0]) {
        state.is_need_notify = data.role[0].is_need_display_notify;
      } else {
        state.auth_user_errors = true;
        state.auth_user = null;
        http.setToken(null);
        state.auth_token = null;
      }
    }
  },
  [AUTH_USER_LOADING_CHANGE](state, data) {
    state.auth_user_loading = data;
  },
  [AUTH_USER_ERRORS_CHANGE](state, data) {
    state.auth_user_errors = data;
  },
  [AUTH_TOKEN_CHANGE](state, data) {
    http.setToken(data);

    state.auth_token = data;
  },
  [AUTH_TOKEN_LOADING](state, data) {
    state.auth_token_loading = data;
  },
  [USER_QUERY_CHANGE](state, data) {
    state.query = data;
  },
  [USER_NOTIFY_CHANGE](state, data) {
    state.is_need_notify = data;
    if (state.auth_user.role[0].is_need_display_notify)
      state.is_need_notify = true;
  },
  [USER_SET_SHOW_IS_WORK_CHANGE](state, data) {
    if (data) {
      state.auth_user.is_show_work = data.data.is_show_work;
      localStorage.setItem("users.auth_user", JSON.stringify(state.auth_user));
    }
  },
  //the data must be boolean type
  [USER_SET_OFFER_ACCEPTED] (state,data){
    state.auth_user.offer_accepted = data;
    localStorage.setItem("users.auth_user", JSON.stringify(state.auth_user));
  },

  [USER_PASSWORD_ERROR_CHANGE] (state, data) {
    state.user_password_errors = data
  },
  [USER_PASSWORD_SAVING_CHANGE] (state, data) {
    state.user_password_saving = data
  },
  [USER_PASSWORD_CHANGE] (state, data) {
    state.user_password = data
  },
  [USER_NEED_NEW_PASSWORD_CHANGE] (state, data) {
    state.auth_user.is_need_new_password = data;
    localStorage.setItem("users.auth_user", JSON.stringify(state.auth_user));
  },
};

export default {
  state,
  actions,
  mutations
};
