import api from './../../lib/api';

export const STOCK_LOAD         = "STOCK_LOAD";
export const STOCK_UPDATE       = "STOCK_UPDATE";
export const STOCK_CREATE       = "STOCK_CREATE";
export const STOCK_DELETE       = "STOCK_DELETE";
export const STOCK_LIST_LOAD    = "STOCK_LIST_LOAD";

const STOCK_CHANGE              = "STOCK_CHANGE";
const STOCK_LOADING_CHANGE      = "STOCK_LOADING_CHANGE";
const STOCK_SAVING_CHANGE       = "STOCK_SAVING_CHANGE";
const STOCK_ERRORS_CHANGE       = "STOCK_ERRORS_CHANGE";
const STOCK_DELETE_CHANGE       = "STOCK_DELETE_CHANGE";
const STOCK_CREATE_CHANGE       = "STOCK_CREATE_CHANGE";
const STOCK_LIST_CHANGE         = "STOCK_LIST_CHANGE";
const STOCK_LIST_LOADING_CHANGE = "STOCK_LIST_LOADING_CHANGE";

const state = {
    stock:                 null,

    stock_loading:         false,
    stock_saving:          false,

    stock_list:            [],
    stock_list_loading:    false,

    stock_errors:          {},

    stock_empty:           {
        id: 0,
        name: "",
        comment: "",
        is_work: true,
        stock_types: {id:null},
        organizations: {id:0}
    },
};

const actions = {
    [STOCK_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'wholesale_stocks',
            id,
            params,
            state,
            commit,
            STOCK_LIST_LOADING_CHANGE,
            STOCK_LIST_CHANGE,
        );
    },

    [STOCK_LOAD] ({ state, commit }, id) {
        if (!id) {
            commit(STOCK_CHANGE, state.stock_empty);
            return;
        }
        return api.load(
            'wholesale_stock',
            id,
            state,
            commit,
            STOCK_LOADING_CHANGE,
            STOCK_CHANGE
        )
    },

    [STOCK_UPDATE] ({ state, commit }, { id, data }) {

        return api.update(
            'wholesale_stock',
            id,
            data,
            state,
            commit,
            STOCK_SAVING_CHANGE,
            STOCK_CHANGE,
            STOCK_ERRORS_CHANGE
        )
    },

    [STOCK_CREATE] ({ state, commit }, data) {

        return api.create(
            'wholesale_stocks',
            0,
            data,
            state,
            commit,
            STOCK_SAVING_CHANGE,
            STOCK_CREATE_CHANGE,
            STOCK_ERRORS_CHANGE
        )
    },

    [STOCK_DELETE] ({ state, commit }, id) {

        return api.delete(
            'wholesale_stock',
            id,
            state,
            commit,
            STOCK_DELETE_CHANGE,
            STOCK_CHANGE,
            STOCK_ERRORS_CHANGE
        )
    },
};

const mutations = {
    [STOCK_CHANGE](state, data) {
        state.stock = data
    },
    [STOCK_LOADING_CHANGE](state, data) {
        state.stock_loading = data
    },
    [STOCK_SAVING_CHANGE](state, data) {
        state.stock_saving = data
    },
    [STOCK_ERRORS_CHANGE](state, data) {
        state.stock_errors = data
    },
    [STOCK_CREATE_CHANGE](state, data) {
        state.stock_creating = data
    },
    [STOCK_DELETE_CHANGE](state, data) {
        // eslint-disable-next-line no-console
        console.log('QQ',data)
    },
    [STOCK_LIST_CHANGE](state, data) {
        if(data){
            state.stock_list = data.data ? data.data : data;
        }
    },
    [STOCK_LIST_LOADING_CHANGE](state, data) {
        state.stock_list_loading = data;
    }
};

export default {
    state,
    actions,
    mutations
}
