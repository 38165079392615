import api from './../../lib/api';

export const COMPETENCE_LOAD                 = 'COMPETENCE_LOAD';
export const COMPETENCE_UPDATE               = 'COMPETENCE_UPDATE';
export const COMPETENCE_CREATE               = 'COMPETENCE_CREATE';

export const COMPETENCE_LOAD_DATATABLE       = 'COMPETENCE_LOAD_DATATABLE';
export const COMPETENCE_LOAD_BY_USER         = 'COMPETENCE_LOAD_BY_USER';

const COMPETENCE_CHANGE                      = 'COMPETENCE_CHANGE';
const COMPETENCE_LOADING_CHANGE              = 'COMPETENCE_LOADING_CHANGE';
const COMPETENCE_SAVING_CHANGE               = 'COMPETENCE_SAVING_CHANGE';
const COMPETENCE_ERRORS_CHANGE               = 'COMPETENCE_ERRORS_CHANGE';
const COMPETENCE_CREATE_CHANGE               = 'COMPETENCE_CREATE_CHANGE';

const COMPETENCE_DATATABLE_CHANGE            = 'COMPETENCE_DATATABLE_CHANGE';
const COMPETENCE_DATATABLE_LOADING_CHANGE    = 'COMPETENCE_DATATABLE_LOADING_CHANGE';

const COMPETENCE_LIST_CHANGE                 = 'COMPETENCE_LIST_CHANGE';
const COMPETENCE_LIST_LOADING_CHANGE         = 'COMPETENCE_LIST_LOADING_CHANGE';

const COMPETENCES_BY_USER_CHANGE             = 'COMPETENCES_BY_USER_CHANGE';

const state = {
    competence            : null,
    competence_loading    : false,

    competence_saving     : false,
    competence_errors     : {},

    competence_list_total    : 0,
    competence_list           : null,
    competence_by_user        : [],
    competence_list_loading   : false,

    competence_creating           : {},
    competence_creating_errors    : {},
    competence_empty              : {
        id: 0,
        name: '',
        organization_id: null,
        is_work: true,
        is_random: false,
        competence_cat_id: '',
        competence_group_id: '',
        type_calc: 'count_type_question',
        description: '',
        instruction: '',
        img: '',
        sort: 'level',
        time: 0,
        json_options: {
            recom_time: 0,
            sort_answer: false,
            is_previous: false,
            is_next: false,
        },
        competence_options: [
            {
                level_type: 2,
                question_count: 20,
                level_start: 1,
                level_stop: 3,
            },
        ],
    }
};

const actions = {
    [COMPETENCE_LOAD] ({ commit, state }, id) {
        if (!id) {
            commit(COMPETENCE_CHANGE, _.cloneDeep(state.competence_empty));
            return;
        }
        return api.load(
            'competence',
            id,
            state,
            commit,
            COMPETENCE_LOADING_CHANGE,
            COMPETENCE_CHANGE
        )
    },

    [COMPETENCE_UPDATE] ({ commit, state }, {id, data}) {

        return api.update(
            'competence',
            id,
            data,
            state,
            commit,
            COMPETENCE_SAVING_CHANGE,
            COMPETENCE_CHANGE,
            COMPETENCE_ERRORS_CHANGE
        );
    },

    [COMPETENCE_CREATE] ({ commit, state }, data) {
        let id = data.id ? data.id : 0;

        return api.create(
            'competences',
            id,
            data,
            state,
            commit,
            COMPETENCE_SAVING_CHANGE,
            COMPETENCE_CREATE_CHANGE,
            COMPETENCE_ERRORS_CHANGE
        );
    },

    [COMPETENCE_LOAD_DATATABLE] ({ commit, state }, data) {
        let params  = data.params;
        let id  = data.id ? data.id : null;
        return api.list(
            'competences',
            id,
            params,
            state,
            commit,
            COMPETENCE_DATATABLE_LOADING_CHANGE,
            COMPETENCE_DATATABLE_CHANGE
        );
    },
    [COMPETENCE_LOAD_BY_USER] ({ commit, state }, data) {
        let params  = data.params;
        let id  = data.id ? data.id : 0;
        return api.load(
            'competences.by_user',
            id,
            state,
            commit,
            COMPETENCE_LOADING_CHANGE,
            COMPETENCES_BY_USER_CHANGE
        );
    },
};

const mutations = {
    [COMPETENCE_DATATABLE_LOADING_CHANGE](state, data) {
        state.competence_list_loading = data;
    },
    [COMPETENCE_DATATABLE_CHANGE](state, data) {

        if (data) {
            state.competence_list          = data.data;
            state.competence_list_total    = data.total;
        } else {
            state.competence_list          = [];
            state.competence_list_total    = 0;
        }

    },
    [COMPETENCE_CHANGE](state, data) {
        state.competence = data;
    },
    [COMPETENCE_LOADING_CHANGE](state, data) {
        state.competence_loading = data;
    },
    [COMPETENCE_SAVING_CHANGE](state, data) {
        state.competence_saving   = data;
    },
    [COMPETENCE_ERRORS_CHANGE](state, data) {
        state.competence_errors   = data;
    },
    [COMPETENCE_CREATE_CHANGE](state, data) {
        state.competence_creating = data;
    },
    [COMPETENCE_LIST_CHANGE](state, data) {
        if(data){
            state.competence_list = data.data ? data.data : data;
        }
    },
    [COMPETENCES_BY_USER_CHANGE](state, data) {
        if (data) {
            state.competence_by_user = data.data ? data.data : data;
        } else {
            state.competence_by_user = []
        }
    },
    [COMPETENCE_LIST_LOADING_CHANGE](state, data) {
        state.competence_list_loading = data;
    },
};

export default {
    state,
    actions,
    mutations
}
