import http from './../../lib/http';
import api from './../../lib/api';

export const CALLER_ID_UPDATE = 'CALLER_ID_UPDATE';
export const CALLER_ID_CREATE = 'CALLER_ID_CREATE';
export const CALLER_ID_REBIND = 'CALLER_ID_REBIND';
export const CALLER_ID_GET_OPERATORS = 'CALLER_ID_GET_OPERATORS';
export const CALLER_ID_GET_FOR_IN = 'CALLER_ID_GET_FOR_IN';
export const CALLER_ID_GET_BY_VALUE = 'CALLER_ID_GET_BY_VALUE';
export const CALLER_ID_GET_FOR_ATS_QUEUE_GROUP = 'CALLER_ID_GET_FOR_ATS_QUEUE_GROUP';

const CALLER_ID_UPDATE_CHANGE = 'CALLER_ID_UPDATE_CHANGE';
const CALLER_ID_REBIND_CHANGE = 'CALLER_ID_REBIND_CHANGE';
const CALLER_ID_CHANGE = 'CALLER_ID_CHANGE';
const CALLER_ID_ERRORS_CHANGE = 'CALLER_ID_ERRORS_CHANGE';
const CALLER_ID_CREATE_CHANGE = 'CALLER_ID_CREATE_CHANGE';
const CALLER_ID_GET_OPERATORS_LOADING_CHANGE = 'CALLER_ID_GET_OPERATORS_LOADING_CHANGE';
const CALLER_ID_GET_OPERATORS_CHANGE = 'CALLER_ID_GET_OPERATORS_CHANGE';
const CALLER_ID_GET_FOR_IN_LOADING_CHANGE = 'CALLER_ID_GET_FOR_IN_LOADING_CHANGE';
const CALLER_ID_GET_FOR_IN_CHANGE = 'CALLER_ID_GET_FOR_IN_CHANGE';
const CALLER_ID_LOADING_GET_BY_VALUE_CHANGE = 'CALLER_ID_LOADING_GET_BY_VALUE_CHANGE';
const CALLER_ID_GET_BY_VALUE_CHANGE = 'CALLER_ID_GET_BY_VALUE_CHANGE';

const state = {
    caller_id: null,
    caller_id_loading: false,
    caller_id_saving: false,
    caller_id_creating: false,
    caller_id_errors: {},

    operators_loading: false,
    caller_id_get_by_value_loading: false,
    operators_list: [],
    operators_list_total: 0,

    caller_id_for_in_loading: false,
    caller_id_for_in_list: [],
    caller_id_for_in_list_total: 0,

    caller_id_list: [],
    caller_id_list_total: 0,
    caller_id_list_loading: false,
    caller_id_get_by_value: {},

    caller_id_empty: {
        id: 0,
        caller_id: "",
        sip_id: null,
    }
};

const actions = {
    [CALLER_ID_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'caller_ids',
            id,
            model,
            state,
            commit,
            CALLER_ID_UPDATE_CHANGE,
            CALLER_ID_CHANGE,
            CALLER_ID_ERRORS_CHANGE);
    },
    [CALLER_ID_REBIND]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'caller_ids.rebind',
            id,
            model,
            state,
            commit,
            CALLER_ID_REBIND_CHANGE,
            CALLER_ID_CHANGE,
            CALLER_ID_ERRORS_CHANGE);
    },
    [CALLER_ID_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'caller_ids.list',
            id,
            model,
            state,
            commit,
            CALLER_ID_CREATE_CHANGE,
            CALLER_ID_CHANGE,
            CALLER_ID_ERRORS_CHANGE);
    },
    [CALLER_ID_GET_OPERATORS] ({commit, state}, id, filter) {
        if (!filter) {
            filter = null
        }

        return api.list(
            'caller_ids.get_operators',
            id,
            filter,
            state,
            commit,
            CALLER_ID_GET_OPERATORS_LOADING_CHANGE,
            CALLER_ID_GET_OPERATORS_CHANGE
        );
    },
    [CALLER_ID_GET_FOR_IN] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }

        return api.list(
            'caller_ids.get_for_in',
            0,
            filter,
            state,
            commit,
            CALLER_ID_GET_FOR_IN_LOADING_CHANGE,
            CALLER_ID_GET_FOR_IN_CHANGE
        );
    },
    [CALLER_ID_GET_FOR_ATS_QUEUE_GROUP] ({commit, state}, id) {
        if(id == 0){
            return;
        }
        return api.list(
            'caller_ids.get_for_ats_queue_group',
            id,
            null,
            state,
            commit,
            CALLER_ID_GET_OPERATORS_LOADING_CHANGE,
            CALLER_ID_GET_OPERATORS_CHANGE
        );
    },
    [CALLER_ID_GET_BY_VALUE]({ commit, state }, caller_id) {
        if (!caller_id) {
            return;
        }
        return api.load(
            "caller_ids.get_by_value",
            caller_id,
            state,
            commit,
            CALLER_ID_LOADING_GET_BY_VALUE_CHANGE,
            CALLER_ID_GET_BY_VALUE_CHANGE
        );
    },
};

const mutations = {
    [CALLER_ID_CHANGE] (state, data) {
        state.caller_id_errors = {};
        if(data && typeof data === 'object'){
            state.caller_id_errors = {};
            state.caller_id = data;
            state.caller_id_empty = {
                id: 0,
                caller_id: "",
                sip_id: null,
            }
        }
    },
    [CALLER_ID_CREATE_CHANGE] (state, data) {
        state.caller_id_creating = data;
    },
    [CALLER_ID_UPDATE_CHANGE] (state, data) {
        state.caller_id_saving = data;
    },
    [CALLER_ID_REBIND_CHANGE] (state, data) {
        state.caller_id_saving = data;
    },
    [CALLER_ID_ERRORS_CHANGE] (state, data) {
        state.caller_id_errors = data;
    },
    [CALLER_ID_GET_OPERATORS_LOADING_CHANGE] (state, data) {
        state.operators_loading = data;
    },
    [CALLER_ID_GET_FOR_IN_LOADING_CHANGE] (state, data) {
        state.caller_id_for_in_loading = data;
    },
    [CALLER_ID_LOADING_GET_BY_VALUE_CHANGE] (state, data) {
        state.caller_id_get_by_value_loading = data;
    },
    [CALLER_ID_GET_OPERATORS_CHANGE] (state, data) {
        if(data) {
            state.operators_list        = data.data;
            state.operators_list_total  = data.total;
        }
        else {
            state.operators_list        = [];
            state.operators_list_total  = 0;
        }
    },
    [CALLER_ID_GET_FOR_IN_CHANGE] (state, data) {
        if(data) {
            state.caller_id_for_in_list        = data.data;
            state.caller_id_for_in_list_total  = data.total;
        }
        else {
            state.caller_id_for_in_list        = [];
            state.caller_id_for_in_list_total  = 0;
        }
    },
    [CALLER_ID_GET_BY_VALUE_CHANGE] (state, data) {
        if(data) {
            state.caller_id_get_by_value        = data;
        }
        else {
            state.caller_id_get_by_value        = [];
        }
    },
};

export default {
    state,
    actions,
    mutations
}
