import http from "./../../lib/http";
import api from "./../../lib/api";
import VueRouter from "./../../routes.js";

export const PROFILE_LOAD = "PROFILE_LOAD";
export const PROFILE_UPDATE = "PROFILE_SAVE";
export const PROFILE_OPERATOR_TIME_LOAD = "PROFILE_OPERATOR_TIME_LOAD";
export const PROFILE_INTEGRATIONS = "PROFILE_INTEGRATIONS";
export const PROFILE_CUSTOM_INTEGRATIONS = "PROFILE_CUSTOM_INTEGRATIONS";
export const PROFILE_CUSTOM_INTEGRATIONS_CREATE = "PROFILE_CUSTOM_INTEGRATIONS_CREATE";
export const PROFILE_CUSTOM_INTEGRATIONS_UPDATE = "PROFILE_CUSTOM_INTEGRATIONS_UPDATE";
export const PROFILE_CUSTOM_INTEGRATION_DELETE = "PROFILE_CUSTOM_INTEGRATION_DELETE";

const PROFILE_CHANGE              = "PROFILE_CHANGE";
const PROFILE_LOADING_CHANGE      = "PROFILE_LOADING_CHANGE";
const PROFILE_SAVING_CHANGE       = "PROFILE_SAVING_CHANGE";
const PROFILE_ERRORS_CHANGE       = "PROFILE_ERRORS_CHANGE";


const PROFILE_OPERATOR_TIME_CHANGE              = "PROFILE_OPERATOR_TIME_CHANGE";
const PROFILE_OPERATOR_TIME_LOADING_CHANGE      = "PROFILE_OPERATOR_TIME_LOADING_CHANGE";

export const CHECK_TELEGRAM_VERIFY_CODE = "CHECK_TELEGRAM_VERIFY_CODE";
const CHECK_TELEGRAM_VERIFY_CODE_CHANGE = "CHECK_TELEGRAM_VERIFY_CHANGE";

const PROFILE_INTEGRATIONS_LOADING_CHANGE = "PROFILE_INTEGRATIONS_LOADING_CHANGE";
const PROFILE_INTEGRATIONS_CHANGE = "PROFILE_INTEGRATIONS_CHANGE";
const PROFILE_CUSTOM_INTEGRATIONS_LOADING_CHANGE = "PROFILE_CUSTOM_INTEGRATIONS_LOADING_CHANGE";
const PROFILE_CUSTOM_INTEGRATIONS_SAVING_CHANGE = "PROFILE_CUSTOM_INTEGRATIONS_SAVING_CHANGE";
const PROFILE_CUSTOM_INTEGRATIONS_ERRORS_CHANGE = "PROFILE_CUSTOM_INTEGRATIONS_ERRORS_CHANGE";
const PROFILE_CUSTOM_INTEGRATIONS_CHANGE = "PROFILE_CUSTOM_INTEGRATIONS_CHANGE";
const PROFILE_CUSTOM_INTEGRATION_EVENT_CHANGE = "PROFILE_CUSTOM_INTEGRATION_EVENT_CHANGE";
const PROFILE_CUSTOM_INTEGRATION_DATA_DELETE = "PROFILE_CUSTOM_INTEGRATION_DATA_DELETE";

const integrations_empty = () => {
    return {
        amocrm: {
            domain:null,
            status:false
        },
        bitrix: {
            domain:null,
            status:false
        },
        hh: {
            host:null,
            status:false
        }
    }
}

const state = {
    profile: null,
    profile_loading: false,
    profile_saving: false,
    profile_errors: {},

    profile_integrations_loading: false,
    profile_integrations: integrations_empty(),

    profile_custom_integrations_loading: false,
    profile_custom_integrations_saving: false,
    profile_custom_integrations_errors: {},
    profile_custom_integrations: [],

    profile_operator_time: {},
    profile_operator_time_loading: false,
    check_telegram_verify_code: {}
};

const actions = {
    [PROFILE_LOAD]({ commit, state }, id) {
        return api.load(
            'profile',
            id,
            state,
            commit,
            PROFILE_LOADING_CHANGE,
            PROFILE_CHANGE
        );
    },
    [PROFILE_UPDATE]({ commit, state }, data){

        let id = state.profile.id;

        return api.create(
            'profile',
            id,
            data,
            state,
            commit,
            PROFILE_SAVING_CHANGE,
            PROFILE_CHANGE,
            PROFILE_ERRORS_CHANGE,
        );
    },
    [PROFILE_OPERATOR_TIME_LOAD]({ commit, state }, data) {
        let id  = data.id ? data.id : null;
        api.list(
            'profile.operator_time',
            id,
            data,
            state,
            commit,
            PROFILE_OPERATOR_TIME_LOADING_CHANGE,
            PROFILE_OPERATOR_TIME_CHANGE
        );
    },
    [CHECK_TELEGRAM_VERIFY_CODE]({ commit, state }, data) {
        commit(CHECK_TELEGRAM_VERIFY_CODE_CHANGE, null);
        let url = api.main_url + "/api/v2/profile/check_telegram_verify_code";
            return http.post(url, {'verify_code': data}).then(response => {
            if (response && response.data) {
                commit(CHECK_TELEGRAM_VERIFY_CODE_CHANGE, response);
                return true;
            }
        });
    },

    [PROFILE_INTEGRATIONS] ({commit, state}) {
        return api.load(
            'profile.integrations',
            0,
            state,
            commit,
            PROFILE_INTEGRATIONS_LOADING_CHANGE,
            PROFILE_INTEGRATIONS_CHANGE
        );
    },

    [PROFILE_CUSTOM_INTEGRATIONS] ({commit, state}) {
        return api.list(
            'custom_integrations',
            0,
            null,
            state,
            commit,
            PROFILE_CUSTOM_INTEGRATIONS_LOADING_CHANGE,
            PROFILE_CUSTOM_INTEGRATIONS_CHANGE
        );
    },

    [PROFILE_CUSTOM_INTEGRATIONS_CREATE] ({commit, state}, data) {
        return api.create(
            'custom_integrations',
            0,
            data,
            state,
            commit,
            PROFILE_CUSTOM_INTEGRATIONS_SAVING_CHANGE,
            PROFILE_CUSTOM_INTEGRATION_EVENT_CHANGE,
            PROFILE_CUSTOM_INTEGRATIONS_ERRORS_CHANGE,
        );
    },

    [PROFILE_CUSTOM_INTEGRATIONS_UPDATE] ({commit, state}, data) {
        const id = data.id;
        return api.update(
            'custom_integrations.update',
            id,
            data,
            state,
            commit,
            PROFILE_CUSTOM_INTEGRATIONS_SAVING_CHANGE,
            PROFILE_CUSTOM_INTEGRATION_EVENT_CHANGE,
            PROFILE_CUSTOM_INTEGRATIONS_ERRORS_CHANGE,
        );
    },
    [PROFILE_CUSTOM_INTEGRATION_DELETE]({ commit, state }, id) {
        return api.delete(
          "custom_integrations.delete",
          id,
          state,
          commit,
          PROFILE_CUSTOM_INTEGRATION_DATA_DELETE
        );
    },
};

const mutations = {
    [PROFILE_CHANGE](state, data) {
        state.profile = data;
    },
    [PROFILE_LOADING_CHANGE](state, data) {
        state.profile_loading = data;
    },
    [PROFILE_SAVING_CHANGE](state, data) {
        state.profile_saving = data;
    },
    [PROFILE_ERRORS_CHANGE](state, data) {
        state.profile_errors = data;
    },
    [PROFILE_OPERATOR_TIME_CHANGE](state, data) {
        state.profile_operator_time = data;
    },
    [PROFILE_OPERATOR_TIME_LOADING_CHANGE](state, data) {
        state.profile_loading = data;
    },
    [CHECK_TELEGRAM_VERIFY_CODE_CHANGE](state, data) {
        if (data) {
            state.check_telegram_verify_code = data.data.data;
        } else {
            state.check_telegram_verify_code = {};
        }
    },

    [PROFILE_INTEGRATIONS_CHANGE](state, data) {
        if(data){
            state.profile_integrations = data;
        }
    },
    [PROFILE_INTEGRATIONS_LOADING_CHANGE](state, data) {
        state.profile_integrations_loading = data;
    },
    [PROFILE_CUSTOM_INTEGRATIONS_CHANGE](state, data) {
        if (data) {
            state.profile_custom_integrations = data.data;
        }
    },
    [PROFILE_CUSTOM_INTEGRATION_EVENT_CHANGE](state, data) {
        if (data) {
            const exists = state.profile_custom_integrations.findIndex((event) => event.id === data.id);
            if (exists >= 0) {
                state.profile_custom_integrations.splice(exists, 1);
            }
            state.profile_custom_integrations.push(data);
            if (exists === -1) {
                VueRouter.push({
                    name: 'custom-integration-event',
                    params: { id: data.id, event: data.event },
                });
            }
        }
    },
    [PROFILE_CUSTOM_INTEGRATIONS_LOADING_CHANGE](state, data) {
        state.profile_custom_integrations_loading = data;
    },
    [PROFILE_CUSTOM_INTEGRATIONS_SAVING_CHANGE](state, data) {
        state.profile_custom_integrations_saving = data;
    },
    [PROFILE_CUSTOM_INTEGRATIONS_ERRORS_CHANGE](state, data) {
        state.profile_custom_integrations_errors = data;
    },
    [PROFILE_CUSTOM_INTEGRATION_DATA_DELETE] (state, data) {
        if(data) {
            const exists = state.profile_custom_integrations.findIndex((event) => event.id === data);
            if (exists >= 0) {
                state.profile_custom_integrations.splice(exists, 1);
            }
            VueRouter.push({
                name: 'custom-integration'
            });
        }
    },
};
export default {
    state,
    actions,
    mutations,
}
