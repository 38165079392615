import api from './../../lib/api';

export const ATS_USER_LOAD                     = 'ATS_USER_LOAD';
export const ATS_USER_CREATE                   = 'ATS_USER_CREATE';
export const ATS_USER_UPDATE                   = 'ATS_USER_UPDATE';
export const ATS_USER_VPN_UPDATE               = 'ATS_USER_VPN_UPDATE';
export const ATS_USER_DELETE                   = 'ATS_USER_DELETE';
export const ATS_USER_LIST                     = 'ATS_USER_LIST';
export const ATS_USER_GET_LOGIN                = "ATS_USER_GET_LOGIN";
export const ATS_USER_GET_LOGIN_VTWO           = "ATS_USER_GET_LOGIN_VTWO";
export const ATS_USER_CALLER_ID_LIST           = "ATS_USER_CALLER_ID_LIST";

export const USER_GET_LIST_ATS                 = 'USER_GET_LIST_ATS';
export const ATS_USER_PASSWORD_UPDATE          = 'ATS_USER_PASSWORD_UPDATE';

const ATS_USER_CHANGE                          = 'ATS_USER_CHANGE';
const ATS_USER_LOADING_CHANGE                  = 'ATS_USER_LOADING_CHANGE';
const ATS_USER_CREATING_CHANGE                 = 'ATS_USER_CREATING_CHANGE';
const ATS_USER_SAVING_CHANGE                   = 'ATS_USER_SAVING_CHANGE';
const ATS_USER_DELETE_CHANGE                   = 'ATS_USER_DELETE_CHANGE';
const ATS_USER_LIST_LOADING_CHANGE             = 'ATS_USER_LIST_LOADING_CHANGE';
const ATS_USER_LIST_CHANGE                     = 'ATS_USER_LIST_CHANGE';
const ATS_USER_LOGIN_LOADING_CHANGE            = "ATS_USER_LOGIN_LOADING_CHANGE";
const ATS_USER_LOGIN_CHANGE                    = "ATS_USER_LOGIN_CHANGE";
const ATS_USER_CALLER_ID_LIST_LOADING_CHANGE   = "ATS_USER_CALLER_ID_LIST_LOADING_CHANGE";
const ATS_USER_CALLER_ID_LIST_CHANGE           = "ATS_USER_CALLER_ID_LIST_CHANGE";

const ATS_USER_ERRORS_CHANGE                   = 'ATS_USER_ERRORS_CHANGE';

export const ATS_USER_LIST_ATS_GROUP           = 'ATS_USER_LIST_ATS_GROUP';
const ATS_USER_LIST_ATS_GROUP_LOADING_CHANGE   = 'ATS_USER_LIST_ATS_GROUP_LOADING_CHANGE';
const ATS_USER_LIST_ATS_GROUP_CHANGE           = 'ATS_USER_LIST_ATS_GROUP_CHANGE';

export const ATS_SIP_REGISTR_DATA              = 'ATS_SIP_REGISTR_DATA';
const ATS_SIP_REGISTR_DATA_LOADING_CHANGE      = 'ATS_SIP_REGISTR_DATA_LOADING_CHANGE';
const ATS_SIP_REGISTR_DATA_CHANGE              = 'ATS_SIP_REGISTR_DATA_CHANGE';

const state = {
    ats_user: null,
    ats_user_loading: false,
    ats_user_saving: false,
    ats_user_creating: false,
    ats_user_errors: {},

    ats_user_list: [],
    ats_user_list_total: 0,
    ats_user_list_loading: false,

    caller_ids: [],
    caller_ids_loading: false,
    caller_ids_total: 0,

    ats_user_login_loading: null,
    ats_user_login: null,

    ats_user_empty: {
        id: 0,
        login: "",
        passwd: "",
        max_channels: 1,
        ats_group_id: 0,
        is_work: false,
        is_vpn: false,
        comment: "",
        user_id:0,
        provider_id:null,
        option_in_call : true,
        out_calls  : true,
    },
    
    ats_user_list_ats_group         : [],
    ats_user_list_ats_group_loading : 0,
    ats_user_list_ats_group_total   : false,
    
    ats_sip_registr_data   : [],
    ats_sip_registr_data_loading : 0,
};

const actions = {
    [ATS_USER_LOAD] ({commit, state}, id) {
        if (id=='0') {
            commit(ATS_USER_CHANGE, state.ats_user_empty);
            return;
        }

        return api.load('ats_users', id, state, commit, ATS_USER_LOADING_CHANGE, ATS_USER_CHANGE);
    },
    [ATS_USER_CREATE] ({commit, state}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create('ats_users.list', id, model, state, commit, ATS_USER_CREATING_CHANGE, ATS_USER_CHANGE, ATS_USER_ERRORS_CHANGE);
    },
    [ATS_USER_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update('ats_users', id, model, state, commit, ATS_USER_SAVING_CHANGE, ATS_USER_CHANGE, ATS_USER_ERRORS_CHANGE);
    },
    [ATS_USER_VPN_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update('ats_users_upd_vpn', id, model, state, commit, ATS_USER_SAVING_CHANGE, ATS_USER_CHANGE, ATS_USER_ERRORS_CHANGE);
    },
    [ATS_USER_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete('ats_users', id, state, commit, ATS_USER_DELETE_CHANGE);
    },
    [ATS_USER_CALLER_ID_LIST] ({commit, state}, id, filter) {
        if (!filter) {
            filter = null;
        }

        return api.list(
            'ats_users.get_caller_ids',
            id,
            filter,
            state,
            commit,
            ATS_USER_CALLER_ID_LIST_LOADING_CHANGE,
            ATS_USER_CALLER_ID_LIST_CHANGE
        );
    },
    [ATS_USER_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = {
                is_work: true
            };
        }

        return api.list(
            'ats_users.list',
            0,
            filter,
            state,
            commit,
            ATS_USER_LIST_LOADING_CHANGE,
            ATS_USER_LIST_CHANGE
        );
    },
    [ATS_USER_GET_LOGIN]({ commit, state }, id) {
        let request = api.load(
          "ats_users.get_login",
          0,
          state,
          commit,
          ATS_USER_LOGIN_LOADING_CHANGE,
          ATS_USER_LOGIN_CHANGE
        );
        return request;
    },
    [ATS_USER_GET_LOGIN_VTWO]({ commit, state }, id) {
        let request = api.load(
          "ats_users.get_login_v2",
          0,
          state,
          commit,
          ATS_USER_LOGIN_LOADING_CHANGE,
          ATS_USER_LOGIN_CHANGE
        );
        return request;
    },
    [USER_GET_LIST_ATS] ({commit, state}, filter) {
        return api.list(
            'user_get_list_ats',
            0,
            filter,
            state,
            commit,
            ATS_USER_LIST_LOADING_CHANGE,
            ATS_USER_LIST_CHANGE
        );
    },
    [ATS_USER_PASSWORD_UPDATE]({commit, state}, id) {
        return api.update(
            'ats_user_password',
            id,
            null,
            state,
            commit,
            ATS_USER_SAVING_CHANGE,
            ATS_USER_CHANGE,
            ATS_USER_ERRORS_CHANGE
        );
    },
    // получаем Sip и название групп для звонилки
    [ATS_USER_LIST_ATS_GROUP] ({commit, state}, filter) {
        return api.list(
            'user_get_list_ats_group',
            0,
            filter,
            state,
            commit,
            ATS_USER_LIST_ATS_GROUP_LOADING_CHANGE,
            ATS_USER_LIST_ATS_GROUP_CHANGE
        );
    },
    // получаем данные Sip для коннекта к Атс
    [ATS_SIP_REGISTR_DATA] ({commit, state}, id) {
        return api.load(
            'get_sip_registration_data',
            id,
            state,
            commit,
            ATS_SIP_REGISTR_DATA_LOADING_CHANGE,
            ATS_SIP_REGISTR_DATA_CHANGE
        );
    }
};

const mutations = {
    [ATS_USER_LIST_LOADING_CHANGE] (state, data) {
        state.ats_user_list_loading = data;
    },
    [ATS_USER_CREATING_CHANGE](state, data) {
        state.ats_user_creating = data;
    },
    [ATS_USER_SAVING_CHANGE](state, data) {
        state.ats_user_saving = data;
    },
    [ATS_USER_LIST_CHANGE] (state, data) {
        if(data) {
            state.ats_user_list        = data.data;
            state.ats_user_list_total  = data.total;
        }
        else {
            state.ats_user_list        = [];
            state.ats_user_list_total  = 0;
        }
    },
    [ATS_USER_LOADING_CHANGE] (state, data) {
        state.ats_user_loading = data;
    },
    [ATS_USER_CHANGE] (state, data) {
        if(data && typeof data === 'object'){
            state.ats_user_errors = {};
            state.ats_user = data;
            state.ats_user_empty = {
                id: 0,
                login: "",
                passwd: "",
                max_channels: 1,
                ats_group_id: 0,
                provider_id: null,
                is_work: false,
                comment: "",
                user_id:0,
                option_in_call : true,
                out_calls  : true,
            }
        }
    },
    [ATS_USER_ERRORS_CHANGE](state, data) {
        state.ats_user_errors = data;
    },
    [ATS_USER_LOGIN_LOADING_CHANGE](state, data) {
        state.ats_user_login_loading = data;
    },
    [ATS_USER_LOGIN_CHANGE](state, data) {
        state.ats_user_login = data;
    },
    [ATS_USER_CALLER_ID_LIST_LOADING_CHANGE] (state, data) {
        state.caller_ids_loading = data;
    },
    [ATS_USER_CALLER_ID_LIST_CHANGE] (state, data) {
        if(data) {
            state.caller_ids        = data.data;
            state.caller_ids_total  = data.total;
        }
        else {
            state.caller_ids        = [];
            state.caller_ids_total  = 0;
        }
    },
    [ATS_USER_DELETE_CHANGE](state, id) {
        if (id) {
            if (state.ats_user_list) {
                let list = [];
                state.ats_user_list.forEach(ats_user => {
                    if (ats_user.id !== id) {
                        list.push({...ats_user});
                    }
                });

                state.ats_user_list = list;
            }
        }
    },
    [ATS_USER_LIST_ATS_GROUP_LOADING_CHANGE] (state, data) {
        state.ats_user_list_ats_group_loading = data;
    },
    [ATS_USER_LIST_ATS_GROUP_CHANGE] (state, data) {
        if(data) {
            state.ats_user_list_ats_group        = data.data;
            state.ats_user_list_ats_group_total  = data.total;
        } else {
            state.ats_user_list_ats_group        = [];
            state.ats_user_list_ats_group_total  = 0;
        }
    },
    [ATS_SIP_REGISTR_DATA_LOADING_CHANGE] (state, data) {
        state.ats_sip_registr_data_loading = data;
    },
    [ATS_SIP_REGISTR_DATA_CHANGE] (state, data) {
        if(data) {
            state.ats_sip_registr_data        = data.data;
        } else {
            state.ats_sip_registr_data        = [];
        }
    }
};

export default {
    state,
    actions,
    mutations
}
