import api from './../../lib/api';

export const SIP_LOAD = 'SIP_LOAD';
export const SIP_LIST = 'SIP_LIST';
export const SIP_CREATE = 'SIP_CREATE';
export const SIP_UPDATE = 'SIP_UPDATE';
export const SIP_DELETE = 'SIP_DELETE';
export const SIP_CALLER_ID_LIST = 'SIP_CALLER_ID_LIST';
export const SIP_CALLER_ID_ALL = 'SIP_CALLER_ID_ALL';

const SIP_CHANGE = 'SIP_CHANGE';
const SIP_LOADING_CHANGE = 'SIP_LOADING_CHANGE';
const SIP_LIST_LOADING_CHANGE = 'SIP_LIST_LOADING_CHANGE';
const SIP_LIST_CHANGE = 'SIP_LIST_CHANGE';
const SIP_UPDATE_CHANGE = 'SIP_UPDATE_CHANGE';
const SIP_CREATE_CHANGE = 'SIP_CREATE_CHANGE';
const SIP_DELETE_CHANGE = 'SIP_DELETE_CHANGE';
const SIP_ERRORS_CHANGE = 'SIP_ERRORS_CHANGE';
const SIP_CALLER_ID_LIST_LOADING_CHANGE = 'SIP_CALLER_ID_LIST_LOADING_CHANGE';
const SIP_CALLER_ID_LIST_CHANGE = 'SIP_CALLER_ID_LIST_CHANGE';
const SIP_CALLER_ID_ALL_LOADING_CHANGE = 'SIP_CALLER_ID_ALL_LOADING_CHANGE';
const SIP_CALLER_ID_ALL_CHANGE = 'SIP_CALLER_ID_ALL_CHANGE';

const state = {
    sip: null,
    sip_loading: false,
    sip_saving: false,
    sip_creating: false,
    sip_errors: {},
    
    caller_ids: [],
    caller_ids_loading: false,
    caller_ids_total: 0,
    
    caller_ids_all: [],
    caller_ids_all_loading: false,
    caller_ids_all_total: 0,
    
    sip_list: [],
    sip_list_total: 0,
    sip_list_loading: false,
    
    sip_empty: {
        id: 0,
        host: "",
        port: 5060,
        passwd: "",
        login: "",
        max_channels: 1,
        template: "",
        connect_type: "",
        ats_group_id: 0,
        description: "",
        is_work: true
    }
};

const actions = {
    [SIP_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(SIP_CHANGE, state.sip_empty);
            return;
        }
        
        return api.load('sips', id, state, commit, SIP_LOADING_CHANGE, SIP_CHANGE);
    },
    [SIP_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null;
        }
        
        return api.list(
            'sips.list',
            0,
            filter,
            state,
            commit,
            SIP_LIST_LOADING_CHANGE,
            SIP_LIST_CHANGE
        );
    },
    [SIP_CALLER_ID_LIST] ({commit, state}, id, filter) {
        if (!filter) {
            filter = null;
        }
        
        return api.list(
            'sips.get_caller_ids',
            id,
            filter,
            state,
            commit,
            SIP_CALLER_ID_LIST_LOADING_CHANGE,
            SIP_CALLER_ID_LIST_CHANGE
        );
    },
    [SIP_CALLER_ID_ALL] ({commit, state}, filter) {
        if (!filter) {
            filter = null;
        }
        
        return api.list(
            'sips.get_all_caller_ids',
            0,
            filter,
            state,
            commit,
            SIP_CALLER_ID_ALL_LOADING_CHANGE,
            SIP_CALLER_ID_ALL_CHANGE
        );
    },
    [SIP_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'sips',
            id,
            model,
            state,
            commit,
            SIP_UPDATE_CHANGE,
            SIP_CHANGE,
            SIP_ERRORS_CHANGE);
    },
    [SIP_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'sips.list',
            id,
            model,
            state,
            commit,
            SIP_CREATE_CHANGE,
            SIP_CHANGE,
            SIP_ERRORS_CHANGE);
    },
    [SIP_DELETE]({commit, state}, id) {
        if (!id) {
            id = 0;
        }
        return api.delete(
            'sips',
            id,
            state,
            commit,
            SIP_DELETE_CHANGE
        );
    },
};

const mutations = {
    [SIP_CHANGE] (state, data) {
        if(data && typeof data === 'object'){
            state.sip_errors = {};
            state.sip = data;
            state.sip_empty = {
                id: 0,
                host: "",
                port: 5060,
                passwd: "",
                login: "",
                max_channels: 1,
                template: "",
                connect_type: "",
                ats_group_id: 0,
                description: "",
                is_work: true
            }
        }
    },
    [SIP_LIST_CHANGE] (state, data) {
        if(data) {
            state.sip_list        = data.data;
            state.sip_list_total  = data.total;
        }
        else {
            state.sip_list        = [];
            state.sip_list_total  = 0;
        }
    },
    [SIP_CALLER_ID_LIST_CHANGE] (state, data) {
        if(data) {
            state.caller_ids        = data.data;
            state.caller_ids_total  = data.total;
        }
        else {
            state.caller_ids        = [];
            state.caller_ids_total  = 0;
        }
    },
    [SIP_CALLER_ID_ALL_CHANGE] (state, data) {
        if(data) {
            state.caller_ids_all        = data.data;
            state.caller_ids_all_total  = data.total;
        }
        else {
            state.caller_ids_all        = [];
            state.caller_ids_all_total  = 0;
        }
    },
    [SIP_LOADING_CHANGE] (state, data) {
        state.sip_loading = data;
    },
    [SIP_LIST_LOADING_CHANGE] (state, data) {
        state.sip_list_loading = data;
    },
    [SIP_CALLER_ID_LIST_LOADING_CHANGE] (state, data) {
        state.caller_ids_loading = data;
    },
    [SIP_CALLER_ID_ALL_LOADING_CHANGE] (state, data) {
        state.caller_ids_all_loading = data;
    },
    [SIP_UPDATE_CHANGE] (state, data) {
        state.sip_saving = data;
    },
    [SIP_ERRORS_CHANGE] (state, data) {
        state.sip_errors = data;
    },
    [SIP_CREATE_CHANGE] (state, data) {
        state.sip_creating = data;
    },
    [SIP_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.sip_list) {
                let list = [];
                state.sip_list.forEach(item => {
                    if (item.id !== id) {
                        list.push({...item});
                    }
                });
    
                state.sip_list = list;
            }
        }
    },
};

export default {
    state,
    actions,
    mutations
}
