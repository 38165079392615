import http from './../../lib/http';
import api from './../../lib/api';

export const GEO_LIST                 = 'GEO_LIST';

export const GEO_LOAD                 = 'GEO_LOAD';
export const GEO_LOAD_BY_PHONE        = 'GEO_LOAD_BY_PHONE';
export const GEO_UPDATE               = 'GEO_UPDATE';
export const GEO_CREATE               = 'GEO_CREATE';

export const GEO_LOAD_DATATABLE       = 'GEO_LOAD_DATATABLE';

export const GEO_CHANGE               = 'GEO_CHANGE';

const GEO_BY_PHONE_CHANGE             = 'GEO_BY_PHONE_CHANGE';
const GEO_BY_PHONE_LOADING_CHANGE     = 'GEO_BY_PHONE_LOADING_CHANGE';


const GEO_LOADING_CHANGE              = 'GEO_LOADING_CHANGE';
const GEO_SAVING_CHANGE               = 'GEO_SAVING_CHANGE';
const GEO_ERRORS_CHANGE               = 'GEO_ERRORS_CHANGE';

const GEO_CREATING_CHANGE             = 'GEO_CREATING_CHANGE';

const GEO_DATATABLE_CHANGE            = 'GEO_DATATABLE_CHANGE';
const GEO_DATATABLE_LOADING_CHANGE    = 'GEO_DATATABLE_LOADING_CHANGE';

export const GEO_DELETE		      = 'GEO_DELETE';
export const GEO_DELETE_CHANGE        = 'GEO_DELETE_CHANGE';

const state = {
    geo            : null,
    geo_by_phone   : null,
    geo_loading    : false,
    geo_by_phone_loading : false,
    geo_saving     : false,
    geo_errors     : {},

    geo_list_total     : 0, 
    geo_list           : null,
    geo_list_loading   : false,

    geo_creating           : false,
    geo_creating_errors    : {},
    geo_empty              : {
                                  id: 0,
                                  name: "",
                                  code: ""
                              }
};

const actions = {
    [GEO_LOAD] ({ commit, state }, id) {
        if(!id) {
	    state.geo_empty = {id:0, name: null, code:null};
            commit(GEO_CHANGE, state.geo_empty);

            return;
        }

        return api.load('geo', id, state, commit, GEO_LOADING_CHANGE, GEO_CHANGE);
    },

    [GEO_LOAD_BY_PHONE] ({ commit, state }, id) {
        if(!id) {
        state.geo_empty = {id:0, name: null, code:null};
            commit(GEO_CHANGE, state.geo_empty);
            return;
        }

        return api.load('geo.phone', id, state, commit, GEO_BY_PHONE_LOADING_CHANGE, GEO_BY_PHONE_CHANGE);
    },

    [GEO_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('geo', id, model, state, commit, GEO_SAVING_CHANGE, GEO_CHANGE, GEO_ERRORS_CHANGE);
    },

    [GEO_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('geo.list', id, model, state, commit, GEO_CREATING_CHANGE, GEO_CHANGE, GEO_ERRORS_CHANGE);
    },

    [GEO_LOAD_DATATABLE] ({ commit, state }, data) {        
        let params  = data.params;
        let id  = data.id ? data.id : null;

        return api.list(
            'geo.list', 
            id,
            params,
            state, 
            commit, 
            GEO_DATATABLE_LOADING_CHANGE, 
            GEO_DATATABLE_CHANGE);
    },
    [GEO_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'geo',
            id,
            state,
            commit,
            GEO_DELETE_CHANGE
        );
    },


};

const mutations = {
    [GEO_DATATABLE_LOADING_CHANGE](state, data) {
        state.geo_list_loading = data;
    },
    [GEO_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.geo_list        = data.data;
            state.geo_list_total  = data.total;
        }
        else {
            state.geo_list        = [];
            state.geo_list_total  = 0;
        }
    },
    [GEO_CHANGE](state, data) {
        state.geo  = data;
    },
    [GEO_BY_PHONE_CHANGE](state, data) {
        state.geo_by_phone  = data;
    },
    [GEO_LOADING_CHANGE](state, data) {
        state.geo_loading = data;
    },
    [GEO_BY_PHONE_LOADING_CHANGE](state, data) {
        state.geo_by_phone_loading = data;
    },
    [GEO_SAVING_CHANGE](state, data) {
        state.geo_saving   = data;
    },
    [GEO_ERRORS_CHANGE](state, data) {
        state.geo_errors   = data;
    },
    [GEO_CREATING_CHANGE](state, data) {
        state.geo_creating = data;
    },
    [GEO_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.geo_list) {

                let list = [];
                state.geo_list.forEach(geo => {
                    if (geo.id !== id) {
                        list.push({...geo});
                    }
                });

                state.geo_list = list;
            }
        }
    },


};

export default {
    state,
    actions,
    mutations
}
