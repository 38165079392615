import http from './../../lib/http';
import api from './../../lib/api';

export const OUT_ROUTE_LIST = 'OUT_ROUTE_LIST';
export const OUT_ROUTE_LOAD = 'OUT_ROUTE_LOAD';
export const OUT_ROUTE_CREATE = 'OUT_ROUTE_CREATE';
export const OUT_ROUTE_UPDATE = 'OUT_ROUTE_UPDATE';
export const OUT_ROUTE_DELETE = 'OUT_ROUTE_DELETE';

const OUT_ROUTE_LIST_LOADING_CHANGE = 'OUT_ROUTE_LIST_LOADING_CHANGE';
const OUT_ROUTE_LIST_CHANGE = 'OUT_ROUTE_LIST_CHANGE';
const OUT_ROUTE_LOADING_CHANGE = 'OUT_ROUTE_LOADING_CHANGE';
const OUT_ROUTE_CHANGE = 'OUT_ROUTE_CHANGE';
const OUT_ROUTE_CREATE_CHANGE = 'OUT_ROUTE_CREATE_CHANGE';
const OUT_ROUTE_UPDATE_CHANGE = 'OUT_ROUTE_UPDATE_CHANGE';
const OUT_ROUTE_DELETE_CHANGE = 'OUT_ROUTE_DELETE_CHANGE';
const OUT_ROUTE_ERRORS_CHANGE = 'OUT_ROUTE_ERRORS_CHANGE';

const state = {
    out_route: null,
    out_route_loading: false,
    out_route_saving: false,
    out_route_creating: false,
    out_route_errors: {},
    
    out_route_list: [],
    out_route_list_total: 0,
    out_route_list_loading: false,
    
    out_route_empty: {
        id: 0,
        name: "",
        mask: "",
        prefix: "",
        perlace_count: 0,
        comment: "",
        trunks1: "",
        trunks2: "",
        trunks_p1: 0,
        trunks_p2: 0,
        ats_group_id: 0,
        provider_id: 0,
        is_work: true,
    }
};

const actions = {
    [OUT_ROUTE_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }
        
        return api.list(
            'out_routes.list',
            0,
            filter,
            state,
            commit,
            OUT_ROUTE_LIST_LOADING_CHANGE,
            OUT_ROUTE_LIST_CHANGE
        );
    },
    [OUT_ROUTE_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(OUT_ROUTE_CHANGE, state.out_route_empty);
            return;
        }
        
        return api.load(
            'out_routes',
            id,
            state,
            commit,
            OUT_ROUTE_LOADING_CHANGE,
            OUT_ROUTE_CHANGE
        );
    },
    [OUT_ROUTE_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'out_routes.list',
            id,
            model,
            state,
            commit,
            OUT_ROUTE_CREATE_CHANGE,
            OUT_ROUTE_CHANGE,
            OUT_ROUTE_ERRORS_CHANGE);
    },
    [OUT_ROUTE_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'out_routes',
            id,
            model,
            state,
            commit,
            OUT_ROUTE_UPDATE_CHANGE,
            OUT_ROUTE_CHANGE,
            OUT_ROUTE_ERRORS_CHANGE);
    },
    [OUT_ROUTE_DELETE] ({commit, state}, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'out_routes', 
            id, 
            state, 
            commit, 
            OUT_ROUTE_DELETE_CHANGE
        );
    }
};

const mutations = {
    [OUT_ROUTE_LIST_LOADING_CHANGE] (state, data) {
        state.out_route_list_loading = data;
    },
    [OUT_ROUTE_LIST_CHANGE] (state, data) {
        if(data) {
            state.out_route_list        = data.data;
            state.out_route_list_total  = data.total;
        }
        else {
            state.out_route_list        = [];
            state.out_route_list_total  = 0;
        }
    },
    [OUT_ROUTE_LOADING_CHANGE] (state, data) {
        state.out_route_loading = data;
    },
    [OUT_ROUTE_CHANGE] (state, data) {
        state.out_route_errors = {};
        if(data && typeof data === 'object'){
            state.out_route = data;
            state.out_route_empty = {
                id: 0,
                name: "",
                mask: "",
                prefix: "",
                perlace_count: 0,
                comment: "",
                trunks1: "",
                trunks2: "",
                trunks_p1: 0,
                trunks_p2: 0,
                ats_group_id: 0,
                provider_id: 0,
                is_work: true,
            }
        }
    },
    [OUT_ROUTE_CREATE_CHANGE] (state, data) {
        state.out_route_creating = data;
    },
    [OUT_ROUTE_UPDATE_CHANGE] (state, data) {
        state.out_route_saving = data;
    },
    [OUT_ROUTE_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.out_route_list) {
                let list = [];
                state.out_route_list.forEach(prov => {
                    if (prov.id !== id) {
                        list.push({...prov});
                    }
                });

                state.out_route_list = list;
            }
        }
    },
    [OUT_ROUTE_ERRORS_CHANGE] (state, data) {
        state.out_route_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}