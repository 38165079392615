import http from './../../lib/http';
import api from './../../lib/api';

export const PIMPAY_INVOICE_LIST = 'PIMPAY_INVOICE_LIST';
export const PIMPAY_INVOICE_LIST_BY_ORDER = 'PIMPAY_INVOICE_LIST_BY_ORDER';
export const PIMPAY_INVOICE_LOAD = 'PIMPAY_INVOICE_LOAD';
export const PIMPAY_INVOICE_CREATE = 'PIMPAY_INVOICE_CREATE';
export const PIMPAY_INVOICE_UPDATE = 'PIMPAY_INVOICE_UPDATE';
export const PIMPAY_INVOICE_DELETE = 'PIMPAY_INVOICE_DELETE';

const PIMPAY_INVOICE_LIST_LOADING_CHANGE = 'PIMPAY_INVOICE_LIST_LOADING_CHANGE';
const PIMPAY_INVOICE_LIST_CHANGE = 'PIMPAY_INVOICE_LIST_CHANGE';
const PIMPAY_INVOICE_LOADING_CHANGE = 'PIMPAY_INVOICE_LOADING_CHANGE';
const PIMPAY_INVOICE_CHANGE = 'PIMPAY_INVOICE_CHANGE';
const PIMPAY_INVOICE_CREATE_CHANGE = 'PIMPAY_INVOICE_CREATE_CHANGE';
const PIMPAY_INVOICE_UPDATE_CHANGE = 'PIMPAY_INVOICE_UPDATE_CHANGE';
const PIMPAY_INVOICE_DELETE_CHANGE = 'PIMPAY_INVOICE_DELETE_CHANGE';
const PIMPAY_INVOICE_ERRORS_CHANGE = 'PIMPAY_INVOICE_ERRORS_CHANGE';

const state = {
    pimpay_invoice: null,
    pimpay_invoice_loading: false,
    pimpay_invoice_saving: false,
    pimpay_invoice_creating: false,
    pimpay_invoice_errors: {},
    
    pimpay_invoice_list: [],
    pimpay_invoice_list_total: 0,
    pimpay_invoice_list_loading: false,
    
    pimpay_invoice_empty: {
        id: 0,
        order_id: 0,
        shop_id: 0,
        external_id: "",
        method: "",
        manager_id: 0,
        prepay_amount: 0.00,
        currency: "",
        status: 0,
        status_info: "",
        status_time: "",
        invoice_id: 0,
    },
}

const actions = {
    [PIMPAY_INVOICE_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }
        
        return api.list(
            'pimpay_invoices.list',
            0,
            filter,
            state,
            commit,
            PIMPAY_INVOICE_LIST_LOADING_CHANGE,
            PIMPAY_INVOICE_LIST_CHANGE
        );
    },
    [PIMPAY_INVOICE_LIST_BY_ORDER] ({commit, state}, order_id) {
        return api.list(
            'pimpay_invoices.list_by_order',
            order_id,
            null,
            state,
            commit,
            PIMPAY_INVOICE_LIST_LOADING_CHANGE,
            PIMPAY_INVOICE_LIST_CHANGE
        );
    },
    [PIMPAY_INVOICE_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(PIMPAY_INVOICE_CHANGE, state.pimpay_invoice_empty);
            return;
        }
        
        return api.load(
            'pimpay_invoices',
            id,
            state,
            commit,
            PIMPAY_INVOICE_LOADING_CHANGE,
            PIMPAY_INVOICE_CHANGE
        );
    },
    [PIMPAY_INVOICE_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'pimpay_invoices.list',
            id,
            model,
            state,
            commit,
            PIMPAY_INVOICE_CREATE_CHANGE,
            PIMPAY_INVOICE_CHANGE,
            PIMPAY_INVOICE_ERRORS_CHANGE);
    },
    [PIMPAY_INVOICE_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'pimpay_invoices',
            id,
            model,
            state,
            commit,
            PIMPAY_INVOICE_UPDATE_CHANGE,
            PIMPAY_INVOICE_CHANGE,
            PIMPAY_INVOICE_ERRORS_CHANGE);
    },
    [PIMPAY_INVOICE_DELETE] ({commit, state}, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'pimpay_invoices', 
            id, 
            state, 
            commit, 
            PIMPAY_INVOICE_DELETE_CHANGE
        );
    }
};

const mutations = {
    [PIMPAY_INVOICE_LIST_LOADING_CHANGE] (state, data) {
        state.pimpay_invoice_list_loading = data;
    },
    [PIMPAY_INVOICE_LIST_CHANGE] (state, data) {
        if(data) {
            state.pimpay_invoice_list        = data.data;
            state.pimpay_invoice_list_total  = data.total;
        }
        else {
            state.pimpay_invoice_list        = [];
            state.pimpay_invoice_list_total  = 0;
        }
    },
    [PIMPAY_INVOICE_LOADING_CHANGE] (state, data) {
        state.pimpay_invoice_loading = data;
    },
    [PIMPAY_INVOICE_CHANGE] (state, data) {
        state.pimpay_invoice_errors = {};
        if(data && typeof data === 'object'){
            state.pimpay_invoice = data;
            state.pimpay_invoice_empty = {
                id: 0,
                order_id: 0,
                shop_id: 0,
                external_id: "",
                method: "",
                manager_id: 0,
                prepay_amount: 0.00,
                currency: "",
                status: 0,
                status_info: "",
                status_time: "",
                invoice_id: 0,
            }
        }
    },
    [PIMPAY_INVOICE_CREATE_CHANGE] (state, data) {
        state.pimpay_invoice_creating = data;
    },
    [PIMPAY_INVOICE_UPDATE_CHANGE] (state, data) {
        state.pimpay_invoice_saving = data;
    },
    [PIMPAY_INVOICE_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.pimpay_invoice_list) {
                let list = [];
                state.pimpay_invoice_list.forEach(prov => {
                    if (prov.id !== id) {
                        list.push({...prov});
                    }
                });

                state.pimpay_invoice_list = list;
            }
        }
    },
    [PIMPAY_INVOICE_ERRORS_CHANGE] (state, data) {
        state.pimpay_invoice_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}
