import http from './../../lib/http';
import api from './../../lib/api';

export const LOG_ACTIVITIES_ADD = 'LOG_ACTIVITIES_ADD';
const LOG_ACTIVITIES_ADD_CHANGE = 'LOG_ACTIVITIES_ADD_CHANGE';
const LOG_ACTIVITIES_ADD_ERRORS_CHANGE = 'LOG_ACTIVITIES_ADD_ERRORS_CHANGE';

const state = { 
  log_activities_resp: null,
  log_activities_resp_error: null
};

const actions = {

    [LOG_ACTIVITIES_ADD] ({ commit, state }, data) {
      return api.create('log_activities.add_to_log', 0, data, state, commit, LOG_ACTIVITIES_ADD_CHANGE, null, LOG_ACTIVITIES_ADD_ERRORS_CHANGE);
    }

};

const mutations = {
  [LOG_ACTIVITIES_ADD_CHANGE](state, data) {
        state.log_activities_resp = data;
  },
  [LOG_ACTIVITIES_ADD_ERRORS_CHANGE](state, data) {
        state.log_activities_resp_error = data;
  }
};


export default {
  state,
  actions,
  mutations
};
