import http from './../../lib/http';
import api from './../../lib/api';

export const BITRIX_DELETE = 'BITRIX_DELETE';
export const BITRIX_SET_SETTINGS = "BITRIX_SET_SETTINGS";
export const BITRIX_SET_SECRET = "BITRIX_SET_SECRET";
export const BITRIX_CONDITIONS_UPDATE = "BITRIX_CONDITIONS_UPDATE";
const BITRIX_DATA_ERRORS   = 'BITRIX_DATA_ERRORS';
const BITRIX_DATA_DELETE   = 'BITRIX_DATA_DELETE';
const BITRIX_SAVING_CHANGE = "BITRIX_SAVING_CHANGE";
const BITRIX_CHANGE = "BITRIX_CHANGE";
const BITRIX_ERRORS_CHANGE = "BITRIX_ERRORS_CHANGE";
const BITRIX_SECRET_SAVING_CHANGE = "BITRIX_SECRET_SAVING_CHANGE";
const BITRIX_SECRET_CHANGE = "BITRIX_SECRET_CHANGE";
const BITRIX_CONDITIONS_SAVING_CHANGE = "BITRIX_CONDITIONS_SAVING_CHANGE";
const BITRIX_UPDATE_CONDITIONS_CHANGE = "BITRIX_UPDATE_CONDITIONS_CHANGE";
const BITRIX_CONDITIONS_ERRORS_CHANGE = "BITRIX_CONDITIONS_ERRORS_CHANGE";

const state = {
    data: [],
    errors: {},
    loading: false,
    urls:[],
    urls_saving:false,
    secret_saving:false,
    secret:[],
};

const actions = {
    [BITRIX_SET_SETTINGS]({ commit, state }, data){
        return api.create(
            'bitrix.set_settings',
            0,
            data,
            state,
            commit,
            BITRIX_SAVING_CHANGE,
            BITRIX_CHANGE,
            BITRIX_ERRORS_CHANGE,
        );
    },
    [BITRIX_SET_SECRET]({ commit, state }, data){
        return api.create(
            'bitrix.set_secret',
            0,
            data,
            state,
            commit,
            BITRIX_SECRET_SAVING_CHANGE,
            BITRIX_SECRET_CHANGE,
            BITRIX_ERRORS_CHANGE,
        );
    },
    [BITRIX_DELETE]({ commit, state }, id) {
        return api.delete(
            "bitrix.delete",
            id,
            state,
            commit,
            BITRIX_DATA_DELETE
        );
    },
    [BITRIX_CONDITIONS_UPDATE] ({ commit, state }, data) {
        if (data.type == "stages") {
            return api.create('bitrix.update_stages', data.id, data.data, state, commit, BITRIX_CONDITIONS_SAVING_CHANGE, BITRIX_UPDATE_CONDITIONS_CHANGE, BITRIX_CONDITIONS_ERRORS_CHANGE);
        } else if (data.type == "statuses"){
            return api.create('bitrix.update_statuses', data.id, data.data, state, commit, BITRIX_CONDITIONS_SAVING_CHANGE, BITRIX_UPDATE_CONDITIONS_CHANGE, BITRIX_CONDITIONS_ERRORS_CHANGE);
        }
    },
};

const mutations = {
    [BITRIX_DATA_DELETE] (state, data) {
        if(data) {
            state.bitrix_data        = [];
        }
    },
    [BITRIX_SAVING_CHANGE](state, data) {
        state.urls_saving = data;
    },
    [BITRIX_SECRET_SAVING_CHANGE](state, data) {
        state.secret_saving = data;
    },
    [BITRIX_CHANGE](state, data) {
        state.urls = data;
    },
    [BITRIX_SECRET_CHANGE](state, data) {
        state.secret = data;
    },
    [BITRIX_ERRORS_CHANGE](state, data) {
        state.errors = data;
    },
    [BITRIX_CONDITIONS_SAVING_CHANGE](state, data) {
        return;
    },
    [BITRIX_UPDATE_CONDITIONS_CHANGE](state, data) {
        return;
    },
    [BITRIX_CONDITIONS_ERRORS_CHANGE](state, data) {
        return;
    },
};


export default {
    state,
    actions,
    mutations
}