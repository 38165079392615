let currDomain = document.domain;
let tmpArrDomain = currDomain.split('.');
// определение домена и подстановка названия поддомена бэка
tmpArrDomain.unshift('back');

let backDomain = tmpArrDomain.join('.');
tmpArrDomain[0] = 'login';
let loginBackDomain = tmpArrDomain.join('.');

// определяемый домен бэка
let mainUrl = document.location.protocol + '//' + backDomain;
let loginMainUrl = document.location.protocol + '//' + loginBackDomain;

export default {
    mainUrl,
    loginMainUrl,
};
