import http from './../../lib/http';
import api from './../../lib/api';

// Загрузить АТС с бэка по ID
export const SIP_CRM_PARM_LOAD                 = 'SIP_CRM_PARM_LOAD';

// Изменить АТС по ID
export const SIP_CRM_PARM_UPDATE               = 'SIP_CRM_PARM_UPDATE';

// Создать АТС
export const SIP_CRM_PARM_CREATE               = 'SIP_CRM_PARM_CREATE';

const SIP_CRM_PARM_CHANGE                      = 'SIP_CRM_PARM_CHANGE';
const SIP_CRM_PARM_LOADING_CHANGE              = 'SIP_CRM_PARM_LOADING_CHANGE';
const SIP_CRM_PARM_SAVING_CHANGE               = 'SIP_CRM_PARM_SAVING_CHANGE';
const SIP_CRM_PARM_ERRORS_CHANGE               = 'SIP_CRM_PARM_ERRORS_CHANGE';
const SIP_CRM_PARM_CREATING_CHANGE             = 'SIP_CRM_PARM_CREATING_CHANGE';

const state = {
    sip_srm_parm: null,
    sip_srm_parm_loading: false,
    sip_srm_parm_saving: false,
    sip_srm_parm_errors: {},

    sip_srm_parm_list_total: 0, 
    sip_srm_parm_list: null,
    sip_srm_parm_list_loading: false,

    sip_srm_parm_creating: false,
    sip_srm_parm_creating_errors: {},
    sip_srm_parm_empty: {}
};

const actions = {
    [SIP_CRM_PARM_LOAD] ({ commit, state }, id) {
        // if(!id) {
        //     commit(SIP_CRM_PARM_CHANGE, state.sip_srm_parm_empty);
        //     return;
        // }

        return api.load('sip_srm_property', id, state, commit, SIP_CRM_PARM_LOADING_CHANGE, SIP_CRM_PARM_CHANGE);
    },
    [SIP_CRM_PARM_CREATE] ({commit, state}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create('sip_srm_property', id, model, state, commit, SIP_CRM_PARM_CREATING_CHANGE, SIP_CRM_PARM_CHANGE, SIP_CRM_PARM_ERRORS_CHANGE);
    },
    [SIP_CRM_PARM_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update('sip_srm_property', id, model, state, commit, SIP_CRM_PARM_SAVING_CHANGE, SIP_CRM_PARM_CHANGE, SIP_CRM_PARM_ERRORS_CHANGE);
    }
};

const mutations = {
    [SIP_CRM_PARM_CHANGE](state, data) {
        if(data && typeof data === 'object'){
            state.sip_srm_parm_errors = {};
            state.sip_srm_parm = data;
            state.sip_srm_parm_empty = {};
        }
    },
    [SIP_CRM_PARM_LOADING_CHANGE](state, data) {
        state.sip_srm_parm_loading = data;
    },
    [SIP_CRM_PARM_CREATING_CHANGE](state, data) {
        state.sip_srm_parm_creating = data;
    },
    [SIP_CRM_PARM_ERRORS_CHANGE](state, data) {
        state.sip_srm_parm_errors = data;
    },
    [SIP_CRM_PARM_SAVING_CHANGE](state, data) {
        state.sip_srm_parm_saving = data;
    }
};

export default {
    state,
    actions,
    mutations
}
