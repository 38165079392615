import api from './../../lib/api';

export const NLU_SERVER_CREATE = 'NLU_SERVER_CREATE';
export const NLU_SERVER_DELETE = 'NLU_SERVER_DELETE';
export const NLU_SERVER_LOAD_HISTORY = 'NLU_SERVER_LOAD_HISTORY';
export const NLU_SERVER_LOAD_LIST = 'NLU_SERVER_LOAD_LIST';
export const NLU_SERVER_UPDATE = 'NLU_SERVER_UPDATE';

const NLU_SERVER_HISTORY_LOADING_CHANGE = 'NLU_SERVER_HISTORY_LOADING_CHANGE';
const NLU_SERVER_HISTORY_CHANGE = 'NLU_SERVER_HISTORY_CHANGE';
const NLU_SERVER_LIST_LOADING_CHANGE = 'NLU_SERVER_LIST_LOADING_CHANGE';
const NLU_SERVER_LIST_CHANGE = 'NLU_SERVER_LIST_CHANGE';
const NLU_SERVER_LOADING = 'NLU_SERVER_LOADING';
const NLU_SERVER_LOADED = 'NLU_SERVER_LOADED';
const NLU_SERVER_ERRORS = 'NLU_SERVER_ERRORS';
const NLU_SERVER_DELETING = 'NLU_SERVER_DELETING';

const state = {
    server: {},
    server_history_loading: false,
    server_history: [],
    server_history_total: 0,
    server_list_loading: false,
    server_list: [],
    server_errors: {},
};

const actions = {

    /**
     * Загружает историю изменения серверов
     */
    [NLU_SERVER_LOAD_HISTORY] ({ commit, state }, data) {
        return api.list(
            'nlu.servers.history', 0, data, state, commit, NLU_SERVER_HISTORY_LOADING_CHANGE, NLU_SERVER_HISTORY_CHANGE
        );
    },

    /**
     * Загружает список серверов
     */
    [NLU_SERVER_LOAD_LIST] ({ commit, state }, data) {
        return api.list(
            'nlu.servers', 0, data, state, commit, NLU_SERVER_LIST_LOADING_CHANGE, NLU_SERVER_LIST_CHANGE
        );
    },

    /**
     * Отправляет запрос на создание сервера
     *
     * @return {Promise}
     */
    async [NLU_SERVER_CREATE]({ commit, state }, data) {
        const success = await api.create(
            'nlu.servers',
            0,
            data,
            state,
            commit,
            NLU_SERVER_LOADING,
            NLU_SERVER_LOADED,
            NLU_SERVER_ERRORS
        ).catch((error) => {
            return Promise.reject(state.server_errors);
        });

        if (success) {
            return Promise.resolve(state.server);
        }
    },

    /**
     * Отправляет запрос на обновление сервера
     */
    [NLU_SERVER_UPDATE]({ commit, state }, data) {
        return api.update(
            'nlu.server',
            data.id,
            data,
            state,
            commit,
            NLU_SERVER_LOADING,
            NLU_SERVER_LOADED,
            NLU_SERVER_ERRORS
        );
    },

    /**
     * Отправляет запрос на удаление сервера
     */
    [NLU_SERVER_DELETE]({ commit, state }, id) {
        return api.delete(
            'nlu.server',
            id,
            state,
            commit,
            NLU_SERVER_DELETING
        );
    },
};

const mutations = {

    /**
     * Изменяет флаг загрузки истории
     */
    [NLU_SERVER_HISTORY_LOADING_CHANGE](state, data) {
        state.server_history_loading = data;
    },

    /**
     * Обновляет спиок с историей
     */
    [NLU_SERVER_HISTORY_CHANGE](state, data) {
        if (data) {
            state.server_history = data.data;
            state.server_history_total = data.total;
        } else {
            state.server_history = [];
            state.server_history_total = 0;
        }
    },

    /**
     * Изменяет флаг загрузки списка серверов
     */
    [NLU_SERVER_LIST_LOADING_CHANGE](state, data) {
        state.server_list_loading = data;
    },

    /**
     * Обновляет спиок серверов
     */
    [NLU_SERVER_LIST_CHANGE](state, data) {
        if(data) {
            state.server_list = data.data.sort((a, b) => a.id - b.id);
        } else {
            state.server_list = [];
        }
    },

    /**
     * Изменяет флаг загрузки сервера
     */
    [NLU_SERVER_LOADING](state, data) {
        state.server_loading = data;
    },

    /**
     * Удаляет сервер с ID id из хранилища
     */
    [NLU_SERVER_DELETING](state, id) {
        const index = state.server_list.findIndex((server) => server.id === id);
        if (index < 0) {
            return;
        }
        state.server_list.splice(index, 1);
    },

    /**
     * Обновляет сервер в хранилище
     */
    [NLU_SERVER_LOADED](state, data) {
        if (data) {
            state.server = data;

            const inList = state.server_list.find((server) => server.id === data.id);
            if (inList) {
                inList.title = data.title;
                inList.url = data.url;
                inList.languages = data.languages;
            } else {
                console.log(NLU_SERVER_LOADED, data);
                state.server_list.push(data);
            }
        }
    },

    /**
     * Обновляет список ошибок запросов в хранилище
     */
    [NLU_SERVER_ERRORS](state, data) {
        state.server_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}
