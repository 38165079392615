import http from "./../../lib/http";
import api from "./../../lib/api";

export const DELIVERY_TYPE_LIST = "DELIVERY_TYPE_LIST";

export const DELIVERY_TYPE_LOAD = "DELIVERY_TYPE_LOAD";
export const DELIVERY_TYPE_LOAD_BY_KEY = "DELIVERY_TYPE_LOAD_BY_KEY";
export const DELIVERY_TYPE_UPDATE = "DELIVERY_TYPE_UPDATE";
export const DELIVERY_TYPE_CREATE = "DELIVERY_TYPE_CREATE";
export const POSTCODE_INFO_CREATE = "POSTCODE_INFO_CREATE";

export const DELIVERY_TYPE_LOAD_DATATABLE = "DELIVERY_TYPE_LOAD_DATATABLE";

export const DELIVERY_TYPE_CHANGE = "DELIVERY_TYPE_CHANGE";
const DELIVERY_TYPE_LOADING_CHANGE = "DELIVERY_TYPE_LOADING_CHANGE";
const POSTCODE_INFO_CHANGE = "POSTCODE_INFO_CHANGE_CHANGE";
const PROJECT_LOADING_CHANGE = "PROJECT_LOADING_CHANGE";
const DELIVERY_TYPE_SAVING_CHANGE = "DELIVERY_TYPE_SAVING_CHANGE";
const DELIVERY_TYPE_ERRORS_CHANGE = "DELIVERY_TYPE_ERRORS_CHANGE";
const POSTCODE_INFO_ERRORS_CHANGE = "POSTCODE_INFO_ERRORS_CHANGE";
const PROJECT_ERRORS_CHANGE = "PROJECT_ERRORS_CHANGE";

const DELIVERY_TYPE_CREATING_CHANGE = "DELIVERY_TYPE_CREATING_CHANGE";
const POSTCODE_INFO_CREATING_CHANGE = "POSTCODE_INFO_CREATING_CHANGE";
const PROJECT_CREATING_CHANGE = "PROJECT_CREATING_CHANGE";

const DELIVERY_TYPE_DATATABLE_CHANGE = "DELIVERY_TYPE_DATATABLE_CHANGE";
const DELIVERY_TYPE_DATATABLE_LOADING_CHANGE =
  "DELIVERY_TYPE_DATATABLE_LOADING_CHANGE";

const POSTCODE_INFO_DATATABLE_CHANGE = "POSTCODE_INFO_DATATABLE_CHANGE";
const POSTCODE_INFO_DATATABLE_LOADING_CHANGE =
  "POSTCODE_INFO_DATATABLE_LOADING_CHANGE";

export const DELIVERY_TYPE_DELETE = "DELIVERY_TYPE_DELETE";
export const DELIVERY_TYPE_DELETE_CHANGE = "DELIVERY_TYPE_DELETE_CHANGE";

export const DT_PROJECT_CREATE = "DT_PROJECT_CREATE";
export const PROJECT_CHANGE = "PROJECT_CHANGE";
export const PROJECT_LIST_LOAD = "PROJECT_LIST_LOAD";
export const POSTCODE_INFO_REINDEX = "POSTCODE_INFO_REINDEX";
export const POSTCODE_INFO_GET_ONE = "POSTCODE_INFO_GET_ONE";
const PROJECT_LIST_LOAD_CHANGE = "PROJECT_LIST_LOAD_CHANGE";
export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_DELETE_CHANGE = "PROJECT_DELETE_CHANGE";

export const STOCKS_LIST_LOAD = "STOCKS_LIST_LOAD";
const STOCKS_LIST_LOAD_CHANGE = "STOCKS_LIST_LOAD_CHANGE";

export const CURRENT_DELIVERY_TYPE_ID_CHANGE =
  "CURRENT_DELIVERY_TYPE_ID_CHANGE";

const state = {
  delivery_type: null,
  postcode_info: null,
  delivery_type_loading: false,
  project_loading: false,
  delivery_type_saving: false,
  delivery_type_errors: {},
  postcode_info_errors: {},
  project_errors: {},

  delivery_type_list_total: 0,
  postcode_info_list_total: 0,
  delivery_type_list: [],
  postcode_info_list: [],
  delivery_type_list_loading: false,
  postcode_info_list_loading: false,

  delivery_type_creating: false,
  postcoe_info_creating: false,
  project_creating: false,
  delivery_type_creating_errors: {},
  delivery_type_empty: {
    id: 0,
    name: "",
    surplus_percent: 0.00,
    is_work: true,
    stock_id: null
  },

  stocks_list_total: 0,
  stocks_list: null,

  project: null,
  project_list: null,
  project_list_total: 0,

  current_delivery_type_id: 0
};

const actions = {
  [DELIVERY_TYPE_LOAD]({ commit, state }, id) {
    if (!id) {
      state.delivery_type_empty = {
        id: 0,
        name: null,
        surplus_percent: 0.00,
        is_work: true,
        stock_id: null
      };
      commit(DELIVERY_TYPE_CHANGE, state.delivery_type_empty);
      return;
    }
    return api.load(
      "delivery_types",
      id,
      state,
      commit,
      DELIVERY_TYPE_LOADING_CHANGE,
      DELIVERY_TYPE_CHANGE
    );
  },

  [DELIVERY_TYPE_LOAD_BY_KEY]({ commit, state }, key) {
    if (!key) {
      return;
    }
    return api.load(
      "delivery_types.list_by_key",
      key,
      state,
      commit,
      DELIVERY_TYPE_DATATABLE_LOADING_CHANGE,
      DELIVERY_TYPE_DATATABLE_CHANGE
    );
  },

  [DELIVERY_TYPE_UPDATE]({ commit, state }, data) {
    let id = data.id;
    let model = data.model;

    return api.update(
      "delivery_types",
      id,
      model,
      state,
      commit,
      DELIVERY_TYPE_SAVING_CHANGE,
      DELIVERY_TYPE_CHANGE,
      DELIVERY_TYPE_ERRORS_CHANGE
    );
  },

  [DELIVERY_TYPE_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "delivery_types.list",
      id,
      model,
      state,
      commit,
      DELIVERY_TYPE_CREATING_CHANGE,
      DELIVERY_TYPE_CHANGE,
      DELIVERY_TYPE_ERRORS_CHANGE
    );
  },

  [POSTCODE_INFO_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = 0;

    return api.create(
      "postcode_info",
      id,
      model,
      state,
      commit,
      POSTCODE_INFO_CREATING_CHANGE,
      POSTCODE_INFO_CHANGE,
      POSTCODE_INFO_ERRORS_CHANGE
    );
  },

  [POSTCODE_INFO_REINDEX]({ commit, state }) {
    return api.list(
      "postcode_info_reindex",
      0,
      null,
      state,
      commit,
      POSTCODE_INFO_CHANGE,
      POSTCODE_INFO_ERRORS_CHANGE
    );
  },

  [POSTCODE_INFO_GET_ONE]({ commit, state }, data) {
    let model = data.model;
    return api.list(
      "postcode_info_get_one",
      0,
      model,
      state,
      commit,
      POSTCODE_INFO_DATATABLE_LOADING_CHANGE,
      POSTCODE_INFO_DATATABLE_CHANGE
    );
  },

  [DELIVERY_TYPE_LOAD_DATATABLE]({ commit, state }, data) {
    return api.list(
      "delivery_types.list",
      null,
      data,
      state,
      commit,
      DELIVERY_TYPE_DATATABLE_LOADING_CHANGE,
      DELIVERY_TYPE_DATATABLE_CHANGE
    );
  },
  [DELIVERY_TYPE_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.delete(
      "delivery_types",
      id,
      state,
      commit,
      DELIVERY_TYPE_DELETE_CHANGE
    );
  },
  [DT_PROJECT_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "delivery_type_projects",
      id,
      model,
      state,
      commit,
      PROJECT_CREATING_CHANGE,
      PROJECT_CHANGE,
      PROJECT_ERRORS_CHANGE
    );
  },

  [PROJECT_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "delivery_type_projects",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      PROJECT_LIST_LOAD_CHANGE
    );
  },
  [PROJECT_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.delete(
      "delivery_type_projects",
      id,
      state,
      commit,
      PROJECT_DELETE_CHANGE
    );
  },
  [STOCKS_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
        "wholesale_stocks",
        id,
        null,
        state,
        commit,
        DELIVERY_TYPE_LOADING_CHANGE,
        STOCKS_LIST_LOAD_CHANGE
    );
  },
};

const mutations = {
  [DELIVERY_TYPE_DATATABLE_LOADING_CHANGE](state, data) {
    state.delivery_type_list_loading = data;
  },
  [DELIVERY_TYPE_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.delivery_type_list = data.data;
      state.delivery_type_list_total = data.total;
    } else {
      state.delivery_type_list = [];
      state.delivery_type_list_total = 0;
    }
  },
  [POSTCODE_INFO_DATATABLE_LOADING_CHANGE](state, data) {
    state.postcode_info_list_loading = data;
  },
  [POSTCODE_INFO_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.postcode_info_list = data.data;
      state.postcode_info_list_total = data.total;
    } else {
      state.postcode_info_list = [];
      state.postcode_info_list_total = 0;
    }
  },
  [DELIVERY_TYPE_CHANGE](state, data) {
    state.delivery_type = data;

    if (data && data.id != 0) {
      if (state.delivery_type_list) {
        let list = [];
        let is_update = false;
        state.delivery_type_list.forEach(delivery_type => {
          if (delivery_type.id == data.id) {
            delivery_type = data;
            is_update = true;
          }
          list.push(delivery_type);
        });
        if (!is_update) {
          list.push(data);
        }
        state.delivery_type_list = list;
      }
    }
  },
  [STOCKS_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.stocks_list = data.data;
      state.stocks_list_total = data.total;
    } else {
      state.stocks_list = [];
      state.stocks_list_total = 0;
    }
  },
  [POSTCODE_INFO_CHANGE](state, data) {
    state.postcode_info = data;
  },
  [DELIVERY_TYPE_LOADING_CHANGE](state, data) {
    state.delivery_type_loading = data;
  },
  [DELIVERY_TYPE_SAVING_CHANGE](state, data) {
    state.delivery_type_saving = data;
  },
  [DELIVERY_TYPE_ERRORS_CHANGE](state, data) {
    state.delivery_type_errors = data;
  },
  [POSTCODE_INFO_ERRORS_CHANGE](state, data) {
    state.postcode_info_errors = data;
  },
  [DELIVERY_TYPE_CREATING_CHANGE](state, data) {
    state.delivery_type_creating = data;
  },
  [POSTCODE_INFO_CREATING_CHANGE](state, data) {
    state.postcode_info_creating = data;
  },
  [DELIVERY_TYPE_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.delivery_type_list) {
        let list = [];
        state.delivery_type_list.forEach(delivery_type => {
          if (delivery_type.id !== id) {
            list.push({ ...delivery_type });
          }
        });

        state.delivery_type_list = list;
      }
    }
  },
  [PROJECT_LOADING_CHANGE](state, data) {
    state.delivery_type_loading = data;
  },
  [PROJECT_CHANGE](state, data) {
    state.project = data;
    if (state.project_list) state.project_list.push(data);
    state.project_list_total++;
  },
  [PROJECT_CREATING_CHANGE](state, data) {
    state.project_creating = data;
  },
  [PROJECT_ERRORS_CHANGE](state, data) {
    state.project_errors = data;
  },
  [PROJECT_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.project_list = data.data;
      state.project_list_total = data.total;
    } else {
      state.project_list = [];
      state.project_list_total = 0;
    }
  },
  [PROJECT_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.project_list) {
        let list = [];
        state.project_list.forEach(project => {
          if (project.id !== id) {
            list.push({ ...project });
          }
        });

        state.project_list = list;
      }
    }
  },
  [CURRENT_DELIVERY_TYPE_ID_CHANGE](state, data) {
    state.current_delivery_type_id = data;
  }
};

export default {
  state,
  actions,
  mutations
};
