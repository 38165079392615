import http from './../../lib/http';
import api from './../../lib/api';

// Загрузить АТС с бэка по ID
export const ATS_LOAD                 = 'ATS_LOAD';

// Загрузить все АТС с бэка
export const ATS_LIST                 = 'ATS_LIST';

export const ATS_LIST_FOR_SELECT      = 'ATS_LIST_FOR_SELECT';

// Изменить АТС по ID
export const ATS_UPDATE               = 'ATS_UPDATE';

// Создать АТС
export const ATS_CREATE               = 'ATS_CREATE';

// Удалить АТС по ID
export const ATS_DELETE		          = 'ATS_DELETE';

const ATS_CHANGE                      = 'ATS_CHANGE';
const ATS_LOADING_CHANGE              = 'ATS_LOADING_CHANGE';
const ATS_SAVING_CHANGE               = 'ATS_SAVING_CHANGE';
const ATS_ERRORS_CHANGE               = 'ATS_ERRORS_CHANGE';
const ATS_CREATING_CHANGE             = 'ATS_CREATING_CHANGE';
const ATS_LIST_CHANGE                 = 'ATS_LIST_CHANGE';
const ATS_LIST_LOADING_CHANGE         = 'ATS_LIST_LOADING_CHANGE';
const ATS_DELETE_CHANGE  	          = 'ATS_DELETE_CHANGE';

const ATS_LIST_FOR_SELECT_LOADING_CHANGE = 'ATS_LIST_FOR_SELECT_LOADING_CHANGE';
const ATS_LIST_FOR_SELECT_CHANGE         = 'ATS_LIST_FOR_SELECT_CHANGE';

const state = {
    ats: null,
    ats_loading: false,
    ats_saving: false,
    ats_errors: {},

    ats_list_total: 0,
    ats_list: null,
    ats_list_loading: false,

    ats_creating: false,
    ats_creating_errors: {},

    ats_list_for_select: [],
    ats_list_for_select_loading: false,

    ats_empty: {
        id: 0,
        ip: "",
        key: "",
        name: "",
        ip_servers: null,
        description: null,
        is_work: false,
        is_default: false
    }
};

const actions = {
    [ATS_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(ATS_CHANGE, state.ats_empty);
            return;
        }

        return api.load('ats', id, state, commit, ATS_LOADING_CHANGE, ATS_CHANGE);
    },
    [ATS_LIST] ({ commit, state }, data) {
        return api.list(
            'ats.list',
            null,
            data,
            state,
            commit,
            ATS_LIST_LOADING_CHANGE,
            ATS_LIST_CHANGE);
    },
    [ATS_LIST_FOR_SELECT] ({ commit, state }, id) {
        if (!id) {
            id  = 0;
        }

        return api.list(
            'ats.get_list_for_select',
            id,
            null,
            state,
            commit,
            ATS_LIST_FOR_SELECT_LOADING_CHANGE,
            ATS_LIST_FOR_SELECT_CHANGE
        );
    },
    [ATS_CREATE] ({commit, state}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create('ats.list', id, model, state, commit, ATS_CREATING_CHANGE, ATS_CHANGE, ATS_ERRORS_CHANGE);
    },
    [ATS_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update('ats', id, model, state, commit, ATS_SAVING_CHANGE, ATS_CHANGE, ATS_ERRORS_CHANGE);
    },
    [ATS_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete('ats', id, state, commit, ATS_DELETE_CHANGE);
    },
};

const mutations = {
    [ATS_CHANGE](state, data) {
        if(data && typeof data === 'object'){
            state.ats_errors = {}; //////////////////////////////////////////////////////
            state.ats = data;
            state.ats_empty = {
                id: 0,
                ip: "",
                key: "",
                name: "",
                description: null,
                is_work: false,
                is_default: false
            };
        }
    },
    [ATS_LOADING_CHANGE](state, data) {
        state.ats_loading = data;
    },
    [ATS_LIST_LOADING_CHANGE](state, data) {
        state.ats_list_loading = data;
    },
    [ATS_LIST_CHANGE](state, data) {
        if(data) {
            state.ats_list        = data.data;
            state.ats_list_total  = data.total;
        }
        else {
            state.ats_list        = [];
            state.ats_list_total  = 0;
        }
    },

    [ATS_LIST_FOR_SELECT_LOADING_CHANGE](state, data) {
        state.ats_list_for_select_loading = data;
    },
    [ATS_LIST_FOR_SELECT_CHANGE](state, data) {
        if (data) {
            state.ats_list_for_select = data.data;
        } else {
            state.ats_list_for_select = [];
        }
    },

    [ATS_CREATING_CHANGE](state, data) {
        state.ats_creating = data;
    },
    [ATS_ERRORS_CHANGE](state, data) {
        state.ats_errors = data;
    },
    [ATS_SAVING_CHANGE](state, data) {
        state.ats_saving = data;
    },
    [ATS_DELETE_CHANGE](state, id) {
        if (id) {
            if (state.ats_list) {
                let list = [];
                state.ats_list.forEach(ats => {
                    if (ats.id !== id) {
                        list.push({...ats});
                    }
                });

                state.ats_list = list;
            }
        }
    }
};

export default {
    state,
    actions,
    mutations
}
