import http from '@/lib/http';
import api from '@/lib/api';
import Vue from 'vue';

export const ROBOT_STEPS_INFO_LOAD                     = 'ROBOT_STEPS_INFO_LOAD';
export const ROBOT_STEPS_INFO_CREATE                   = 'ROBOT_STEPS_INFO_CREATE';
export const ROBOT_STEPS_INFO_UPDATE                   = 'ROBOT_STEPS_INFO_UPDATE';
export const ROBOT_STEPS_INFO_DELETE                   = 'ROBOT_STEPS_INFO_DELETE';
export const ROBOT_STEPS_INFO_LIST                     = 'ROBOT_STEPS_INFO_LIST';
export const ROBOT_STEPS_INFO_LOAD_BY_KEY              = "ROBOT_STEPS_INFO_LOAD_BY_KEY";
export const ROBOT_STEPS_INFO_LOAD_FILE_INFO           = "ROBOT_STEPS_INFO_LOAD_FILE_INFO";

const ROBOT_STEPS_INFO_CHANGE                          = 'ROBOT_STEPS_INFO_CHANGE';
const ROBOT_STEPS_INFO_LOADING_CHANGE                  = 'ROBOT_STEPS_INFO_LOADING_CHANGE';
const ROBOT_STEPS_INFO_LIST_LOADING_CHANGE             = 'ROBOT_STEPS_INFO_LIST_LOADING_CHANGE';
const ROBOT_STEPS_INFO_LIST_CHANGE                     = 'ROBOT_STEPS_INFO_LIST_CHANGE';

const ROBOT_STEPS_INFO_SAVING_CHANGE                   = 'ROBOT_STEPS_INFO_SAVING_CHANGE';
const ROBOT_STEPS_INFO_ERRORS_CHANGE                   = 'ROBOT_STEPS_INFO_ERRORS_CHANGE';
const ROBOT_STEPS_INFO_SET_FILE_INFO                   = "ROBOT_STEPS_INFO_SET_FILE_INFO";

const state = {
    robot_steps_info: null,
    robot_steps_info_list: [],
    robot_steps_info_loading: false,
    robot_steps_info_saving: false,
    robot_steps_info_creating: false,
    robot_steps_info_errors: {},
    robot_steps_info_list_loading: false,
    robot_steps_info_files: {},
};

const actions = {
    [ROBOT_STEPS_INFO_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update('robot_steps_info', id, model, state, commit, ROBOT_STEPS_INFO_SAVING_CHANGE, ROBOT_STEPS_INFO_CHANGE, ROBOT_STEPS_INFO_ERRORS_CHANGE);
    },
    [ROBOT_STEPS_INFO_LIST] ({commit, state}, filter) {
        return api.list(
            'robot_steps_info.list',
            0,
            filter,
            state,
            commit,
            ROBOT_STEPS_INFO_LIST_LOADING_CHANGE,
            ROBOT_STEPS_INFO_LIST_CHANGE
        );
    },
    [ROBOT_STEPS_INFO_LOAD_BY_KEY]({ commit, state }, key) {
        if (!key) {
            return;
        }
        return api.load(
            "robot_steps_info.list_by_key",
            key,
            state,
            commit,
            ROBOT_STEPS_INFO_LIST_LOADING_CHANGE,
            ROBOT_STEPS_INFO_LIST_CHANGE
        );
    },
    [ROBOT_STEPS_INFO_LOAD_FILE_INFO]({ commit }, id) {
        commit(ROBOT_STEPS_INFO_SET_FILE_INFO, {
            id,
            data: null,
        });
        return http.get(`${api.main_url}/api/v2/files/get_infos/${id}`).then((response) => {
            commit(ROBOT_STEPS_INFO_SET_FILE_INFO, {
                id,
                data: response.data[0] || false,
            });
        }).catch((error)  =>  {
            commit(ROBOT_STEPS_INFO_SET_FILE_INFO, {
                id,
                data: false,
            });
            console.error(`get_infos/${id} from fs error:`, error);
        });
    },
};

const mutations = {
    [ROBOT_STEPS_INFO_SAVING_CHANGE](state, data) {
        state.robot_steps_info_saving = data;
    },
    [ROBOT_STEPS_INFO_LIST_LOADING_CHANGE] (state, data) {
        state.robot_steps_info_list_loading = data;
    },
    [ROBOT_STEPS_INFO_LIST_CHANGE] (state, data) {
        if(data) {
            state.robot_steps_info_list        = data.data;
        }
        else {
            state.robot_steps_info_list        = [];
        }
    },
    [ROBOT_STEPS_INFO_LOADING_CHANGE] (state, data) {
        state.ats_group_loading = data;
    },
    [ROBOT_STEPS_INFO_CHANGE] (state, data) {
        if(data && typeof data === 'object'){
            state.robot_steps_info_errors = {};
            state.robot_steps_info = data;
        }
    },
    [ROBOT_STEPS_INFO_ERRORS_CHANGE](state, data) {
        state.robot_steps_info_errors = data;
    },
    [ROBOT_STEPS_INFO_SET_FILE_INFO](state, { id, data }) {
        Vue.set(state.robot_steps_info_files, id, data);
    },
};

export default {
    state,
    actions,
    mutations
}
