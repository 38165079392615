import api from './../../lib/api';

export const INTERPRETATION_LOAD                 = 'INTERPRETATION_LOAD';
export const INTERPRETATION_UPDATE               = 'INTERPRETATION_UPDATE';
export const INTERPRETATION_CREATE               = 'INTERPRETATION_CREATE';

export const INTERPRETATION_LOAD_DATATABLE       = 'INTERPRETATION_LOAD_DATATABLE';

const INTERPRETATION_CHANGE                      = 'INTERPRETATION_CHANGE';
const INTERPRETATION_LOADING_CHANGE              = 'INTERPRETATION_LOADING_CHANGE';
const INTERPRETATION_SAVING_CHANGE               = 'INTERPRETATION_SAVING_CHANGE';
const INTERPRETATION_ERRORS_CHANGE               = 'INTERPRETATION_ERRORS_CHANGE';
const INTERPRETATION_CREATE_CHANGE               = 'INTERPRETATION_CREATE_CHANGE';

const INTERPRETATION_DATATABLE_CHANGE            = 'INTERPRETATION_DATATABLE_CHANGE';
const INTERPRETATION_DATATABLE_LOADING_CHANGE    = 'INTERPRETATION_DATATABLE_LOADING_CHANGE';

const state = {
    interpretation            : null,
    interpretation_loading    : false,
    interpretation_saving     : false,
    interpretation_errors     : {},
    interpretation_list_total    : 0,
    interpretation_list          : null,
    interpretation_list_loading  : false,
    interpretation_empty         : {
        id: 0,
        name: '',
        description: '',
        interpretation: '',
        min_point: 0,
        max_point: 0,
        key: '',
        is_work: true,
        view_type: 'text',
        level_type: 2,
        options: null,
        competence_id: null,
        organization_id: null,
        competence_group_id: null,
    }
};

const actions = {
    [INTERPRETATION_LOAD] ({ commit, state }, id) {
        if (!id) {
            commit(INTERPRETATION_CHANGE, _.cloneDeep(state.interpretation_empty));
            return;
        }
        return api.load(
            'interpretation',
            id,
            state,
            commit,
            INTERPRETATION_LOADING_CHANGE,
            INTERPRETATION_CHANGE
        )
    },

    [INTERPRETATION_UPDATE] ({ commit, state }, {id, data}) {

        return api.update(
            'interpretation',
            id,
            data,
            state,
            commit,
            INTERPRETATION_SAVING_CHANGE,
            INTERPRETATION_CHANGE,
            INTERPRETATION_ERRORS_CHANGE
        );
    },

    [INTERPRETATION_CREATE] ({ commit, state }, data) {
        let id = data.id ? data.id : 0;

        return api.create(
            'interpretations',
            id,
            data,
            state,
            commit,
            INTERPRETATION_SAVING_CHANGE,
            INTERPRETATION_CREATE_CHANGE,
            INTERPRETATION_ERRORS_CHANGE
        );
    },

    [INTERPRETATION_LOAD_DATATABLE] ({ commit, state }, data) {
        let params  = data.params;
        let id  = data.id ? data.id : null;
        return api.list(
            'interpretations',
            id,
            params,
            state,
            commit,
            INTERPRETATION_DATATABLE_LOADING_CHANGE,
            INTERPRETATION_DATATABLE_CHANGE
        );
    },
};

const mutations = {
    [INTERPRETATION_DATATABLE_LOADING_CHANGE](state, data) {
        state.interpretation_list_loading = data;
    },
    [INTERPRETATION_DATATABLE_CHANGE](state, data) {

        if (data) {
            state.interpretation_list          = data.data;
            state.interpretation_list_total    = data.total;
        } else {
            state.interpretation_list          = [];
            state.interpretation_list_total    = 0;
        }

    },
    [INTERPRETATION_CHANGE](state, data) {
        state.interpretation = data;
    },
    [INTERPRETATION_LOADING_CHANGE](state, data) {
        state.interpretation_loading = data;
    },
    [INTERPRETATION_SAVING_CHANGE](state, data) {
        state.interpretation_saving   = data;
    },
    [INTERPRETATION_ERRORS_CHANGE](state, data) {
        state.interpretation_errors   = data;
    },
    [INTERPRETATION_CREATE_CHANGE](state, data) {
        state.interpretation = data;
    },
};

export default {
    state,
    actions,
    mutations
}
