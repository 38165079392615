require("./bootstrap");
require("./components");

import get_main_url from './lib/get_main_url';
import log from "./lib/log";
import http from "./lib/http";
import App from "./views/app/App";
import router from "./routes";
import store from "./store";
import Vue from "vue";

Vue.config.errorHandler = log.error
Vue.config.productionTip = false;

Vue.prototype.$main_url = get_main_url.mainUrl;
Vue.prototype.$login_main_url = get_main_url.loginMainUrl;
Vue.prototype.$recaptcha_key = process.env.VUE_APP_RECAPTCHA_SITEKEY || 'empty';

if (!Vue.prototype.$main_url) {
    alert("For developers\n\nPlease make file .env.development in project root directory with content\n\tVUE_APP_MAIN_URL = 'http://crmka.back.test'\nand restart npm run serve")
    throw 'VUE_APP_MAIN_URL is undefined'
}

Vue.prototype.$langs = {
    ru: {
        title: "Русский",
        for_lang: "Для русского языка",
        sign: "RU",
    },
    en: {
        title: "English",
        for_lang: "For english",
        sign: "EN",
    },
};

http.setStore(store);

new Vue({
  router,
  store,
  template: "<App/>",
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  if (to.fullPath == '/') {
    // редирект с корня сайта
    console.log('transition from the main');
    if (store.state.users.auth_user && store.state.users.auth_user.mainlink != '/') {
      router.push(store.state.users.auth_user.mainlink);
    } else {
      router.push('/orders');
    }
  } else {
    document.title = to.meta.label;
    next();
  }
});

router.afterEach((to, from) => {
if(store.state.users.auth_user){
    if(store.state.users.auth_user.lang != 'ru' && to.meta['label_'+store.state.users.auth_user.lang]){
        document.title = to.meta['label_'+store.state.users.auth_user.lang];
    }
}
})
