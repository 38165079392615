import http from './../../lib/http';
import api from './../../lib/api';

export const DELIVERY_DPD_LOAD_DATATABLE       = 'DELIVERY_DPD_LOAD_DATATABLE';

const DELIVERY_DPD_DATATABLE_LOADING_CHANGE    = 'DELIVERY_DPD_DATATABLE_LOADING_CHANGE';
const DELIVERY_DPD_DATATABLE_CHANGE            = 'DELIVERY_DPD_DATATABLE_CHANGE';

const state = {
    delivery_dpd_list           : null,
    delivery_dpd_list_total     : 0,
    delivery_dpd_list_loading   : false
};

const actions = {
    [DELIVERY_DPD_LOAD_DATATABLE] ({ commit, state }, filter) {
        return api.list(
            'delivery_dpd_report',
            null,
            filter,
            state,
            commit,
            DELIVERY_DPD_DATATABLE_LOADING_CHANGE,
            DELIVERY_DPD_DATATABLE_CHANGE);
    }
};
const mutations = {
    [DELIVERY_DPD_DATATABLE_LOADING_CHANGE](state, data) {
        state.delivery_dpd_list_loading = data;
    },
    [DELIVERY_DPD_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.delivery_dpd_list        = data.data;
            state.delivery_dpd_list_total  = data.total;
        }
        else {
            state.delivery_dpd_list        = [];
            state.delivery_dpd_list_total  = 0;
        }
    }
};

export default {
    state,
    actions,
    mutations
}
