import api from './../../lib/api';

export const REPORT_SETTING_LIST = 'REPORT_SETTING_LIST'
export const REPORT_SETTING_LOAD = 'REPORT_SETTING_LOAD'
export const REPORT_SETTING_UPDATE = 'REPORT_SETTING_UPDATE'
export const REPORT_SETUP_CALL_CENTER_V2 = 'REPORT_SETUP_CALL_CENTER_V2'
export const REPORT_SETUP_CALL_CENTER_V2_DELETE = 'REPORT_SETUP_CALL_CENTER_V2_DELETE'
export const REPORT_SETUP_CALL_CENTER_V2_LOAD = 'REPORT_SETUP_CALL_CENTER_V2_LOAD'
export const REPORT_SETUP_CALL_CENTER_V2_ORGS_LOAD = 'REPORT_SETUP_CALL_CENTER_V2_ORGS_LOAD'
export const REPORT_CALL_CENTER_V2_DATA = 'REPORT_CALL_CENTER_V2_DATA'
export const REPORT_GET_CALC_DATA = 'REPORT_GET_CALC_DATA'

const REPORT_SETTING_LOADED = 'REPORT_SETTING_LOADED'
const REPORT_SETTING_LIST_LOADING = 'REPORT_SETTING_LIST_LOADING'
const REPORT_SETTING_LOADING = 'REPORT_SETTING_LOADING'
const REPORT_SETTING_LIST_LOADED = 'REPORT_SETTING_LIST_LOADED'
const REPORT_SETTING_CHANGE = 'REPORT_SETTING_CHANGE'
const REPORT_SETTING_UPDATING = 'REPORT_SETTING_UPDATING'
const REPORT_SETTING_ERRORS = 'REPORT_SETTING_ERRORS'
const REPORT_SETTING_CALC_ERRORS = 'REPORT_SETTING_CALC_ERRORS'

const REPORT_SETTING_CALL_CENTER_V2_UPDATING = 'REPORT_SETTING_CALL_CENTER_V2_UPDATING'
const REPORT_SETTING_CALL_CENTER_V2_CHANGE = 'REPORT_SETTING_CALL_CENTER_V2_CHANGE'
const REPORT_SETTING_CALL_CENTER_V2_ERRORS = 'REPORT_SETTING_CALL_CENTER_V2_ERRORS'
const REPORT_SETTING_CALL_CENTER_V2_LOADING = 'REPORT_SETTING_CALL_CENTER_V2_LOADING'
const REPORT_SETTING_CALL_CENTER_V2_LOADED = 'REPORT_SETTING_CALL_CENTER_V2_LOADED'
const REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADING = 'REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADING'
const REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADED = 'REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADED'

const REPORT_CALL_CENTER_V2_DATA_LOADING = 'REPORT_CALL_CENTER_V2_DATA_LOADING'
const REPORT_CALL_CENTER_V2_DATA_LOADED = 'REPORT_CALL_CENTER_V2_DATA_LOADED'

const REPORT_SETTING_CALC_LOADING_CHANGE = 'REPORT_SETTING_CALC_LOADING_CHANGE'
const REPORT_SETTING_CALC_CHANGE = 'REPORT_SETTING_CALC_CHANGE'

const state = {
    report_setting: {},
    report_setting_list: [],
    report_setting_errors: {},
    report_setting_updating: false,
    report_setting_loading: false,
    report_setting_list_loading: false,
    report_setting_call_center_v2_updating: false,
    report_setting_call_center_v2_loading: false,
    report_setting_call_center_v2_orgs_loading: false,
    call_center_v2_data_loading: false,
    report_setting_call_center_v2: {},
    report_setting_call_center_orgs_v2: {},
    call_center_data_v2: {},
    report_setting_update_call_center_v2:{},
    report_setting_call_center_v2_errors: {},
    calc_list: {},
    calc_list_loading: {},
    calc_errors: {}
}

const actions = {
    [REPORT_SETTING_LOAD] ({commit}, path) {
        return api.load(
            'report_settings',
            path,
            null,
            commit,
            REPORT_SETTING_LOADING,
            REPORT_SETTING_LOADED
        );
    },
    [REPORT_SETTING_LIST] ({commit}, path) {
        return api.load(
            'report_settings',
            path,
            null,
            commit,
            REPORT_SETTING_LIST_LOADING,
            REPORT_SETTING_LIST_LOADED
        );
    },
    [REPORT_SETTING_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'report_settings',
            id,
            model,
            state,
            commit,
            REPORT_SETTING_UPDATING,
            REPORT_SETTING_CHANGE,
            REPORT_SETTING_ERRORS);
    },
    [REPORT_SETUP_CALL_CENTER_V2]({commit, state}, data) { 
        let id = data.id;
        let model = data.model;
        return api.update(
            'report_settings_call_center_v2',
            id,
            model,
            state,
            commit,
            REPORT_SETTING_CALL_CENTER_V2_UPDATING,
            REPORT_SETTING_CALL_CENTER_V2_CHANGE,
            REPORT_SETTING_CALL_CENTER_V2_ERRORS);
    },
    [REPORT_SETUP_CALL_CENTER_V2_LOAD] ({commit}, path) {
        return api.load(
            'report_settings_call_center_v2',
            path,
            null,
            commit,
            REPORT_SETTING_CALL_CENTER_V2_LOADING,
            REPORT_SETTING_CALL_CENTER_V2_LOADED
        );
    },
    [REPORT_SETUP_CALL_CENTER_V2_ORGS_LOAD] ({commit}, path) {
        return api.load(
            'report_settings_call_center_v2_orgs',
            path,
            null,
            commit,
            REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADING,
            REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADED
        );
    },
    [REPORT_CALL_CENTER_V2_DATA] ({commit}, path) {
        return api.load(
            'call_center_v2_data',
            path,
            null,
            commit,
            REPORT_CALL_CENTER_V2_DATA_LOADING,
            REPORT_CALL_CENTER_V2_DATA_LOADED
        );
    },
    [REPORT_SETUP_CALL_CENTER_V2_DELETE]({ commit, state }, id) {
        if (!id) {
            id = 0;
        }
        return api.delete(
            "report_settings_call_center_v2", 
            id, 
            state, 
            commit, 
            REPORT_SETTING_CALL_CENTER_V2_CHANGE
        );
    },
    [REPORT_GET_CALC_DATA]({ commit, state }, key) { 
        if (!key) {
            return;
        }
        return api.list(
            'report_settings_get_calc',
            null, 
            key,
            state, 
            commit, 
            REPORT_SETTING_CALC_LOADING_CHANGE, 
            REPORT_SETTING_CALC_CHANGE,
            REPORT_SETTING_CALC_ERRORS);
    },
}

const mutations = {
    [REPORT_SETTING_LOADED] (state, data) {
        if (data) {
            state.report_setting = data
        } else {
            state.report_setting = {}
        }
    },
    [REPORT_SETTING_CALL_CENTER_V2_LOADED] (state, data) {
        if (data) {
            state.report_setting_call_center_v2 = data
        } else {
            state.report_setting_call_center_v2 = []
        }
    },
    [REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADED] (state, data) {
        if (data) {
            state.report_setting_call_center_orgs_v2 = data
        } else {
            state.report_setting_call_center_orgs_v2 = []
        }
    },
    [REPORT_CALL_CENTER_V2_DATA_LOADED] (state, data) {
        if (data) {
            state.call_center_data_v2 = data
        } else {
            state.call_center_data_v2 = []
        }
    },
    [REPORT_SETTING_LIST_LOADED] (state, data) {
        if (data) {
            state.report_setting_list = data
        } else {
            state.report_setting_list = []
        }
    },
    [REPORT_SETTING_CHANGE] (state, data) {
        state.report_setting_errors = {};
        if(data && typeof data === 'object'){
            state.report_setting = data;
        } else {
            state.report_setting = {};
        }
    },
    [REPORT_SETTING_CALL_CENTER_V2_CHANGE] (state, data) {
        state.report_setting_call_center_v2_errors = {};
        if(data && typeof data === 'object'){
            state.report_setting_update_call_center_v2 = data;
        } else {
            state.report_setting_update_call_center_v2 = {};
        }
    },
    [REPORT_SETTING_ERRORS] (state, data) {
        state.report_setting_errors = data;
    },
    [REPORT_SETTING_CALL_CENTER_V2_ERRORS] (state, data) {
        state.report_setting_call_center_v2_errors = data;
    },
    [REPORT_SETTING_UPDATING] (state, data) {
        state.report_setting_updating = data;
    },
    [REPORT_SETTING_CALL_CENTER_V2_UPDATING] (state, data) {
        state.report_setting_call_center_v2_updating = data;
    },
    [REPORT_SETTING_LIST_LOADING] (state, data) {
        state.report_setting_list_loading = data;
    },
    [REPORT_SETTING_CALL_CENTER_V2_LOADING] (state, data) {
        state.report_setting_call_center_v2_loading = data;
    },
    [REPORT_SETTING_CALL_CENTER_V2_ORGS_LOADING] (state, data) {
        state.report_setting_call_center_v2_orgs_loading = data;
    },
    [REPORT_CALL_CENTER_V2_DATA_LOADING] (state, data) {
        state.call_center_v2_data_loading = data;
    },
    [REPORT_SETTING_LOADING] (state, data) {
        state.report_setting_loading = data;
    },
    [REPORT_SETTING_CALC_LOADING_CHANGE](state, data) {
        state.calc_list_loading = data;
    },    
    [REPORT_SETTING_CALC_CHANGE](state, data) {
        if (data) {
          state.calc_list = data.data;
        } else {
          state.calc_list = {};
        }
    },    
    [REPORT_SETTING_CALC_ERRORS](state, data) {
        state.calc_errors = data;
    },
}

export default {
    state,
    actions,
    mutations
}