import api from './../../lib/api';

export const TAGS_DB_LOAD_DATATABLE                     = 'TAGS_DB_LOAD_DATATABLE';
export const TAGS_DB_LOAD_DATATABLE_BY_INDUSTRIES       = 'TAGS_DB_LOAD_DATATABLE_BY_INDUSTRIES';
export const TAGS_DB_LOAD_DATATABLE_BY_INDUSTRY_ID      = 'TAGS_DB_LOAD_DATATABLE_BY_INDUSTRY_ID';
export const TAG_LOAD       = 'TAG_LOAD';
export const TAG_UPDATE       = 'TAG_UPDATE';
export const TAG_CREATE       = 'TAG_CREATE';

const TAGS_DB_DATATABLE_LOADING_CHANGE = 'TAGS_DB_DATATABLE_LOADING_CHANGE';
const TAGS_DB_DATATABLE_CHANGE = 'TAGS_DB_DATATABLE_CHANGE';
const TAG_LOADING_CHANGE = 'TAG_LOADING_CHANGE';
const TAG_CHANGE = 'TAG_CHANGE';
const TAG_CREATE_CHANGE = 'TAG_CREATE_CHANGE';
const TAG_SAVING_CHANGE = 'TAG_SAVING_CHANGE';
const TAG_ERRORS_CHANGE = 'TAG_ERRORS_CHANGE';

const state = {    
    tags_list           : [],
    tags_list_loading   : false,
    tags_errors:{},
    tag            : {},
    tag_loading    : false,
    tag_saving     : false,
    tag_errors     : {},
    tag_empty        : {
        name: '',
        type: null,
        industries: [],
        industry_ids: [],
    },
};

const actions = {
    [TAGS_DB_LOAD_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'tags', 0, data, state, commit, TAGS_DB_DATATABLE_LOADING_CHANGE, TAGS_DB_DATATABLE_CHANGE
        );
    },
    [TAGS_DB_LOAD_DATATABLE_BY_INDUSTRIES] ({ commit, state }, data) {
        return api.list(
            'tags.industries', 0, data, state, commit, TAGS_DB_DATATABLE_LOADING_CHANGE, TAGS_DB_DATATABLE_CHANGE
        );
    },
    [TAGS_DB_LOAD_DATATABLE_BY_INDUSTRY_ID] ({ commit, state }, data) {
        return api.list(
            'tags.industries.id', data.id, data, state, commit, TAGS_DB_DATATABLE_LOADING_CHANGE, TAGS_DB_DATATABLE_CHANGE
        );
    },
    [TAG_LOAD] ({ commit, state }, id) {
        if (!id) {
            commit(TAG_CHANGE, _.cloneDeep(state.tag_empty));
            return;
        }
        return api.load(
            'tag.load', id, state, commit, TAG_LOADING_CHANGE, TAG_CHANGE
        );
    },
    [TAG_UPDATE] ({ commit, state }, {id, data}) {
        return api.update(
            'tag.update', id, data, state, commit, TAG_SAVING_CHANGE, TAG_CHANGE, TAG_ERRORS_CHANGE
        );
    },
    [TAG_CREATE] ({ commit, state }, data) {
        return api.create(
            'tag.create', 0,  data, state,  commit, TAG_SAVING_CHANGE, TAG_CREATE_CHANGE, TAG_ERRORS_CHANGE
        );
    },
};

const mutations = {
    [TAGS_DB_DATATABLE_LOADING_CHANGE](state, data) {
        state.tags_list_loading = data;
    },
    [TAGS_DB_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.tags_list        = data.data;
        }
        else {
            state.tags_list        = [];
        }
    },
    [TAG_LOADING_CHANGE](state, data) {
        state.tag_loading = data;
    },
    [TAG_CHANGE](state, data) {
        state.tag = data;
    },
    [TAG_SAVING_CHANGE](state, data) {
        state.tag_saving   = data;
    },
    [TAG_ERRORS_CHANGE](state, data) {
        state.tag_errors   = data;
    },
    [TAG_CREATE_CHANGE](state, data) {
        state.tag = data;
    },
};

export default {
    state,
    actions,
    mutations
}
