import http from "./../../lib/http";
import api from "./../../lib/api";

export const ACCORD_CREATE = "ACCORD_CREATE";

const ACCORD_CREATING_CHANGE = "ACCORD_CREATING_CHANGE";
const ACCORD_CHANGE = "ACCORD_CHANGE";
const ACCORD_ERRORS_CHANGE = "ACCORD_ERRORS_CHANGE";

export const SEND_DPD_CREATE = "SEND_DPD_CREATE";
export const ORDER_LOAD_CREATE = "ORDER_LOAD_CREATE";
export const ORDER_LOAD_STOP = 'ORDER_LOAD_STOP';

const state = {
  accord: null,
  accord_errors: {},
  accord_creating:false
};

const actions = {
  [ACCORD_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "accord.create",
      id,
      model,
      state,
      commit,
      ACCORD_CREATING_CHANGE,
      ACCORD_CHANGE,
      ACCORD_ERRORS_CHANGE
    );
  },
  [SEND_DPD_CREATE]({ commit, state }, data) {
    let model = data.model;

    return api.create(
      "send_dpd_create",
      0,
      model,
      state,
      commit,
      ACCORD_CREATING_CHANGE,
      ACCORD_CHANGE,
      ACCORD_ERRORS_CHANGE
    );
  },
  [ORDER_LOAD_CREATE]({ commit, state }, data) {
    let model = data.model;
    return api.create(
      "order_load",
      0,
      model,
      state,
      commit,
      ACCORD_CREATING_CHANGE,
      ACCORD_CHANGE,
      ACCORD_ERRORS_CHANGE
    );
  },
    [ORDER_LOAD_STOP]({commit, state}, load_id) {
        let id = load_id;
        let model = null;
        return api.update('order_load_stop', id, model, state, commit, ACCORD_CREATING_CHANGE, ACCORD_CHANGE, ACCORD_ERRORS_CHANGE);
    }
};

const mutations = {
  [ACCORD_CHANGE](state, data) {
    if(data) {
      state.accord        = data.data;
    }
    else {
      state.accord        = [];
    }
  },
  [ACCORD_CREATING_CHANGE](state, data) {
    state.accord_creating = data;
  },
  [ACCORD_ERRORS_CHANGE](state, data) {
    state.accord        = [];
    state.accord_errors = data;
  },
};

export default {
  state,
  actions,
  mutations
};
