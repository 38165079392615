import api from './../../lib/api';

export const SALE_STATUSES_LIST_LOAD        = "SALE_STATUSES_LIST_LOAD";
export const SALE_STATUS_LOAD               = "SALE_STATUS_LOAD";
export const SALE_STATUS_CREATE             = "SALE_STATUS_CREATE";
export const SALE_STATUS_UPDATE             = "SALE_STATUS_UPDATE";

const SALE_STATUSES_LIST_CHANGE             = "SALE_STATUSES_LIST_CHANGE";
const SALE_STATUSES_LIST_LOADING_CHANGE     = "SALE_STATUSES_LIST_LOADING_CHANGE";

const SALE_STATUS_CHANGE                    = "SALE_STATUS_CHANGE";
const SALE_STATUS_LOADING_CHANGE            = "SALE_STATUS_LOADING_CHANGE";
const SALE_STATUS_ERRORS_CHANGE             = "SALE_STATUS_ERRORS_CHANGE";
const SALE_STATUS_SAVING_CHANGE             = "SALE_STATUS_SAVING_CHANGE";

const SALE_STATUS_CREATING_CHANGE           = "SALE_STATUS_CREATING_CHANGE";
const SALE_STATUS_CREATING_ERRORS_CHANGE    = "SALE_STATUS_CREATING_ERRORS_CHANGE";

export const SALE_STATUS_DELETE		        = 'SALE_STATUS_DELETE';
export const SALE_STATUS_DELETE_CHANGE  	= 'SALE_STATUS_DELETE_CHANGE';

const state = {
    sale_status: null,
    sale_status_loading: false,
    sale_status_saving: false,
    sale_status_errors: {},

    sale_status_list: null,
    sale_status_list_loading: false,

    sale_status_creating: false,
    sale_status_creating_errors: {},
    sale_status_empty: {
        id: 0,
        name: "",
        is_work: false,
        desc:"",
        organization_id: ""
    }
};

const actions = {
    [SALE_STATUSES_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'sale_statuses',
            id,
            params,
            state,
            commit,
            SALE_STATUSES_LIST_LOADING_CHANGE,
            SALE_STATUSES_LIST_CHANGE,
        );
    },


    [SALE_STATUS_LOAD]({state, commit}, id) {
        if (!id) {
            commit(SALE_STATUS_CHANGE, null);
            return
        }

        return api.load(
            'sale_status',
            id,
            state,
            commit,
            SALE_STATUS_LOADING_CHANGE,
            SALE_STATUS_CHANGE,
        );
    },
    [SALE_STATUS_CREATE]({state, commit}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create(
            'sale_statuses',
            id,
            model,
            state,
            commit,
            SALE_STATUS_CREATING_CHANGE,
            SALE_STATUS_CHANGE,
            SALE_STATUS_CREATING_ERRORS_CHANGE,
        );
    },
    [SALE_STATUS_UPDATE]({state, commit}, data) {
        let id = data.id;
        let model = data.model;

        return api.update(
            'sale_status',
            id,
            model,
            state,
            commit,
            SALE_STATUS_SAVING_CHANGE,
            SALE_STATUS_CHANGE,
            SALE_STATUS_ERRORS_CHANGE,
        );
    },
    [SALE_STATUS_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'sale_status',
            id,
            state,
            commit,
            SALE_STATUS_DELETE_CHANGE,
            SALE_STATUS_CHANGE,
            SALE_STATUS_ERRORS_CHANGE,
        );
    },
};

const mutations = {
    [SALE_STATUSES_LIST_CHANGE](state, data) {
        if (data) {
            state.sale_status_list = data.data ? data.data : data;
        }
    },
    [SALE_STATUSES_LIST_LOADING_CHANGE](state, data) {
        state.sale_status_list_loading = data;
    },
    [SALE_STATUS_CHANGE](state, data) {
        if (data) {
            state.sale_status = data;
            return;
        }
        state.sale_status = state.sale_status_empty;
    },
    [SALE_STATUS_LOADING_CHANGE](state, data) {
        state.sale_status_loading = data;
    },
    [SALE_STATUS_ERRORS_CHANGE](state, data) {
        state.sale_status_errors = data;
    },
    [SALE_STATUS_SAVING_CHANGE](state, data) {
        state.sale_status_saving = data;
    },
    [SALE_STATUS_CREATING_CHANGE](state, data) {
        state.sale_status_creating = data;
    },
    [SALE_STATUS_CREATING_ERRORS_CHANGE](state, data) {
        state.sale_status_creating_errors = data;
    },
    [SALE_STATUS_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.sale_status_list) {

                let list = [];
                state.sale_status_list.forEach(sale_status => {
                    if (sale_status.id !== id) {
                        list.push({...sale_status});
                    }
                });

                state.sale_status_list = list;
            }
        }
    },
};

export default {
    state,
    actions,
    mutations,
}