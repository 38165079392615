import api from './../../lib/api';

export const RB_ACTION_DASHBOARDS_LOAD = 'RB_ACTION_DASHBOARDS_LOAD';
const RB_MUTATION_DASHBOARDS_LOADING = 'RB_MUTATION_DASHBOARDS_LOADING';
const RB_MUTATION_DASHBOARDS_LOADED = 'RB_MUTATION_DASHBOARDS_LOADED';
const RB_MUTATION_DASHBOARDS_LOAD_ERRORS = 'RB_MUTATION_DASHBOARDS_LOAD_ERRORS';

export const RB_ACTION_DASHBOARD_LOAD = 'RB_ACTION_DASHBOARD_LOAD';
const RB_MUTATION_DASHBOARD_LOADING = 'RB_MUTATION_DASHBOARD_LOADING';
const RB_MUTATION_DASHBOARD_LOADED = 'RB_MUTATION_DASHBOARD_LOADED';
const RB_MUTATION_DASHBOARD_LOAD_ERRORS = 'RB_MUTATION_DASHBOARD_LOAD_ERRORS';

const state = {
  dashboards: {
    items: [],
    total: 0,
    loading: false
  },
  dashboard: {
    id: 0,
    name: '',
    description: '',
    reports: []
  },
  dashboardLoading: false
}

const actions = {
  /**
   * Загрузить дашборды
   * @param {Object} params 
   * @returns 
   */
  [RB_ACTION_DASHBOARDS_LOAD]({commit,state},params) {
    return api.list(
      'rb.dashboards.load',
      null,
      params,
      state,
      commit,
      RB_MUTATION_DASHBOARDS_LOADING,
      RB_MUTATION_DASHBOARDS_LOADED,
      RB_MUTATION_DASHBOARDS_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить дашборд
   * @param {Object} params 
   * @returns 
   */
  [RB_ACTION_DASHBOARD_LOAD]({commit,state},{dashboardId, params}) {
    return api.list(
      'rb.dashboards.dashboard.load',
      dashboardId,
      params,
      state,
      commit,
      RB_MUTATION_DASHBOARD_LOADING,
      RB_MUTATION_DASHBOARD_LOADED,
      RB_MUTATION_DASHBOARD_LOAD_ERRORS
    ); 
  },
}

const mutations = {
  [RB_MUTATION_DASHBOARDS_LOADING](state, status) {
    state.dashboards.loading = status
  },
  [RB_MUTATION_DASHBOARDS_LOADED](state, data) {
    if (data) {
      state.dashboards.items = data.data
      state.dashboards.total = data.total
    }
  },
  [RB_MUTATION_DASHBOARDS_LOAD_ERRORS]() {},

  [RB_MUTATION_DASHBOARD_LOADING](state, status) {
    state.dashboardLoading = status
  },
  [RB_MUTATION_DASHBOARD_LOADED](state, data) {
    if (data) {
      state.dashboard = data.data
    }
  },
  [RB_MUTATION_DASHBOARD_LOAD_ERRORS]() {},
}

export default {
  state,
  actions,
  mutations,
}