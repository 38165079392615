import http from './../../lib/http';
import api from './../../lib/api';

export const SMS_LIST                 = 'SMS_LIST';

export const SMS_LOAD                 = 'SMS_LOAD';
export const SMS_UPDATE               = 'SMS_UPDATE';
export const SMS_CREATE               = 'SMS_CREATE';
export const SMS_CREATE_MASS          = 'SMS_CREATE_MASS';

export const SMS_LOAD_DATATABLE       = 'SMS_LOAD_DATATABLE';

export const SMS_CHANGE               = 'SMS_CHANGE';
const SMS_LOADING_CHANGE              = 'SMS_LOADING_CHANGE';
const SMS_SAVING_CHANGE               = 'SMS_SAVING_CHANGE';
const SMS_ERRORS_CHANGE               = 'SMS_ERRORS_CHANGE';

const SMS_CREATING_CHANGE             = 'SMS_CREATING_CHANGE';

const SMS_DATATABLE_CHANGE            = 'SMS_DATATABLE_CHANGE';
const SMS_DATATABLE_LOADING_CHANGE    = 'SMS_DATATABLE_LOADING_CHANGE';

export const SMS_DELETE		          = 'SMS_DELETE';
export const SMS_DELETE_CHANGE        = 'SMS_DELETE_CHANGE';

const state = {
    sms            : [],
    sms_provider         : null,

    sms_loading    : false,
    sms_saving     : false,
    sms_errors     : {},

    sms_list_total     : 0, 
    sms_total          : 0,
    sms_list           : {},
    sms_list_loading   : false,

    sms_creating           : false,
    sms_creating_errors    : {},
    sms_empty              : {
                                  id: 0,
                                  name: "",
                                  code: ""
                              }
};

const actions = {
    [SMS_LOAD] ({ commit, state }, key) {

        if(!key) {
	    state.sms_empty = {key:0, name: null, sms:null, data:[]};
            commit(SMS_CHANGE, state.sms_empty);

            return;
        }   
        state.sms_provider = key;    
        return api.load('sms', key, state, commit, SMS_LOADING_CHANGE, SMS_CHANGE);
    },

    [SMS_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;        
        return api.update('sms', id, model, state, commit, SMS_SAVING_CHANGE, SMS_CHANGE, SMS_ERRORS_CHANGE);
    },

    [SMS_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = 0;

        return api.create('sms.list', id, model, state, commit, SMS_CREATING_CHANGE, SMS_CHANGE, SMS_ERRORS_CHANGE);
    },

    [SMS_CREATE_MASS] ({ commit, state }, data) {
        let model   = data.model;
        let id      = 0;

        return api.create('sms.list_mass', id, model, state, commit, SMS_CREATING_CHANGE, SMS_CHANGE, SMS_ERRORS_CHANGE);
    },

    [SMS_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'sms',
            id, 
            null,
            state, 
            commit, 
            SMS_DATATABLE_LOADING_CHANGE, 
            SMS_DATATABLE_CHANGE);
    },
    [SMS_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'sms',
            id,
            state,
            commit,
            SMS_DELETE_CHANGE
        );
    },


};

const mutations = {
    [SMS_DATATABLE_LOADING_CHANGE](state, data) {
        state.sms_list_loading = data;
    },
    [SMS_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.sms_list        = data.data;
            state.sms_list_total  = data.total;
        }
        else {
            state.sms_list        = [];
            state.sms_list_total  = 0;
        }        
    },
    [SMS_CHANGE](state, data) {                
        if(data) {
            state.sms        = data.data;            
        }
        else {
            state.sms        = [];            
        }                        
    },
    [SMS_LOADING_CHANGE](state, data) {
        state.sms_loading = data;
    },
    [SMS_SAVING_CHANGE](state, data) {
        state.sms_saving   = data;
    },
    [SMS_ERRORS_CHANGE](state, data) {                
        state.sms_errors   = data;
    },
    [SMS_CREATING_CHANGE](state, data) {        
        state.sms_creating = data;
    },
    [SMS_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.sms) {

                let list = [];
                state.sms.forEach(sms => {
                    if (sms.id !== id) {
                        list.push({...sms});
                    }
                });

                state.sms = list;
            }
        }
    },


};

export default {
    state,
    actions,
    mutations
}
