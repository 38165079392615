/**
 * Блок разметки диалога
 *
 * @type   {Object}
 * @author axsmak
 */
export default class DialogMark {

  /**
  * Идентификатор блока
  *
  * - Для интентов - их ID в БД
  * - Для NLU-интента, при отсутствии в БД - его name
  * - Для остальных - случайно сгенерированная строка
  *
  * @type {String|Number}
  */
  id = generateUid();

  /**
  * Тип
  *
  * - blank        Неразмеченный блок. Полностью неразмеченный диалог изначально состоит из одного блока этого типа
  * - client       Фраза клиента
  * - operator     Фраза оператора (бота)
  * - intent       NLU-интент
  * - bot_intent   Сценарный интент
  * - screen       Экран бота. ! Идентификатор блока - не является ID экрана в БД!
  *
  * @type {String}
  */
  type = 'blank';

  /**
  * Название блока
  *
  * Для интентов и экранов - их названия
  * Для других отсутствует
  *
  * @type {String|null}
  */
  title = null;

  /**
   * Позиция начала блока в тексте диалога
   *
   * @type {Number}
   */
  from = 0;

  /**
  * Длина текста блока
  *
  * @type {Number}
  */
  size = 0;

  /**
  * ID родительского блока
  *
  * @type {String}
  */
  parentBlock = undefined;

  /**
   * Подтверждение теста NLU пользователем
   *
   * - name     String     Имя интента
   * - accept   Boolean    Подтверждает ли пользователь интент
   *
   * @type {Object}
   */
  nlu = null;

  /**
   * Случайно сгенерированный сроковый идентификатор
   *
   * @type {String}
   */
  tid = generateUid();

  /**
   * Список дочерних типов, тех, которые имеют parentBlock
   */
  static get childTypes() {
    return [
      'bot_intent',
      'intent',
      'screen',
    ];
  }

  /**
   * Список основных типов, тех, которые могут иметь дочерние блоки
   */
  static get mainTypes() {
    return [
      'client',
      'operator',
    ];
  }

  /**
   * Возвращает true, если type является дочерним
   *
   * @param  {String} type
   * @return {Boolean}
   * @author axsmak
   */
  static isChild(type) {
    return this.childTypes.includes(type);
  }

  /**
   * Возвращает true, если type является основным
   *
   * @param  {String} type
   * @return {Boolean}
   * @author axsmak
   */
  static isMain(type) {
    return this.mainTypes.includes(type);
  }

  /**
   * Применяет данные из data к блоку
   *
   * Будут исползованы те свойства объекта data, которые совпадают по названию с объявленными в классе
   *
   * @param  {Object} data
   * @return {DialogMark}
   * @author axsmak
   */
  load(data) {
    Object.keys(this).forEach((key) => {
      this[key] = data[key] ?? this[key];
    });

    if (!this.parentBlock) {
      delete this.parentBlock;
    }

    if (!this.nlu) {
      delete this.nlu;
    }

    return this;
  }

  /**
   * Создаёт дочерний блок
   *
   * @param  {String}             type            Тип дочернего блока
   * @param  {Number|null}        [start=null]    Позиция начала текста в родительском блоке
   * @param  {Number|null}        [size=null]     Длина текста
   * @param  {String|Number|null} [id=null]       Идентификатор
   * @param  {String|null}        [title=null}]   Название
   * @return {DialogMark|null}
   * @author axsmak
   */
  createChild({ type, start = null, size = null, id = null, title = null }) {
    if (!this.validateChildType(type)) {
      return null;
    }

    const from = this.from + (start ? start : 0);
    const isChild = DialogMark.isChild(type);
    const data = {
      title,
      from,
      size: Math.min(...[
        (size ? size : this.size) + (isChild ? 0 : 1), // BUG: Точно не понимаю почему так и не всегда работает...
        this.size - (start ? start : 0),
      ]),
      type,
    };

    if (isChild) {
      if (id) {
        data.id = id;
      }

      data.parentBlock = this.id;
    } else {
      data.id = id ? id : this.id;
    }

    const child = createMark(data);

    return child;
  }

  /**
   * Проверяет type на валидность
   *
   * Если родительский блок имеет тип blank, то валидные type - client или operator
   * Если родительский блок имеет тип client, то валидные type - intent, bot_intent
   * Если родительский блок имеет тип operator, то валидный type - screen
   *
   * @param  {String} type  [description]
   * @return {Boolean}
   * @author axsmak
   */
  validateChildType(type) {
    switch (this.type) {
      case 'blank':
        return DialogMark.isMain(type);
        break;

      case 'client':
        return [
          'intent',
          'bot_intent',
        ].includes(type);
        break;

      case 'operator':
        return type === 'screen';
        break;

      default:
        return false;
    }
  }
}

/**
 * Создаёт экземпляр разметки и загружает в него переданные данные data
 *
 * @param  {Object} data  Данные разметки
 * @return {DialogMark}
 * @author axsmak
 */
const createMark = function createMarkupFromData(data) {
  return (new DialogMark).load(data);
};

/**
 * Генерирует случайную строку, длиной 11 символов из цифр и букв латинского алфавита в нижнем регистре
 *
 * @return {String}
 * @author axsmak
 */
const generateUid = function generateStringUid() {
  return Math.random().toString(36).substring(2);
}

export {
  createMark,
  DialogMark,
};
