import api from '../../lib/api';

export const RB_ACTION_CONSTRUCTOR_SOURCES_LOAD = 'RB_ACTION_CONSTRUCTOR_SOURCES_LOAD'
const RB_MUTATION_CONSTRUCTOR_SOURCES_LOADING = 'RB_MUTATION_CONSTRUCTOR_SOURCES_LOADING'
const RB_MUTATION_CONSTRUCTOR_SOURCES_LOADED = 'RB_MUTATION_CONSTRUCTOR_SOURCES_LOADED'
const RB_MUTATION_CONSTRUCTOR_SOURCES_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_SOURCES_LOAD_ERRORS'

export const RB_ACTION_CONSTRUCTOR_SOURCE_LOAD = 'RB_ACTION_CONSTRUCTOR_SOURCE_LOAD';
const RB_MUTATION_CONSTRUCTOR_SOURCE_LOADING = 'RB_MUTATION_CONSTRUCTOR_SOURCE_LOADING';
const RB_MUTATION_CONSTRUCTOR_SOURCE_LOADED = 'RB_MUTATION_CONSTRUCTOR_SOURCE_LOADED';
const RB_MUTATION_CONSTRUCTOR_SOURCE_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_SOURCE_LOAD_ERRORS';

export const RB_ACTION_CONSTRUCTOR_SOURCE_STORE = 'RB_ACTION_CONSTRUCTOR_SOURCE_STORE';
const RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADING = 'RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADING';
const RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADED = 'RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADED';
const RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_ERRORS';

export const RB_ACTION_CONSTRUCTOR_SOURCE_UPDATE = 'RB_ACTION_CONSTRUCTOR_SOURCE_UPDATE';
const RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADING = 'RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADING';
const RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADED = 'RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADED';
const RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_ERRORS';

export const RB_ACTION_CONSTRUCTOR_SOURCE_DELETE = 'RB_ACTION_CONSTRUCTOR_SOURCE_DELETE';
const RB_MUTATION_CONSTRUCTOR_SOURCE_DELETE_LOADING = 'RB_ACTION_CONSTRUCTOR_SOURCE_DELETE_LOADING';

export const RB_ACTION_CONSTRUCTOR_SOURCE_COLUMNS_LOAD = 'RB_ACTION_CONSTRUCTOR_SOURCE_COLUMNS_LOAD'
const RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADING = 'RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADING'
const RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADED = 'RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADED'
const RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOAD_ERRORS'

export const RB_ACTION_CONSTRUCTOR_TEMPLATES_SOURCES_LOAD = 'RB_ACTION_CONSTRUCTOR_TEMPLATES_SOURCES_LOAD'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADING = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADING'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADED = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADED'
const RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOAD_ERRORS'

export const RB_MUTATION_CONSTRUCTOR_SOURCE_EMPTY = 'RB_MUTATION_CONSTRUCTOR_SOURCE_EMPTY';


const state = {
  sources: {
    items: [],
    total: 0,
    loading: false,
    columns: {
      loading: false,
      selectOptions: []
    },
    source: {
      name: "",
      description: "",
      provider: "elastic",
      settings: {
        dataset_name: "",
        columns: []
      }
    },
  },
  templateSources: {
    items: [],
    loading: false,
  }
}

const actions = {
  /**
   * Загрузить источники данных
   * @param commit
   * @param state
   * @param {Object} params
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_SOURCES_LOAD]({commit, state}, params) {
    return api.list(
      'rb.constructor.sources.load',
      null,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_SOURCES_LOADING,
      RB_MUTATION_CONSTRUCTOR_SOURCES_LOADED,
      RB_MUTATION_CONSTRUCTOR_SOURCES_LOAD_ERRORS
    );
  },
  /**
   * Загрузить источник данных
   * @param commit
   * @param state
   * @param {Number} sourceId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_SOURCE_LOAD]({commit, state}, sourceId) {
    return api.load(
      'rb.constructor.sources.source.load',
      sourceId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_SOURCE_LOADING,
      RB_MUTATION_CONSTRUCTOR_SOURCE_LOADED,
      RB_MUTATION_CONSTRUCTOR_SOURCE_LOAD_ERRORS
    );
  },
  /**
   * Создать источник данных
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_SOURCE_STORE]({commit, state}) {
    return api.create(
      'rb.constructor.sources.source.store',
      null,
      state.sources.source,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADING,
      RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADED,
      RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_ERRORS
    );
  },
  /**
   * Обновить источник данных
   * @param commit
   * @param state
   * @param {Number} sourceId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_SOURCE_UPDATE]({commit, state}, sourceId) {
    return api.update(
      'rb.constructor.sources.source.update',
      sourceId,
      state.sources.source,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADING,
      RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADED,
      RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_ERRORS
    );
  },
  /**
   * Удалить источник данных
   * @param commit
   * @param state
   * @param {Number} sourceId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_SOURCE_DELETE]({commit, state}, sourceId) {
    return api.delete(
      'rb.constructor.sources.source.delete',
      sourceId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_SOURCE_DELETE_LOADING
    );
  },
  /**
   * Загрузить колонки источника данных
   * @param commit
   * @param state
   * @param {Number} sourceId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_SOURCE_COLUMNS_LOAD]({commit, state}, sourceId) {
    return api.load(
      'rb.constructor.sources.source.columns',
      sourceId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADING,
      RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADED,
      RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOAD_ERRORS
    );
  },
  /**
   * Загрузить источники данных для шаблона
   * @param commit
   * @param state
   * @param {Object} params
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_TEMPLATES_SOURCES_LOAD]({commit, state}, params) {
    return api.list(
        'rb.constructor.templates.sources.load',
        null,
        params,
        state,
        commit,
        RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADING,
        RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADED,
        RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOAD_ERRORS
    );
  },
}

const mutations = {
  [RB_MUTATION_CONSTRUCTOR_SOURCES_LOADING](state, status) {
    state.sources.loading = status
  },
  [RB_MUTATION_CONSTRUCTOR_SOURCES_LOADED](state, data) {
    if (data) {
      state.sources.items = data.data
      state.sources.total = data.total
    }
  },
  [RB_MUTATION_CONSTRUCTOR_SOURCES_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_SOURCE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_SOURCE_LOADED](state, data) {
    if (data) {
      let source = data.data
      state.sources.source.name = source.name
      state.sources.source.description = source.description
      state.sources.source.provider = source.provider
      state.sources.source.settings.dataset_name = source.settings.dataset_name
      state.sources.source.settings.columns = source.settings.columns || []
    }
  },
  [RB_MUTATION_CONSTRUCTOR_SOURCE_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_SOURCE_STORE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_SOURCE_UPDATE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_SOURCE_DELETE_LOADING]() {},

  [RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADING](state, status) {
    state.sources.columns.loading = status
  },
  [RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOADED](state, data) {
    if (data) {
      let columns = data.data
      state.sources.columns.selectOptions = [
        {
          id: null,
          name: 'Select column',
        }
      ]

      columns.forEach(column => {
        state.sources.columns.selectOptions.push({
          id: column.name,
          name: column.name,
          type: column.type,
        })
      });
    }
  },
  [RB_MUTATION_CONSTRUCTOR_SOURCE_COLUMNS_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_SOURCE_EMPTY](state) {
    state.sources.source.name = ""
    state.sources.source.description = ""
    state.sources.source.provider = "elastic"
    state.sources.source.settings.dataset_name = ""
    state.sources.source.settings.columns = []
  },

  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADING](state, status) {
    state.templateSources.loading = status
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOADED](state, data) {
    if (data) {
      state.templateSources.items = data.data
    }
  },
  [RB_MUTATION_CONSTRUCTOR_TEMPLATE_SOURCES_LOAD_ERRORS]() {},
}

export default {
  state,
  actions,
  mutations,
}
