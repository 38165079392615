import http from './../../lib/http';
import api from './../../lib/api';

export const TARIFF_SPEECH_ORGANIZATION_LOAD      = 'TARIFF_SPEECH_ORGANIZATION_LOAD';
export const TARIFF_SPEECH_ORGANIZATION_SAVE      = 'TARIFF_SPEECH_ORGANIZATION_SAVE';
export const TARIFF_SPEECH_ORGANIZATION_DATATABLE = 'TARIFF_SPEECH_ORGANIZATION_DATATABLE';
export const TARIFF_SPEECH_ORGANIZATION_SET_EMPTY = 'TARIFF_SPEECH_ORGANIZATION_SET_EMPTY';

const TARIFF_SPEECH_ORGANIZATION_LOADING_CHANGE           = 'TARIFF_SPEECH_ORGANIZATION_LOADING_CHANGE';
const TARIFF_SPEECH_ORGANIZATION_CHANGE                   = 'TARIFF_SPEECH_ORGANIZATION_CHANGE';
const ERRORS_TARIFF_SPEECH_ORGANIZATION_LOAD              = 'ERRORS_TARIFF_SPEECH_ORGANIZATION_LOAD';
const LOADING                                      = 'LOADING';
const LOADED_UPDATE                                = 'LOADED_UPDATE';
const ERRORS_UPDATE                                = 'ERRORS_UPDATE';
const TARIFF_SPEECH_ORGANIZATION_DATATABLE_LOADING_CHANGE = 'TARIFF_SPEECH_ORGANIZATION_DATATABLE_LOADING_CHANGE';
const TARIFF_SPEECH_ORGANIZATION_DATATABLE_CHANGE         = 'TARIFF_SPEECH_ORGANIZATION_DATATABLE_CHANGE';
const SET_EMPTY                                    = 'SET_EMPTY';
const TARIFF_SPEECH_PROVIDER_DATATABLE_LOADING_CHANGE                                    = 'TARIFF_SPEECH_PROVIDER_DATATABLE_LOADING_CHANGE';
const TARIFF_SPEECH_PROVIDER_DATATABLE_CHANGE                                    = 'TARIFF_SPEECH_PROVIDER_DATATABLE_CHANGE';




const tariff_speech_empty = () => {
    return {
        info: null,
        tariffs_speech: []
    }
}

const state = {
    tariff_speech                     : tariff_speech_empty(),
    tariff_speech_loading             : {},
    tariff_speech_errors              : {},
    tariff_speech_loading             : {},
    tariff_speech_list_loading        : false,
    tariff_speech_list                : null,
    tariff_speech_list_total          : 0,
    tariff_speech_provider_list       : {}
};

const actions = {
    [TARIFF_SPEECH_ORGANIZATION_LOAD] ({ commit, state }, id) {
        return api.load(
            'tariff_speech.organization',
            id,
            state,
            commit,
            TARIFF_SPEECH_ORGANIZATION_LOADING_CHANGE,
            TARIFF_SPEECH_ORGANIZATION_CHANGE,
            ERRORS_TARIFF_SPEECH_ORGANIZATION_LOAD
        );
    },
    [TARIFF_SPEECH_ORGANIZATION_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'tariff_speech.organization.list', 
            0,
            data,
            state,
            commit,
            TARIFF_SPEECH_ORGANIZATION_DATATABLE_LOADING_CHANGE, 
            TARIFF_SPEECH_ORGANIZATION_DATATABLE_CHANGE);
    },
    [TARIFF_SPEECH_ORGANIZATION_SAVE]({ commit, state }, data) {
        if (data.type != "new") {
            return api.update('tariff_speech.organization.update', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        } else {
            return api.create('tariff_speech.organization.create', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        }
    },
    [TARIFF_SPEECH_ORGANIZATION_SET_EMPTY]({ commit, state }) {
        commit(SET_EMPTY);
    },
};

const mutations = {
    [TARIFF_SPEECH_ORGANIZATION_LOADING_CHANGE] (state, data) {
        state.tariff_speech_loading = data;
    },
    [TARIFF_SPEECH_ORGANIZATION_CHANGE] (state, data) {
        if(data){
            state.tariff_speech = data;
        }
    },
    [ERRORS_TARIFF_SPEECH_ORGANIZATION_LOAD](state, data) {
        state.tariff_speech_errors = data;
    },
    [LOADING](state, data) {
        state.tariff_speech_loading        = data;
    },
    [LOADED_UPDATE](state, data) {
        if (data) {
            state.tariff_speech_organization = data;
        }
    },
    [ERRORS_UPDATE](state, data) {
        state.tariff_speech_errors = data;
    },
    [TARIFF_SPEECH_ORGANIZATION_DATATABLE_LOADING_CHANGE](state, data) {
        state.tariff_speech_list_loading = data;
    },
    [TARIFF_SPEECH_ORGANIZATION_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.tariff_speech_list        = data.data;
            state.tariff_speech_list_total  = data.total;
        }
        else {
            state.tariff_speech_list        = [];
            state.tariff_speech_list_total  = 0;
        }
    },
    [SET_EMPTY](state) {
        state.tariff_speech = tariff_speech_empty();
    },
};

export default {
    state,
    actions,
    mutations
}
