// import http from "./../../lib/http";
import api from "./../../lib/api";

export const UNLOAD_LOAD = "UNLOAD_LOAD";
export const UNLOAD_UPDATE = "UNLOAD_UPDATE";
export const UNLOAD_CREATE = "UNLOAD_CREATE";
export const UNLOAD_NEW_CHANGE = "UNLOAD_NEW_CHANGE";

export const UNLOAD_LOAD_DATATABLE = "UNLOAD_LOAD_DATATABLE";

export const UNLOAD_CHANGE = "UNLOAD_CHANGE";
const UNLOAD_LOADING_CHANGE = "UNLOAD_LOADING_CHANGE";
const UNLOAD_SAVING_CHANGE = "UNLOAD_SAVING_CHANGE";
const UNLOAD_ERRORS_CHANGE = "UNLOAD_ERRORS_CHANGE";

const UNLOAD_CREATING_CHANGE = "UNLOAD_CREATING_CHANGE";

const UNLOAD_DATATABLE_CHANGE = "UNLOAD_DATATABLE_CHANGE";
const UNLOAD_DATATABLE_LOADING_CHANGE = "UNLOAD_DATATABLE_LOADING_CHANGE";

const state = {
  unload: {
    id: 0,
    name: null,
    comment: null,
    config: null,
    organization_id: null,
    is_work: true
  },
  unload_loading: false,
  unload_saving: false,
  unload_errors: {},

  unload_list_total: 0,
  unload_list: null,
  unload_list_loading: false,

  unload_creating: false,
  unload_creating_errors: {}
};

const actions = {
  [UNLOAD_LOAD]({ commit, state }, id) {
    if (!id) {
      commit(UNLOAD_CHANGE, null);

      return;
    }

    return api.load(
      "unload",
      id,
      state,
      commit,
      UNLOAD_LOADING_CHANGE,
      UNLOAD_CHANGE
    );
  },

  [UNLOAD_UPDATE]({ commit, state }, data) {
    let id = data.id;
    let model = data.model;

    return api.update(
      "unload",
      id,
      model,
      state,
      commit,
      UNLOAD_SAVING_CHANGE,
      UNLOAD_CHANGE,
      UNLOAD_ERRORS_CHANGE
    );
  },

  [UNLOAD_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "unload.list",
      id,
      model,
      state,
      commit,
      UNLOAD_CREATING_CHANGE,
      UNLOAD_ERRORS_CHANGE
    );
  },

  [UNLOAD_LOAD_DATATABLE]({ commit, state }, filter) {
    return api.list(
      "unload.list",
      0,
      filter,
      state,
      commit,
      UNLOAD_DATATABLE_LOADING_CHANGE,
      UNLOAD_DATATABLE_CHANGE
    );
  }
};

const mutations = {
  [UNLOAD_DATATABLE_LOADING_CHANGE](state, data) {
    state.unload_list_loading = data;
  },
  [UNLOAD_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.unload_list = data.data;
      state.unload_list_total = data.total;
    } else {
      state.unload_list = [];
      state.unload_list_total = 0;
    }
  },
  [UNLOAD_NEW_CHANGE](state) {
    state.unload = {
      id: 0,
      name: null,
      comment: null,
      config: null,
      is_work: true
    };
  },
  [UNLOAD_CHANGE](state, data) {
    if (data && data.data && data.data[0]) state.unload = data.data[0];
  },
  [UNLOAD_LOADING_CHANGE](state, data) {
    state.unload_loading = data;
  },
  [UNLOAD_SAVING_CHANGE](state, data) {
    state.unload_saving = data;
  },
  [UNLOAD_ERRORS_CHANGE](state, data) {
    state.unload_errors = data;
  },
  [UNLOAD_CREATING_CHANGE](state, data) {
    state.unload_creating = data;
  }
};

export default {
  state,
  actions,
  mutations
};
