import api from './../../lib/api';

export const PAGE_SETTING_LOAD    = 'PAGE_SETTING_LOAD';
export const PAGE_SETTING_UPDATE  = 'PAGE_SETTING_UPDATE';

const PAGE_SETTING_LOADING_CHANGE = 'PAGE_SETTING_LOADING_CHANGE';
const PAGE_SETTING_CHANGE         = 'PAGE_SETTING_CHANGE';
const PAGE_SETTING_UPDATE_CHANGE  = 'PAGE_SETTING_UPDATE_CHANGE';
const PAGE_SETTING_ERRORS_CHANGE  = 'PAGE_SETTING_ERRORS_CHANGE';

const state = {
    page_setting: null,
    page_setting_loading: false,
    page_setting_saving: false,
    page_setting_errors: {},
};

const actions = {
    [PAGE_SETTING_LOAD] ({commit, state}) {
        
        return api.load(
            'page_settings',
            0,
            state,
            commit,
            PAGE_SETTING_LOADING_CHANGE,
            PAGE_SETTING_CHANGE
        );
    },
    [PAGE_SETTING_UPDATE]({commit, state}, data) {
        let model = {
            setting: JSON.stringify(data),
        }
        return api.update(
            'page_settings',
            0,
            model,
            state,
            commit,
            PAGE_SETTING_UPDATE_CHANGE,
            PAGE_SETTING_CHANGE,
            PAGE_SETTING_ERRORS_CHANGE);
    },
};

const mutations = {
    [PAGE_SETTING_LOADING_CHANGE] (state, data) {
        state.page_setting_loading = data;
    },
    [PAGE_SETTING_CHANGE] (state, data) {
        state.page_setting_errors = {};
        if(data && typeof data === 'object'){
            state.page_setting = data;
        } else {
            state.page_setting = {}
        }
    },
    [PAGE_SETTING_UPDATE_CHANGE] (state, data) {
        state.page_setting_saving = data;
    },
    [PAGE_SETTING_ERRORS_CHANGE] (state, data) {
        state.page_setting_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}