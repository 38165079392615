import CustomStore from "devextreme/data/custom_store";

/**
 * Класс добавляет в стандартный CustomStore метод, который позволяет
 * получить любое поле, пришедшее с бэка, по его имени
 * @type {Object}
 */
export default class CustomStoreExtended extends CustomStore {
    _data = {};
    
    /**
     * Возвращает любое поле, пришедшее с бэка, по его имени
     * @method getData
     * @param  {String}  field         Имя поля
     * @param  {Mixed}   default_value Значение по умолчанию
     * @return {Mixed}
     */
    getData(field, default_value = false) {
        if (this._data.hasOwnProperty(field)) {
            return this._data[field];
        } else {
            return default_value;
        }
    }
};
