import Vue from 'vue';
import api from './../../lib/api';
import http from './../../lib/http';
import VueRouter from "./../../routes.js";

export const NLU_INTENT_LOAD_DATATABLE = 'NLU_INTENT_LOAD_DATATABLE';
export const NLU_INTENT_LOAD_DATATABLE_BY_FILTER = 'NLU_INTENT_LOAD_DATATABLE_BY_FILTER';
export const NLU_INTENT_LOAD = 'NLU_INTENT_LOAD';
export const NLU_INTENT_LOAD_BY_NAME = 'NLU_INTENT_LOAD_BY_NAME';
export const NLU_INTENT_DELETE = 'NLU_INTENT_DELETE';
export const NLU_INTENT_LOAD_DATASET = 'NLU_INTENT_LOAD_DATASET';
export const NLU_INTENT_ADD_SAMPLE = 'NLU_INTENT_ADD_SAMPLE';
export const NLU_INTENT_MOVE = 'NLU_INTENT_MOVE';

const NLU_INTENT_DATATABLE_LOADING_CHANGE = 'NLU_INTENT_DATATABLE_LOADING_CHANGE';
const NLU_INTENT_DATATABLE_CHANGE = 'NLU_INTENT_DATATABLE_CHANGE';
const NLU_INTENT_DATATABLE_BY_FILTER_LOADING_CHANGE = 'NLU_INTENT_DATATABLE_BY_FILTER_LOADING_CHANGE';
const NLU_INTENT_DATATABLE_BY_FILTER_CHANGE = 'NLU_INTENT_DATATABLE_BY_FILTER_CHANGE';
const NLU_INTENT_DATASET_LOADING = 'NLU_INTENT_DATASET_LOADING';
const NLU_INTENT_SAMPLE_ADDED = 'NLU_INTENT_SAMPLE_ADDED';
const NLU_INTENT_MOVED = 'NLU_INTENT_MOVED';

export const NLU_INTENT_CREATE = 'NLU_INTENT_CREATE';
export const NLU_INTENT_UPDATE = 'NLU_INTENT_UPDATE';
export const NLU_INTENT_CLEAR = 'NLU_INTENT_CLEAR';
export const NLU_INTENT_TEST = 'NLU_INTENT_TEST';

const NLU_INTENT_LOADING = 'NLU_INTENT_LOADING';
const NLU_INTENT_DELETING = 'NLU_INTENT_DELETING';
const NLU_INTENT_LOADED = 'NLU_INTENT_LOADED';
const NLU_INTENT_ERRORS = 'NLU_INTENT_ERRORS';

export const NLU_INTENTS_CLEAR_GROUP = 'NLU_INTENTS_CLEAR_GROUP';

const state = {
    intent_list: [],
    intent_list_by_filter: [],
    pagination_by_filter: {
        per_page: 40,
        total: 0,
        current_page: 1,
    },
    intent_per_page: 0,
    intent_total: 0,
    intent_list_loading: false,
    intent_list_by_filter_loading: false,
    intent: {},
    intent_errors: {},
    intent_loading: false,
    dataset_loading: false,
    test_text: '',
};

const actions = {
    [NLU_INTENT_LOAD_DATATABLE]({ commit, state }, data) {
        return api.list(
            'nlu.intents', 0, data, state, commit, NLU_INTENT_DATATABLE_LOADING_CHANGE, NLU_INTENT_DATATABLE_CHANGE
        );
    },
    [NLU_INTENT_LOAD_DATATABLE_BY_FILTER]({ commit, state }, data) {
        return api.list(
            'nlu.intents', 0, data, state, commit, NLU_INTENT_DATATABLE_BY_FILTER_LOADING_CHANGE, NLU_INTENT_DATATABLE_BY_FILTER_CHANGE
        );
    },
    async [NLU_INTENT_CREATE]({ commit, state }, data) {
        const success = await api.create(
            'nlu.intents',
            0,
            data,
            state,
            commit,
            NLU_INTENT_LOADING,
            NLU_INTENT_LOADED,
            NLU_INTENT_ERRORS
        ).catch((error) => {
            return Promise.reject(state.intent_errors);
        });

        if (success) {
            return Promise.resolve(state.intent);
        }
    },
    [NLU_INTENT_UPDATE]({ commit, state }, data) {
        const payload = _.cloneDeep(data);
        _.each(payload.examples, (sample) => {
            delete sample.checked;
        });

        return api.update(
            'nlu.intent',
            data.id,
            payload,
            state,
            commit,
            NLU_INTENT_LOADING,
            NLU_INTENT_LOADED,
            NLU_INTENT_ERRORS
        );
    },
    [NLU_INTENT_LOAD]({ commit, state }, data) {
        return api.load(
            'nlu.intent',
            data.id,
            state,
            commit,
            NLU_INTENT_LOADING,
            NLU_INTENT_LOADED
        );
    },
    [NLU_INTENT_LOAD_BY_NAME]({ commit, state }, name) {
        return api.load(
            'nlu.intent_by_name',
            name,
            state,
            commit,
            NLU_INTENT_LOADING,
            NLU_INTENT_LOADED
        );
    },
    async [NLU_INTENT_LOAD_DATASET]({ commit, state }, data) {
        commit(NLU_INTENT_DATASET_LOADING, true);
        return http.get(`${api.main_url}/api/v2/nlu/dataset`, data, {
            responseType: 'blob',
        }).then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        }).finally(() => commit(NLU_INTENT_DATASET_LOADING, false));
    },
    [NLU_INTENT_DELETE]({ commit, state }, id) {
        return api.delete(
            'nlu.intent',
            id,
            state,
            commit,
            NLU_INTENT_DELETING
        );
    },
    [NLU_INTENT_ADD_SAMPLE]({ commit, state }, { id, text, remove_from }) {
        const data = { text, remove_from };

        return http
            .post(`${api.main_url}/api/v2/nlu/intents/${id}/add_sample`, data)
            .then(({ data }) => {
                commit(NLU_INTENT_SAMPLE_ADDED, data);
                return Promise.resolve(data);
            })
            .catch((error) => Promise.reject(error));
    },
    [NLU_INTENT_MOVE]({ commit, state }, payload) {
        return http
            .put(`${api.main_url}/api/v2/nlu/intents/${payload.id}/move`, payload)
            .then(({ data }) => {
                commit(NLU_INTENT_MOVED, payload);
                return Promise.resolve(data);
            })
            .catch((error) => Promise.reject(error));
    },
};

const mutations = {
    [NLU_INTENT_DATATABLE_LOADING_CHANGE](state, data) {
        state.intent_list_loading = data;
    },
    [NLU_INTENT_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.intent_list = data.data;
            state.intent_per_page = data.per_page;
            state.intent_total = data.total;
        } else {
            state.intent_list = [];
        }
    },

    [NLU_INTENT_DATATABLE_BY_FILTER_LOADING_CHANGE](state, data) {
        state.intent_list_by_filter_loading = data;
    },
    [NLU_INTENT_DATATABLE_BY_FILTER_CHANGE](state, data) {
        if (data) {
            state.intent_list_by_filter = data.data;
            state.pagination_by_filter.per_page = data.per_page;
            state.pagination_by_filter.current_page = data.current_page;
            state.pagination_by_filter.total = data.total;
        } else {
            state.intent_list_by_filter = [];
        }
    },
    [NLU_INTENT_LOADING](state, data) {
        state.intent_loading = data;
    },

    [NLU_INTENT_DELETING](state, id) {
        const index = state.intent_list.findIndex((intent) => intent.id === id);
        if (index < 0) {
            return;
        }
        state.intent_list.splice(index, 1);
    },
    [NLU_INTENT_CLEAR](state) {
        state.intent = {};
    },
    [NLU_INTENT_TEST](state, text) {
        state.test_text = text;
    },
    [NLU_INTENT_LOADED](state, data) {
        if (data) {
            state.intent = data;

            const inList = state.intent_list.find((intent) => intent.id === data.id);
            if (inList) {
                inList.title = data.title;
                inList.language = data.language;
                inList.approval.approved = data.examples.filter((intent) => Boolean(intent.approved)).length;
                inList.approval.pending = data.examples.length - inList.approval.approved;
            } else {
                data.approval ??= {
                    pending: 0,
                    approved: 0,
                };
                state.intent_list.push(data);
            }
        }
    },
    [NLU_INTENT_SAMPLE_ADDED](state, data) {
        const intentId = data[0].intent_id;
        if (state.intent.id === intentId) {
            data.forEach((sample) => {
                state.intent.examples.push(sample);
            });
        }

        const inList = state.intent_list.find((intent) => intent.id === intentId);
        if (inList && inList.approval) {
            inList.approval.pending += data.length;
        }
    },
    [NLU_INTENT_MOVED](state, { id, group_id }) {
        if (state.intent.id === id) {
            state.intent.group_id = group_id;
        }

        const inList = state.intent_list.find((intent) => intent.id === id);
        if (inList) {
            inList.group_id = group_id;
        }
    },
    [NLU_INTENT_DATASET_LOADING](state, data) {
        state.dataset_loading = data;
    },
    [NLU_INTENT_ERRORS](state, data) {
        state.intent_errors = data;
    },
    [NLU_INTENTS_CLEAR_GROUP](state, groupId) {
        state.intent_list.forEach((intent) => {
            if (intent.group_id === groupId) {
                Vue.set(intent, 'group_id', null);
            }
        });
    },
};

export default {
    state,
    actions,
    mutations
}
