import api from './../../lib/api';

export const TEST_QUESTION_LOAD         = "TEST_QUESTION_LOAD";
export const TEST_QUESTION_UPDATE       = "TEST_QUESTION_UPDATE";
export const TEST_QUESTION_CREATE       = "TEST_QUESTION_CREATE";
export const TEST_QUESTION_LIST_LOAD    = "TEST_QUESTION_LIST_LOAD";

const TEST_QUESTION_CHANGE              = "TEST_QUESTION_CHANGE";
const TEST_QUESTION_LOADING_CHANGE      = "TEST_QUESTION_LOADING_CHANGE";
const TEST_QUESTION_SAVING_CHANGE       = "TEST_QUESTION_SAVING_CHANGE";
const TEST_QUESTION_ERRORS_CHANGE       = "TEST_QUESTION_ERRORS_CHANGE";
const TEST_QUESTION_CREATE_CHANGE       = "TEST_QUESTION_CREATE_CHANGE";
const TEST_QUESTION_LIST_CHANGE         = "TEST_QUESTION_LIST_CHANGE";
const TEST_QUESTION_LIST_LOADING_CHANGE = "TEST_QUESTION_LIST_LOADING_CHANGE";

const state = {
    test_question:                 null,

    test_question_loading:         false,
    test_question_saving:          false,

    test_question_list:            [],
    test_question_list_loading:    false,

    test_question_errors:          {},

    test_question_empty:           {
        id: 0,
        question: "",
        img: "",
        is_work: true,
        answers: [{
            answer: '',
            point: 0,
            key: '',
            img: '',
            color_text: '',
            is_work: true,
        }],
    },
};

const actions = {
    [TEST_QUESTION_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'test_questions',
            id,
            params,
            state,
            commit,
            TEST_QUESTION_LIST_LOADING_CHANGE,
            TEST_QUESTION_LIST_CHANGE,
        );
    },

    [TEST_QUESTION_LOAD] ({ state, commit }, id) {
        if (!id) {
            commit(TEST_QUESTION_CHANGE, _.cloneDeep(state.test_question_empty));
            return;
        }
        return api.load(
            'test_question',
            id,
            state,
            commit,
            TEST_QUESTION_LOADING_CHANGE,
            TEST_QUESTION_CHANGE
        )
    },

    [TEST_QUESTION_UPDATE] ({ state, commit }, { id, data }) {

        return api.create(
            'test_question',
            id,
            data,
            state,
            commit,
            TEST_QUESTION_SAVING_CHANGE,
            TEST_QUESTION_CHANGE,
            TEST_QUESTION_ERRORS_CHANGE
        )
    },

    [TEST_QUESTION_CREATE] ({ state, commit }, data) {

        return api.create(
            'test_questions',
            0,
            data,
            state,
            commit,
            TEST_QUESTION_SAVING_CHANGE,
            TEST_QUESTION_CHANGE,
            TEST_QUESTION_ERRORS_CHANGE
        )
    },

};

const mutations = {
    [TEST_QUESTION_CHANGE](state, data) {
        if (data && typeof data.validation === 'string') {
            data.validation = JSON.parse(data.validation);
        }
        state.test_question = data
    },
    [TEST_QUESTION_LOADING_CHANGE](state, data) {
        state.test_question_loading = data
    },
    [TEST_QUESTION_SAVING_CHANGE](state, data) {
        state.test_question_saving = data
    },
    [TEST_QUESTION_ERRORS_CHANGE](state, data) {
        state.test_question_errors = data
    },
    [TEST_QUESTION_CREATE_CHANGE](state, data) {
        state.test_question_creating = data
    },
    [TEST_QUESTION_LIST_CHANGE](state, data) {
        if(data){
            state.test_question_list = data.data ? data.data : data;
        }
    },
    [TEST_QUESTION_LIST_LOADING_CHANGE](state, data) {
        state.test_question_list_loading = data;
    }
};

export default {
    state,
    actions,
    mutations,
}
