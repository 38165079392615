import http from './../../lib/http';
import api from './../../lib/api';

export const SMS_PROVIDER_LIST                 = 'SMS_PROVIDER_LIST';

export const SMS_PROVIDER_LOAD                 = 'SMS_PROVIDER_LOAD';
export const SMS_PROVIDER_UPDATE               = 'SMS_PROVIDER_UPDATE';
export const SMS_PROVIDER_CREATE               = 'SMS_PROVIDER_CREATE';

export const SMS_PROVIDER_LOAD_DATATABLE       = 'SMS_PROVIDER_LOAD_DATATABLE';

export const SMS_PROVIDER_CHANGE               = 'SMS_PROVIDER_CHANGE';
const SMS_PROVIDER_LOADING_CHANGE              = 'SMS_PROVIDER_LOADING_CHANGE';
const SMS_PROVIDER_SAVING_CHANGE               = 'SMS_PROVIDER_SAVING_CHANGE';
const SMS_PROVIDER_ERRORS_CHANGE               = 'SMS_PROVIDER_ERRORS_CHANGE';

const SMS_PROVIDER_CREATING_CHANGE             = 'SMS_PROVIDER_CREATING_CHANGE';

const SMS_PROVIDER_DATATABLE_CHANGE            = 'SMS_PROVIDER_DATATABLE_CHANGE';
const SMS_PROVIDER_DATATABLE_LOADING_CHANGE    = 'SMS_PROVIDER_DATATABLE_LOADING_CHANGE';

export const SMS_PROVIDER_DELETE		      = 'SMS_PROVIDER_DELETE';
export const SMS_PROVIDER_DELETE_CHANGE        = 'SMS_PROVIDER_DELETE_CHANGE';

const state = {
    sms_provider            : null,
    sms_provider_loading    : false,
    sms_provider_saving     : false,
    sms_provider_errors     : {},

    sms_provider_list_total     : 0,
    sms_provider_list           : null,
    sms_provider_list_loading   : false,

    sms_provider_creating           : false,
    sms_provider_creating_errors    : {},
    sms_provider_empty              : {
                                  id: 0,
                                  name: null,
                                  is_translit: true,
                                  min_character_translit: 70,
                                  sms_provider: null,
                                  data: {
                                    off_check_balance: true,
                                  },
                              }
};

const actions = {
    [SMS_PROVIDER_LOAD] ({ commit, state }, id) {

        if(!id) {
            commit(SMS_PROVIDER_CHANGE, _.cloneDeep(state.sms_provider_empty));

            return;
        }
        return api.load('sms_provider', id, state, commit, SMS_PROVIDER_LOADING_CHANGE, SMS_PROVIDER_CHANGE);
    },

    [SMS_PROVIDER_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('sms_provider', id, model, state, commit, SMS_PROVIDER_SAVING_CHANGE, SMS_PROVIDER_CHANGE, SMS_PROVIDER_ERRORS_CHANGE);
    },

    [SMS_PROVIDER_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('sms_provider.list', id, model, state, commit, SMS_PROVIDER_CREATING_CHANGE, SMS_PROVIDER_CHANGE, SMS_PROVIDER_ERRORS_CHANGE);
    },

    [SMS_PROVIDER_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'sms_provider.list',
            id,
            null,
            state,
            commit,
            SMS_PROVIDER_DATATABLE_LOADING_CHANGE,
            SMS_PROVIDER_DATATABLE_CHANGE);
    },
    [SMS_PROVIDER_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'sms_provider',
            id,
            state,
            commit,
            SMS_PROVIDER_DELETE_CHANGE
        );
    },


};

const mutations = {
    [SMS_PROVIDER_DATATABLE_LOADING_CHANGE](state, data) {
        state.sms_provider_list_loading = data;
    },
    [SMS_PROVIDER_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.sms_provider_list        = data.data;
            state.sms_provider_list_total  = data.total;
        }
        else {
            state.sms_provider_list        = [];
            state.sms_provider_list_total  = 0;
        }
    },
    [SMS_PROVIDER_CHANGE](state, data) {
        state.sms_provider  = data;
    },
    [SMS_PROVIDER_LOADING_CHANGE](state, data) {
        state.sms_provider_loading = data;
    },
    [SMS_PROVIDER_SAVING_CHANGE](state, data) {
        state.sms_provider_saving   = data;
    },
    [SMS_PROVIDER_ERRORS_CHANGE](state, data) {
        state.sms_provider_errors   = data;
    },
    [SMS_PROVIDER_CREATING_CHANGE](state, data) {
        state.sms_provider_creating = data;
    },
    [SMS_PROVIDER_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.sms_provider_list) {

                let list = [];
                state.sms_provider_list.forEach(sms_provider => {
                    if (sms_provider.id !== id) {
                        list.push({...sms_provider});
                    }
                });

                state.sms_provider_list = list;
            }
        }
    },


};

export default {
    state,
    actions,
    mutations
}
