import api from '../../lib/api';

export const RB_ACTION_CONSTRUCTOR_COLUMN_LOAD = 'RB_ACTION_CONSTRUCTOR_COLUMN_LOAD'
const RB_MUTATION_CONSTRUCTOR_COLUMN_LOADING = 'RB_MUTATION_CONSTRUCTOR_COLUMN_LOADING'
const RB_MUTATION_CONSTRUCTOR_COLUMN_LOADED = 'RB_MUTATION_CONSTRUCTOR_COLUMN_LOADED'
const RB_MUTATION_CONSTRUCTOR_COLUMN_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_COLUMN_LOAD_ERRORS'

export const RB_ACTION_CONSTRUCTOR_COLUMN_STORE = 'RB_ACTION_CONSTRUCTOR_COLUMN_STORE'
const RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADING = 'RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADING'
const RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADED = 'RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADED'
const RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_ERRORS'

export const RB_ACTION_CONSTRUCTOR_COLUMN_UPDATE = 'RB_ACTION_CONSTRUCTOR_COLUMN_UPDATE'
const RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADING = 'RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADING'
const RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADED = 'RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADED'
const RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_ERRORS'

export const RB_ACTION_CONSTRUCTOR_COLUMN_DELETE = 'RB_ACTION_CONSTRUCTOR_COLUMN_DELETE';
const RB_MUTATION_CONSTRUCTOR_COLUMN_DELETE_LOADING = 'RB_MUTATION_CONSTRUCTOR_COLUMN_DELETE_LOADING';

export const RB_MUTATION_CONSTRUCTOR_COLUMN_EMPTY = 'RB_MUTATION_CONSTRUCTOR_COLUMN_EMPTY';


const state = {
  column: {
    name: "",
    description: "",
    formula: "",
    template_id: 0,
    isClickable: false,
  }
}

const actions = {
  /**
   * Загрузить колонку
   * @param commit
   * @param state
   * @param {Number} columnId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_COLUMN_LOAD]({commit, state}, columnId) {
    return api.load(
      'rb.constructor.columns.column.load',
      columnId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_COLUMN_LOADING,
      RB_MUTATION_CONSTRUCTOR_COLUMN_LOADED,
      RB_MUTATION_CONSTRUCTOR_COLUMN_LOAD_ERRORS
    ); 
  },
  /**
   * Создать колонку
   * @param commit
   * @param state
   * @param {Number} templateId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_COLUMN_STORE]({commit, state}, templateId) {
    let params = state.column
    params.template_id = templateId

    return api.create(
      'rb.constructor.columns.column.store',
      null,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADING,
      RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADED,
      RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_ERRORS
    ); 
  },
  /**
   * Обновить колонку
   * @param {Object} params
   * @returns
   * @param columnId
   * @param templateId
   */
  [RB_ACTION_CONSTRUCTOR_COLUMN_UPDATE]({commit, state}, {columnId, templateId}) {
    let params = state.column
    params.template_id = templateId
    delete params.position

    return api.update(
      'rb.constructor.columns.column.update',
      columnId,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADING,
      RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADED,
      RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_ERRORS
    ); 
  },
  /**
   * Удалить колонку
   * @param commit
   * @param state
   * @param {Number} columnId
   * @returns
   */
  [RB_ACTION_CONSTRUCTOR_COLUMN_DELETE]({commit, state}, columnId) {
    return api.delete(
      'rb.constructor.columns.column.delete',
      columnId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_COLUMN_DELETE_LOADING
    ); 
  },
}

const mutations = {
  [RB_MUTATION_CONSTRUCTOR_COLUMN_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_COLUMN_LOADED](state, data) {
    if (data) {
      let column = data.data

      state.column.name = column.name
      state.column.description = column.description
      state.column.formula = column.formula
      state.column.position = column.position
      state.column.template_id = column.template_id
      state.column.isClickable = column.isClickable
      state.column.clickableUrl = column.clickableUrl
      state.column.clickableColumn = column.clickableColumn
    }
  },
  [RB_MUTATION_CONSTRUCTOR_COLUMN_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_COLUMN_STORE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_COLUMN_UPDATE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_COLUMN_DELETE_LOADING]() {},


  [RB_MUTATION_CONSTRUCTOR_COLUMN_EMPTY](state, template_id) {
    state.column.name = ""
    state.column.description = ""
    state.column.formula = ""
    state.column.template_id = template_id
    state.column.isClickable = false
    delete state.column.clickableUrl
    delete state.column.clickableColumn
  },
}

export default {
  state,
  actions,
  mutations,
}