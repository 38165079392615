import api from "./../../lib/api"

export const PURCHASE_LOAD                 = 'PURCHASE_LOAD';
export const PURCHASE_UPDATE               = 'PURCHASE_UPDATE';
export const PURCHASE_CREATE               = 'PURCHASE_CREATE';

export const CLOSING_DAY_LOAD              = 'CLOSING_DAY_LOAD';
export const CLOSING_DAY_CREATE            = 'CLOSING_DAY_CREATE';
const CLOSING_DAY_CHANGE                   = 'CLOSING_DAY_CHANGE';
const CLOSING_DAY_LOADING_CHANGE           = 'CLOSING_DAY_LOADING_CHANGE';
const CLOSING_DAY_SAVING_CHANGE            = 'CLOSING_DAY_SAVING_CHANGE';
const CLOSING_DAY_ERRORS_CHANGE            = 'CLOSING_DAY_ERRORS_CHANGE';
const CLOSING_DAY_CREATE_CHANGE            = 'CLOSING_DAY_CREATE_CHANGE';


const PURCHASE_CHANGE                      = 'PURCHASE_CHANGE';
const PURCHASE_LOADING_CHANGE              = 'PURCHASE_LOADING_CHANGE';
const PURCHASE_SAVING_CHANGE               = 'PURCHASE_SAVING_CHANGE';
const PURCHASE_ERRORS_CHANGE               = 'PURCHASE_ERRORS_CHANGE';
const PURCHASE_CREATE_CHANGE               = 'PURCHASE_CREATE_CHANGE';

const PURCHASE_CREATING_CHANGE             = 'PURCHASE_CREATING_CHANGE';

const state = {
    purchase            : null,
    purchase_loading    : false,

    purchase_saving     : false,
    purchase_errors     : {},

    purchase_creating           : false,
    purchase_creating_errors    : {},

    purchase_empty              : {
        purchase_status: "",
        seller_quantity: 0,
        seller_purchase_price: 0,
        purchaser_quantity: 0,
        purchaser_purchase_price: 0,
        provider: "",
        stock_arrival_date: "",
        payment_method: ""
    },

    closing_day_empty            : {
        closed_dates: []
    },

    closing_day         : null,
    closing_day_loading : false,

    closing_day_saving  : false,
    closing_day_errors  : {},

    closing_day_creating        : false,
    closing_day_creating_errors : {},
};

const actions = {
    [PURCHASE_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(PURCHASE_CHANGE, state.purchase_empty);

            return;
        }

        return api.load(
            'wholesaler_purchases',
            id,
            state,
            commit,
            PURCHASE_LOADING_CHANGE,
            PURCHASE_CHANGE
        );
    },

    [PURCHASE_UPDATE] ({ commit, state }, {id, data}) {

        return api.update(
            'wholesaler_purchase',
            id,
            data,
            state,
            commit,
            PURCHASE_SAVING_CHANGE,
            PURCHASE_CHANGE,
            PURCHASE_ERRORS_CHANGE
        );
    },

    [PURCHASE_CREATE] ({ commit, state }, data) {
        let id      = data.id ? data.id : 0;

        return api.create(
            'wholesaler_purchases',
            id,
            data,
            state,
            commit,
            PURCHASE_SAVING_CHANGE,
            PURCHASE_CREATE_CHANGE,
            PURCHASE_ERRORS_CHANGE
        );
    },

    [CLOSING_DAY_LOAD] ({ commit, state }, data) {
        return api.load(
            'closing_day',
            data,
            state,
            commit,
            CLOSING_DAY_LOADING_CHANGE,
            CLOSING_DAY_CHANGE
        );
    },

    [CLOSING_DAY_CREATE] ({ commit, state }, data) {
        let id = 0;

        return api.create(
            'closing_day_create',
            id,
            data,
            state,
            commit,
            CLOSING_DAY_SAVING_CHANGE,
            CLOSING_DAY_CREATE_CHANGE,
            CLOSING_DAY_ERRORS_CHANGE
        )
    },
};

const mutations = {
    [PURCHASE_CHANGE](state, data) {
        if (data) {
            state.purchase = data;
            return;
        }
        state.purchase = state.purchase_empty;
    },
    [PURCHASE_LOADING_CHANGE](state, data) {
        state.purchase_loading = data;
    },
    [PURCHASE_SAVING_CHANGE](state, data) {
        state.purchase_saving   = data;
    },
    [PURCHASE_ERRORS_CHANGE](state, data) {
        state.purchase_errors   = data;
    },
    [PURCHASE_CREATING_CHANGE](state, data) {
        state.purchase_creating = data;
    },

    //закрытие дня
    [CLOSING_DAY_SAVING_CHANGE](state, data) {
        state.closing_day_saving = data;
    },
    [CLOSING_DAY_CHANGE](state, data) {
        if(data){
            state.closing_day = data;
            return;
        }
        state.closing_day = state.closing_day_empty;
    },
    [CLOSING_DAY_CREATE_CHANGE] (state, data){
        state.closing_day_creating = data;
    },
    [CLOSING_DAY_ERRORS_CHANGE] (state, data) {
        state.closing_day_errors = data;
    },
    [CLOSING_DAY_LOADING_CHANGE] (state, data) {
        state.closing_day_loading = data;
    }


};

export default {
    state,
    actions,
    mutations
}