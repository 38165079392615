import api from './../../lib/api';

export const MY_TARGETS_LOAD = 'MY_TARGETS_LOAD'
export const MY_TARGETS_UPDATE = 'MY_TARGETS_UPDATE'
export const MY_TARGETS_REPORTS = 'MY_TARGETS_REPORTS'

const MY_TARGETS_LOADING = 'MY_TARGETS_LOADING'
const MY_TARGETS_LOADED = 'MY_TARGETS_LOADED'
const MY_TARGETS_UPDATING = 'MY_TARGETS_UPDATING'
const MY_TARGETS_CHANGE = 'MY_TARGETS_CHANGE'
const MY_TARGETS_ERRORS = 'MY_TARGETS_ERRORS'
const MY_TARGETS_REPORTS_LOADING_CHANGE = 'MY_TARGETS_REPORTS_LOADING_CHANGE'
const MY_TARGETS_REPORTS_CHANGE = 'MY_TARGETS_REPORTS_CHANGE'
const MY_TARGETS_REPORTS_ERRORS = 'MY_TARGETS_REPORTS_ERRORS'

const state = {    
    my_targets_loading: false,
    my_targets_empty: {
        shift_count: 0,
        t_quantity: 0,
        appr_count: 0,
        upsale_count: 0
    },
    my_targets:{
        shift_count: 0,
        t_quantity: 0,
        appr_count: 0,
        upsale_count: 0
    },
    my_targets_updating: false,
    my_targets_errors: {},
    my_targets_reports_loading:[],
    my_targets_reports: [],

}

const actions = {
    [MY_TARGETS_LOAD] ({commit, state}, data) {
        return api.list(
            'my_targets_load',
            0,
            data,
            state,
            commit,
            MY_TARGETS_LOADING,
            MY_TARGETS_LOADED
        );
    },
    [MY_TARGETS_UPDATE]({commit, state}, data) {
        return api.update(
            'my_targets_create_update',
            null,
            data,
            state,
            commit,
            MY_TARGETS_UPDATING,
            MY_TARGETS_CHANGE,
            MY_TARGETS_ERRORS);
    },
    [MY_TARGETS_REPORTS]({ commit, state }, key) { 
        if (!key) {
            return;
        }
        return api.list(
            'my_targets_reports',
            null, 
            key,
            state, 
            commit, 
            MY_TARGETS_REPORTS_LOADING_CHANGE, 
            MY_TARGETS_REPORTS_CHANGE,
            MY_TARGETS_REPORTS_ERRORS);
    },
}

const mutations = {
    [MY_TARGETS_LOADING] (state, data) {
        state.my_targets_loading = data;
    },
    [MY_TARGETS_LOADED] (state, data) {
        if (data) {
            state.my_targets = data.data;
        } else {
            state.my_targets = state.my_targets_empty;
        }
    },
    [MY_TARGETS_UPDATING] (state, data) {
        state.my_targets_updating = data;
    },
    [MY_TARGETS_CHANGE] (state, data) {
        state.my_targets_errors = {};
        if(data && typeof data === 'object'){
            state.my_targets = data;
        } else {
            state.my_targets = state.my_targets_empty;
        }
    },
    [MY_TARGETS_ERRORS] (state, data) {
        state.my_targets_errors = data;
    },
    [MY_TARGETS_REPORTS_LOADING_CHANGE](state, data) {
        state.my_targets_reports_loading = data;
    },
    [MY_TARGETS_REPORTS_CHANGE](state, data) {
        if (data) {
          state.my_targets_reports = data.data;
        } else {
          state.my_targets_reports = [];
        }
    },
    [MY_TARGETS_REPORTS_ERRORS](state, data) {
        state.my_targets_errors = data;
    },
}

export default {
    state,
    actions,
    mutations
}