import api from "./../../lib/api"

export const SERVICE_LOAD                 = 'SERVICE_LOAD';
export const SERVICE_UPDATE               = 'SERVICE_UPDATE';
export const SERVICE_CREATE               = 'SERVICE_CREATE';
export const SERVICE_LOAD_LIST            = 'SERVICE_LOAD_LIST';

const SERVICE_CHANGE                      = 'SERVICE_CHANGE';
const SERVICE_LOADING_CHANGE              = 'SERVICE_LOADING_CHANGE';
const SERVICE_SAVING_CHANGE               = 'SERVICE_SAVING_CHANGE';
const SERVICE_ERRORS_CHANGE               = 'SERVICE_ERRORS_CHANGE';
const SERVICE_CREATE_CHANGE               = 'SERVICE_CREATE_CHANGE';
const SERVICE_LIST_CHANGE                 = 'SERVICE_LIST_CHANGE';
const SERVICE_LIST_LOADING_CHANGE         = 'SERVICE_LIST_LOADING_CHANGE';

const SERVICE_CREATING_CHANGE             = 'SERVICE_CREATING_CHANGE';

const state = {
    service            : null,
    service_loading    : false,

    service_list           : [],
    services_loading   : false,

    service_saving     : false,
    service_errors     : {},

    service_creating           : false,
    service_creating_errors    : {},

    service_empty              : {
        id: 0,
        name: "",
        comment: "",
        organizations: {id:null},
        article: "",
        service_type: "Другая услуга",
        price: "",
        is_work: true
    }
};

const actions = {
    [SERVICE_LOAD_LIST] ({ commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'wholesaler_services',
            id,
            params,
            state,
            commit,
            SERVICE_LIST_LOADING_CHANGE,
            SERVICE_LIST_CHANGE,
        );
    },
    [SERVICE_LOAD] ({ commit, state }, id) {
        if(!id) {
            state.service_empty = {
                id: 0,
                    name: "",
                    comment: "",
                    organizations: {id:null},
                    article: "",
                    service_type: "Другая услуга",
                    price: "",
                    is_work: true
            };
            commit(SERVICE_CHANGE, state.service_empty);

            return;
        }

        return api.load(
            'wholesaler_service',
            id,
            state,
            commit,
            SERVICE_LOADING_CHANGE,
            SERVICE_CHANGE
        );
    },

    [SERVICE_UPDATE] ({ commit, state }, {id, data}) {

        return api.update(
            'wholesaler_service',
            id,
            data,
            state,
            commit,
            SERVICE_SAVING_CHANGE,
            SERVICE_CHANGE,
            SERVICE_ERRORS_CHANGE
        );
    },

    [SERVICE_CREATE] ({ commit, state }, data) {
        let id      = data.id ? data.id : 0;

        return api.create(
            'wholesaler_services',
            id,
            data,
            state,
            commit,
            SERVICE_SAVING_CHANGE,
            SERVICE_CREATE_CHANGE,
            SERVICE_ERRORS_CHANGE
        );
    },
};

const mutations = {
    [SERVICE_LIST_CHANGE](state, data) {
        if(data){
            state.service_list = data.data ? data.data : data;
        }
    },
    [SERVICE_LIST_LOADING_CHANGE](state, data) {
        state.service_loading = data;
    },
    [SERVICE_CHANGE](state, data) {
        if (data) {
            state.service = data;
            return;
        }
        state.service = state.service_empty;
    },
    [SERVICE_LOADING_CHANGE](state, data) {
        state.service_loading = data;
    },
    [SERVICE_SAVING_CHANGE](state, data) {
        state.service_saving   = data;
    },
    [SERVICE_ERRORS_CHANGE](state, data) {
        state.service_errors   = data;
    },
    [SERVICE_CREATING_CHANGE](state, data) {
        state.service_creating = data;
    }

};

export default {
    state,
    actions,
    mutations
}