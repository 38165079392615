import http from "./../../lib/http";
import api from "./../../lib/api";

export const PROJECT_LOAD = "PROJECT_LOAD";
export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_CREATE = "PROJECT_CREATE";

export const PROJECT_LOAD_DATATABLE = "PROJECT_LOAD_DATATABLE";

export const PROJECT_CHANGE = "PROJECT_CHANGE";
const PROJECT_LOADING_CHANGE = "PROJECT_LOADING_CHANGE";
const PROJECT_SAVING_CHANGE = "PROJECT_SAVING_CHANGE";
const PROJECT_ERRORS_CHANGE = "PROJECT_ERRORS_CHANGE";

const PROJECT_CREATING_CHANGE = "PROJECT_CREATING_CHANGE";

const PROJECT_DATATABLE_CHANGE = "PROJECT_DATATABLE_CHANGE";
const PROJECT_DATATABLE_LOADING_CHANGE = "PROJECT_DATATABLE_LOADING_CHANGE";

export const GEO_LIST_LOAD = "GEO_LIST_LOAD";
const GEO_LIST_LOAD_CHANGE = "GEO_LIST_LOAD_CHANGE";

export const PROJECT_CATEGORY_LIST_LOAD = "PROJECT_CATEGORY_LIST_LOAD";
const PROJECT_CATEGORY_LIST_LOAD_CHANGE = "PROJECT_CATEGORY_LIST_LOAD_CHANGE";

export const PROJECT_MODAL_SHOW_CHANGE = "PROJECT_MODAL_SHOW_CHANGE";

export const TRAFFIC_LIST_LOAD = "TRAFFIC_LIST_LOAD";
const TRAFFIC_LIST_LOAD_CHANGE = "TRAFFIC_LIST_LOAD_CHANGE";

export const PAGE_CREATE = "PAGE_CREATE";
export const PAGE_LIST_LOAD = "PAGE_LIST_LOAD";
const PAGE_LIST_LOAD_CHANGE = "PAGE_LIST_LOAD_CHANGE";
export const PAGE_CHANGE = "PAGE_CHANGE";
export const PROJECT_PAGE_LOAD_DATATABLE = "PAGE_LOAD_DATATABLE";

export const PROJECT_PRODUCT_CREATE = "PROJECT_PRODUCT_CREATE";
export const PROJECT_PRODUCT_LIST_LOAD = "PROJECT_PRODUCT_LIST_LOAD";
export const PROJECT_PRODUCT_DELETE = "PROJECT_PRODUCT_DELETE";
const PRODUCT_CREATING_CHANGE = "PRODUCT_CREATING_CHANGE";
const PRODUCT_CHANGE = "PRODUCT_CHANGE";
const PRODUCT_ERRORS_CHANGE = "PRODUCT_ERRORS_CHANGE";
const PRODUCT_LOADING_CHANGE = "PRODUCT_LOADING_CHANGE";
const PRODUCT_LIST_LOAD_CHANGE = "PRODUCT_LIST_LOAD_CHANGE";
const PRODUCT_DELETE_CHANGE = "PRODUCT_DELETE_CHANGE";

export const PAGE_DELETE = "PAGE_DELETE";
export const PAGE_DELETE_CHANGE = "PAGE_DELETE_CHANGE";

export const PAGE_PHONE_CREATE = "PAGE_PHONE_CREATE";
export const PAGE_PHONE_LIST_LOAD = "PAGE_PHONE_LIST_LOAD";
const PAGE_PHONE_LIST_LOAD_CHANGE = "PAGE_PHONE_LIST_LOAD_CHANGE";
export const PAGE_PHONE_CHANGE = "PAGE_PHONE_CHANGE";
export const PAGE_PHONE_DELETE = "PAGE_PHONE_DELETE";
export const PAGE_PHONE_DELETE_CHANGE = "PAGE_PHONE_DELETE_CHANGE";

export const CURRENT_PAGE_ID_CHANGE = "CURRENT_PAGE_ID_CHANGE";

export const GASKET_CREATE = "GASKET_CREATE";
export const GASKET_LIST_LOAD = "GASKET_LIST_LOAD";
const GASKET_LIST_LOAD_CHANGE = "GASKET_LIST_LOAD_CHANGE";
export const GASKET_CHANGE = "GASKET_CHANGE";

export const GASKET_DELETE = "GASKET_DELETE";
export const GASKET_DELETE_CHANGE = "GASKET_DELETE_CHANGE";

export const GOAL_CREATE = "GOAL_CREATE";
export const GOAL_LIST_LOAD = "GOAL_LIST_LOAD";
const GOAL_LIST_LOAD_CHANGE = "GOAL_LIST_LOAD_CHANGE";
export const GOAL_CHANGE = "GOAL_CHANGE";
export const GOAL_UPDATE_CHANGE = "GOAL_UPDATE_CHANGE";
export const GOAL_UPDATE = "GOAL_UPDATE";

export const GOAL_DELETE = "GOAL_DELETE";
export const GOAL_DELETE_CHANGE = "GOAL_DELETE_CHANGE";
const GOAL_ERRORS_CHANGE = "GOAL_ERRORS_CHANGE";

export const CALL_CENTER_LIST_LOAD = "CALL_CENTER_LIST_LOAD";
const CALL_CENTER_LIST_LOAD_CHANGE = "CALL_CENTER_LIST_LOAD_CHANGE";

export const CARRENCY_LIST_LOAD = "CARRENCY_LIST_LOAD";
const CARRENCY_LIST_LOAD_CHANGE = "CARRENCY_LIST_LOAD_CHANGE";

export const PROJECT_LOAD_SUGGEST = "PROJECT_LOAD_SUGGEST";
const PROJECT_SUGGEST_CHANGE = "PROJECT_SUGGEST_CHANGE";

const state = {
  project: null,
  project_loading: false,
  project_saving: false,
  project_errors: {},

  product_loading: false,
  product_errors: {},
  product_creating: false,
  product: null,
  product_list: null,
  product_list_total: 0,

  project_list_total: 0,
  project_list: null,
  project_list_loading: false,

  project_creating: false,
  project_creating_errors: {},
  project_empty: {
    id: 0,
    title: "",
    description: "",
    organization_id: null,
    name_for_client: "",
    sms_sender: "",
    hold: null,
    URL: null,
    is_private: false,
    is_call_tracking: false,
    is_authors: false,
    is_resale: false,
    category_id: null,
    image: null,
    gender: 0,
    postclick: 30,
    age: [18, 40],
    project_category_kc_id: null
  },
  geo_list_total: 0,
  geo_list: [],

  project_category_total: 0,
  project_category_list: [],

  traffic_list: 0,
  traffic_list_total: null,

  page: null,
  page_list: [],
  page_list_total: 0,

  page_phone: null,
  page_phone_list: null,
  page_phone_list_total: 0,

  current_page_id: 0,

  gasket: null,
  gasket_list: [],
  gasket_list_total: 0,

  goal: null,
  goal_list: [],
  goal_list_total: 0,
  goal_errors: {},

  call_center_total: 0,
  call_center_list: [],

  currency_total: 0,
  currency_list: [],

  project_suggest_list: []
};

const actions = {
  [PROJECT_LOAD]({ commit, state }, id) {
    if (!id) {
      state.project_empty = {
        id: 0,
        title: "",
        description: "",
        organization_id: null,
        name_for_client: "",
        sms_sender: "",
        hold: null,
        URL: null,
        is_private: false,
        is_call_tracking: false,
        is_authors: false,
        is_resale: false,
        category_id: null,
        image: null,
        gender: 0,
        postclick: 30,
        age: [18, 40]
      };
      setTimeout(function() {
        commit(PROJECT_CHANGE, state.project_empty);
      }, 1000);
      return;
    }

    return api.load(
      "project",
      id,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      PROJECT_CHANGE
    );
  },

  [PROJECT_UPDATE]({ commit, state }, data) {
    let id = data.id;
    let model = data.model;

    return api.update(
      "project",
      id,
      model,
      state,
      commit,
      PROJECT_SAVING_CHANGE,
      PROJECT_CHANGE,
      PROJECT_ERRORS_CHANGE
    );
  },

  [PROJECT_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "project.list",
      id,
      model,
      state,
      commit,
      PROJECT_CREATING_CHANGE,
      PROJECT_CHANGE,
      PROJECT_ERRORS_CHANGE
    );
  },

  [PROJECT_PRODUCT_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "project_product",
      id,
      model,
      state,
      commit,
      PRODUCT_CREATING_CHANGE,
      PRODUCT_CHANGE,
      PRODUCT_ERRORS_CHANGE
    );
  },

  [PROJECT_PRODUCT_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "project_product",
      id,
      null,
      state,
      commit,
      PRODUCT_LOADING_CHANGE,
      PRODUCT_LIST_LOAD_CHANGE
    );
  },

  [PROJECT_PRODUCT_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.delete(
      "project_product",
      id,
      state,
      commit,
      PRODUCT_DELETE_CHANGE
    );
  },

  [PROJECT_LOAD_SUGGEST]({ commit, state }, data) {
    return api.list(
      "project.suggest",
      null,
      data,
      state,
      commit,
      PROJECT_DATATABLE_LOADING_CHANGE,
      PROJECT_SUGGEST_CHANGE
    );
  },

  [PROJECT_LOAD_DATATABLE]({ commit, state }, data) {
    return api.list(
      "project.list",
      null,
      data,
      state,
      commit,
      PROJECT_DATATABLE_LOADING_CHANGE,
      PROJECT_DATATABLE_CHANGE
    );
  },

  [PROJECT_PAGE_LOAD_DATATABLE]({ commit, state }, data) {
    return api.list(
      "project.list_with_pages",
      null,
      data,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      PAGE_LIST_LOAD_CHANGE
    );
  },

  [GEO_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "geo.list",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      GEO_LIST_LOAD_CHANGE
    );
  },

  [PROJECT_CATEGORY_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "project_category.list",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      PROJECT_CATEGORY_LIST_LOAD_CHANGE
    );
  },

  [TRAFFIC_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "traffic.list",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      TRAFFIC_LIST_LOAD_CHANGE
    );
  },

  [PAGE_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "page.list",
      id,
      model,
      state,
      commit,
      PROJECT_CREATING_CHANGE,
      PAGE_CHANGE,
      PROJECT_ERRORS_CHANGE
    );
  },

  [PAGE_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "page",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      PAGE_LIST_LOAD_CHANGE
    );
  },
  [PAGE_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.delete("page", id, state, commit, PAGE_DELETE_CHANGE);
  },
  [PAGE_PHONE_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "page_phone.list",
      id,
      model,
      state,
      commit,
      PROJECT_CREATING_CHANGE,
      PAGE_PHONE_CHANGE,
      PROJECT_ERRORS_CHANGE
    );
  },

  [PAGE_PHONE_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "page_phone",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      PAGE_PHONE_LIST_LOAD_CHANGE
    );
  },
  [PAGE_PHONE_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.delete(
      "page_phone",
      id,
      state,
      commit,
      PAGE_PHONE_DELETE_CHANGE
    );
  },
  [GASKET_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "gasket.list",
      id,
      model,
      state,
      commit,
      PROJECT_CREATING_CHANGE,
      GASKET_CHANGE,
      PROJECT_ERRORS_CHANGE
    );
  },

  [GASKET_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "gasket",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      GASKET_LIST_LOAD_CHANGE
    );
  },
  [GASKET_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.delete("gasket", id, state, commit, GASKET_DELETE_CHANGE);
  },
  [GOAL_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "goal.list",
      id,
      model,
      state,
      commit,
      PROJECT_CREATING_CHANGE,
      GOAL_CHANGE,
      GOAL_ERRORS_CHANGE
    );
  },

  [GOAL_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "goal",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      GOAL_LIST_LOAD_CHANGE
    );
  },
  [GOAL_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.delete("goal", id, state, commit, GOAL_DELETE_CHANGE);
  },
  [GOAL_UPDATE]({ commit, state }, data) {
    let id = data.id;    
    let model = data;    
    return api.update(
      "goal",
      id,
      model,
      state,
      commit,
      PROJECT_CREATING_CHANGE,
      GOAL_UPDATE_CHANGE,
      GOAL_ERRORS_CHANGE
    );
  },
  [CALL_CENTER_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "call_center.list",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      CALL_CENTER_LIST_LOAD_CHANGE
    );
  },
  [CARRENCY_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "currency.list",
      id,
      null,
      state,
      commit,
      PROJECT_LOADING_CHANGE,
      CARRENCY_LIST_LOAD_CHANGE
    );
  }
};

const mutations = {
  [PROJECT_DATATABLE_LOADING_CHANGE](state, data) {
    state.project_list_loading = data;
  },
  [PROJECT_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.project_list = data.data;
      state.project_list_total = data.total;
    } else {
      state.project_list = [];
      state.project_list_total = 0;
    }
  },
  [PROJECT_SUGGEST_CHANGE](state, data) {
    if (data) {
      state.project_suggest_list = data.data;
    } else {
      state.project_suggest_list = [];
    }
  },
  [PROJECT_CHANGE](state, data) {
    state.project = data;
  },
  [PROJECT_LOADING_CHANGE](state, data) {
    state.project_loading = data;
  },
  [PROJECT_SAVING_CHANGE](state, data) {
    state.project_saving = data;
  },
  [PROJECT_ERRORS_CHANGE](state, data) {
    state.project_errors = data;
  },
  [PROJECT_CREATING_CHANGE](state, data) {
    state.project_creating = data;
  },

  [PRODUCT_CHANGE](state, data) {
    state.product = data;
    if (state.product_list) state.product_list.push(data);
    state.product_list_total++;
  },

  [PRODUCT_CREATING_CHANGE](state, data) {
    state.product_creating = data;
  },

  [PRODUCT_ERRORS_CHANGE](state, data) {
    state.product_errors = data;
  },

  [PRODUCT_LOADING_CHANGE](state, data) {
    state.product_loading = data;
  },

  [PRODUCT_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.product_list = data.data;
      state.product_list_total = data.total;
    } else {
      state.product_list = [];
      state.product_list_total = 0;
    }
  },

  [PRODUCT_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.product_list) {
        let list = [];
        state.product_list.forEach(product => {
          if (product.id !== id) {
            list.push({ ...product });
          }
        });

        state.product_list = list;
      }
    }
  },

  [GEO_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.geo_list = data.data;
      state.geo_list_total = data.total;
    } else {
      state.geo_list = [];
      state.geo_list_total = 0;
    }
  },

  [PROJECT_CATEGORY_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.project_category_list = data.data;
      state.project_category_list_total = data.total;
    } else {
      state.project_category_list = [];
      state.project_category_list_total = 0;
    }
  },

  [TRAFFIC_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.traffic_list = data.data;
      state.traffic_list_total = data.total;
    } else {
      state.traffic_list = [];
      state.traffic_list_total = 0;
    }
  },

  [PAGE_CHANGE](state, data) {
    state.page = data;
    state.page_list.push(data);
    state.page_list_total++;
  },

  [PAGE_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.page_list = data.data;
      state.page_list_total = data.total;
    } else {
      state.page_list = [];
      state.page_list_total = 0;
    }
  },

  [PAGE_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.page_list) {
        let list = [];
        state.page_list.forEach(page => {
          if (page.id !== id) {
            list.push({ ...page });
          }
        });

        state.page_list = list;
      }
    }
  },
  [PAGE_PHONE_CHANGE](state, data) {
    state.page_phone = data;
    state.page_phone_list.push(data);
    state.page_phone_list_total++;
  },

  [PAGE_PHONE_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.page_phone_list = data.data;
      state.page_phone_list_total = data.total;
    } else {
      state.page_phone_list = [];
      state.page_phone_list_total = 0;
    }
  },
  [PAGE_PHONE_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.page_phone_list) {
        let list = [];
        state.page_phone_list.forEach(page_phone => {
          if (page_phone.id !== id) {
            list.push({ ...page_phone });
          }
        });

        state.page_phone_list = list;
      }
    }
  },
  [CURRENT_PAGE_ID_CHANGE](state, data) {
    state.current_page_id = data;
  },
  [GASKET_CHANGE](state, data) {
    state.gasket = data;
    state.gasket_list.push(data);
    state.gasket_list_total++;
  },

  [GASKET_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.gasket_list = data.data;
      state.gasket_list_total = data.total;
    } else {
      state.gasket_list = [];
      state.gasket_list_total = 0;
    }
  },
  [GASKET_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.gasket_list) {
        let list = [];
        state.gasket_list.forEach(gasket => {
          if (gasket.id !== id) {
            list.push({ ...gasket });
          }
        });

        state.gasket_list = list;
      }
    }
  },
  [GOAL_CHANGE](state, data) {
    state.goal = data;
    state.goal_list.push(data);
    state.goal_list_total++;
  },

  [GOAL_UPDATE_CHANGE](state, data) {
    state.goal = data;    
  },

  [GOAL_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.goal_list = data.data;
      state.goal_list_total = data.total;
    } else {
      state.goal_list = [];
      state.goal_list_total = 0;
    }
  },
  [GOAL_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.goal_list) {
        let list = [];
        state.goal_list.forEach(goal => {
          if (goal.id !== id) {
            list.push({ ...goal });
          }
        });

        state.goal_list = list;
      }
    }
  },
  [GOAL_ERRORS_CHANGE](state, data) {
    state.goal_errors = data;
  },

  [CALL_CENTER_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.call_center_list = data.data;
      state.call_center_list_total = data.total;
    } else {
      state.call_center_list = [];
      state.call_center_list_total = 0;
    }
  },
  [CARRENCY_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.currency_list = data.data;
      state.currency_list_total = data.total;
    } else {
      state.currency_list = [];
      state.currency_list_total = 0;
    }
  }
};

export default {
  state,
  actions,
  mutations
};
