import http from './../../lib/http';
import api from './../../lib/api';
import {TRAFFIC_DELETE, TRAFFIC_DELETE_CHANGE} from "./traffics";

export const ORDER_ADVERT_SOURCES_LIST_LOAD         = "ORDER_ADVERT_SOURCES_LIST_LOAD";
export const ORDER_ADVERT_SOURCE_LOAD               = "ORDER_ADVERT_SOURCE_LOAD";
export const ORDER_ADVERT_SOURCE_CREATE             = "ORDER_ADVERT_SOURCE_CREATE";
export const ORDER_ADVERT_SOURCE_UPDATE             = "ORDER_ADVERT_SOURCE_UPDATE";

const ORDER_ADVERT_SOURCES_LIST_CHANGE              = "ORDER_ADVERT_SOURCES_LIST_CHANGE";
const ORDER_ADVERT_SOURCES_LIST_LOADING_CHANGE      = "ORDER_ADVERT_SOURCES_LIST_LOADING_CHANGE";

const ORDER_ADVERT_SOURCE_CHANGE                    = "ORDER_ADVERT_SOURCE_CHANGE";
const ORDER_ADVERT_SOURCE_LOADING_CHANGE            = "ORDER_ADVERT_SOURCE_LOADING_CHANGE";
const ORDER_ADVERT_SOURCE_ERRORS_CHANGE             = "ORDER_ADVERT_SOURCE_ERRORS_CHANGE";
const ORDER_ADVERT_SOURCE_SAVING_CHANGE             = "ORDER_ADVERT_SOURCE_SAVING_CHANGE";

const ORDER_ADVERT_SOURCE_CREATING_CHANGE           = "ORDER_ADVERT_SOURCE_CREATING_CHANGE";
const ORDER_ADVERT_SOURCE_CREATING_ERRORS_CHANGE    = "ORDER_ADVERT_SOURCE_CREATING_ERRORS_CHANGE";

export const ORDER_ADVERT_SOURCE_DELETE		        = 'ORDER_ADVERT_SOURCE_DELETE';
export const ORDER_ADVERT_SOURCE_DELETE_CHANGE  	= 'ORDER_ADVERT_SOURCE_DELETE_CHANGE';

const state = {
    order_advert_source: null,
    order_advert_source_loading: false,
    order_advert_source_saving: false,
    order_advert_source_errors: {},

    order_advert_source_list: null,
    order_advert_source_list_loading: false,

    order_advert_source_creating: false,
    order_advert_source_creating_errors: {},
    order_advert_source_empty: {
        id: 0,
        name: "",
        is_show: false,
        organization_id: [],
    }
};

const actions = {
    [ORDER_ADVERT_SOURCES_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'order_advert_sources',
            id,
            params,
            state,
            commit,
            ORDER_ADVERT_SOURCES_LIST_LOADING_CHANGE,
            ORDER_ADVERT_SOURCES_LIST_CHANGE,
        );
    },


    [ORDER_ADVERT_SOURCE_LOAD]({state, commit}, id) {
        if (!id) {
            commit(ORDER_ADVERT_SOURCE_CHANGE, null);
            return
        }

        return api.load(
            'order_advert_source',
            id,
            state,
            commit,
            ORDER_ADVERT_SOURCE_LOADING_CHANGE,
            ORDER_ADVERT_SOURCE_CHANGE,
        );
    },
    [ORDER_ADVERT_SOURCE_CREATE]({state, commit}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create(
            'order_advert_sources',
            id,
            model,
            state,
            commit,
            ORDER_ADVERT_SOURCE_CREATING_CHANGE,
            ORDER_ADVERT_SOURCE_CHANGE,
            ORDER_ADVERT_SOURCE_CREATING_ERRORS_CHANGE,
        );
    },
    [ORDER_ADVERT_SOURCE_UPDATE]({state, commit}, data) {
        let id = data.id;
        let model = data.model;

        return api.update(
            'order_advert_source',
            id,
            model,
            state,
            commit,
            ORDER_ADVERT_SOURCE_SAVING_CHANGE,
            ORDER_ADVERT_SOURCE_CHANGE,
            ORDER_ADVERT_SOURCE_ERRORS_CHANGE,
        );
    },
    [ORDER_ADVERT_SOURCE_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'order_advert_source',
            id,
            state,
            commit,
            ORDER_ADVERT_SOURCE_DELETE_CHANGE,
            ORDER_ADVERT_SOURCE_CHANGE,
            ORDER_ADVERT_SOURCE_ERRORS_CHANGE,
        );
    },
};

const mutations = {
    [ORDER_ADVERT_SOURCES_LIST_CHANGE](state, data) {
        if (data) {
            state.order_advert_source_list = data.data ? data.data : data;
        }
    },
    [ORDER_ADVERT_SOURCES_LIST_LOADING_CHANGE](state, data) {
        state.order_advert_source_list_loading = data;
    },
    [ORDER_ADVERT_SOURCE_CHANGE](state, data) {
        if (data) {
            state.order_advert_source = data;
            return;
        }
        state.order_advert_source = state.order_advert_source_empty;
    },
    [ORDER_ADVERT_SOURCE_LOADING_CHANGE](state, data) {
        state.order_advert_source_loading = data;
    },
    [ORDER_ADVERT_SOURCE_ERRORS_CHANGE](state, data) {
        state.order_advert_source_errors = data;
    },
    [ORDER_ADVERT_SOURCE_SAVING_CHANGE](state, data) {
        state.order_advert_source_saving = data;
    },
    [ORDER_ADVERT_SOURCE_CREATING_CHANGE](state, data) {
        state.order_advert_source_creating = data;
    },
    [ORDER_ADVERT_SOURCE_CREATING_ERRORS_CHANGE](state, data) {
        state.order_advert_source_creating_errors = data;
    },
    [ORDER_ADVERT_SOURCE_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.order_advert_source_list) {

                let list = [];
                state.order_advert_source_list.forEach(order_advert_source => {
                    if (order_advert_source.id !== id) {
                        list.push({...order_advert_source});
                    }
                });

                state.order_advert_source_list = list;
            }
        }
    },
};

export default {
    state,
    actions,
    mutations,
}