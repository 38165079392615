import Vue from "vue";
import api from './../../lib/api';
import http from './../../lib/http';
import lang from "./../../lib/lang";


export const RB_ACTION_REPORTS_LOAD = 'RB_ACTION_REPORTS_LOAD';
const RB_MUTATION_REPORTS_LOADING = 'RB_MUTATION_REPORTS_LOADING';
const RB_MUTATION_REPORTS_LOADED = 'RB_MUTATION_REPORTS_LOADED';
const RB_MUTATION_REPORTS_LOAD_ERRORS = 'RB_MUTATION_REPORTS_LOAD_ERRORS';

export const RB_ACTION_REPORT_INFO_LOAD = 'RB_ACTION_REPORT_INFO_LOAD';
const RB_MUTATION_REPORT_INFO_LOADING = 'RB_MUTATION_REPORT_INFO_LOADING';
const RB_MUTATION_REPORT_INFO_LOADED = 'RB_MUTATION_REPORT_INFO_LOADED';
const RB_MUTATION_REPORT_INFO_LOAD_ERRORS = 'RB_MUTATION_REPORT_INFO_LOAD_ERRORS';

export const RB_ACTION_REPORT_DATA_LOAD = 'RB_ACTION_REPORT_DATA_LOAD';
const RB_MUTATION_REPORT_DATA_LOADING = 'RB_ACTION_REPORT_DATA_LOADING';
const RB_MUTATION_REPORT_DATA_LOADED = 'RB_ACTION_REPORT_DATA_LOADED';
const RB_MUTATION_REPORT_DATA_LOAD_ERRORS = 'RB_MUTATION_REPORT_DATA_LOAD_ERRORS';

export const RB_ACTION_CONFIGURATION_STORE = 'RB_ACTION_CONFIGURATION_STORE';
const RB_MUTATION_CONFIGURATION_STORE_LOADING = 'RB_MUTATION_CONFIGURATION_STORE_LOADING';
const RB_MUTATION_CONFIGURATION_STORE_LOADED = 'RB_MUTATION_CONFIGURATION_STORE_LOADED';
const RB_MUTATION_CONFIGURATION_STORE_ERORRS = 'RB_MUTATION_CONFIGURATION_STORE_ERORRS';

export const RB_ACTION_CONFIGURATION_UPDATE = 'RB_ACTION_CONFIGURATION_UPDATE';
const RB_MUTATION_CONFIGURATION_UPDATE_LOADING = 'RB_MUTATION_CONFIGURATION_UPDATE_LOADING';
const RB_MUTATION_CONFIGURATION_UPDATE_LOADED = 'RB_MUTATION_CONFIGURATION_UPDATE_LOADED';
const RB_MUTATION_CONFIGURATION_UPDATE_ERORRS = 'RB_MUTATION_CONFIGURATION_UPDATE_ERORRS';

export const RB_ACTION_CONFIGURATION_LOAD = 'RB_ACTION_CONFIGURATION_LOAD';
const RB_MUTATION_CONFIGURATION_LOADING = 'RB_MUTATION_CONFIGURATION_LOADING';
const RB_MUTATION_CONFIGURATION_LOADED = 'RB_MUTATION_CONFIGURATION_LOADED';
const RB_MUTATION_CONFIGURATION_LOAD_ERRORS = 'RB_MUTATION_CONFIGURATION_LOAD_ERRORS';

export const RB_ACTION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOAD = 'RB_ACTION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOAD';
const RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADING = 'RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADING';
const RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADED = 'RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADED';
const RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOAD_ERRORS = 'RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOAD_ERRORS';

export const RB_ACTION_REPORT_CLICKABLE_COLUMN_DATA_LOAD = 'RB_ACTION_REPORT_CLICKABLE_COLUMN_DATA_LOAD';
const RB_MUTATION_REPORT_CLICKABLE_COLUMN_DATA_LOADING = 'RB_MUTATION_REPORT_CLICKABLE_COLUMN_DATA_LOADING';
const RB_MUTATION_CLICKABLE_COLUMN_DATA_LOADED = 'RB_MUTATION_CLICKABLE_COLUMN_DATA_LOADED';
const RB_MUTATION_CLICKABLE_COLUMN_DATA_LOAD_ERRORS = 'RB_MUTATION_CLICKABLE_COLUMN_DATA_LOAD_ERRORS';

export const RB_MUTATION_KEY_SET = 'RB_MUTATION_KEY_SET';
export const RB_MUTATION_REPORT_ID_SET = 'RB_MUTATION_REPORT_ID_SET';
export const RB_MUTATION_CONFIGURATION_GROUPS_SET = 'RB_MUTATION_CONFIGURATION_GROUPS_SET';
export const RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_SET = 'RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_SET';
export const RB_MUTATION_SELECT_FILTERS_DATE_RANGE_SET = 'RB_MUTATION_SELECT_FILTERS_DATE_RANGE_SET';
export const RB_MUTATION_TRANSFORM_SELECT_FILTERS_TO_CONFIGURATION_FILTERS = 'RB_MUTATION_TRANSFORM_SELECT_FILTERS_TO_CONFIGURATION_FILTERS';
export const RB_MUTATION_TRANSFORM_SELECT_VARIABLES_TO_CONFIGURATION_VARIABLES = 'RB_MUTATION_TRANSFORM_SELECT_VARIABLES_TO_CONFIGURATION_VARIABLES';
export const RB_MUTATION_CLICKABLE_COLUMN_CONFIG_SET = 'RB_MUTATION_CLICKABLE_COLUMN_CONFIG_SET';

const state = {
  reports: {
    items: [],
    total: 0,
    loading: false
  },
  greports: {}
}

const actions = {
  /**
   * Загрузить отчеты
   * @param commit
   * @param state
   * @param {Object} params
   * @returns
   */
  [RB_ACTION_REPORTS_LOAD]({commit,state},params) {
    return api.reportDataList(
      'rb.reports.load',
      null,
      params,
      state,
      commit,
      RB_MUTATION_REPORTS_LOADING,
      RB_MUTATION_REPORTS_LOADED,
      RB_MUTATION_REPORTS_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить информацию об отчете
   * @param commit
   * @param state
   * @param {Number} reportId отчета
   * @returns
   */
  [RB_ACTION_REPORT_INFO_LOAD]({commit, state}, reportId) { 
    let params = []

    if (state.greports[reportId].key) {
      params.session_id = state.greports[reportId].key
    }
    return api.reportDataList(
      'rb.reports.report.info.load',
      reportId,
      params,
      state,
      commit,
      RB_MUTATION_REPORT_INFO_LOADING,
      RB_MUTATION_REPORT_INFO_LOADED,
      RB_MUTATION_REPORT_INFO_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить данные отчета
   * @param commit
   * @param state
   * @param {Number} reportId id отчета
   * @returns
   */
  [RB_ACTION_REPORT_DATA_LOAD]({commit, state}, reportId) {
    let params = {}

    if (state.greports[reportId].key) {
      params.session_id = state.greports[reportId].key
    }

    return api.reportDataList(
      'rb.reports.report.data.load',
      reportId,
      params,
      state,
      commit,
      RB_MUTATION_REPORT_DATA_LOADING,
      RB_MUTATION_REPORT_DATA_LOADED,
      RB_MUTATION_REPORT_DATA_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить конфигурацию отчета
   * @param commit
   * @param state
   * @param {Number} reportId id отчета
   */
  [RB_ACTION_CONFIGURATION_LOAD]({commit, state}, {reportId}) { 
    return api.reportDataLoad(
      `/gateway/report-builder/api/v1/reports/${reportId}/configurations/${state.greports[reportId].key}`,
      reportId,
      state,
      commit,
      RB_MUTATION_CONFIGURATION_LOADING,
      RB_MUTATION_CONFIGURATION_LOADED,
      RB_MUTATION_CONFIGURATION_LOAD_ERRORS
    ); 
  },
  /**
   * Создать конфигурацию отчета
   * @returns 
   */
  [RB_ACTION_CONFIGURATION_STORE]({commit, state}, reportId) { 
    return api.reportDataCreate(
      'rb.reports.report.configuration.store',
      reportId,
      state.greports[reportId].configuration,
      state,
      commit,
      RB_MUTATION_CONFIGURATION_STORE_LOADING,
      RB_MUTATION_CONFIGURATION_STORE_LOADED,
      RB_MUTATION_CONFIGURATION_STORE_ERORRS
    ); 
  },
  /**
   * Обновить конфигурацию отчета
   * @returns 
   */
  [RB_ACTION_CONFIGURATION_UPDATE]({commit, state}, reportId) { 
    return api.reportDataUpdate(
      `/gateway/report-builder/api/v1/reports/${reportId}/configurations/${state.greports[reportId].key}`,
      reportId,
      state.greports[reportId].configuration,
      state,
      commit,
      RB_MUTATION_CONFIGURATION_UPDATE_LOADING,
      RB_MUTATION_CONFIGURATION_UPDATE_LOADED,
      RB_MUTATION_CONFIGURATION_UPDATE_ERORRS
    ); 
  },
  /**
   * Загрузить данные фильтра с типом searchable_multiselect
   * @param commit
   * @param state
   * @param query
   * @param {Object} filter
   * @param reportId
   * @returns
   */
  [RB_ACTION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOAD]({commit, state}, {query, filter, reportId}) {
    let params = []

    if (state.greports[reportId].key) {
      params.session_id = state.greports[reportId].key
    }

    if (query.length > 0) {
      params.searchValue = query;
    }
    params.column = filter.column
    return api.reportDataList(
      `/gateway/report-builder/api/v1/reports/${reportId}/columns/${filter.column}/filters/searchable-multiselect`,
      reportId,
      params,
      state,
      commit,
      RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADING,
      RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADED,
      RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить данные кликабельных колонок отчета
   * @param commit
   * @param state
   * @param {Number} reportId id отчета
   * @param {Number} columnId id колонки
   * @param {Array} groups группы
   * @param {Number} page страница пагинации
   * @returns
   */
  [RB_ACTION_REPORT_CLICKABLE_COLUMN_DATA_LOAD]({commit, state}, {reportId,page}) {
    let clickableColumnConfig = state.greports[reportId].clickableColumn.config
    let params = {groups: clickableColumnConfig.groups, page}

    if (state.greports[reportId].key) {
      params.session_id = state.greports[reportId].key
    }

    return api.reportDataList(
        `/gateway/report-builder/api/v1/reports/${reportId}/columns/${clickableColumnConfig.columnId}/clickable`,
        reportId,
        params,
        state,
        commit,
        RB_MUTATION_REPORT_CLICKABLE_COLUMN_DATA_LOADING,
        RB_MUTATION_CLICKABLE_COLUMN_DATA_LOADED,
        RB_MUTATION_CLICKABLE_COLUMN_DATA_LOAD_ERRORS
    );
  },
}

const mutations = {
  [RB_MUTATION_REPORTS_LOADING](state,{status}) {
    state.reports.loading = status
  },
  [RB_MUTATION_REPORTS_LOADED](state,{data}) {
    if (data) {
      state.reports.items = data.data
      state.reports.total = data.total
    }
  },
  [RB_MUTATION_REPORTS_LOAD_ERRORS]() {},

  [RB_MUTATION_REPORT_INFO_LOADING](state,{status,payload}) {
    state.greports[payload.id].infoLoading = status
  },
  [RB_MUTATION_REPORT_INFO_LOADED](state,{data,payload}) {
    if (data) {
      state.greports[payload.id].info = data.data
    }
  },
  [RB_MUTATION_REPORT_INFO_LOAD_ERRORS]() {},

  [RB_MUTATION_REPORT_DATA_LOADING](state,{status,payload}) {
    state.greports[payload.id].dataLoading = status
  },
  [RB_MUTATION_REPORT_DATA_LOADED](state,{data,payload}) {
    if (data) {
      let report = data.data

      state.greports[payload.id].data = report
      state.greports[payload.id].data.settings.summary.calculateCustomSummary = () => {}

      if (state.greports[payload.id].configuration.groups.length > 0) {
        state.greports[payload.id].data.settings.summary.totalItems = report.settings.summary.totalItems
        state.greports[payload.id].data.settings.summary.groupItems = report.settings.summary.groupItems
      } else {
        state.greports[payload.id].data.settings.summary.totalItems = []
        state.greports[payload.id].data.settings.summary.groupItems = []
      }
    }
  },
  [RB_MUTATION_REPORT_DATA_LOAD_ERRORS]() {},

  [RB_MUTATION_CONFIGURATION_LOADING](state,{status,payload}) {
    state.greports[payload.id].configurationLoading = status
  },
  [RB_MUTATION_CONFIGURATION_LOADED](state,{data,payload}) {
    if (data) {
      let configuration = data.data
      state.greports[payload.id].configuration.groups = configuration.groups
      state.greports[payload.id].configuration.filters = configuration.filters
      state.greports[payload.id].configuration.variables = configuration.variables

      if (configuration.variables) {
        state.greports[payload.id].info.variables.map(variable => {
          let configurationVariables = configuration.variables.filter(configurationVariable => configurationVariable.codeName === variable.codeName)
          if (configurationVariables.length > 0) {
            if (variable.type === 'local') {
              variable.value = configurationVariables[0].value
            }
            if (variable.type === 'api') {
              variable.enable = configurationVariables[0].enable
            }
          }
          return variable
        })
      }

      if (configuration.filters) {
        state.greports[payload.id].selectedFilters = {}
        state.greports[payload.id].configuration.filters.forEach(filter => {
          if (filter.type==='searchable_multiselect') {
            Vue.set(state.greports[payload.id].selectedFilters, filter.column, filter.items)
          } else if (filter.type==='date_range') {
            Vue.set(state.greports[payload.id].selectedFilters, filter.column, {
              from: filter.from,
              to: filter.to,
            })
          }
        });
      }
    }
  },
  [RB_MUTATION_CONFIGURATION_LOAD_ERRORS]() {},

  [RB_MUTATION_CONFIGURATION_STORE_LOADING]() {},
  [RB_MUTATION_CONFIGURATION_STORE_LOADED](state,{data,payload}) {
    if (data) {
      state.greports[payload.id].key = data.data.session_id
    }
  },
  [RB_MUTATION_CONFIGURATION_STORE_ERORRS]() {},

  [RB_MUTATION_CONFIGURATION_UPDATE_LOADING]() {},
  [RB_MUTATION_CONFIGURATION_UPDATE_LOADED]() {},
  [RB_MUTATION_CONFIGURATION_UPDATE_ERORRS]() {},

  [RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADING](state,{status,payload}) {
    Vue.set(state.greports[payload.id].searchableMultiselectLoading, payload.params.column, status)
  },
  [RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOADED](state,{data,payload}) {
    if (data) {
      Vue.set(state.greports[payload.id].searchableMultiselect, payload.params.column, data.data)
    }
  },
  [RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_DATA_LOAD_ERRORS]() {},
  
  [RB_MUTATION_CONFIGURATION_GROUPS_SET](state,{reportId, groups}) {
    state.greports[reportId].configuration.groups = groups
  },
  [RB_MUTATION_SELECT_FILTERS_SEARCHABLE_MULTISELECT_SET](state,{reportId, column, value}) {
    Vue.set(state.greports[reportId].selectedFilters, column, value)
  },
  [RB_MUTATION_SELECT_FILTERS_DATE_RANGE_SET](state,{reportId, column, type, value}) {
    Vue.set(state.greports[reportId].selectedFilters[column], type, value)
  },
  [RB_MUTATION_TRANSFORM_SELECT_FILTERS_TO_CONFIGURATION_FILTERS](state, {reportId}) {
    state.greports[reportId].configuration.filters = []
    for (const [columnName, selectedFilterData] of Object.entries(state.greports[reportId].selectedFilters)) {
      let filterData = state.greports[reportId].info.filters.find(obj => {
        return obj.column === columnName
      })
      if (!filterData) {
        continue;
      }
      let data = {
        column: columnName,
        type: filterData.type,
      }

      if (filterData.type==='searchable_multiselect') {
        if (selectedFilterData.length===0) {
          continue;
        }
        data.items = selectedFilterData
      }

      if (filterData.type==='date_range') {
        data.from = selectedFilterData.from
        data.to = selectedFilterData.to
      }
      
      state.greports[reportId].configuration.filters.push(data)
    }
  },
  [RB_MUTATION_TRANSFORM_SELECT_VARIABLES_TO_CONFIGURATION_VARIABLES](state, {reportId}) {
    state.greports[reportId].configuration.variables = state.greports[reportId].info.variables
      .map(variable => {
        let data = {
          codeName: variable.codeName,
          type: variable.type,
        };
        if (variable.type === 'local') {
          data.value = variable.value
        } else if(variable.type === 'api') {
          data.enable = variable.enable
        }

        return data;
      })
  },
  [RB_MUTATION_KEY_SET](state,{key,reportId}) {
    state.greports[reportId].key = key
  },
  [RB_MUTATION_REPORT_ID_SET](state,reportId) {
    state.reportId = reportId
    Vue.set(state.greports, reportId, {
      key: '',
      info: {
        id: 0,
        name: '',
        description: '',
        groups: [],
        filters: [],
        displayOptions: [],
        variables: [],
      },
      data: {
        id: 0,
        settings: {
          columns: [],
          summary: {
            totalItems: [],
            groupItems: [],
          },
        },
        summary: {},
        items: [],
      },
      configuration: {
        groups: [],
        filters: [],
        variables: [],
      },
      searchableMultiselect: {},
      searchableMultiselectLoading: {},
      selectedFilters: {},
      infoLoading: false,
      dataLoading: false,
      configurationLoading: false,
      clickableColumn: {
        config: {
          columnId: 0,
          groups: []
        },
        total: 0,
        loading: [],
        data: [],
      }
    })
  },
  [RB_MUTATION_REPORT_CLICKABLE_COLUMN_DATA_LOADING](state,{status,payload}) {
    state.greports[payload.id].clickableColumn.loading = status
  },
  [RB_MUTATION_CLICKABLE_COLUMN_DATA_LOADED](state,{data,payload}) {
    if (data) {
      state.greports[payload.id].clickableColumn.data = data.data
      state.greports[payload.id].clickableColumn.total = data.total
    }
  },
  [RB_MUTATION_CLICKABLE_COLUMN_DATA_LOAD_ERRORS]() {},

  [RB_MUTATION_CLICKABLE_COLUMN_CONFIG_SET](state,{reportId, columnId, groups}) {
    state.greports[reportId].clickableColumn.config.columnId = columnId
    state.greports[reportId].clickableColumn.config.groups = groups
  },
}

/**
 * api.list с расширенной логикой для отчетов(отправка пэйлода в мутацию)
 */
api.reportDataList = (
    endpoint,
    id,
    params,
    state,
    commit,
    loadingChange,
    dataChange,
    errorsChange = null
) => {
  let url = api.format(endpoint, id);
  const tmpLang = http.getLang();
  let payload = {id, params}

  commit(loadingChange, {status: true, payload});
  commit(dataChange, {data: null, payload});
  if (errorsChange){
    commit(errorsChange, {data: {}, payload});
  }

  return http
    .get(url, params)
    .then(response => {
      commit(loadingChange, {status: false, payload});

      if (response && response.data) {
        let dataChangePayload = {
          data: response.data.data,
          total: response.data.total
        }

        if (response.data.meta) {
          dataChangePayload.total = response.data.meta.total
        }
        commit(dataChange, {data: dataChangePayload, payload});

        Vue.notify({
          group: "app",
          type: "success",
          title: lang.get(dataChange, tmpLang),
          text: lang.get('successfully_completed_event', tmpLang),
          duration: 500
        });

        return true;
      }
    })
    .catch(error => {
      let errorMsg = error.message

      if (errorsChange) {
        if (error.errors) {
          commit(errorsChange, {data: error.errors, payload});
        } else if (error.data) {
          commit(errorsChange, {data: error.data, payload});
          errorMsg = error.data.message || '';
        }
      }

      Vue.notify({
        group: "app",
        type: "error",
        title: errorMsg,
        text: api.errors_format(error.errors),
        duration: 10000
      });

      return false;
    });
}

/**
 * api.load с расширенной логикой для отчетов(отправка пэйлода в мутацию)
 */
api.reportDataLoad = (
    endpoint,
    id,
    state,
    commit,
    loadingChange,
    dataChange,
    errorsChange = null
) => {
  let url = api.format(endpoint, id);
  const tmpLang = http.getLang();
  let payload = {id, params: {}}

  if (loadingChange) {
    commit(loadingChange, {status: true, payload});
  }

  commit(dataChange, {data: null, payload});

  if (errorsChange){
    commit(errorsChange, {data: {}, payload});
  }

  return http
    .get(url)
    .then(response => {
      if (loadingChange) {
        commit(loadingChange, {status: false, payload});
      }

      if (response && response.data) {
        commit(dataChange, {data: response.data, payload});

        Vue.notify({
          group: "app",
          type: "success",
          title: lang.get(dataChange, tmpLang),
          text: lang.get('successfully_completed_event', tmpLang),
          duration: 500
        });

        return true;
      }
    })
    .catch(error => {
      let errorMsg = error.message

      if (errorsChange) {
        if (error.errors) {
          commit(errorsChange, {data: error.errors, payload});
        } else if (error.data) {
          commit(errorsChange, {data: error.data, payload});
          errorMsg = error.data.message || '';
        }
      }

      Vue.notify({
        group: "app",
        type: "error",
        title: errorMsg,
        text: api.errors_format(error.errors),
        duration: 10000
      });

      return false;
    });
};

/**
 * api.create с расширенной логикой для отчетов(отправка пэйлода в мутацию)
 */
api.reportDataCreate = (
  endpoint,
  id,
  data,
  state,
  commit,
  creatingChange,
  dataChange,
  errorsChange
) => {
  let url = api.format(endpoint, id);
  const tmpLang = http.getLang();
  let payload = {id, params: data}

  commit(creatingChange, {status: true, payload});

  commit(errorsChange, {data: {}, payload});

  return http
    .post(url, data)
    .then(response => {
      commit(creatingChange, {status: false, payload});

      if (response && response.data) {
        commit(dataChange, {data: response.data, payload});

        Vue.notify({
          group: "app",
          type: "success",
          title: lang.get(dataChange, tmpLang),
          text: lang.get('successfully_completed_event', tmpLang),
          duration: 500
        });

        return true;
      } else {
        return false;
      }
    })
    .catch(error => {

      commit(creatingChange, {status: false, payload});
      let errorMsg = error.message;

      if (error.errors) {
        commit(errorsChange, {data: error.errors, payload});
      } else if (error.data) {
        commit(errorsChange, {data: error.data, payload});
        errorMsg = error.data.message || '';
      }

      Vue.notify({
        group: "app",
        type: "error",
        title: errorMsg,
        text: api.errors_format(error.errors),
        duration: 10000
      });

      return false;
    });
};

/**
 * api.update с расширенной логикой для отчетов(отправка пэйлода в мутацию)
 */
api.reportDataUpdate = (
  endpoint,
  id,
  data,
  state,
  commit,
  savingChange,
  dataChange,
  errorsChange
) => {
  let url = api.format(endpoint, id);
  const tmpLang = http.getLang();
  let payload = {id, params: data}

  commit(savingChange, {status: true, payload});
  commit(errorsChange, {data: {}, payload});

  return http
    .put(url, data)
    .then(response => {
      commit(savingChange, {status: false, payload});

      if (response && response.data) {
        commit(dataChange, {data: response.data, payload});

        Vue.notify({
          group: "app",
          type: "success",
          title: lang.get(dataChange, tmpLang),
          text: lang.get('successfully_completed_event', tmpLang),
          duration: 500
        });

        return true;
      } else {
        return false;
      }
    })
    .catch(error => {
      // eslint-disable-next-line
      console.error(error);
      commit(savingChange, {status: false, payload});
      let errorMsg = error.message;

      if (error.errors) {
        commit(errorsChange, {data: error.errors, payload});
      } else if (error.data) {
        commit(errorsChange, {data: error.data, payload});
        errorMsg = error.data.message || '';
      }

      Vue.notify({
        group: "app",
        type: "error",
        title: errorMsg,
        text: api.errors_format(error.errors),
        duration: 10000
      });

      return false;
    });
};

export default {
  state,
  actions,
  mutations,
}