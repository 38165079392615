import api from './../../lib/api';

export const NOTIFICATIONS_LOAD_DATATABLE       = 'NOTIFICATIONS_LOAD_DATATABLE';
export const NOTIFICATIONS_LOAD       = 'NOTIFICATIONS_LOAD';
export const NOTIFICATIONS_UNREAD_COUNT = 'NOTIFICATIONS_UNREAD_COUNT';
export const NOTIFICATIONS_MARK_READ = 'NOTIFICATIONS_MARK_READ';

const NOTIFICATIONS_DATATABLE_LOADING_CHANGE = 'NOTIFICATIONS_DATATABLE_LOADING_CHANGE';
const NOTIFICATIONS_DATATABLE_CHANGE = 'NOTIFICATIONS_DATATABLE_CHANGE';
const NOTIFICATIONS_LOADING_CHANGE = 'NOTIFICATIONS_LOADING_CHANGE';
const NOTIFICATIONS_CHANGE = 'NOTIFICATIONS_CHANGE';
const NOTIFICATIONS_MARK_READ_CHANGE = 'NOTIFICATIONS_MARK_READ_CHANGE';
const NOTIFICATIONS_ERRORS = 'NOTIFICATIONS_ERRORS';

const NOTIFICATIONS_UNREAD_COUNT_LOADING_CHANGE = 'NOTIFICATIONS_UNREAD_COUNT_LOADING_CHANGE';
const NOTIFICATIONS_UNREAD_COUNT_CHANGE = 'NOTIFICATIONS_UNREAD_COUNT_CHANGE';


const state = {
    notifications_list_total     : 0, 
    notifications_unread_count     : 0, 
    notifications_list           : null,
    notifications_list_loading   : false,
    notifications_unread_count_loading   : false,
    notifications_errors: {},
    notification   : {},
};

const getters = {
  notifications_unread_count: state => {
    return state.notifications_unread_count;
  }
};

const actions = {
    [NOTIFICATIONS_LOAD_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'notifications.list', 
            0,
            data,
            state,
            commit,
            NOTIFICATIONS_DATATABLE_LOADING_CHANGE, 
            NOTIFICATIONS_DATATABLE_CHANGE);
    },
    [NOTIFICATIONS_UNREAD_COUNT] ({ commit, state }, data) {
        return api.list(
            'notifications.unread_count', 
            0,
            data,
            state,
            commit,
            NOTIFICATIONS_UNREAD_COUNT_LOADING_CHANGE, 
            NOTIFICATIONS_UNREAD_COUNT_CHANGE);
    },
    [NOTIFICATIONS_LOAD] ({commit, state}, id) {
        return api.load(
            'notifications.load',
            id,
            state,
            commit,
            NOTIFICATIONS_LOADING_CHANGE,
            NOTIFICATIONS_CHANGE
        );
    },
    [NOTIFICATIONS_MARK_READ] ({commit, state}, id) {
        return api.update(
            'notifications.mark_read',
            id,
            {},
            state,
            commit,
            NOTIFICATIONS_LOADING_CHANGE,
            NOTIFICATIONS_MARK_READ_CHANGE,
            NOTIFICATIONS_ERRORS
        );
    },
};

const mutations = {
    [NOTIFICATIONS_DATATABLE_LOADING_CHANGE](state, data) {
        state.notifications_list_loading = data;
    },
    [NOTIFICATIONS_UNREAD_COUNT_LOADING_CHANGE](state, data) {
        state.notifications_unread_count_loading = data;
    },
    [NOTIFICATIONS_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.notifications_list        = data.data;
            state.notifications_list_total  = data.total;
        }
        else {
            state.notifications_list        = [];
            state.notifications_list_total  = 0;
        }
    },
    [NOTIFICATIONS_UNREAD_COUNT_CHANGE](state, data) {
        if(data) {            
            state.notifications_unread_count  = data.total;
        }
        else {            
            state.notifications_unread_count  = 0;
        }
    },
    [NOTIFICATIONS_LOADING_CHANGE] (state, data) {
        state.notification_loading = data;
    },
    [NOTIFICATIONS_CHANGE] (state, data) {
        if(data && typeof data === 'object'){
            state.notification = data;
        }
    },
    [NOTIFICATIONS_MARK_READ_CHANGE] (state, data) {
        if(data && typeof data === 'object'){
            state.notifications_unread_count  = data.total;
        }
    },
    [NOTIFICATIONS_ERRORS](state, data) {
        state.notifications_errors   = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
