import http from './../../lib/http';
import api from './../../lib/api';

export const PROJECT_CATEGORY_LIST                 = 'PROJECT_CATEGORY_LIST';

export const PROJECT_CATEGORY_LOAD                 = 'PROJECT_CATEGORY_LOAD';
export const PROJECT_CATEGORY_UPDATE               = 'PROJECT_CATEGORY_UPDATE';
export const PROJECT_CATEGORY_CREATE               = 'PROJECT_CATEGORY_CREATE';

export const PROJECT_CATEGORY_LOAD_DATATABLE       = 'PROJECT_CATEGORY_LOAD_DATATABLE';

export const PROJECT_CATEGORY_CHANGE               = 'PROJECT_CATEGORY_CHANGE';
const PROJECT_CATEGORY_LOADING_CHANGE              = 'PROJECT_CATEGORY_LOADING_CHANGE';
const PROJECT_CATEGORY_SAVING_CHANGE               = 'PROJECT_CATEGORY_SAVING_CHANGE';
const PROJECT_CATEGORY_ERRORS_CHANGE               = 'PROJECT_CATEGORY_ERRORS_CHANGE';

const PROJECT_CATEGORY_CREATING_CHANGE             = 'PROJECT_CATEGORY_CREATING_CHANGE';

const PROJECT_CATEGORY_DATATABLE_CHANGE            = 'PROJECT_CATEGORY_DATATABLE_CHANGE';
const PROJECT_CATEGORY_DATATABLE_LOADING_CHANGE    = 'PROJECT_CATEGORY_DATATABLE_LOADING_CHANGE';

export const PROJECT_CATEGORY_DELETE		  = 'PROJECT_CATEGORY_DELETE';
export const PROJECT_CATEGORY_DELETE_CHANGE  	  = 'PROJECT_CATEGORY_DELETE_CHANGE';


const state = {
    project_category            : null,
    project_category_loading    : false,
    project_category_saving     : false,
    project_category_errors     : {},

    project_category_list_total     : 0,
    project_category_list           : null,
    project_category_list_loading   : false,

    project_category_creating           : false,
    project_category_creating_errors    : {},
    project_category_empty              : {
                                  id: 0,
                                  name: "",
                                  is_work: true
                              }
};

const actions = {
    [PROJECT_CATEGORY_LOAD] ({ commit, state }, id) {
        if(!id) {
	    state.project_category_empty = {
	    	id : 0,
		name : null,
		is_work : true
	    };
            commit(PROJECT_CATEGORY_CHANGE, state.project_category_empty);
            return;
        }

        return api.load('project_category', id, state, commit, PROJECT_CATEGORY_LOADING_CHANGE, PROJECT_CATEGORY_CHANGE);
    },

    [PROJECT_CATEGORY_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('project_category', id, model, state, commit, PROJECT_CATEGORY_SAVING_CHANGE, PROJECT_CATEGORY_CHANGE, PROJECT_CATEGORY_ERRORS_CHANGE);
    },

    [PROJECT_CATEGORY_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('project_category.list', id, model, state, commit, PROJECT_CATEGORY_CREATING_CHANGE, PROJECT_CATEGORY_CHANGE, PROJECT_CATEGORY_ERRORS_CHANGE);
    },

    [PROJECT_CATEGORY_LOAD_DATATABLE] ({ commit, state }, data) {

        return api.list(
            'project_category.list', 
            null, 
            data,
            state, 
            commit, 
            PROJECT_CATEGORY_DATATABLE_LOADING_CHANGE, 
            PROJECT_CATEGORY_DATATABLE_CHANGE);
    },
    [PROJECT_CATEGORY_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'project_category',
            id,
            state,
            commit,
            PROJECT_CATEGORY_DELETE_CHANGE
        );
    },

};

const mutations = {
    [PROJECT_CATEGORY_DATATABLE_LOADING_CHANGE](state, data) {
        state.project_category_list_loading = data;
    },
    [PROJECT_CATEGORY_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.project_category_list        = data.data;
            state.project_category_list_total  = data.total;
        }
        else {
            state.project_category_list        = [];
            state.project_category_list  = 0;
        }

    },
    [PROJECT_CATEGORY_CHANGE](state, data) {
        state.project_category  = data;
        
        if(data && data.id != 0) {
            if (state.project_category_list) {
            let list = [];
            let is_update = false;
            state.project_category_list.forEach(project_category => {
                if (project_category.id == data.id) {
                    project_category = data;    
                    is_update = true;
                }
                list.push(project_category);
            });
            if(!is_update){
                list.push(data);
            }
            state.project_category_list = list;
            }
        }


    },
    [PROJECT_CATEGORY_LOADING_CHANGE](state, data) {
        state.project_category_loading = data;
    },
    [PROJECT_CATEGORY_SAVING_CHANGE](state, data) {
        state.project_category_saving   = data;
    },
    [PROJECT_CATEGORY_ERRORS_CHANGE](state, data) {
        state.project_category_errors   = data;
    },
    [PROJECT_CATEGORY_CREATING_CHANGE](state, data) {
        state.project_category_creating = data;
    },
    [PROJECT_CATEGORY_DELETE_CHANGE](state, id) {
	
        if(id) {
            if (state.project_category_list) {
            let list = [];
            state.project_category_list.forEach(project_category => {
                if (project_category.id !== id) {
                list.push({...project_category});
                }
            });

            state.project_category_list = list;
            }
        }
    },

};

export default {
    state,
    actions,
    mutations
}
