<template>
    <div class="text-center">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color:#17a2b8;"></i>
    </div>
</template>

<script>
    export default {

    }
</script>

