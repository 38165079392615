import http from './../../lib/http';
import api from './../../lib/api';

export const CALL_LOAD                 = 'CALL_LOAD';

export const CALL_LOAD_DATATABLE       = 'CALL_LOAD_DATATABLE';

const CALL_DATATABLE_CHANGE            = 'CALL_DATATABLE_CHANGE';
const CALL_DATATABLE_LOADING_CHANGE    = 'CALL_DATATABLE_LOADING_CHANGE';
const CALL_CHANGE                      = 'CALL_CHANGE';
const CALL_LOADING_CHANGE              = 'CALL_LOADING_CHANGE';
const CALL_ERRORS_CHANGE               = 'CALL_ERRORS_CHANGE';

export const CALL_DO                   = 'CALL_DO';

const state = {
    call            : null,
    call_loading    : false,
    call_saving     : false,
    call_errors     : {},

    call_list_total     : 0,
    call_list           : null,
    call_list_loading   : false,

    call_creating           : false,
    call_creating_errors    : {},

    call_empty              : {}
};

const actions = {
    [CALL_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(CALL_CHANGE, state.call_empty);

            return;
        }

        return api.load('call.getbycall', id, state, commit, CALL_LOADING_CHANGE, CALL_CHANGE);
    },
    [CALL_LOAD_DATATABLE] ({ commit, state }, data) {

        return api.list(
            'call.list',
            null,
            data,
            state,
            commit,
            CALL_DATATABLE_LOADING_CHANGE,
            CALL_DATATABLE_CHANGE);
    },

    [CALL_DO] ({ commit, state }, data) {
        let params = data.params;

        return api.list(
            'call.do',
            null,
            params,
            state,
            commit,
            CALL_DATATABLE_LOADING_CHANGE,
            CALL_DATATABLE_CHANGE
        )
    }
};

const mutations = {

    [CALL_DATATABLE_LOADING_CHANGE](state, data) {
        state.call_list_loading = data;
    },
    [CALL_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.call_list        = data.data;
            state.call_list_total  = data.total;
        }
        else {
            state.call_list        = [];
            state.call_list_total  = 0;
        }

    },
    [CALL_CHANGE](state, data) {
        state.call  = data;
    },
    [CALL_LOADING_CHANGE](state, data) {
        state.call_loading = data;
    },
    [CALL_ERRORS_CHANGE](state, data) {
        state.call_errors   = data;
    }
};

export default {
    state,
    actions,
    mutations
}
