const state = {
  actionHashTab: '',
  myWindowSize: {
    width: 0,
    height: 0,
  },
};

const getters = {
  getActionHashTab: state => {
    return state.actionHashTab;
  },
  getResizeWindow: state => {
    return state.myWindowSize;
  },
};

const actions = {
  actionAddHashString({ commit }, data) {
    commit('actionAddedHashString', data);
  },
  actionResizeWindow({ commit }, data) {
    commit('actionAddedSizeWindow', data);
  },
};

const mutations = {
  actionAddedHashString(state, data) {
    state.actionHashTab = data;
  },
  actionAddedSizeWindow(state, data) {
    state.myWindowSize = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
