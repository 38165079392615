<template>
    <multiselect
        v-model="options"
        :options="options"
        :tag-placeholder="getTranslate.add_value"
        :multiple="true"
        :taggable="true"
        :placeholder="getTranslate.enter_a_value"
        label="text"
        track-by="value"
        @tag="add"
        @input="updateValue"
    >
    </multiselect>
</template>

<script>
    export default {
        name: 'MultipleInput',
        props : {
            value : {}
        },
        data() {
            return {
                options     : [],
            };
        },
        created() {
            if(!this.value || !this.value.terms || !this.value.terms.length) {
                return;
            }

            this.setValue();
        },
        watch : {
            value() {
                this.setValue();
            }
        },
        methods : {
            setValue() {
                let options = [];
                if(this.value && this.value.terms) {
                    this.value.terms.forEach(val => {
                        let option  = {
                            value   : val,
                            text    : val
                        };

                        options.push(option);
                    });
                }

                this.options    = options;
            },
            updateValue() {
                let value  = [];
                this.options.forEach(el => {
                    value.push(el.value);
                });

                this.$emit('input', {'terms' : value});
            },
            add(val) {
                let option  = {
                    value   : val,
                    text    : val
                };

                this.options.push(option);
                this.updateValue();
            }
        }
    }
</script>

<style scoped>

</style>
