import api from './../../lib/api';

export const PHONES_LOAD = 'PHONES_LOAD';
const PHONES_LOADING = 'PHONES_LOADING';
const PHONES_LOADED = 'PHONES_LOADED';

const state = {
  changedPhones: {},
  phones: [],
  phonesLoading: false,
  phonesTotal: 0,
  phonesPage: 1,
  phonesPerPage: 0,
};

const actions = {
  [PHONES_LOAD] ({ commit, state }, data) {
    return api.list(
      'settings.phones',
      0,
      data,
      state,
      commit,
      PHONES_LOADING,
      PHONES_LOADED,
    );
  },
};

const mutations = {
  [PHONES_LOADING](state,{status}) {
    state.phonesLoading = status;
  },
  [PHONES_LOADED](state,data) {
    if (data) {
      state.phones = data.data;
      state.phonesTotal = data.total;
      state.phonesPage = data.page;
      state.phonesPerPage = data.per_page;
    }
  },
};

export default {
  state,
  actions,
  mutations
}
