import http from './../../lib/http';
import api from './../../lib/api';

export const PRODUCT_CATEGORY_LOAD                 = 'PRODUCT_CATEGORY_LOAD';
export const PRODUCT_CATEGORY_UPDATE               = 'PRODUCT_CATEGORY_UPDATE';
export const PRODUCT_CATEGORY_CREATE               = 'PRODUCT_CATEGORY_CREATE';

export const PRODUCT_CATEGORY_LOAD_DATATABLE       = 'PRODUCT_CATEGORY_LOAD_DATATABLE';

export const PRODUCT_CATEGORY_ACTIVE_LOAD          = 'PRODUCT_CATEGORY_ACTIVE_LOAD';

const PRODUCT_CATEGORY_CHANGE                      = 'PRODUCT_CATEGORY_CHANGE';
const PRODUCT_CATEGORY_LOADING_CHANGE              = 'PRODUCT_CATEGORY_LOADING_CHANGE';
const PRODUCT_CATEGORY_SAVING_CHANGE               = 'PRODUCT_CATEGORY_SAVING_CHANGE';
const PRODUCT_CATEGORY_ERRORS_CHANGE               = 'PRODUCT_CATEGORY_ERRORS_CHANGE';
const PRODUCT_CATEGORY_CREATE_CHANGE               = 'PRODUCT_CATEGORY_CREATE_CHANGE';

const PRODUCT_CATEGORY_CREATING_CHANGE             = 'PRODUCT_CATEGORY_CREATING_CHANGE';

const PRODUCT_CATEGORY_DATATABLE_CHANGE            = 'PRODUCT_CATEGORY_DATATABLE_CHANGE';
const PRODUCT_CATEGORY_DATATABLE_LOADING_CHANGE    = 'PRODUCT_DATATABLE_LOADING_CHANGE';

const PRODUCT_CATEGORY_ACTIVE_LOADING_CHANGE       = 'PRODUCT_CATEGORY_ACTIVE_LOADING_CHANGE';
const PRODUCT_CATEGORY_ACTIVE_CHANGE               = 'PRODUCT_CATEGORY_ACTIVE_CHANGE';


const state = {
    product_category            : null,
    product_category_loading    : false,

    product_category_saving     : false,
    product_category_errors     : {},

    product_category_list_total    : 0,
    product_category_list           : null,
    product_category_list_loading   : false,

    product_category_active_total    : 0,
    product_category_active           : null,
    product_category_active_loading   : false,

    product_category_creating           : false,
    product_category_creating_errors    : {},
    product_category_empty              : {
                                            id: 0,
                                            name: "",
                                            organization: "",
                                            is_work: 1
                                        }
};

const actions = {
    [PRODUCT_CATEGORY_LOAD] ({ commit, state }, id) {
        if(!id) {
            commit(PRODUCT_CATEGORY_CHANGE, state.product_category_empty);

            return;
        }

        return api.load(
            'product_category',
            id,
            state,
            commit,
            PRODUCT_CATEGORY_LOADING_CHANGE,
            PRODUCT_CATEGORY_CHANGE
        );
    },

    [PRODUCT_CATEGORY_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update(
            'product_category',
            id,
            model,
            state,
            commit,
            PRODUCT_CATEGORY_SAVING_CHANGE,
            PRODUCT_CATEGORY_CHANGE,
            PRODUCT_CATEGORY_ERRORS_CHANGE
        );
    },

    [PRODUCT_CATEGORY_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create(
            'product_category.list',
            id,
            model,
            state,
            commit,
            PRODUCT_CATEGORY_SAVING_CHANGE,
            PRODUCT_CATEGORY_CREATE_CHANGE,
            PRODUCT_CATEGORY_ERRORS_CHANGE
        );
    },

    [PRODUCT_CATEGORY_LOAD_DATATABLE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.list(
            'product_category.list',
            id,
            null,
            state,
            commit,
            PRODUCT_CATEGORY_DATATABLE_LOADING_CHANGE,
            PRODUCT_CATEGORY_DATATABLE_CHANGE
        );
    },

    [PRODUCT_CATEGORY_ACTIVE_LOAD] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.list(
            'product_category.active',
            id,
            null,
            state,
            commit,
            PRODUCT_CATEGORY_ACTIVE_LOADING_CHANGE,
            PRODUCT_CATEGORY_ACTIVE_CHANGE
        );
    }
};

const mutations = {
    [PRODUCT_CATEGORY_DATATABLE_LOADING_CHANGE](state, data) {
        state.product_category_list_loading = data;
    },
    [PRODUCT_CATEGORY_ACTIVE_LOADING_CHANGE](state, data) {
        state.product_category_active_loading = data;
    },
    [PRODUCT_CATEGORY_DATATABLE_CHANGE](state, data) {

        if (data) {
            state.product_category_list          = data.data;
            state.product_category_list_total    = data.total;
        } else {
            state.product_category_list          = [];
            state.product_category_list_total    = 0;
        }

    },
    [PRODUCT_CATEGORY_ACTIVE_CHANGE](state, data) {
        if (data) {
            state.product_category_active          = data.data;
            state.product_category_active_total    = data.total;
        } else {
            state.product_category_active          = [];
            state.product_category_active_total    = 0;
        }
    },
    [PRODUCT_CATEGORY_CHANGE](state, data) {
        state.product_category  = data;

        if (data) {
            if (state.product_category_list) {
                let list = [];
                state.product_category_list.forEach(product_category => {
                    if (product_category.id == data.id) {
                        list.push({...data});
                    }
                    else {
                        list.push(product_category);
                    }
                });

                state.product_category_list = list;
            }
        }
    },
    [PRODUCT_CATEGORY_LOADING_CHANGE](state, data) {
        state.product_category_loading = data;
    },
    [PRODUCT_CATEGORY_SAVING_CHANGE](state, data) {
        state.product_category_saving   = data;

    },
    [PRODUCT_CATEGORY_ERRORS_CHANGE](state, data) {
        state.product_category_errors   = data;
    },
    [PRODUCT_CATEGORY_CREATING_CHANGE](state, data) {
        state.product_category_creating = data;
    },
    [PRODUCT_CATEGORY_CREATE_CHANGE](state, data) {
        if (data) {
            if (state.product_category_list) {
                state.product_category_list.unshift({...data});
            }

        }
    }
};

export default {
    state,
    actions,
    mutations
}