import AppLoader from './components/Loader.vue';
Vue.component('app-loader', AppLoader);

import SavingButton from './components/SavingButton.vue';
Vue.component('saving-button', SavingButton);

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import ModelInput from './components/ModelInput';
Vue.component('app-model-input', ModelInput);

import MultipleInput from './components/MultipleInput';
Vue.component('app-multiple-input', MultipleInput);

import Notifications from 'vue-notification';
Vue.use(Notifications);

// import VJstree from 'vue-jstree';
import VJstree from './components/vue-jstree/tree';
Vue.component('v-jstree', VJstree);

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)
