import http from './../../lib/http';
import api from './../../lib/api';

export const PACKED_LOAD_DATATABLE       = 'PACKED_LOAD_DATATABLE';

const PACKED_DATATABLE_LOADING_CHANGE    = 'PACKED_DATATABLE_LOADING_CHANGE';
const PACKED_DATATABLE_CHANGE            = 'PACKED_DATATABLE_CHANGE';

export const PACKED_CREATE               = 'PACKED_CREATE';
const PACKED_CREATE_CHANGE               = 'PACKED_CREATE_CHANGE';
const PACKED_SAVING_CHANGE               = 'PACKED_SAVING_CHANGE';
const PACKED_ERRORS_CHANGE               = 'PACKED_ERRORS_CHANGE';

export const PACKED_UPDATE               = 'PACKED_UPDATE';

export const PACKED_COUNT                = 'PACKED_COUNT';
const PACKED_COUNT_CHANGE                = 'PACKED_COUNT_CHANGE';

export const PACKED_TEST_LOAD_DATATABLE           = 'PACKED_TEST_LOAD_DATATABLE';
const PACKED_TEST_DATATABLE_LOADING_CHANGE        = 'PACKED_TEST_DATATABLE_LOADING_CHANGE';
const PACKED_TEST_DATATABLE_CHANGE                = 'PACKED_TEST_DATATABLE_CHANGE';

export const PACKED_BARCODE_LOAD_DATATABLE        = 'PACKED_BARCODE_LOAD_DATATABLE';

export const PACKED_GEO_LOAD_DATATABLE            = 'PACKED_GEO_LOAD_DATATABLE';
const PACKED_GEO_LOADING_CHANGE                   = 'PACKED_GEO_LOADING_CHANGE';
const PACKED_GEO_CHANGE                           = 'PACKED_GEO_CHANGE';

export const WHO_PACKED_LOAD_DATATABLE            = 'WHO_PACKED_LOAD_DATATABLE';

const state = {
    packed_list           : null,
    packed_list_total     : 0,
    packed_list_loading   : false,
    packed_saving         : false,
    packed_creating       : false,
    packed_errors         : [],
    packed_count          : 0,
    packed_test_list           : null,
    packed_test_list_total     : 0,
    packed_test_list_loading   : false,
    packed_geo_loading         : false,
    packed_geo                 : {},
    packed_geo_total           : 0,
};

const actions = {
    [PACKED_LOAD_DATATABLE] ({ commit, state }, filter) {
        return api.list(
            'packed.list',
            null,
            filter,
            state,
            commit,
            PACKED_DATATABLE_LOADING_CHANGE,
            PACKED_DATATABLE_CHANGE);
    },
    [PACKED_CREATE] ({ commit, state }, data) {
        return api.create(
            'packed',
            0,
            data,
            state,
            commit,
            PACKED_SAVING_CHANGE,
            PACKED_CREATE_CHANGE,
            PACKED_ERRORS_CHANGE
        );
    },
    [PACKED_UPDATE] ({ commit, state }, data) {
        let id      = data.barcode;
        let model   = data;

        return api.update(
            'unpacked.up',
            0,
            model,
            state,
            commit,
            PACKED_SAVING_CHANGE,
            PACKED_CREATE_CHANGE,
            PACKED_ERRORS_CHANGE
        );
    },
    [PACKED_COUNT] ({ commit, state }, filter) {
        return api.list(
            'packed.count',
            null,
            filter,
            state,
            commit,
            PACKED_DATATABLE_LOADING_CHANGE,
            PACKED_COUNT_CHANGE
        );
    },
    [PACKED_TEST_LOAD_DATATABLE] ({ commit, state }, filter) {
        return api.list(
            'packed.test',
            null,
            filter,
            state,
            commit,
            PACKED_TEST_DATATABLE_LOADING_CHANGE,
            PACKED_TEST_DATATABLE_CHANGE);
    },
    [PACKED_BARCODE_LOAD_DATATABLE] ({ commit, state }, filter) {
        return api.list(
            'packed.barcodes',
            null,
            filter,
            state,
            commit,
            PACKED_DATATABLE_LOADING_CHANGE,
            PACKED_DATATABLE_CHANGE);
    },
    [WHO_PACKED_LOAD_DATATABLE] ({ commit, state }, filter) {
        return api.list(
            'packed.who_packed',
            null,
            filter,
            state,
            commit,
            PACKED_DATATABLE_LOADING_CHANGE,
            PACKED_DATATABLE_CHANGE);
    },
    [PACKED_GEO_LOAD_DATATABLE] ({ commit, state }, filter) {
        return api.list(
            'packed.geo',
            null,
            filter,
            state,
            commit,
            PACKED_GEO_LOADING_CHANGE,
            PACKED_GEO_CHANGE);
    },
};
const mutations = {
    [PACKED_DATATABLE_LOADING_CHANGE](state, data) {
        state.packed_list_loading = data;
    },
    [PACKED_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.packed_list        = data.data;
            state.packed_list_total  = data.total;
        }
        else {
            state.packed_list        = [];
            state.packed_list_total  = 0;
        }
    },
    [PACKED_SAVING_CHANGE](state, data) {
        state.packed_saving   = data;
    },
    [PACKED_ERRORS_CHANGE](state, data) {
        state.packed_errors   = data;
    },
    [PACKED_CREATE_CHANGE](state, data) {
        if (data) {
            // if (state.packed_list) {
                // state.packed_list.unshift({...data});
            // }
        }
    },
    [PACKED_COUNT_CHANGE](state, data) {
        if (data) {
            state.packed_count   = data;
        }
    },
    [PACKED_TEST_DATATABLE_LOADING_CHANGE](state, data) {
        state.packed_test_list_loading = data;
    },
    [PACKED_TEST_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.packed_test_list        = data.data;
            state.packed_test_list_total  = data.total;
        }
        else {
            state.packed_test_list        = [];
            state.packed_test_list_total  = 0;
        }
    },
    [PACKED_GEO_LOADING_CHANGE](state, data) {
        state.packed_geo_loading = data;
    },
    [PACKED_GEO_CHANGE](state, data) {
        if(data) {
            state.packed_geo        = data.data;
            state.packed_geo_total  = data.total;
        }
        else {
            state.packed_geo        = [];
            state.packed_geo_total  = 0;
        }
    },
};

export default {
    state,
    actions,
    mutations
}
