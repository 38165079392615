import api from '../../lib/api';
import http from "@/lib/http";

export const SOLUTIONS_DRAFT_COUNT_LOAD = 'SOLUTIONS_DRAFT_COUNT_LOAD'
const SOLUTIONS_DRAFT_COUNT_CHANGE = 'SOLUTIONS_DRAFT_COUNT_CHANGE'


const state = {
  draftedSolutionCount: 1
}

const actions = {
  /**
   * Получить количество черновиков
   * @param commit
   * @param state
   * @param params
   * @returns {*}
   */
  [SOLUTIONS_DRAFT_COUNT_LOAD]({commit}) {
      return http.get(`${api.main_url}/api/v2/bpm/solution-manager/solutions/drafts/count`).then((response) => {
        let solutions = response.data.data;
        commit(SOLUTIONS_DRAFT_COUNT_CHANGE, solutions)
        return solutions;
      });
  },
}

const mutations = {
  [SOLUTIONS_DRAFT_COUNT_CHANGE](state, data) {
    if (data) {
      state.draftedSolutionCount = data.draft_count;
    }
  },
}

export default {
  state,
  actions,
  mutations,
}