import http from './../../lib/http';
import api from './../../lib/api';

export const ATS_MONITOR_DIAL_COEFF_LOAD = 'ATS_MONITOR_DIAL_COEFF_LOAD';
export const ATS_MONITOR_CURRENT_CALLS_LOAD = 'ATS_MONITOR_CURRENT_CALLS_LOAD';
export const ATS_MONITOR_OPER_STATES_LOAD = 'ATS_MONITOR_OPER_STATES_LOAD';
export const ATS_MONITOR_MINI_ANALYTICS_LOAD = 'ATS_MONITOR_MINI_ANALYTICS_LOAD';
export const ATS_MONITOR_LAGS_LOAD = 'ATS_MONITOR_LAGS_LOAD';
export const ATS_MONITOR_MOVE_UP = 'ATS_MONITOR_MOVE_UP';
export const ATS_MONITOR_MOVE_DOWN = 'ATS_MONITOR_MOVE_DOWN';

const ATS_MONITOR_DIAL_COEFF_LOADING_CHANGE = 'ATS_MONITOR_DIAL_COEFF_LOADING_CHANGE';
const ATS_MONITOR_DIAL_COEFF_CHANGE = 'ATS_MONITOR_DIAL_COEFF_CHANGE';
const ATS_MONITOR_CURRENT_CALLS_LOADING_CHANGE = 'ATS_MONITOR_CURRENT_CALLS_LOADING_CHANGE';
const ATS_MONITOR_CURRENT_CALLS_CHANGE = 'ATS_MONITOR_CURRENT_CALLS_CHANGE';
const ATS_MONITOR_OPER_STATES_LOADING_CHANGE = 'ATS_MONITOR_OPER_STATES_LOADING_CHANGE';
const ATS_MONITOR_OPER_STATES_CHANGE = 'ATS_MONITOR_OPER_STATES_CHANGE';
const ATS_MONITOR_MINI_ANALYTICS_LOADING_CHANGE = 'ATS_MONITOR_MINI_ANALYTICS_LOADING_CHANGE';
const ATS_MONITOR_MINI_ANALYTICS_CHANGE = 'ATS_MONITOR_MINI_ANALYTICS_CHANGE';
const ATS_MONITOR_LAGS_LOADING_CHANGE = 'ATS_MONITOR_LAGS_LOADING_CHANGE';
const ATS_MONITOR_LAGS_CHANGE = 'ATS_MONITOR_LAGS_CHANGE';
const ATS_MONITOR_LAGS_MOVED = 'ATS_MONITOR_LAGS_MOVED';
const ATS_MONITOR_LAGS_UPDATE_CHANGE = 'ATS_MONITOR_LAGS_UPDATE_CHANGE';
const ATS_MONITOR_LAGS_ERRORS_CHANGE = 'ATS_MONITOR_LAGS_ERRORS_CHANGE';

const state = {
    ats_monitor_dial_coeff_loading: false,    
    ats_monitor_dial_coeff: [],
    ats_monitor_dial_coeff_total: 0,
    
    ats_monitor_current_calls_loading: false,    
    ats_monitor_current_calls: [],
    
    ats_monitor_oper_states_loading: false,    
    ats_monitor_oper_states: [],
    
    ats_monitor_mini_analytics_loading: false,    
    ats_monitor_mini_analytics: [],
    
    ats_monitor_lags_loading: false,    
    ats_monitor_lags_updating: false,
    ats_monitor_lags_errors: false,
    ats_monitor_lags: [],
};

const actions = {
    [ATS_MONITOR_DIAL_COEFF_LOAD] ({commit, state}, params) {        
        return api.list(
            'ats_monitoring.dial_coeff',
            0,
            params,
            state,
            commit,
            ATS_MONITOR_DIAL_COEFF_LOADING_CHANGE,
            ATS_MONITOR_DIAL_COEFF_CHANGE
        );
    },
    [ATS_MONITOR_CURRENT_CALLS_LOAD] ({commit, state}) {        
        return api.load(
            'ats_monitoring.current_calls',
            0,
            state,
            commit,
            ATS_MONITOR_CURRENT_CALLS_LOADING_CHANGE,
            ATS_MONITOR_CURRENT_CALLS_CHANGE
        );
    },
    [ATS_MONITOR_OPER_STATES_LOAD] ({commit, state}) {        
        return api.load(
            'ats_monitoring.oper_states',
            0,
            state,
            commit,
            ATS_MONITOR_OPER_STATES_LOADING_CHANGE,
            ATS_MONITOR_OPER_STATES_CHANGE
        );
    },
    [ATS_MONITOR_MINI_ANALYTICS_LOAD] ({commit, state}, params) {
        return api.list(
            'ats_monitoring.mini_analytics',
            0,
            params,
            state,
            commit,
            ATS_MONITOR_MINI_ANALYTICS_LOADING_CHANGE,
            ATS_MONITOR_MINI_ANALYTICS_CHANGE
        );
    },
    [ATS_MONITOR_LAGS_LOAD] ({commit, state}, queue_id) {
        return api.load(
            'ats_monitoring.get_lags',
            queue_id,
            state,
            commit,
            ATS_MONITOR_LAGS_LOADING_CHANGE,
            ATS_MONITOR_LAGS_CHANGE
        );
    },
    [ATS_MONITOR_MOVE_UP] ({commit, state}, orders) {
        return api.update(
            'ats_monitoring.move_up_orders',
            0,
            orders,
            state,
            commit,
            ATS_MONITOR_LAGS_UPDATE_CHANGE,
            ATS_MONITOR_LAGS_MOVED,
            ATS_MONITOR_LAGS_ERRORS_CHANGE);
    },
    [ATS_MONITOR_MOVE_DOWN] ({commit, state}, orders) {
        return api.update(
            'ats_monitoring.move_down_orders',
            0,
            orders,
            state,
            commit,
            ATS_MONITOR_LAGS_UPDATE_CHANGE,
            ATS_MONITOR_LAGS_MOVED,
            ATS_MONITOR_LAGS_ERRORS_CHANGE);
    },
};

const mutations = {
    [ATS_MONITOR_DIAL_COEFF_LOADING_CHANGE] (state, data) {
        state.ats_monitor_dial_coeff_loading = data;
    },
    [ATS_MONITOR_DIAL_COEFF_CHANGE] (state, data) {
        if(data) {
            state.ats_monitor_dial_coeff = data.data;
            state.ats_monitor_dial_coeff_total = data.total;
        }
        else {
            state.ats_monitor_dial_coeff = [];
            state.ats_monitor_dial_coeff_total = 0;
        }
    },
    [ATS_MONITOR_CURRENT_CALLS_LOADING_CHANGE] (state, data) {
        state.ats_monitor_current_calls_loading = data;
    },
    [ATS_MONITOR_CURRENT_CALLS_CHANGE] (state, data) {
        if(data) {
            state.ats_monitor_current_calls = data.data;
        }
        else {
            state.ats_monitor_current_calls = [];
        }
    },
    [ATS_MONITOR_OPER_STATES_LOADING_CHANGE] (state, data) {
        state.ats_monitor_oper_states_loading = data;
    },
    [ATS_MONITOR_OPER_STATES_CHANGE] (state, data) {
        if(data) {
            state.ats_monitor_oper_states = data.data;
        }
        else {
            state.ats_monitor_oper_states = [];
        }
    },
    [ATS_MONITOR_MINI_ANALYTICS_LOADING_CHANGE] (state, data) {
        state.ats_monitor_mini_analytics_loading = data;
    },
    [ATS_MONITOR_MINI_ANALYTICS_CHANGE] (state, data) {
        if(data) {
            state.ats_monitor_mini_analytics = data.data;
        }
        else {
            state.ats_monitor_mini_analytics = [];
        }
    },
    [ATS_MONITOR_LAGS_LOADING_CHANGE] (state, data) {
        state.ats_monitor_lags_loading = data;
    },
    [ATS_MONITOR_LAGS_CHANGE] (state, data) {
        if(data) {
            state.ats_monitor_lags = data.data;
        }
        else {
            state.ats_monitor_lags = [];
        }
    },
    [ATS_MONITOR_LAGS_UPDATE_CHANGE] (state, data) {
        state.ats_monitor_lags_updating = data;
    },
    [ATS_MONITOR_LAGS_ERRORS_CHANGE] (state, data) {
        state.ats_monitor_lags_errors = data;
    },
    [ATS_MONITOR_LAGS_MOVED] (state, data) {
        console.log(data);
    },
};

export default {
    state,
    actions,
    mutations
}