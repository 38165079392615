import api from './../../lib/api';

export const OPT_TRAFFIC_SOURCES_LIST_LOAD         = "OPT_TRAFFIC_SOURCES_LIST_LOAD";
export const OPT_TRAFFIC_SOURCE_LOAD               = "OPT_TRAFFIC_SOURCE_LOAD";
export const OPT_TRAFFIC_SOURCE_CREATE             = "OPT_TRAFFIC_SOURCE_CREATE";
export const OPT_TRAFFIC_SOURCE_UPDATE             = "OPT_TRAFFIC_SOURCE_UPDATE";

const OPT_TRAFFIC_SOURCES_LIST_CHANGE              = "OPT_TRAFFIC_SOURCES_LIST_CHANGE";
const OPT_TRAFFIC_SOURCES_LIST_LOADING_CHANGE      = "OPT_TRAFFIC_SOURCES_LIST_LOADING_CHANGE";

const OPT_TRAFFIC_SOURCE_CHANGE                    = "OPT_TRAFFIC_SOURCE_CHANGE";
const OPT_TRAFFIC_SOURCE_LOADING_CHANGE            = "OPT_TRAFFIC_SOURCE_LOADING_CHANGE";
const OPT_TRAFFIC_SOURCE_ERRORS_CHANGE             = "OPT_TRAFFIC_SOURCE_ERRORS_CHANGE";
const OPT_TRAFFIC_SOURCE_SAVING_CHANGE             = "OPT_TRAFFIC_SOURCE_SAVING_CHANGE";

const OPT_TRAFFIC_SOURCE_CREATING_CHANGE           = "OPT_TRAFFIC_SOURCE_CREATING_CHANGE";
const OPT_TRAFFIC_SOURCE_CREATING_ERRORS_CHANGE    = "OPT_TRAFFIC_SOURCE_CREATING_ERRORS_CHANGE";

export const OPT_TRAFFIC_SOURCE_DELETE		       = 'OPT_TRAFFIC_SOURCE_DELETE';
export const OPT_TRAFFIC_SOURCE_DELETE_CHANGE  	   = 'OPT_TRAFFIC_SOURCE_DELETE_CHANGE';

const state = {
    opt_traffic_source: null,
    opt_traffic_source_loading: false,
    opt_traffic_source_saving: false,
    opt_traffic_source_errors: {},

    opt_traffic_source_list: null,
    opt_traffic_source_list_loading: false,

    opt_traffic_source_creating: false,
    opt_traffic_source_creating_errors: {},
    opt_traffic_source_empty: {
        id: 0,
        name: "",
        is_show: false
    }
};

const actions = {
    [OPT_TRAFFIC_SOURCES_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'opt_traffic_sources',
            id,
            params,
            state,
            commit,
            OPT_TRAFFIC_SOURCES_LIST_LOADING_CHANGE,
            OPT_TRAFFIC_SOURCES_LIST_CHANGE,
        );
    },


    [OPT_TRAFFIC_SOURCE_LOAD]({state, commit}, id) {
        if (!id) {
            commit(OPT_TRAFFIC_SOURCE_CHANGE, null);
            return
        }

        return api.load(
            'opt_traffic_source',
            id,
            state,
            commit,
            OPT_TRAFFIC_SOURCE_LOADING_CHANGE,
            OPT_TRAFFIC_SOURCE_CHANGE,
        );
    },
    [OPT_TRAFFIC_SOURCE_CREATE]({state, commit}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create(
            'opt_traffic_sources',
            id,
            model,
            state,
            commit,
            OPT_TRAFFIC_SOURCE_CREATING_CHANGE,
            OPT_TRAFFIC_SOURCE_CHANGE,
            OPT_TRAFFIC_SOURCE_CREATING_ERRORS_CHANGE,
        );
    },
    [OPT_TRAFFIC_SOURCE_UPDATE]({state, commit}, data) {
        let id = data.id;
        let model = data.model;

        return api.update(
            'opt_traffic_source',
            id,
            model,
            state,
            commit,
            OPT_TRAFFIC_SOURCE_SAVING_CHANGE,
            OPT_TRAFFIC_SOURCE_CHANGE,
            OPT_TRAFFIC_SOURCE_ERRORS_CHANGE,
        );
    },
    [OPT_TRAFFIC_SOURCE_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'opt_traffic_source',
            id,
            state,
            commit,
            OPT_TRAFFIC_SOURCE_DELETE_CHANGE,
            OPT_TRAFFIC_SOURCE_CHANGE,
            OPT_TRAFFIC_SOURCE_ERRORS_CHANGE,
        );
    },
};

const mutations = {
    [OPT_TRAFFIC_SOURCES_LIST_CHANGE](state, data) {
        if (data) {
            state.opt_traffic_source_list = data.data ? data.data : data;
        }
    },
    [OPT_TRAFFIC_SOURCES_LIST_LOADING_CHANGE](state, data) {
        state.opt_traffic_source_list_loading = data;
    },
    [OPT_TRAFFIC_SOURCE_CHANGE](state, data) {
        if (data) {
            state.opt_traffic_source = data;
            return;
        }
        state.opt_traffic_source = state.opt_traffic_source_empty;
    },
    [OPT_TRAFFIC_SOURCE_LOADING_CHANGE](state, data) {
        state.opt_traffic_source_loading = data;
    },
    [OPT_TRAFFIC_SOURCE_ERRORS_CHANGE](state, data) {
        state.opt_traffic_source_errors = data;
    },
    [OPT_TRAFFIC_SOURCE_SAVING_CHANGE](state, data) {
        state.opt_traffic_source_saving = data;
    },
    [OPT_TRAFFIC_SOURCE_CREATING_CHANGE](state, data) {
        state.opt_traffic_source_creating = data;
    },
    [OPT_TRAFFIC_SOURCE_CREATING_ERRORS_CHANGE](state, data) {
        state.opt_traffic_source_creating_errors = data;
    },
    [OPT_TRAFFIC_SOURCE_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.opt_traffic_source_list) {

                let list = [];
                state.opt_traffic_source_list.forEach(opt_traffic_source => {
                    if (opt_traffic_source.id !== id) {
                        list.push({...opt_traffic_source});
                    }
                });

                state.opt_traffic_source_list = list;
            }
        }
    },
};

export default {
    state,
    actions,
    mutations,
}