import api from './../../lib/api';

// Загрузить АТС с бэка по ID
export const TARIFF_MESSENGER_LOAD      = 'TARIFF_MESSENGER_LOAD';
export const TARIFF_MESSENGER_SAVE      = 'TARIFF_MESSENGER_SAVE';
export const TARIFF_MESSENGER_DATATABLE = 'TARIFF_MESSENGER_DATATABLE';
export const TARIFF_MESSENGER_SET_EMPTY = 'TARIFF_MESSENGER_SET_EMPTY';

const TARIFF_MESSENGER_LOADING_CHANGE   = 'TARIFF_MESSENGER_LOADING_CHANGE';
const TARIFF_MESSENGER_CHANGE           = 'TARIFF_MESSENGER_CHANGE';
const ERRORS_TARIFF_MESSENGER_LOAD      = 'ERRORS_TARIFF_MESSENGER_LOAD';
const LOADING                           = 'LOADING';
const LOADED_UPDATE                     = 'LOADED_UPDATE';
const ERRORS_UPDATE                     = 'ERRORS_UPDATE';
const TARIFF_MESSENGER_DATATABLE_LOADING_CHANGE   = 'TARIFF_MESSENGER_DATATABLE_LOADING_CHANGE';
const TARIFF_MESSENGER_DATATABLE_CHANGE           = 'TARIFF_MESSENGER_DATATABLE_CHANGE';
const SET_EMPTY                         = 'SET_EMPTY';

const org_empty = () => {
  return {
    info: null,
    tariffs_messengers: []
  }
}

const state = {
  tmessenger              : org_empty(),
  tmessenger_loading      : {},
  tmessenger_errors       : {},
  loading                 : {},
  tmessenger_list_loading : false,
  tmessenger_list         : null,
  tmessenger_list_total   : 0
};

const actions = {
  [TARIFF_MESSENGER_LOAD] ({ commit, state }, id) {
    return api.load(
      'tariff_messenger',
      id,
      state,
      commit,
      TARIFF_MESSENGER_LOADING_CHANGE,
      TARIFF_MESSENGER_CHANGE,
      ERRORS_TARIFF_MESSENGER_LOAD
    );
  },
  [TARIFF_MESSENGER_DATATABLE] ({ commit, state }, data) {
    return api.list(
      'tariff_messenger.list', 
      0,
      data,
      state,
      commit,
      TARIFF_MESSENGER_DATATABLE_LOADING_CHANGE, 
      TARIFF_MESSENGER_DATATABLE_CHANGE
    );
  },
  [TARIFF_MESSENGER_SAVE]({ commit, state }, data) {
    if (data.type != "new") {
      return api.update('tariff_messenger.update', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
    } else {
      return api.create('tariff_messenger.create', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
    }
  },
  [TARIFF_MESSENGER_SET_EMPTY]({ commit, state }) {
    commit(SET_EMPTY);
  },
};

const mutations = {
    [TARIFF_MESSENGER_LOADING_CHANGE] (state, data) {
      state.tmessenger_loading = data;
    },
    [TARIFF_MESSENGER_CHANGE] (state, data) {
      if(data){
        state.tmessenger = data;
      }
    },
    [ERRORS_TARIFF_MESSENGER_LOAD](state, data) {
      state.tmessenger_errors = data;
    },
    [LOADING](state, data) {
      state.loading        = data;
    },
    [LOADED_UPDATE](state, data) {
      if (data) {
        state.tmessenger = data;
      }
    },
    [ERRORS_UPDATE](state, data) {
      state.tmessenger_errors = data;
    },
    [TARIFF_MESSENGER_DATATABLE_LOADING_CHANGE](state, data) {
      state.tmessenger_list_loading = data;
    },
    [TARIFF_MESSENGER_DATATABLE_CHANGE](state, data) {
      if(data) {
        state.tmessenger_list        = data.data;
        state.tmessenger_list_total  = data.total;
      } else {
        state.tmessenger_list        = [];
        state.tmessenger_list_total  = 0;
      }
    },
    [SET_EMPTY](state) {
      state.tmessenger = org_empty();
    },
};

export default {
  state,
  actions,
  mutations
}
