import get_main_url from './get_main_url';
import http from './http';

let main_url = get_main_url.mainUrl;

let responseFilter = (name, val) => {
    if (name == 'Authorization' && typeof val == 'string' && val.length > 6 && val.substr(0, 6) == 'bearer') {
        val = 'bearer (скрыто)'
    } else if (name == 'request') {
        return undefined;
    }
    return val;
}

const log = {
    error(err, vm, info) {
        let msg = ''
        let uri = vm.$el ? vm.$el.baseURI : ''
        let trace = (el, tab = '---> ') => {
            let name = el.$options.name
            let file = el.$options.__file
            if (!name) {
                if (file) {
                    name = file.split('/').pop()
                    name = name.substr(0, name.length - 4)
                } else {
                    name = el.$parent ? 'Anonimous' : 'Root'
                }
            }
            file = file ? ' at ' + file : ''
            msg += tab + '<'+name+'>' + file + "\n"
            if (tab == '---> ') tab = '      '
            if (el.$parent) trace(el.$parent, tab + '  ')
        }
        trace(vm)
        // eslint-disable-next-line
        console.error('Error', info+"\n", err,"\nOn page "+uri,"\n\nfound in\n\n",msg)
        let data = {
            info: info,
            error: err.name + ": " + err.message,
            uri: uri,
            data: msg,
            stack: err.stack,
        }
        log.toBack(data, 'frontError')
        // eslint-disable-next-line
        Vue.notify({
            group: "app",
            type: "error",
            title: "Error: " + info,
            text: err.name + ": " + err.message + "<br/><i>logged " + new Date().toLocaleString() + "</i>",
            duration: 5000
        });
    },
    toBack(info, action) {
        let data = {
          'info' : info,
          'action' : action,
        };

        // eslint-disable-next-line
        http.post(main_url+'/api/v2/log_activities/add_to_log', data)
    },
    notFound(response) {
        let json = JSON.stringify(response, responseFilter)
        log.toBack(JSON.parse(json), 'notFound')
    },
}

export default log
