import http from './../../lib/http';
import api from './../../lib/api';

export const AUDIT_ORDERS_LOAD_DATATABLE       = 'AUDIT_ORDERS_LOAD_DATATABLE';

const AUDIT_ORDERS_DATATABLE_LOADING_CHANGE    = 'AUDIT_ORDERS_DATATABLE_LOADING_CHANGE';
const AUDIT_ORDERS_DATATABLE_CHANGE            = 'AUDIT_ORDERS_DATATABLE_CHANGE';

const state = {
    audit_orders_list_total     : 0,
    audit_orders_list           : null,
    audit_orders_list_loading   : false
};

const actions = {
    [AUDIT_ORDERS_LOAD_DATATABLE] ({ commit, state }, filter) {
        return api.list(
            'audit_orders.list',
            null,
            filter,
            state,
            commit,
            AUDIT_ORDERS_DATATABLE_LOADING_CHANGE,
            AUDIT_ORDERS_DATATABLE_CHANGE);
    }
};

const mutations = {
    [AUDIT_ORDERS_DATATABLE_LOADING_CHANGE](state, data) {
        state.audit_orders_list_loading = data;
    },
    [AUDIT_ORDERS_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.audit_orders_list        = data.data;
            state.audit_orders_list_total  = data.total;
        }
        else {
            state.audit_orders_list        = [];
            state.audit_orders_list_total  = 0;
        }
    }
};

export default {
    state,
    actions,
    mutations
}
