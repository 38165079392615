import http from './../../lib/http';
import api from './../../lib/api';

export const OTSLEDI_LOAD_DATATABLE       = 'OTSLEDI_LOAD_DATATABLE';
export const OTSLEDI_RUN       = 'OTSLEDI_RUN';

const OTSLEDI_DATATABLE_LOADING_CHANGE            = 'OTSLEDI_DATATABLE_LOADING_CHANGE';
const OTSLEDI_DATATABLE_CHANGE            = 'OTSLEDI_DATATABLE_CHANGE';

const OTSLEDI_RUN_CHANGE    = 'OTSLEDI_RUN_CHANGE';
const OTSLEDI_CHANGE    = 'OTSLEDI_CHANGE';
const OTSLEDI_ERRORS_CHANGE            = 'OTSLEDI_ERRORS_CHANGE';

const state = {
    otsledi_list_total     : 0,
    otsledi_list           : null,
    otsledi_list_loading   : false,
    otsledi: null,
    otsledi_errors: {},
    otsledi_running:false
};

const actions = {
    [OTSLEDI_LOAD_DATATABLE] ({ commit, state }, data) {

        return api.list(
            'otsledi.list',
            null,
            data,
            state,
            commit,
            OTSLEDI_DATATABLE_LOADING_CHANGE,
            OTSLEDI_DATATABLE_CHANGE);
    },

    [OTSLEDI_RUN]({ commit, state }, data) {
        let model = null;
        let id = 0;

        return api.create(
            "otsledi.run",
            id,
            model,
            state,
            commit,
            OTSLEDI_RUN_CHANGE,
            OTSLEDI_CHANGE,
            OTSLEDI_ERRORS_CHANGE
      );
    },
};

const mutations = {

    [OTSLEDI_DATATABLE_LOADING_CHANGE](state, data) {
        state.otsledi_list_loading = data;
    },
    [OTSLEDI_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.otsledi_list        = data.data;
            state.otsledi_list_total  = data.total;
        }
        else {
            state.otsledi_list        = [];
            state.otsledi_list_total  = 0;
        }

    },
    [OTSLEDI_CHANGE](state, data) {
      if(data) {
        state.otsledi        = data.data;
      }
      else {
        state.otsledi        = [];
      }
    },
    [OTSLEDI_RUN_CHANGE](state, data) {
      state.otsledi_running = data;
    },
    [OTSLEDI_ERRORS_CHANGE](state, data) {
      state.otsledi_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}
