import api from './../../lib/api';

export const OPERATOR_SCRIPT_SAVE                 = 'OPERATOR_SCRIPT_SAVE';

export const OPERATOR_SCRIPT_DELETE               = 'OPERATOR_SCRIPT_DELETE';

export const OPERATOR_SCRIPT_LOAD_DATATABLE       = 'OPERATOR_SCRIPT_LOAD_DATATABLE';

const OPERATOR_SCRIPT_UPDATE_CHANGE               = 'OPERATOR_SCRIPT_UPDATE_CHANGE';
const OPERATOR_SCRIPT_SAVING_CHANGE               = 'OPERATOR_SCRIPT_SAVING_CHANGE';
const OPERATOR_SCRIPT_ERRORS_CHANGE               = 'OPERATOR_SCRIPT_ERRORS_CHANGE';
const OPERATOR_SCRIPT_CREATE_CHANGE               = 'OPERATOR_SCRIPT_CREATE_CHANGE';

const OPERATOR_SCRIPT_DATATABLE_CHANGE            = 'OPERATOR_SCRIPT_DATATABLE_CHANGE';
const OPERATOR_SCRIPT_DATATABLE_LOADING_CHANGE    = 'OPERATOR_SCRIPT_DATATABLE_LOADING_CHANGE';

const OPERATOR_SCRIPT_DELETE_CHANGE               = 'OPERATOR_SCRIPT_DELETE_CHANGE';

const state = {
  operator_script            : null,

  operator_script_saving     : false,
  operator_script_errors     : {},

  operator_script_list_total   : 0,
  operator_script_list         : null,
  operator_script_list_loading : false,
};

const actions = {
  [OPERATOR_SCRIPT_SAVE] ({ commit, state }, data) {
    if (data.id) {
      // обновление
      return api.create(
        'operator_script',
        data.id,
        data.data,
        state,
        commit,
        OPERATOR_SCRIPT_SAVING_CHANGE,
        OPERATOR_SCRIPT_UPDATE_CHANGE,
        OPERATOR_SCRIPT_ERRORS_CHANGE
      );
    } else {
      // создание
      return api.create(
        'operator_scripts',
        0,
        data.data,
        state,
        commit,
        OPERATOR_SCRIPT_SAVING_CHANGE,
        OPERATOR_SCRIPT_CREATE_CHANGE,
        OPERATOR_SCRIPT_ERRORS_CHANGE
      );
    }
  },

  [OPERATOR_SCRIPT_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'operator_scripts',
      0,
      filter,
      state,
      commit,
      OPERATOR_SCRIPT_DATATABLE_LOADING_CHANGE,
      OPERATOR_SCRIPT_DATATABLE_CHANGE
    );
  },

  [OPERATOR_SCRIPT_DELETE] ({ commit, state }, id) {
    if (!id) {
      id  = 0;
    }

    return api.delete('operator_script', id, state, commit, OPERATOR_SCRIPT_DELETE_CHANGE);
  },
};

const mutations = {
  [OPERATOR_SCRIPT_DATATABLE_LOADING_CHANGE](state, data) {
    state.operator_script_list_loading = data;
  },
  [OPERATOR_SCRIPT_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.operator_script_list = data.data;
    }
  },
  [OPERATOR_SCRIPT_UPDATE_CHANGE](state, data) {
    state.operator_script = data;
  },

  [OPERATOR_SCRIPT_SAVING_CHANGE](state, data) {
    state.operator_script_saving   = data;
  },
  [OPERATOR_SCRIPT_ERRORS_CHANGE](state, data) {
    state.operator_script_errors   = data;
  },
  [OPERATOR_SCRIPT_CREATE_CHANGE](state, data) {
    state.operator_script = data;
  },
  [OPERATOR_SCRIPT_DELETE_CHANGE](state, data) {

  },
};

export default {
  state,
  actions,
  mutations
}
