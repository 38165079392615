import api from './../../lib/api';

export const MODERATION_UPDATE_OR_CREATE = 'MODERATION_UPDATE_OR_CREATE';
export const MODERATION_FIRST_OR_CREATE  = 'MODERATION_FIRST_OR_CREATE';
export const MODERATION_LOAD     = 'MODERATION_LOAD';

const MODERATION_LOADING = 'MODERATION_LOADING';
const MODERATION_LOADED  = 'MODERATION_LOADED';
const MODERATION_ERRORS  = 'MODERATION_ERRORS';

const state = {
    moderation: {},
    moderation_empty: {
        approved: null,
        reject_message: null,
    },
    moderation_loading: false,
    moderation_errors: {},
};

const actions = {
    async [MODERATION_UPDATE_OR_CREATE]({ commit, state }, data) {
        const success = await api.update(
            'moderation',
            data.id,
            data,
            state,
            commit,
            MODERATION_LOADING,
            MODERATION_LOADED,
            MODERATION_ERRORS
        ).catch((error) => {
            return Promise.reject(state.moderation_errors);
        });

        if (success) {
            return Promise.resolve(state.moderation);
        }
    },

    [MODERATION_LOAD]({ commit, state }, data) {
        return api.loadByParams(
            'moderation',
            data.id,
            data,
            state,
            commit,
            MODERATION_LOADING,
            MODERATION_LOADED
        );
    },

    [MODERATION_FIRST_OR_CREATE]({ commit, state }, data) {
        return api.loadByParams(
            'moderation.first_or_create',
            data.id,
            data,
            state,
            commit,
            MODERATION_LOADING,
            MODERATION_LOADED
        );
    },
};

const mutations = {
    [MODERATION_LOADING](state, data) {
        state.moderation_loading = data;
    },
    [MODERATION_LOADED](state, data) {
        if (data) {
            state.moderation = data;
        } else {
            state.data = _.cloneDeep(state.moderation_empty);
        }
    },
    [MODERATION_ERRORS](state, data) {
        state.moderation_errors = data;
    },
};

export default {
    state,
    actions,
    mutations
}
