
let lang = {
    list : {
        ru : {
            'USER_CHANGE' : 'Пользователь',
            'USER_LIST_CHANGE' : 'Список пользователей',
            'project' : 'Проект',
            'page' : 'Сайт',
            'key' : 'Ключ заказа',
            'action' : 'Действие',            
            'name' : 'Название',
            'price' : 'Цена',
            'quantity' : 'Количество',
            'comment' : 'Комментарий',
            'upsale' : 'Апсейл',
            'delivery_date_finish' : 'Дата доставки',
            'delivery_time_1' : 'Время доставки с',
            'delivery_time_2' : 'Время доставки по',
            'quantity_price' : 'Цена за количество',
            'uniqued_import_id' : 'Импорт ID',
            'product_code' : 'Код продукта',
            'product_price' : 'Цена продукта',
            'weight' : 'Вес',
            'prime_price' : 'Основная цена',
            'sales' : 'Товар в заказе',
            'orders' : 'Данные в заказе',
            'projects' : 'Проект заказа',
            'statuses' : 'Статусы заказа',
            'delivery_types_id': 'Тип доставки',
            'delivery_types_price': 'Стоимость доставки',
            'phones': 'Телефон',
            'client_name': 'ФИО',
            'full_address': 'Полный адресс',
            'region': 'Область',
            'area': 'Район',
            'city': 'Населенный пункт',
            'street': 'Улица',
            'dial_time': 'Время перезвона',
            'home': 'Дом',
            'housing': 'Корпус',
            'room': 'Квартира',
            'postcode': 'Почтовый индекс',
            'new' : 'Новый',
            'change' : 'Изменен',
            'age_id' : 'Дата рождения',
            'manager_id' : 'Редактирование менеджера',
            'delete' : 'Удален',
            'import_webmaster_id' : 'ID Вебмастера: ',
            'type_dop_info' : 'Тип обращения: ',
            'time_zone' : 'Часовой пояс',
            'import_id' : 'ID другой системы: ',
            'origin_id' : 'ID оригинала: ',
            'warehouse' : 'Адрес почтового отделения',
            'warehouse_id' : 'Идентификатор почтового отделения',
            'call_status.addmember' : 'Клиент добавлен в очередь.',
            'call_status.agentdump'    : 'Агент сбросил звонящего во время прослушивания приглашения очереди.',
            'call_status.agentlogin'  : 'Агент залогинился. Канал записан.',
            'call_status.agentlogoff' : 'Агент вышел.',
            'call_status.completeagent'   : 'Звонок был завершен агентом.',
            'call_status.completecaller'  : 'Звонок был завершен абонентом.', 
            'call_status.configreload'    : 'Конфигурация была перезагружена',
            'call_status.connect' : 'Абонент был соединен с агентом.',
            'call_status.enterqueue'   : 'Звонок пришел в очередь.',
            'call_status.exitempty'    : 'Абонент вышел из очереди потому что в очереди не было доступных агентов',
            'call_status.exitwithkey'  : 'Абонент нажал кнопку выхода из очереди.',
            'call_status.exitwithtimeout' : 'Абонент был выброшен из очереди по таймауту(очень долго ждал).',
            'call_status.ringnoanswer'    : 'Оператор не отвечает',
            'call_status.ringcanceled'    : 'Оператор сбросил',
            'call_status.abandon' : 'Клиент ушел',
            'call_type.auto' : 'Автодозвон',
            'call_type.in' : 'Входящие',
            'call_type.out' : 'Исходящий',
            'main' : 'Общая информация',
            'first_name' : 'Имя',
            'last_name' : 'Фамилия',
            'middle_name' : 'Отчество',
            'user_images' : 'Фотография',
            'call_notify' : 'Звонок обрабатывается, ожидайте',
            'successfully_completed_event' : 'Успешно совершено событие!',
            'removed' : 'Удалено',
        },
        en : {
            'USER_CHANGE': 'User',
            'USER_LIST_CHANGE': 'User List',
            'project': 'Project',
            'page': 'Site',
            'key': 'Order key',
            'action': 'Action',
            'name': 'Name',
            'price': 'Price',
            'quantity': 'Quantity',
            'comment': 'Comment',
            'upsale': 'Upsale',
            'delivery_date_finish': 'Delivery date',
            'delivery_time_1': 'Delivery time from',
            'delivery_time_2': 'Delivery time by',
            'quantity_price': 'Quantity price',
            'uniqued_import_id': 'Import ID',
            'product_code': 'Product code',
            'product_price': 'Product price',
            'weight': 'Weight',
            'prime_price': 'Prime price',
            'sales': 'Item in order',
            'orders': 'Order data',
            'projects': 'Project order',
            'statuses': 'Order statuses',
            'delivery_types_id': 'Delivery type',
            'delivery_types_price': 'Delivery cost',
            'phones': 'Phone',
            'client_name': 'Full name',
            'full_address': 'Full address',
            'region': 'Region',
            'area': 'Area',
            'city': 'City',
            'street': 'Street',
            'dial_time': 'Ringing time',
            'home': 'Home',
            'housing': 'housing',
            'room': 'Apartment',
            'postcode': 'Postcode',
            'new': 'New',
            'change': 'Changed',
            'age_id': 'Date of birth',
            'manager_id': 'Edit manager',
            'delete': 'Deleted',
            'import_webmaster_id': 'Webmaster ID:',
            'type_dop_info': 'Request type:',
            'time_zone': 'Time zone',
            'import_id': 'Other system ID:',
            'origin_id': 'Origin ID:',
            'warehouse': 'Post office address',
            'warehouse_id': 'Post office id',
            'call_status.addmember': 'The customer has been added to the queue.',
            'call_status.agentdump': 'The agent dropped the caller while listening to the queue prompt.',
            'call_status.agentlogin': 'The agent is logged in. Channel recorded. ',
            'call_status.agentlogoff': 'The agent has logged out.',
            'call_status.completeagent': 'The call was ended by the agent.',
            'call_status.completecaller': 'The call was ended by the caller.',
            'call_status.configreload': 'The configuration has been reloaded',
            'call_status.connect': 'The subscriber has been connected to the agent.',
            'call_status.enterqueue': 'A call has arrived in the queue.',
            'call_status.exitempty': 'The subscriber left the queue because there were no available agents in the queue',
            'call_status.exitwithkey': 'The subscriber pressed the exit queue button.',
            'call_status.exitwithtimeout': 'The subscriber was thrown out of the queue by timeout (waited a very long time).',
            'call_status.ringnoanswer': 'Operator not responding',
            'call_status.ringcanceled': 'Operator dropped',
            'call_status.abandon': 'Customer left',
            'call_type.auto': 'Auto redial',
            'call_type.in': 'Inbox',
            'call_type.out': 'Outgoing',
            'main': 'General information',
            'first_name': 'Name',
            'last_name': 'Last name',
            'middle_name': 'Middle name',
            'user_images': 'Photo',
            'call_notify': 'Call in progress, please wait',
            'successfully_completed_event' : 'Successfully completed event!',
            'removed' : 'Removed',
        },
    },
    get(key, tLang = 'ru') {
        return this.list[tLang][key];
    }
}
export default lang;
