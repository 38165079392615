import http from './../../lib/http';
import api from './../../lib/api';

export const QUANTITY_PRODUCTS_LOAD_DATATABLE       = 'QUANTITY_PRODUCTS_LOAD_DATATABLE';

const QUANTITY_PRODUCTS_DATATABLE_LOADING_CHANGE    = 'QUANTITY_PRODUCTS_DATATABLE_LOADING_CHANGE';
const QUANTITY_PRODUCTS_DATATABLE_CHANGE            = 'QUANTITY_PRODUCTS_DATATABLE_CHANGE';

const state = {
    quantity_products_list_total     : 0,
    quantity_products_list           : null,
    quantity_products_list_loading   : false,
    quantity_products: null
};

const actions = {
    [QUANTITY_PRODUCTS_LOAD_DATATABLE] ({ commit, state }, data) {

        return api.list(
            'quantity_products.list',
            null,
            data,
            state,
            commit,
            QUANTITY_PRODUCTS_DATATABLE_LOADING_CHANGE,
            QUANTITY_PRODUCTS_DATATABLE_CHANGE);
    }
};

const mutations = {
    
    [QUANTITY_PRODUCTS_DATATABLE_LOADING_CHANGE](state, data) {
        state.quantity_products_list_loading = data;
    },
    [QUANTITY_PRODUCTS_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.quantity_products_list        = data.data;
            state.quantity_products_list_total  = data.total;
        }
        else {
            state.quantity_products_list        = [];
            state.quantity_products_list_total  = 0;
        }
    }
};

export default {
    state,
    actions,
    mutations
}
