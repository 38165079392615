import http from './../../lib/http';
import api from './../../lib/api';

export const AMOCRM_DATA         = 'AMOCRM_DATA';
export const AMOCRM_DELETE       = 'AMOCRM_DELETE';
export const AMOCRM_SET_SETTINGS = 'AMOCRM_SET_SETTINGS';

const AMOCRM_DATA_LOADING_CHANGE = 'AMOCRM_DATA_LOADING_CHANGE';
const AMOCRM_DATA_CHANGE         = 'AMOCRM_DATA_CHANGE';
const AMOCRM_DATA_ERRORS         = 'AMOCRM_DATA_ERRORS';
const AMOCRM_DATA_DELETE         = 'AMOCRM_DATA_DELETE';
const AMOCRM_SAVING_CHANGE       = 'AMOCRM_SAVING_CHANGE';
const AMOCRM_CHANGE              = 'AMOCRM_CHANGE';
const AMOCRM_ERRORS_CHANGE       = 'AMOCRM_ERRORS_CHANGE';

const state = {
    amocrm_data: [],
    amocrm_data_errors: {},
    loading: false,
    amocrm:null,
    amocrm_saving:false,
    amocrm_errors:{},
};

const actions = {
    [AMOCRM_SET_SETTINGS]({ commit, state }, data){
        return api.create(
            'amocrm.set_settings',
            0,
            data,
            state,
            commit,
            AMOCRM_SAVING_CHANGE,
            AMOCRM_CHANGE,
            AMOCRM_ERRORS_CHANGE,
        );
    },

    [AMOCRM_DATA] ({commit, state}) {
        return api.load(
            'amocrm.data',
            0,
            state,
            commit,
            AMOCRM_DATA_LOADING_CHANGE,
            AMOCRM_DATA_CHANGE,
            AMOCRM_DATA_ERRORS
        );
    },

    [AMOCRM_DELETE]({ commit, state }, id) {
        return api.delete(
          "amocrm.data.delete",
          id,
          state,
          commit,
          AMOCRM_DATA_DELETE
        );
    },
};

const mutations = {
    [AMOCRM_SAVING_CHANGE](state, data) {
        state.amocrm_saving = data;
    },
    [AMOCRM_CHANGE](state, data) {
        state.amocrm = data;
    },
    [AMOCRM_ERRORS_CHANGE](state, data) {
        state.amocrm_errors = data;
    },
    [AMOCRM_DATA_LOADING_CHANGE] (state, data) {
        state.amocrm_data_loading = data;
    },
    [AMOCRM_DATA_CHANGE] (state, data) {
        if(data) {
            state.amocrm_data        = data;
        }
        else {
            state.amocrm_data        = [];
        }
    },
    [AMOCRM_DATA_ERRORS](state, data) {
        state.amocrm_data_errors = data;
    },
    [AMOCRM_DATA_DELETE] (state, data) {
        if(data) {
            state.amocrm_data        = [];
        }
    },
};

export default {
    state,
    actions,
    mutations
}