import api from './../../lib/api';

export const QUESTION_GROUP_LOAD         = "QUESTION_GROUP_LOAD";
export const QUESTION_GROUP_UPDATE       = "QUESTION_GROUP_UPDATE";
export const QUESTION_GROUP_CREATE       = "QUESTION_GROUP_CREATE";
export const QUESTION_GROUP_LIST_LOAD    = "QUESTION_GROUP_LIST_LOAD";
export const QUESTION_GROUP_LOAD_IS_QUESTION    = "QUESTION_GROUP_LOAD_IS_QUESTION";

const QUESTION_GROUP_CHANGE              = "QUESTION_GROUP_CHANGE";
const QUESTION_GROUP_LOADING_CHANGE      = "QUESTION_GROUP_LOADING_CHANGE";
const QUESTION_GROUP_SAVING_CHANGE       = "QUESTION_GROUP_SAVING_CHANGE";
const QUESTION_GROUP_ERRORS_CHANGE       = "QUESTION_GROUP_ERRORS_CHANGE";
const QUESTION_GROUP_CREATE_CHANGE       = "QUESTION_GROUP_CREATE_CHANGE";
const QUESTION_GROUP_LIST_CHANGE         = "QUESTION_GROUP_LIST_CHANGE";
const QUESTION_GROUP_LIST_LOADING_CHANGE = "QUESTION_GROUP_LIST_LOADING_CHANGE";

const state = {
  question_group:                 null,

  question_group_loading:         false,
  question_group_saving:          false,

  question_group_list:            [],
  question_group_list_loading:    false,

  question_group_errors:          {},

  question_group_empty:           {
    id: 0,
    name: '',
    is_work: true,
    competence_id: '',
    level: 2,
    type: 'normal',
    is_multiple: false,
    time: 0,
    sort: 2,
    validation: {
      is_img: false
    },
    is_fake: false,
    questions: [],
  },
};

const actions = {
  [QUESTION_GROUP_LIST_LOAD]({commit, state}, data) {
    let id = data && data.id ? data.id : 0;
    let params = data && data.params ? data.params : null;

    return api.list(
      'question_groups',
      id,
      params,
      state,
      commit,
      QUESTION_GROUP_LIST_LOADING_CHANGE,
      QUESTION_GROUP_LIST_CHANGE,
    );
  },

  [QUESTION_GROUP_LOAD] ({ state, commit }, id) {
    if (!id) {
      commit(QUESTION_GROUP_CHANGE, _.cloneDeep(state.question_group_empty));
      return;
    }
    return api.load(
      'question_group',
      id,
      state,
      commit,
      QUESTION_GROUP_LOADING_CHANGE,
      QUESTION_GROUP_CHANGE
    )
  },
  
  [QUESTION_GROUP_LOAD_IS_QUESTION] ({ state, commit }, id) {
    if (!id) {
      commit(QUESTION_GROUP_CHANGE, _.cloneDeep(state.question_group_empty));
      return;
    }
    return api.load(
      'question_group_is_question',
      id,
      state,
      commit,
      QUESTION_GROUP_LOADING_CHANGE,
      QUESTION_GROUP_CHANGE
    )
  },

  [QUESTION_GROUP_UPDATE] ({ state, commit }, { id, data }) {
    return api.update(
      'question_group',
      id,
      data,
      state,
      commit,
      QUESTION_GROUP_SAVING_CHANGE,
      QUESTION_GROUP_CHANGE,
      QUESTION_GROUP_ERRORS_CHANGE
    )
  },

  [QUESTION_GROUP_CREATE] ({ state, commit }, data) {
    return api.create(
      'question_groups',
      0,
      data,
      state,
      commit,
      QUESTION_GROUP_SAVING_CHANGE,
      QUESTION_GROUP_CHANGE,
      QUESTION_GROUP_ERRORS_CHANGE
    )
  },
};

const mutations = {
  [QUESTION_GROUP_CHANGE](state, data) {
    if (data && typeof data.validation === 'string') {
      data.validation = JSON.parse(data.validation);
    }
    state.question_group = data;
  },
  [QUESTION_GROUP_LOADING_CHANGE](state, data) {
    state.question_group_loading = data;
  },
  [QUESTION_GROUP_SAVING_CHANGE](state, data) {
    state.question_group_saving = data;
  },
  [QUESTION_GROUP_ERRORS_CHANGE](state, data) {
    state.question_group_errors = data;
  },
  [QUESTION_GROUP_CREATE_CHANGE](state, data) {
    state.question_group_creating = data;
  },
  [QUESTION_GROUP_LIST_CHANGE](state, data) {
    if (data) {
      state.question_group_list = data.data ? data.data : data;
    }
  },
  [QUESTION_GROUP_LIST_LOADING_CHANGE](state, data) {
    state.question_group_list_loading = data;
  },
};

export default {
  state,
  actions,
  mutations,
}
