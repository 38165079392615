import api from '../../lib/api';

export const RB_ACTION_CONSTRUCTOR_REPORTS_LOAD = 'RB_ACTION_CONSTRUCTOR_REPORTS_LOAD';
const RB_MUTATION_CONSTRUCTOR_REPORTS_LOADING = 'RB_MUTATION_CONSTRUCTOR_REPORTS_LOADING';
const RB_MUTATION_CONSTRUCTOR_REPORTS_LOADED = 'RB_MUTATION_CONSTRUCTOR_REPORTS_LOADED';
const RB_MUTATION_CONSTRUCTOR_REPORTS_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_REPORTS_LOAD_ERRORS';

export const RB_ACTION_CONSTRUCTOR_REPORT_LOAD = 'RB_ACTION_CONSTRUCTOR_REPORT_LOAD';
const RB_MUTATION_CONSTRUCTOR_REPORT_LOADING = 'RB_MUTATION_CONSTRUCTOR_REPORT_LOADING';
const RB_MUTATION_CONSTRUCTOR_REPORT_LOADED = 'RB_MUTATION_CONSTRUCTOR_REPORT_LOADED';
const RB_MUTATION_CONSTRUCTOR_REPORT_LOAD_ERRORS = 'RB_MUTATION_CONSTRUCTOR_REPORT_LOAD_ERRORS';

export const RB_ACTION_CONSTRUCTOR_REPORT_STORE = 'RB_ACTION_CONSTRUCTOR_REPORT_STORE';
const RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADING = 'RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADING';
const RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADED = 'RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADED';
const RB_MUTATION_CONSTRUCTOR_REPORT_STORE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_REPORT_STORE_ERRORS';

export const RB_ACTION_CONSTRUCTOR_REPORT_UPDATE = 'RB_ACTION_CONSTRUCTOR_REPORT_UPDATE';
const RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADING = 'RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADING';
const RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADED = 'RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADED';
const RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_ERRORS = 'RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_ERRORS';

export const RB_ACTION_CONSTRUCTOR_REPORT_DELETE = 'RB_ACTION_CONSTRUCTOR_REPORT_DELETE';
const RB_MUTATION_CONSTRUCTOR_REPORT_DELETE_LOADING = 'RB_ACTION_CONSTRUCTOR_REPORT_DELETE_LOADING';

export const RB_MUTATION_CONSTRUCTOR_REPORT_EMPTY = 'RB_MUTATION_CONSTRUCTOR_REPORT_EMPTY';

const state = {
  reports: {
    items: [],
    total: 0,
    loading: false
  },
  report: {
    name: "",
    description: "",
    template_id: null,
    organizations: [],
    settings: {
      displayOptions: []
    }
  },
}

const actions = {
  /**
   * Загрузить отчеты
   * @param {Object} params 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_REPORTS_LOAD]({commit, state}, params) {
    return api.list(
      'rb.constructor.reports.load',
      null,
      params,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_REPORTS_LOADING,
      RB_MUTATION_CONSTRUCTOR_REPORTS_LOADED,
      RB_MUTATION_CONSTRUCTOR_REPORTS_LOAD_ERRORS
    ); 
  },
  /**
   * Загрузить отчет
   * @param {Number} reportId 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_REPORT_LOAD]({commit, state}, reportId) {
    return api.load(
      'rb.constructor.reports.report.load',
      reportId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_REPORT_LOADING,
      RB_MUTATION_CONSTRUCTOR_REPORT_LOADED,
      RB_MUTATION_CONSTRUCTOR_REPORT_LOAD_ERRORS
    ); 
  },
  /**
   * Создать отчет
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_REPORT_STORE]({commit, state}) {
    return api.create(
      'rb.constructor.reports.report.store',
      null,
      state.report,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADING,
      RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADED,
      RB_MUTATION_CONSTRUCTOR_REPORT_STORE_ERRORS
    ); 
  },
  /**
   * Обновить отчет
   * @param {Number} reportId 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_REPORT_UPDATE]({commit, state}, reportId) {
    return api.update(
      'rb.constructor.reports.report.update',
      reportId,
      state.report,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADING,
      RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADED,
      RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_ERRORS
    ); 
  },
  /**
   * Удалить отчет
   * @param {Number} reportId 
   * @returns 
   */
  [RB_ACTION_CONSTRUCTOR_REPORT_DELETE]({commit, state}, reportId) {
    return api.delete(
      'rb.constructor.reports.report.delete',
      reportId,
      state,
      commit,
      RB_MUTATION_CONSTRUCTOR_REPORT_DELETE_LOADING
    ); 
  },
}

const mutations = {
  [RB_MUTATION_CONSTRUCTOR_REPORTS_LOADING](state, status) {
    state.reports.loading = status
  },
  [RB_MUTATION_CONSTRUCTOR_REPORTS_LOADED](state, data) {
    if (data) {
      state.reports.items = data.data
      state.reports.total = data.total
    }
  },
  [RB_MUTATION_CONSTRUCTOR_REPORTS_LOAD_ERRORS]() {},
  
  [RB_MUTATION_CONSTRUCTOR_REPORT_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_REPORT_LOADED](state, data) {
    if (data) {
      let report = data.data
      state.report.name = report.name
      state.report.description = report.description
      state.report.template_id = report.template.id
      state.report.organizations = report.organizations
      state.report.settings.displayOptions = report.settings.displayOptions
    }
  },
  [RB_MUTATION_CONSTRUCTOR_REPORT_LOAD_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_REPORT_STORE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_REPORT_STORE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADING]() {},
  [RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_LOADED]() {},
  [RB_MUTATION_CONSTRUCTOR_REPORT_UPDATE_ERRORS]() {},

  [RB_MUTATION_CONSTRUCTOR_REPORT_DELETE_LOADING]() {},

  [RB_MUTATION_CONSTRUCTOR_REPORT_EMPTY](state) {
    state.report.name = ""
    state.report.description = ""
    state.report.template_id = null
    state.report.organizations = []
  },
}

export default {
  state,
  actions,
  mutations,
}