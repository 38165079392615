import http from "./../../lib/http";
import api from "./../../lib/api";

export const PROJECT_GOAL_SCRIPT_CREATE = "PROJECT_GOAL_SCRIPT_CREATE";
export const PROJECT_GOAL_SCRIPT_LIST_LOAD = "PROJECT_GOAL_SCRIPT_LIST_LOAD";
const PROJECT_GOAL_SCRIPT_LIST_LOAD_CHANGE = "PROJECT_GOAL_SCRIPT_LIST_LOAD_CHANGE";
export const PROJECT_GOAL_SCRIPT_CHANGE = "PROJECT_GOAL_SCRIPT_CHANGE";
export const PROJECT_GOAL_SCRIPT_UPDATE_CHANGE = "PROJECT_GOAL_SCRIPT_UPDATE_CHANGE";
export const PROJECT_GOAL_SCRIPT_UPDATE = "PROJECT_GOAL_SCRIPT_UPDATE";

export const PROJECT_GOAL_SCRIPT_DELETE = "PROJECT_GOAL_SCRIPT_DELETE";
export const PROJECT_GOAL_SCRIPT_DELETE_CHANGE = "PROJECT_GOAL_SCRIPT_DELETE_CHANGE";
export const CURRENT_GOAL_CHANGE = "CURRENT_GOAL_CHANGE";
const PROJECT_GOAL_SCRIPT_ERRORS_CHANGE = "PROJECT_GOAL_SCRIPT_ERRORS_CHANGE";

const PROJECT_GOAL_CREATING_CHANGE = "PROJECT_GOAL_CREATING_CHANGE";
const PROJECT_GOAL_LOADING_CHANGE = "PROJECT_GOAL_LOADING_CHANGE";

const state = {
  project_goal_script: null,
  project_goal_script_list: [],
  project_goal_script_list_total: 0,
  current_goal: null,
  project_goal_script_errors: {},
  project_goal_creating:false,
  project_goal_loading:false
};

const actions = {  
  [PROJECT_GOAL_SCRIPT_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "project_goal_script.list",
      id,
      model,
      state,
      commit,
      PROJECT_GOAL_CREATING_CHANGE,
      PROJECT_GOAL_SCRIPT_CHANGE,
      PROJECT_GOAL_SCRIPT_ERRORS_CHANGE
    );
  },

  [PROJECT_GOAL_SCRIPT_LIST_LOAD]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.list(
      "project_goal_script",
      id,
      null,
      state,
      commit,
      PROJECT_GOAL_LOADING_CHANGE,
      PROJECT_GOAL_SCRIPT_LIST_LOAD_CHANGE
    );
  },

  [PROJECT_GOAL_SCRIPT_DELETE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }
    return api.delete("goal", id, state, commit, PROJECT_GOAL_SCRIPT_DELETE_CHANGE);
  },

  [PROJECT_GOAL_SCRIPT_UPDATE]({ commit, state }, data) {
    let id = data.id;    
    let model = data.model;    
    return api.create(
      "project_goal_script",
      id,
      model,
      state,
      commit,
      PROJECT_GOAL_CREATING_CHANGE,
      PROJECT_GOAL_SCRIPT_UPDATE_CHANGE,
      PROJECT_GOAL_SCRIPT_ERRORS_CHANGE
    );
  }  
};

const mutations = {  
  [PROJECT_GOAL_SCRIPT_CHANGE](state, data) {
    state.project_goal_script = data;
    state.project_goal_script_list.push(data);
    state.project_goal_script_list_total++;
  },

  [PROJECT_GOAL_SCRIPT_UPDATE_CHANGE](state, data) {
    state.project_goal_script = data;    
  },
  [PROJECT_GOAL_CREATING_CHANGE](state, data) {
    state.project_goal_creating = data;
  },
  [PROJECT_GOAL_LOADING_CHANGE](state, data) {
    state.project_goal_loading = data;
  },
  [PROJECT_GOAL_SCRIPT_LIST_LOAD_CHANGE](state, data) {
    if (data) {
      state.project_goal_script_list = data.data;
      state.project_goal_script_list_total = data.total;
    } else {
      state.project_goal_script_list = [];
      state.project_goal_script_list_total = 0;
    }
  },
  [PROJECT_GOAL_SCRIPT_DELETE_CHANGE](state, id) {
    if (id) {
      if (state.project_goal_script_list) {
        let list = [];
        state.project_goal_script_list.forEach(project_goal_script => {
          if (project_goal_script.id !== id) {
            list.push({ ...project_goal_script });
          }
        });

        state.project_goal_script_list = list;
      }
    }
  },
  [PROJECT_GOAL_SCRIPT_ERRORS_CHANGE](state, data) {
    state.project_goal_script_errors = data;
  },
  [CURRENT_GOAL_CHANGE](state, data) {
    state.current_goal = data;
  },
};

export default {
  state,
  actions,
  mutations
};
