import api from './../../lib/api';
import moment from "moment"

export const USER_FORM_LIST   = 'USER_FORM_LIST';
export const USER_FORM_EXPIRED = 'USER_FORM_EXPIRED';
export const USER_FORM_DOUBLES_LIST = 'USER_FORM_DOUBLES_LIST';
export const USER_FORM_LOAD   = 'USER_FORM_LOAD';
export const USER_FORM_CREATE = 'USER_FORM_CREATE';
export const USER_FORM_UPDATE = 'USER_FORM_UPDATE';
export const USER_FORM_SIMPLE_UPDATE = 'USER_FORM_SIMPLE_UPDATE';
export const USER_FORM_DELETE = 'USER_FORM_DELETE';
export const USER_FORM_SIMPLE_GET_BY_PHONE_NUMBER = 'USER_FORM_SIMPLE_GET_BY_PHONE_NUMBER';
export const USER_FORM_ASSIGN_TEST = 'USER_FORM_ASSIGN_TEST';

const USER_FORM_LIST_LOADING_CHANGE = 'USER_FORM_LIST_LOADING_CHANGE';
const USER_FORM_LIST_CHANGE         = 'USER_FORM_LIST_CHANGE';
const USER_FORM_DOUBLES_LIST_CHANGE = 'USER_FORM_DOUBLES_LIST_CHANGE';
const USER_FORM_EXPIRED_CHANGE      = 'USER_FORM_EXPIRED_CHANGE';
const USER_FORM_LOADING_CHANGE      = 'USER_FORM_LOADING_CHANGE';
const USER_FORM_CHANGE              = 'USER_FORM_CHANGE';
const USER_FORM_CREATE_CHANGE       = 'USER_FORM_CREATE_CHANGE';
const USER_FORM_UPDATE_CHANGE       = 'USER_FORM_UPDATE_CHANGE';
const USER_FORM_DELETE_CHANGE       = 'USER_FORM_DELETE_CHANGE';
const USER_FORM_ERRORS_CHANGE       = 'USER_FORM_ERRORS_CHANGE';
const USER_FORM_TEST_ASSIGNING      = 'USER_FORM_TEST_ASSIGNING';
const USER_FORM_TEST_ASSIGNED       = 'USER_FORM_TEST_ASSIGNED';


const state = {
    user_form: null,
    user_form_loading: false,
    user_form_saving: false,
    user_form_creating: false,
    user_form_test_assigning: false,
    user_form_errors: {},

    user_form_list: [],
    user_form_tests: [],
    user_form_list_total: 0,
    user_form_list_loading: false,

    expired: 0,

    user_form_doubles_list: false,

    user_form_empty: {
        id: 0,
        full_name: "",
        email: '',
        direction: '',
        sex: true,
        marital_status:0,
        interview:0,
        training:0,
        internship:0,
        rejection_reason:0,
        source:0,
        source_type:0,
        year:0,
        status_group_8: null,
        status_group_14: null,
        status_group_15: null,
        orders: [
            {
                phones: [],
            },
        ],
        skills: [],
        users: [],
        competencies: null,
        is_worker: false,
        is_worker_date: null,
        desired_vacancy_hh_id: null,
        last_place_work_id: null,
        expected_salary: null,
        last_update_in_hh: null,
    },
};

const actions = {
    [USER_FORM_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }

        return api.list(
            'user_forms.list',
            0,
            filter,
            state,
            commit,
            USER_FORM_LIST_LOADING_CHANGE,
            USER_FORM_LIST_CHANGE
        );
    },
    [USER_FORM_DOUBLES_LIST] ({commit}, id) {
        if (!id || id == 0) {
            commit(USER_FORM_DOUBLES_LIST_CHANGE, {data:[]});
            return;
        }

        return api.load(
            'user_forms.doubles',
            id,
            null,
            commit,
            null,
            USER_FORM_DOUBLES_LIST_CHANGE
        );
    },
    [USER_FORM_EXPIRED] ({commit}) {
        localStorage.user_forms_exired_last_update = moment().format()
        return api.load(
            'user_forms.expired',
            0,
            null,
            commit,
            null,
            USER_FORM_EXPIRED_CHANGE
        );
    },
    [USER_FORM_LOAD] ({commit, state}, id) {
        if (!id || id == 0) {
            commit(USER_FORM_CHANGE, state.user_form_empty);
            commit(USER_FORM_TEST_ASSIGNED, null);
            return;
        }

        return api.load(
            'user_forms',
            id,
            state,
            commit,
            USER_FORM_LOADING_CHANGE,
            USER_FORM_CHANGE
        );
    },
    [USER_FORM_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'user_forms.list',
            id,
            model,
            state,
            commit,
            USER_FORM_CREATE_CHANGE,
            USER_FORM_CHANGE,
            USER_FORM_ERRORS_CHANGE);
    },
    [USER_FORM_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'user_forms',
            id,
            model,
            state,
            commit,
            USER_FORM_UPDATE_CHANGE,
            USER_FORM_CHANGE,
            USER_FORM_ERRORS_CHANGE);
    },
    [USER_FORM_SIMPLE_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.update(
            'user_forms.simple',
            id,
            model,
            state,
            commit,
            USER_FORM_UPDATE_CHANGE,
            USER_FORM_CHANGE,
            USER_FORM_ERRORS_CHANGE);
    },
    [USER_FORM_DELETE] ({commit, state}, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'user_forms',
            id,
            state,
            commit,
            USER_FORM_DELETE_CHANGE
        );
    },
    [USER_FORM_SIMPLE_GET_BY_PHONE_NUMBER]({ commit, state }, phone) {
        if (!phone) {
            return;
        }
        return api.load(
            "user_forms.simple_get_by_phone_number",
            phone,
            state,
            commit,
            USER_FORM_LOADING_CHANGE,
            USER_FORM_CHANGE
        );
    },
    [USER_FORM_ASSIGN_TEST]({commit, state}, data) {
        return api.create(
            'user_forms.assign_test',
            "",
            data.data,
            state,
            commit,
            USER_FORM_TEST_ASSIGNING,
            USER_FORM_TEST_ASSIGNED,
            USER_FORM_ERRORS_CHANGE);
    },
};

const mutations = {
    [USER_FORM_LIST_LOADING_CHANGE] (state, data) {
        state.user_form_list_loading = data;
    },
    [USER_FORM_DOUBLES_LIST_CHANGE] (state, data) {
        if (data) {
            state.user_form_doubles_list = data.data
        } else {
            state.user_form_doubles_list = false
        }
    },
    [USER_FORM_EXPIRED_CHANGE] (state, data) {
        if (data) {
            state.expired = data.total
        } else {
            state.expired = 0
        }
    },
    [USER_FORM_LIST_CHANGE] (state, data) {
        if(data) {
            state.user_form_list        = data.data;
            state.user_form_list_total  = data.total;
        }
        else {
            state.user_form_list        = [];
            state.user_form_list_total  = 0;
        }
    },
    [USER_FORM_LOADING_CHANGE] (state, data) {
        state.user_form_loading = data;
    },
    [USER_FORM_CHANGE] (state, data) {
        state.user_form_errors = {};
        if(data && typeof data === 'object'){
            if (_.isArray(data.tests)) {
                state.user_form_tests = data.tests
            }
            state.user_form = data;
            state.user_form_empty = {
                id: 0,
                full_name: "",
                sex: true,
                marital_status:0,
                interview:0,
                training:0,
                internship:0,
                rejection_reason:0,
                source:0,
                source_type:0,
                year:0,
                orders: [
                    {
                        phones: [],
                    },
                ],
                skills: [],
                users: [],
                competencies: null,
                is_worker: false,
            }
        }
    },
    [USER_FORM_CREATE_CHANGE] (state, data) {
        state.user_form_creating = data;
    },
    [USER_FORM_UPDATE_CHANGE] (state, data) {
        state.user_form_saving = data;
    },
    [USER_FORM_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.user_form_list) {
                let list = [];
                state.user_form_list.forEach(prov => {
                    if (prov.id !== id) {
                        list.push({...prov});
                    }
                });

                state.user_form_list = list;
            }
        }
    },
    [USER_FORM_ERRORS_CHANGE] (state, data) {
        state.user_form_errors = data;
    },
    [USER_FORM_TEST_ASSIGNING] (state, data) {
        state.user_form_test_assigning = data;
    },
    [USER_FORM_TEST_ASSIGNED] (state, data) {
        state.user_form_errors = {};
        if(data && typeof data === 'object'){
            state.user_form_tests = data.data;
            state.user_form.tests = data.data;
        } else {
            state.user_form_tests = [];
            state.user_form.tests = [];
        }
    },
};

export default {
    state,
    actions,
    mutations
}
