import api from './../../lib/api';

export const TELEPHONY_NUMBER_LOAD                 = 'TELEPHONY_NUMBER_LOAD';
export const TELEPHONY_NUMBER_UPDATE               = 'TELEPHONY_NUMBER_UPDATE';
export const TELEPHONY_NUMBER_CREATE               = 'TELEPHONY_NUMBER_CREATE';

export const TELEPHONY_NUMBER_LOAD_DATATABLE       = 'TELEPHONY_NUMBER_LOAD_DATATABLE';

const TELEPHONY_NUMBER_CHANGE                      = 'TELEPHONY_NUMBER_CHANGE';
const TELEPHONY_NUMBER_LOADING_CHANGE              = 'TELEPHONY_NUMBER_LOADING_CHANGE';
const TELEPHONY_NUMBER_SAVING_CHANGE               = 'TELEPHONY_NUMBER_SAVING_CHANGE';
const TELEPHONY_NUMBER_ERRORS_CHANGE               = 'TELEPHONY_NUMBER_ERRORS_CHANGE';
const TELEPHONY_NUMBER_CREATE_CHANGE               = 'TELEPHONY_NUMBER_CREATE_CHANGE';

const TELEPHONY_NUMBER_DATATABLE_CHANGE            = 'TELEPHONY_NUMBER_DATATABLE_CHANGE';
const TELEPHONY_NUMBER_DATATABLE_LOADING_CHANGE    = 'TELEPHONY_NUMBER_DATATABLE_LOADING_CHANGE';

const state = {
  telephony_number            : null,
  telephony_number_loading    : false,

  telephony_number_saving     : false,
  telephony_number_errors     : {},

  telephony_number_list_total   : 0,
  telephony_number_list         : null,
  telephony_number_list_loading : false,
  telephony_number_empty        : {
    id: 0,
    sip_caller_id_id: null,
    organization_id: null,
  }
};

const actions = {
  [TELEPHONY_NUMBER_LOAD] ({ commit, state }, id) {
    if (!id) {
      commit(TELEPHONY_NUMBER_CHANGE, _.cloneDeep(state.telephony_number_empty));
      return;
    }
    return api.load(
      'telephony_number',
      id,
      state,
      commit,
      TELEPHONY_NUMBER_LOADING_CHANGE,
      TELEPHONY_NUMBER_CHANGE
    );
  },

  [TELEPHONY_NUMBER_UPDATE] ({ commit, state }, {id, data}) {
    return api.update(
      'telephony_number',
      id,
      data,
      state,
      commit,
      TELEPHONY_NUMBER_SAVING_CHANGE,
      TELEPHONY_NUMBER_CHANGE,
      TELEPHONY_NUMBER_ERRORS_CHANGE
    );
  },

  [TELEPHONY_NUMBER_CREATE] ({ commit, state }, data) {
    return api.create(
      'telephony_numbers',
      0,
      data,
      state,
      commit,
      TELEPHONY_NUMBER_SAVING_CHANGE,
      TELEPHONY_NUMBER_CREATE_CHANGE,
      TELEPHONY_NUMBER_ERRORS_CHANGE
    );
  },

  [TELEPHONY_NUMBER_LOAD_DATATABLE] ({ commit, state }, filter) {
    return api.list(
      'telephony_numbers',
      0,
      filter,
      state,
      commit,
      TELEPHONY_NUMBER_DATATABLE_LOADING_CHANGE,
      TELEPHONY_NUMBER_DATATABLE_CHANGE
    );
  },
};

const mutations = {
  [TELEPHONY_NUMBER_DATATABLE_LOADING_CHANGE](state, data) {
    state.telephony_number_list_loading = data;
  },
  [TELEPHONY_NUMBER_DATATABLE_CHANGE](state, data) {
    if (data) {
      state.telephony_number_list          = data.data;
      state.telephony_number_list_total    = data.total;
    } else {
      state.telephony_number_list          = {};
      state.telephony_number_list_total    = 0;
    }
  },
  [TELEPHONY_NUMBER_CHANGE](state, data) {
    state.telephony_number = data;
  },
  [TELEPHONY_NUMBER_LOADING_CHANGE](state, data) {
    state.telephony_number_loading = data;
  },
  [TELEPHONY_NUMBER_SAVING_CHANGE](state, data) {
    state.telephony_number_saving   = data;
  },
  [TELEPHONY_NUMBER_ERRORS_CHANGE](state, data) {
    state.telephony_number_errors   = data;
  },
  [TELEPHONY_NUMBER_CREATE_CHANGE](state, data) {
    state.telephony_number = data;
  },
};

export default {
  state,
  actions,
  mutations
}
