import api from '@/lib/api';
import http from '@/lib/http';
import Container from './Container';
import Section from './Section';
import {
  IntentCustom,
  IntentFile,
  IntentPromoCode,
} from './blocks';

class Bot extends Container {
  brokenIntent = false;
  intents = {};
  options = {
    on_silent: null,
    on_gibberish: null,
    on_noise: null,
    noise_status: false,
    is_template: false,
    noise_hash: null,
    tags: [],
    chat_bot_setup_id: null,
    is_incoming: false,
    type: 0,
    voices: 1,
    language: null,
    nlu_server_id: null,
    on_auto_resp: null,
  };
  nlu_server = {
    languages: [],
  };

  constructor(data) {
    if (_.isString(data)) {
      super(data, 'Bot', 'Section');
    } else if (_.isObject(data)) {
      super(data.title, 'Bot', 'Section', data.desc, data.black_list);
      this.id = data.id;
      if (data.screens) {
        const sections = [];
        _.each(data.screens, (screen) => {
          if (!_.isArray(sections[screen.section])) {
            sections[screen.section] = [];
          }
          sections[screen.section].push(screen);
        });
        this.load(sections);
      }
      _.each(data.intents, (intentData) => {
        if (intentData.samples.length > 0 && intentData.samples[0].type === 'file') {
          this.addIntent(new IntentFile(intentData));
        } else if (intentData.samples.length > 0 && intentData.samples[0].type === 'promo_code') {
          this.addIntent(new IntentPromoCode(intentData));
        } else {
          this.addIntent(new IntentCustom(intentData));
        }
      });
      if (data.options) {
        _.merge(this.options, data.options);

        // Если в загруженном боте указан сервер NLU, то загружаем и его
        if (this.options.nlu_server_id) {
          http
            .get(`${api.main_url}/api/v2/nlu/servers/${this.options.nlu_server_id}`)
            .then((response) => {
              this.nlu_server = response.data;
            });
        }
      }
    } else {
      super('New bot', 'Bot', 'Section');
    }
  }

  get sections() {
    return this.items;
  }

  get startScreen() {
    return this.sections[0].screens[0];
  }

  add() {
    return this.push(new Section());
  }

  addIntent(intent) {
    this.intents[intent.getId()] = intent;
  }
  /**
   * Проверка на наличие сломанных интентов
   * @author SeregaHelf
   */
  checkIntents() {
    this.eachIntent((intent) => {
      if (_.has(this.intents, intent.id)) {
        if (this.intents[intent.id].broken === true) {
          this.brokenIntent = true;
          return false;
        }
      }
    });
  }

  eachAccost(closure) {
    let next = true;

    this.eachScreen(screen => {
      _.each(screen.accosts, (accost, index, section) => {
        next = closure(accost, index, screen, section);
        return next;
      });

      return next;
    });
  }

  eachCondition(closure) {
    let next = true;

    this.eachScreen(screen => {
      _.each(screen.conditions, (condition, index, section) => {
        next = closure(condition, index, screen, section);
        return next;
      });

      return next;
    });
  }

  eachAction(closure) {
    this.eachScreen((screen, index, section) => {
      if (screen.action) {
        return closure(screen.action, index, screen, section);
      }
    });
  }

  eachIntent(closure) {
    let next = true;

    this.eachScreen(screen => {
      _.each(screen.intents, (intent, index, section) => {
        next = closure(intent, index, screen, section);
        return next;
      });

      return next;
    });
  }

  eachScreen(closure) {
    let next = true;

    _.each(this.sections, section => {
      _.each(section.screens, (screen, index) => {
        next = closure(screen, index, section);
        return next;
      });

      return next;
    });
  }

  getScreenById(id) {
    let finded = undefined;
    _.each(this.sections, section => {
      finded = _.find(section.screens, screen => screen.id === id);
      if (finded) {
        return false;
      }
    });
    return finded;
  }

  load(data) {
    _.each(data, section => {
      this.add().load(section);
    });
    return this;
  }

  moveScreen(screen, section, position = null) {
    const fromSection = this.section(screen.parentIndex);
    if (_.isNull(position)) {
      section.push(_.cloneDeep(screen));
    } else {
      section.insert(_.cloneDeep(screen), position);
    }
    fromSection.remove(screen.index);
    if (fromSection.screens.length === 0) {
      this.remove(fromSection.index);
    }
  }

  next(screen, text) {
    let next = null;
    const intent = screen.analyze(text);
    if (intent) {
      next = this.getScreenById(intent.linked);
    }
    return next;
  }

  removeScreen(id, tid=null) {
    let byId = true;
    if (_.isString(tid) && tid.length === 32) {
      byId = false;
    }
    this.eachScreen((screen, index, section) => {
      if (byId) {
        if (id === screen.id) {
          section.remove(index);
          if (section.screens.length === 0) {
            this.remove(section.index);
          }
          return false;
        }
      } else {
        if (tid === screen.tid) {
          section.remove(index);
          if (section.screens.length === 0) {
            this.remove(section.index);
          }
          return false;
        }
      }
    });
  }

  section(index) {
    return this.item(index);
  }

  unload() {
    const data = {
      id: this.id,
      title: this.title,
      desc: this.desc,
      black_list: this.black_list,
      on_silent: this.options.on_silent,
      is_incoming: this.options.is_incoming,
      chat_bot_setup_id: this.options.chat_bot_setup_id,
      on_gibberish: this.options.on_gibberish,
      on_noise: this.options.on_noise,
      language: this.options.language,
      nlu_server_id: this.options.nlu_server_id,
      noise_status: this.options.noise_status,
      noise_hash: this.options.noise_hash,
      is_template: this.options.is_template,
      tags: this.options.tags,
      type: this.options.type >= 0 ? this.options.type : 0,
      is_dtmf: this.options.is_dtmf,
      recognition_start_delay: this.options.recognition_start_delay,
      voices: Number(this.options.voices),
      start_templates: this.options.start_templates ? this.options.start_templates : null,
      screens: [],
      intents: _.cloneDeep(this.intents),
      on_auto_resp: this.options.on_auto_resp,
    };
    this.eachScreen((screen) => {
      data.screens.push(screen.unload());
    });
    return data;
  }
}

export default Bot;
