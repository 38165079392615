import http from './../../lib/http';
import api from './../../lib/api';

export const PROVIDER_LOAD = 'PROVIDER_LOAD';
export const PROVIDER_CREATE = 'PROVIDER_CREATE';
export const PROVIDER_UPDATE = 'PROVIDER_UPDATE';
export const PROVIDER_DELETE = 'PROVIDER_DELETE';
export const PROVIDER_LIST = 'PROVIDER_LIST';
export const PROVIDER_ALL_LIST = 'PROVIDER_ALL_LIST';

const PROVIDER_LIST_LOADING_CHANGE = 'PROVIDER_LIST_LOADING_CHANGE';
const PROVIDER_LIST_CHANGE = 'PROVIDER_LIST_CHANGE';
const PROVIDER_CHANGE = 'PROVIDER_CHANGE';
const PROVIDER_LOADING_CHANGE = 'PROVIDER_LOADING_CHANGE';
const PROVIDER_ERRORS_CHANGE = 'PROVIDER_ERRORS_CHANGE';
const PROVIDER_CREATE_CHANGE = 'PROVIDER_CREATE_CHANGE';
const PROVIDER_UPDATE_CHANGE = 'PROVIDER_UPDATE_CHANGE';
const PROVIDER_DELETE_CHANGE = 'PROVIDER_DELETE_CHANGE';

const state = {
    provider: null,
    provider_loading: false,
    provider_saving: false,
    provider_creating: false,
    provider_errors: {},
    
    provider_list: [],
    provider_list_total: 0,
    provider_list_loading: false,
    
    provider_empty: {
        id: 0,
        name: "",
        comment: "",
        img: null,
    }
};

const actions = {
    [PROVIDER_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }
        
        return api.list(
            'providers.list',
            0,
            filter,
            state,
            commit,
            PROVIDER_LIST_LOADING_CHANGE,
            PROVIDER_LIST_CHANGE
        );
    },
    [PROVIDER_ALL_LIST] ({commit, state}, filter) {
        if (!filter) {
            filter = null
        }
        
        return api.list(
            'providers.all',
            0,
            filter,
            state,
            commit,
            PROVIDER_LIST_LOADING_CHANGE,
            PROVIDER_LIST_CHANGE
        );
    },
    [PROVIDER_LOAD] ({commit, state}, id) {
        if (!id) {
            commit(PROVIDER_CHANGE, state.provider_empty);
            return;
        }
        
        return api.load(
            'providers',
            id,
            state,
            commit,
            PROVIDER_LOADING_CHANGE,
            PROVIDER_CHANGE
        );
    },
    [PROVIDER_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;
        return api.create(
            'providers',
            id,
            model,
            state,
            commit,
            PROVIDER_UPDATE_CHANGE,
            PROVIDER_CHANGE,
            PROVIDER_ERRORS_CHANGE);
    },
    [PROVIDER_CREATE]({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;
        return api.create(
            'providers.list',
            id,
            model,
            state,
            commit,
            PROVIDER_CREATE_CHANGE,
            PROVIDER_CHANGE,
            PROVIDER_ERRORS_CHANGE);
    },
    [PROVIDER_DELETE] ({commit, state}, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete('providers', id, state, commit, PROVIDER_DELETE_CHANGE);
    }
};

const mutations = {
    [PROVIDER_LIST_LOADING_CHANGE] (state, data) {
        state.provider_list_loading = data;
    },
    [PROVIDER_LIST_CHANGE] (state, data) {
        if(data) {
            state.provider_list        = data.data;
            state.provider_list_total  = data.total;
        }
        else {
            state.provider_list        = [];
            state.provider_list_total  = 0;
        }
    },
    [PROVIDER_CHANGE] (state, data) {
        state.provider_errors = {};
        if(data && typeof data === 'object'){
            state.provider = data;
            state.provider_empty = {
                id: 0,
                name: "",
                comment: "",
                img: null,
            }
        }
    },
    [PROVIDER_LOADING_CHANGE] (state, data) {
        state.provider_loading = data;
    },
    [PROVIDER_ERRORS_CHANGE] (state, data) {
        state.provider_errors = data;
    },
    [PROVIDER_CREATE_CHANGE] (state, data) {
        state.provider_creating = data;
    },
    [PROVIDER_UPDATE_CHANGE] (state, data) {
        state.provider_saving = data;
    },
    [PROVIDER_DELETE_CHANGE] (state, id) {
        if (id) {
            if (state.provider_list) {
                let list = [];
                state.provider_list.forEach(prov => {
                    if (prov.id !== id) {
                        list.push({...prov});
                    }
                });

                state.provider_list = list;
            }
        }
    }
};

export default {
    state,
    actions,
    mutations
}
