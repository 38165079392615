import api from './../../lib/api';

export const TEST_LOAD         = "TEST_LOAD";
export const TEST_UPDATE       = "TEST_UPDATE";
export const TEST_CREATE       = "TEST_CREATE";
export const TEST_DELETE       = "TEST_DELETE";
export const TEST_LIST_LOAD    = "TEST_LIST_LOAD";

const TEST_CHANGE              = "TEST_CHANGE";
const TEST_LOADING_CHANGE      = "TEST_LOADING_CHANGE";
const TEST_SAVING_CHANGE       = "TEST_SAVING_CHANGE";
const TEST_ERRORS_CHANGE       = "TEST_ERRORS_CHANGE";
const TEST_DELETE_CHANGE       = "TEST_DELETE_CHANGE";
const TEST_CREATE_CHANGE       = "TEST_CREATE_CHANGE";
const TEST_LIST_CHANGE         = "TEST_LIST_CHANGE";
const TEST_LIST_LOADING_CHANGE = "TEST_LIST_LOADING_CHANGE";

const state = {
    test:                 null,

    test_loading:         false,
    test_saving:          false,

    test_list:            [],
    test_list_loading:    false,

    test_errors:          {},

    test_empty:           {
        id: 0,
        name: "",
        desc: "",
        url_hash: "",
        is_work: true,
        is_public: false,
        main_time: 0,
        lifetime: 24,
        organization_id: null,
        test_options: [
            {
                competence_id: '',
                level_type: 2,
            },
        ],
        img: '',
    },
};

const actions = {
    [TEST_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'tests',
            id,
            params,
            state,
            commit,
            TEST_LIST_LOADING_CHANGE,
            TEST_LIST_CHANGE,
        );
    },

    [TEST_LOAD] ({ state, commit }, id) {
        if (!id) {
            commit(TEST_CHANGE, _.cloneDeep(state.test_empty));
            return;
        }
        return api.load(
            'test',
            id,
            state,
            commit,
            TEST_LOADING_CHANGE,
            TEST_CHANGE
        )
    },

    [TEST_UPDATE] ({ state, commit }, { id, data }) {

        return api.create(
            'test',
            id,
            data,
            state,
            commit,
            TEST_SAVING_CHANGE,
            TEST_CHANGE,
            TEST_ERRORS_CHANGE
        )
    },

    [TEST_CREATE] ({ state, commit }, data) {

        return api.create(
            'tests',
            0,
            data,
            state,
            commit,
            TEST_SAVING_CHANGE,
            TEST_CREATE_CHANGE,
            TEST_ERRORS_CHANGE
        )
    },

    [TEST_DELETE] ({ state, commit }, id) {

        return api.delete(
            'test',
            id,
            state,
            commit,
            TEST_DELETE_CHANGE,
            TEST_CHANGE,
            TEST_ERRORS_CHANGE
        )
    },
};

const mutations = {
    [TEST_CHANGE](state, data) {
        state.test = data
    },
    [TEST_LOADING_CHANGE](state, data) {
        state.test_loading = data
    },
    [TEST_SAVING_CHANGE](state, data) {
        state.test_saving = data
    },
    [TEST_ERRORS_CHANGE](state, data) {
        state.test_errors = data
    },
    [TEST_CREATE_CHANGE](state, data) {
        state.test_creating = data
    },
    [TEST_DELETE_CHANGE](state, data) {
        // eslint-disable-next-line no-console
        console.log('QQ',data)
    },
    [TEST_LIST_CHANGE](state, data) {
        if(data){
            state.test_list = data.data ? data.data : data;
        }
    },
    [TEST_LIST_LOADING_CHANGE](state, data) {
        state.test_list_loading = data;
    }
};

export default {
    state,
    actions,
    mutations
}
