import api from './../../lib/api';

export const PAYMENT_TYPE_LOAD         = "PAYMENT_TYPE_LOAD";
export const PAYMENT_TYPE_UPDATE       = "PAYMENT_TYPE_UPDATE";
export const PAYMENT_TYPE_CREATE       = "PAYMENT_TYPE_CREATE";
export const PAYMENT_TYPE_LIST_LOAD    = "PAYMENT_TYPE_LIST_LOAD";

const PAYMENT_TYPE_CHANGE              = "PAYMENT_TYPE_CHANGE";
const PAYMENT_TYPE_LOADING_CHANGE      = "PAYMENT_TYPE_LOADING_CHANGE";
const PAYMENT_TYPE_SAVING_CHANGE       = "PAYMENT_TYPE_SAVING_CHANGE";
const PAYMENT_TYPE_ERRORS_CHANGE       = "PAYMENT_TYPE_ERRORS_CHANGE";
const PAYMENT_TYPE_CREATE_CHANGE       = "PAYMENT_TYPE_CREATE_CHANGE";
const PAYMENT_TYPE_LIST_CHANGE         = "PAYMENT_TYPE_LIST_CHANGE";
const PAYMENT_TYPE_LIST_LOADING_CHANGE = "PAYMENT_TYPE_LIST_LOADING_CHANGE";

const state = {
    payment_type:                 null,

    payment_type_loading:         false,
    payment_type_saving:          false,

    payment_type_list:            [],
    payment_type_list_loading:    false,

    payment_type_errors:          {},

    payment_type_empty:           {
        id: 0,
        name: "",
        is_work: true,
        description: "",
        organizations: {id:0}
    },
};

const actions = {
    [PAYMENT_TYPE_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'payment_types',
            id,
            params,
            state,
            commit,
            PAYMENT_TYPE_LIST_LOADING_CHANGE,
            PAYMENT_TYPE_LIST_CHANGE,
        );
    },

    [PAYMENT_TYPE_LOAD] ({ state, commit }, id) {
        if (!id || id===0) {
            state.payment_type_empty = {
                id: 0,
                name: "",
                is_work: true,
                description: "",
                organizations: {id:0}
            };
            commit(PAYMENT_TYPE_CHANGE, state.payment_type_empty);
            return;
        }

        return api.load(
            'payment_type',
            id,
            state,
            commit,
            PAYMENT_TYPE_LOADING_CHANGE,
            PAYMENT_TYPE_CHANGE
        )
    },

    [PAYMENT_TYPE_UPDATE] ({ state, commit }, { id, data }) {

        return api.update(
            'payment_type',
            id,
            data,
            state,
            commit,
            PAYMENT_TYPE_SAVING_CHANGE,
            PAYMENT_TYPE_CHANGE,
            PAYMENT_TYPE_ERRORS_CHANGE
        )
    },

    [PAYMENT_TYPE_CREATE] ({ state, commit }, data) {

        return api.create(
            'payment_types',
            0,
            data,
            state,
            commit,
            PAYMENT_TYPE_SAVING_CHANGE,
            PAYMENT_TYPE_CREATE_CHANGE,
            PAYMENT_TYPE_ERRORS_CHANGE
        )
    }
};

const mutations = {
    [PAYMENT_TYPE_CHANGE](state, data) {
        state.payment_type = data
    },
    [PAYMENT_TYPE_LOADING_CHANGE](state, data) {
        state.payment_type_loading = data
    },
    [PAYMENT_TYPE_SAVING_CHANGE](state, data) {
        state.payment_type_saving = data
    },
    [PAYMENT_TYPE_ERRORS_CHANGE](state, data) {
        state.payment_type_errors = data
    },
    [PAYMENT_TYPE_CREATE_CHANGE](state, data) {
        state.payment_type_creating = data
    },
    [PAYMENT_TYPE_LIST_CHANGE](state, data) {
        if(data){
            state.payment_type_list = data.data ? data.data : data;
        }
    },
    [PAYMENT_TYPE_LIST_LOADING_CHANGE](state, data) {
        state.payment_type_list_loading = data;
    }
};

export default {
    state,
    actions,
    mutations
}
