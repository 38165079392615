import http from "./../../lib/http";
import api from "./../../lib/api";

export const STATUS_LOAD = "STATUS_LOAD";
export const STATUS_UPDATE = "STATUS_UPDATE";
export const STATUS_CREATE = "STATUS_CREATE";

export const STATUS_LOAD_DATATABLE = "STATUS_LOAD_DATATABLE";

export const STATUS_CHANGE = "STATUS_CHANGE";
const STATUS_LOADING_CHANGE = "STATUS_LOADING_CHANGE";
const STATUS_SAVING_CHANGE = "STATUS_SAVING_CHANGE";
const STATUS_ERRORS_CHANGE = "STATUS_ERRORS_CHANGE";

const STATUS_CREATING_CHANGE = "STATUS_CREATING_CHANGE";

const STATUS_DATATABLE_CHANGE = "STATUS_DATATABLE_CHANGE";
const STATUS_DATATABLE_LOADING_CHANGE = "STATUS_DATATABLE_LOADING_CHANGE";

export const STATUS_LOAD_ALL = "STATUS_LOAD_ALL";
export const STATUS_LOAD_TREE = "STATUS_LOAD_TREE";

const STATUS_ALL_CHANGE = "STATUS_ALL_CHANGE";
const STATUS_ALL_LOADING_CHANGE = "STATUS_ALL_LOADING_CHANGE";

export const STATUS_GROUP_LOAD_DATATABLE     = "STATUS_GROUP_LOAD_DATATABLE";
const STATUS_GROUP_DATATABLE_LOADING_CHANGE  = "STATUS_GROUP_DATATABLE_LOADING_CHANGE";
const STATUS_GROUP_DATATABLE_CHANGE          = "STATUS_GROUP_DATATABLE_CHANGE";

const state = {
  status: null,
  status_loading: false,
  status_saving: false,
  status_errors: {},

  status_all: null,
  status_all_loading: false,
  status_tree: null,

  status_list: [],
  status_plain_list: null,
  status_list_loading: false,

  status_group_list: [],
  status_group_loading: false,

  status_creating: false,
  status_creating_errors: {},
  status_empty: {
    id: 0,
    langs: {},
    parent_id: 0,
    is_work: 0,
    type: 1,
    color: "#000000",
    sort: 0
  }
};

const actions = {
  [STATUS_LOAD]({ commit, state }, id) {
    if (!id.id) {
      state.status_empty.parent_id = id.parent_id;
      state.status_empty.type = id.type;
      for (let lang in Vue.prototype.$langs) {
          state.status_empty.langs[lang] = {
              name: "",
              desc: "",
              title: "",
          }
      }
      console.log(state.status_empty.langs);
      commit(STATUS_CHANGE, state.status_empty);

      return;
    }

    return api.load(
      "status",
      id.id,
      state,
      commit,
      STATUS_LOADING_CHANGE,
      STATUS_CHANGE
    );
  },

  [STATUS_UPDATE]({ commit, state }, data) {
    let id = data.id;
    let model = data.model;

    return api.update(
      "status",
      id,
      model,
      state,
      commit,
      STATUS_SAVING_CHANGE,
      STATUS_CHANGE,
      STATUS_ERRORS_CHANGE
    );
  },

  [STATUS_CREATE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "status.list",
      id,
      model,
      state,
      commit,
      STATUS_CREATING_CHANGE,
      STATUS_CHANGE,
      STATUS_ERRORS_CHANGE
    );
  },

  [STATUS_LOAD_DATATABLE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "status.list_by_key",
      id,
      null,
      state,
      commit,
      STATUS_DATATABLE_LOADING_CHANGE,
      STATUS_DATATABLE_CHANGE
    );
  },

  [STATUS_LOAD_ALL]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "status.all",
      id,
      model,
      state,
      commit,
      STATUS_ALL_LOADING_CHANGE,
      STATUS_ALL_CHANGE,
      STATUS_ERRORS_CHANGE
    );
  },

  [STATUS_LOAD_TREE]({ commit, state }, data) {
    let model = data.model;
    let id = data.id ? data.id : 0;

    return api.create(
      "status.tree",
      id,
      model,
      state,
      commit,
      STATUS_ALL_LOADING_CHANGE,
      STATUS_ALL_CHANGE,
      STATUS_ERRORS_CHANGE
    );
  },

  [STATUS_GROUP_LOAD_DATATABLE]({ commit, state }, id) {
    if (!id) {
      id = 0;
    }

    return api.list(
      "status.list_group",
      id,
      null,
      state,
      commit,
      STATUS_GROUP_DATATABLE_LOADING_CHANGE,
      STATUS_GROUP_DATATABLE_CHANGE
    );
  },
};

const mutations = {
  [STATUS_ALL_LOADING_CHANGE](state, data) {
    state.status_all_loading = data;
  },
  [STATUS_ALL_CHANGE](state, data) {
    if (data) {
      // state.status_all   = data.data.map(
      //     function(item){
      //         if(!item.value){
      //             item.text=item.name+"("+item.organization_title+") ID:" + item.id;
      //             item.value=item.name;
      //             item.opened=false;
      //             item.disabled=true;
      //         }
      //         return item;}
      // );

      // state.status_all = addCanEditViewChildren(state.status_all);

      // state.status_tree= treeify(state.status_all);
      state.status_tree = data.data;
    } else {
      state.status_all = [];
    }
  },
  [STATUS_DATATABLE_LOADING_CHANGE](state, data) {
    state.status_list_loading = data;
  },
  [STATUS_DATATABLE_CHANGE](state, data) {
    function buildStatusesPlainList(list, group = '') {
      list.forEach(item => {
        if (!item.is_work) {
          return;
        }
        if (!item.children.length) {
          const option = {
            id: item.id,
            name: item.name,
            group: group,
            color: item.color,
          };
          state.status_plain_list.push(option);
        } else {
          if (item.parent_id) {
            buildStatusesPlainList(item.children, `${group}/${item.name}`);
          } else {
            buildStatusesPlainList(item.children, item.name);
          }
        }
      });
    }
    if (data) {
      state.status_list = data.data;
      state.status_plain_list = [];
      buildStatusesPlainList(state.status_list);
    } else {
      state.status_list = [];
      state.status_plain_list = [];
    }
  },
  [STATUS_CHANGE](state, data) {
    state.status = data;
  },
  [STATUS_LOADING_CHANGE](state, data) {
    state.status_loading = data;
  },
  [STATUS_SAVING_CHANGE](state, data) {
    state.status_saving = data;
  },
  [STATUS_ERRORS_CHANGE](state, data) {
    state.status_errors = data;
  },
  [STATUS_CREATING_CHANGE](state, data) {
    state.status_creating = data;
  },
  [STATUS_GROUP_DATATABLE_CHANGE](state, data) {
    if (data && data.data) {
      state.status_group_list = data.data;
    } else {
      state.status_group_list = [];
    }
  },
  [STATUS_GROUP_DATATABLE_LOADING_CHANGE](state, data) {
    state.status_group_loading = data;
  },
};

export default {
  state,
  actions,
  mutations
};

function treeify(list, idAttr, parentAttr, childrenAttr) {
  if (!idAttr) idAttr = "id";
  if (!parentAttr) parentAttr = "parent_id";
  if (!childrenAttr) childrenAttr = "children";

  var treeList = [];

  var lookup = {};
  list.forEach(function(obj) {
    lookup[obj[idAttr]] = obj;
    obj[childrenAttr] = [];
  });

  list.forEach(function(obj) {
    if (obj[parentAttr] != 0) {
      lookup[obj[parentAttr]][childrenAttr].push(obj);
    } else {
      treeList.push(obj);
    }
  });

  console.log(treeList);

  return treeList;
}

function addCanEditViewChildren(list) {
  list.forEach(function(obj) {
    var item = {};
    item.parent_id = obj.id;

    item.id = 0;
    item.text = "Просморт";
    item.value = "show";
    item.icon = "fa fa-check icon-state-success";
    item.opened = false;

    list.push(item);
    var item = {};

    item.parent_id = obj.id;
    item.id = 1;
    item.text = "Возможность установки";
    item.value = "can-set";
    item.icon = "fa fa-exclamation-triangle icon-state-warning";
    item.opened = false;

    list.push(item);
  });
  return list;
}
