import http from './../../lib/http';
import api from './../../lib/api';

export const ENTITY_LIST                 = 'ENTITY_LIST';

export const ENTITY_LOAD                 = 'ENTITY_LOAD';
export const ENTITY_UPDATE               = 'ENTITY_UPDATE';
export const ENTITY_CREATE               = 'ENTITY_CREATE';

export const ENTITY_LOAD_DATATABLE       = 'ENTITY_LOAD_DATATABLE';

export const ENTITY_CHANGE               = 'ENTITY_CHANGE';

const ENTITY_LOADING_CHANGE              = 'ENTITY_LOADING_CHANGE';
const ENTITY_SAVING_CHANGE               = 'ENTITY_SAVING_CHANGE';
const ENTITY_ERRORS_CHANGE               = 'ENTITY_ERRORS_CHANGE';

const ENTITY_CREATING_CHANGE             = 'ENTITY_CREATING_CHANGE';

const ENTITY_DATATABLE_CHANGE            = 'ENTITY_DATATABLE_CHANGE';
const ENTITY_DATATABLE_LOADING_CHANGE    = 'ENTITY_DATATABLE_LOADING_CHANGE';

export const ENTITY_DELETE		  = 'ENTITY_DELETE';
export const ENTITY_DELETE_CHANGE  	  = 'ENTITY_DELETE_CHANGE';

const state = {
    entity            : null,
    entity_loading    : false,
    entity_saving     : false,
    entity_errors     : {},

    entity_list_total     : 0, 
    entity_list           : null,
    entity_list_loading   : false,

    entity_creating           : false,
    entity_creating_errors    : {},
    entity_empty              : {
                                  id: 0,
                                  name: "",
                                  code: ""
                              }
};

const actions = {
    [ENTITY_LOAD] ({ commit, state }, id) {
        if(!id) {
            state.entity_empty = {
                                  id: 0,
                                  name: "",
                                  code: ""
                              };
            commit(ENTITY_CHANGE, state.entity_empty);

            return;
        }

        return api.load('entity', id, state, commit, ENTITY_LOADING_CHANGE, ENTITY_CHANGE);
    },

    [ENTITY_UPDATE] ({ commit, state }, data) {
        let id      = data.id;
        let model   = data.model;

        return api.update('entity', id, model, state, commit, ENTITY_SAVING_CHANGE, ENTITY_CHANGE, ENTITY_ERRORS_CHANGE);
    },

    [ENTITY_CREATE] ({ commit, state }, data) {
        let model   = data.model;
        let id      = data.id ? data.id : 0;


        return api.create('entity.list', id, model, state, commit, ENTITY_CREATING_CHANGE, ENTITY_CHANGE, ENTITY_ERRORS_CHANGE);
    },

    [ENTITY_LOAD_DATATABLE] ({ commit, state }, id) {

        if(!id) {
            id  = 0;
        }

        return api.list(
            'entity.list', 
            id, 
            null,
            state, 
            commit, 
            ENTITY_DATATABLE_LOADING_CHANGE, 
            ENTITY_DATATABLE_CHANGE);
    },
    [ENTITY_DELETE] ({ commit, state }, id) {
        if(!id) {
            id  = 0;
        }

        return api.delete(
            'entity',
            id,
            state,
            commit,
            ENTITY_DELETE_CHANGE
        );
    },

};

const mutations = {
    [ENTITY_DATATABLE_LOADING_CHANGE](state, data) {
        state.entity_list_loading = data;
    },
    [ENTITY_DATATABLE_CHANGE](state, data) {

        if(data) {
            state.entity_list        = data.data;
            state.entity_list_total  = data.total;
        }
        else {
            state.entity_list        = [];
            state.entity_list_total  = 0;
        }
    },
    [ENTITY_CHANGE](state, data) {
 	state.entity  = data;
    },
    [ENTITY_LOADING_CHANGE](state, data) {
        state.entity_loading = data;
    },
    [ENTITY_SAVING_CHANGE](state, data) {
        state.entity_saving   = data;
    },
    [ENTITY_CREATING_CHANGE](state, data) {
	state.entity_creating = data;
    },
    [ENTITY_ERRORS_CHANGE](state, data) {
        state.entity_errors   = data;
    },
    [ENTITY_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.entity_list) {

                let list = [];
                state.entity_list.forEach(entity => {
                    if (entity.id !== id) {
                        list.push({...entity});
                    }
                });

                state.entity_list = list;
            }
        }
    },

};

export default {
    state,
    actions,
    mutations
}
