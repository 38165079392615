const reindex = function reAssignItemIndexes(item, index) {
  item.index = index;
  if (_.has(item, 'items') && _.isArray(item.items)) {
    _.each(item.items, subItem => {
      subItem.parentIndex = index;
    });
  }
};

class Container {
  className = '';
  index = 0;
  itemClassName = '';
  items = [];
  parentIndex = -1;
  title = '';
  desc = '';
  black_list = [];

  constructor(title, className, itemClassName, desc = '', black_list = []) {
    this.className = className;
    this.itemClassName = itemClassName;
    this.title = title;
    this.desc = desc;
    this.black_list = black_list;
  }

  get items() {
    return this.items;
  }

  get size() {
    return _.size(this.items);
  }

  /**
   * Добавляет новый пустой элемент
   * NB! Следует переопределить в потомке, чтобы добавлял экземпляр itemClassName
   * @return {object}      Добавленный элемент
   */
  add() {
    return this.push({});
  }

  first() {
    return _.first(this.items);
  }

  insert(item, position) {
    if (item.className !== this.itemClassName) {
      throw `Item must be object of the ${this.itemClassName} class`;
    }
    reindex(item, position);
    item.parentIndex = this.index;
    this.items.splice(position, 0, item);
    for (let pos = position + 1; pos < this.items.length; pos++) {
      reindex(this.items[pos], pos);
    }
    return item;
  }

  item(index) {
    if (index < this.size) {
      return this.items[index];
    } else {
      throw `${this.className}.${this.itemClassName.toLowerCase()}: Max index must be ${this.size - 1}, but ${index} recieved`;
    }
  }

  last() {
    return _.last(this.items);
  }

  /**
   * Добавляем item в конец коллекции
   * @param  {object} item
   * @return {object}      Добавленный элемент
   */
  push(item) {
    if (item.className === this.itemClassName) {
      reindex(item, this.size);
      item.parentIndex = this.index;
      this.items.push(item);
      return this.last();
    } else {
      throw `Item must be object of the ${this.itemClassName} class`;
    }
  }

  remove(index) {
    this.items.splice(index, 1);
    for (let pos = index + 1; pos < this.items.length; pos++) {
      reindex(this.items[pos], pos);
    }
  }

  unload() {
    const data = [];
    _.each(this.items, item => {
      data.push(item.unload());
    });
    return data;
  }
}

export default Container;
