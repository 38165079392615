import api from "./../../lib/api";

export const ORDER_SENDER_LOAD          = 'ORDER_SENDER_LOAD';
export const ORDER_SENDER_UPDATE        = 'ORDER_SENDER_UPDATE';
export const ORDER_SENDER_CREATE        = 'ORDER_SENDER_CREATE';
export const ORDER_SENDER_DELETE        = 'ORDER_SENDER_DELETE';

export const ORDER_SENDER_LOAD_DATABLE  = 'ORDER_SENDER_LOAD_DATABLE';

const ORDER_SENDER_CHANGE               = 'ORDER_SENDER_CHANGE';
const ORDER_SENDER_LOADING_CHANGE       = 'ORDER_SENDER_LOADING_CHANGE';
const ORDER_SENDER_SAVING_CHANGE        = 'ORDER_SENDER_SAVING_CHANGE';
const ORDER_SENDER_ERRORS_CHANGE        = 'ORDER_SENDER_ERRORS_CHANGE';
const ORDER_SENDER_CREATE_CHANGE        = 'ORDER_SENDER_CREATE_CHANGE';
const ORDER_SENDER_DELETE_CHANGE        = 'ORDER_SENDER_DELETE_CHANGE';

const ORDER_SENDER_DATABLE_CHANGE       = 'ORDER_SENDER_DATABLE_CHANGE';
const ORDER_SENDER_DATABLE_LOADING_CHANGE = 'ORDER_SENDER_DATABLE_LOADING_CHANGE';

const state = {
    order_sender                : null,
    order_sender_loading        : false,
    order_sender_errors         : {},

    order_sender_saving         : false,

    order_sender_creating       : false,
    order_sender_creating_errors: {},

    order_sender_list           : null,
    order_sender_list_loading   : false,

    order_sender_empty          : {
                                    id: 0,
                                    organization_id: 0,
                                    name: "",
                                    iin: "",
                                    phone: "",
                                    is_work: 0,
                                }
};

const actions = {
    [ORDER_SENDER_LOAD] ({commit, state}, id) {
        if (!id){
            commit(ORDER_SENDER_CHANGE, state.order_sender_empty);
            return;
        }

        return api.load (
            'order_senders',
            id,
            state,
            commit,
            ORDER_SENDER_LOADING_CHANGE,
            ORDER_SENDER_CHANGE,
        );
    },
    [ORDER_SENDER_UPDATE] ({commit, state}, data) {
        let id = data.id;
        let model = data.model;

        return api.update(
            'order_senders',
            id,
            model,
            state,
            commit,
            ORDER_SENDER_SAVING_CHANGE,
            ORDER_SENDER_CHANGE,
            ORDER_SENDER_ERRORS_CHANGE,
        );
    },
    [ORDER_SENDER_CREATE] ({commit, state}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;

        return api.create(
            'order_senders.list',
            id,
            model,
            state,
            commit,
            ORDER_SENDER_SAVING_CHANGE,
            ORDER_SENDER_CREATE_CHANGE,
            ORDER_SENDER_ERRORS_CHANGE,
        );
    },
    // [ORDER_SENDER_DELETE] ({commit, state},id) {
    //     if (!id)
    //         id = 0;
    //
    //     return api.delete(
    //         'order_senders',
    //         id,
    //         state,
    //         commit,
    //     );
    // },

    [ORDER_SENDER_LOAD_DATABLE] ({commit, state}, data) {
        let id = data.id ? data.id : null;
        let params = data.params;

        return api.list(
            'order_senders.list',
            id,
            params,
            state,
            commit,
            ORDER_SENDER_DATABLE_LOADING_CHANGE,
            ORDER_SENDER_DATABLE_CHANGE,
        );
    },
};

const mutations = {
    [ORDER_SENDER_CHANGE] (state, data) {
        state.order_sender = data;
    },
    [ORDER_SENDER_LOADING_CHANGE] (state, data) {
        state.order_sender_loading = data;
    },
    [ORDER_SENDER_SAVING_CHANGE] (state, data) {
        state.order_sender_saving = data;
    },
    [ORDER_SENDER_ERRORS_CHANGE] (state, data) {
        state.order_sender_errors = data;
    },
    [ORDER_SENDER_CREATE_CHANGE] (state, data) {
        state.order_sender_creating = data;
    },
    [ORDER_SENDER_DATABLE_LOADING_CHANGE] (state, data) {
        state.order_sender_list_loading = data;
    },
    [ORDER_SENDER_DATABLE_CHANGE] (state, data) {
        if (data) {
            state.order_sender_list = data.data;
        }
        else {
            state.order_sender_list = [];
        }
    }
};

export default {
    state,
    actions,
    mutations,
}