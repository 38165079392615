import api from '@/lib/api';
import Vue from 'vue';
import _ from 'lodash';

const state = {
  adding: false,
  errors: {},
  list: {},
  loading: false,
  totalServers: 0,
};

const getters = {
};

const actions = {
  add({ commit, state }, data) {
    return api.create('robot.templates', 0, data, state, commit, 'adding', 'added', 'errors');
  },
  delete({ commit, state }, id) {
    return api.delete('robot.template', id, state, commit, 'itemDeleting');
  },
  list({ commit, state }, data) {
    return api.list('robot.templates', 0, data, state, commit, 'listLoading', 'listLoaded');
  },
};

const mutations = {
  added(state, response) {
    if (_.isArray(response.data)) {
      _.each(response.data, (item) => {
        Vue.notify({
          group: 'app',
          duration: item.status === 201 ? 3000 : 10000,
          type: item.status === 201 ? 'success' : 'warn',
          title: `Account ${item.accountId}`,
          text: item.message,
        });
      });
    }
  },
  adding(state, response) {
    state.adding = response;
  },
  errors(state, response) {
    state.errors = response;
  },
  itemDeleted(state, id) {
    Vue.delete(state.list, id);
  },
  itemDeleting(state, id) {
    if (state.list[id]) {
      if (state.list[id].deleting) {
        Vue.delete(state.list[id], 'deleting');
      } else {
        Vue.set(state.list[id], 'deleting', true);
      }
    }
  },
  listLoaded(state, response) {
    if (response && _.isObject(response.data)) {
      state.list = response.data;
      state.totalServers = response.total;
    } else {
      state.list = {};
      state.totalServers = 0;
    }
  },
  listLoading(state, response) {
    state.loading = response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
