<template>
    <b-button @click="click" :disabled="saving" variant="success">
        <i v-if="saving" class="fa fa-cog fa-spin fa-fw"></i>
        <slot></slot>
    </b-button>
</template>

<script>
    export default {
        props : {
            saving  : {}
        },
        methods : {
            click() {
                this.$emit('click');
            }
        }
    }
</script>

