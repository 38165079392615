import get_main_url from './get_main_url';
import http from "./http";
import lang from "./lang";
import moment from "moment";
import Vue from "vue";
import CustomStore from "./custom_store_extended";

let endpoints = {
  organization: "/api/v2/organizations/{id}",
  "organization.list": "/api/v2/organizations/{id}/list",
  "organization.children": "/api/v2/organizations/{id}/children",
  "organization.company": "/api/v2/organizations/{id}/company",
  "organization.by_role": "/api/v2/organizations/{id}/by_role",
  "organization.by_organization": "/api/v2/organizations/{id}/by_organization",
  "organization.for_sms_template": "/api/v2/organizations",
  "organization.for_tree": "/api/v2/organizations/{id}/for_tree",
  "organization.attach_role": "/api/v2/organizations/{id}/attach_role",
  "organization.detach_role": "/api/v2/organizations/{id}/detach_role",
  "organization.get_api_key": "/api/v2/organizations/get/api_key",
  "organization.get_sms_token": "/api/v2/organizations/{id}/get/sms_token",
  "organization.set_api_key_bpm_interface": "/api/v2/organizations/get/set_api_key_bpm_interface",
  "organization.bpm_bp": "/api/v2/organizations/bp/org_bpm_list",
  "organization.interface.create": "/api/v2/organizations/create_by_interface",

  "tariff.organization": "/api/v2/tariffs/{id}",
  "tariff.organization.list": "/api/v2/tariffs",
  "tariff.organization.create": "/api/v2/tariffs/{id}",
  "tariff.organization.update": "/api/v2/tariffs/{id}",

  "tariff_messenger": "/api/v2/tariffs_messengers/{id}",
  "tariff_messenger.list": "/api/v2/tariffs_messengers",
  "tariff_messenger.create": "/api/v2/tariffs_messengers/{id}",
  "tariff_messenger.update": "/api/v2/tariffs_messengers/{id}",

  "tariff.ats_users.list": "/api/v2/tariffs/{id}/ats_users",
  "tariff.sips.list": "/api/v2/tariffs/{id}/sips",

  "tariff_sms.organization": "/api/v2/tariffs_sms/{id}",
  "tariff_sms.organization.list": "/api/v2/tariffs_sms",
  "tariff_sms.sms_provider.list": "/api/v2/tariffs_sms_provider_list",
  "tariff_sms.organization.create": "/api/v2/tariffs_sms/{id}",
  "tariff_sms.organization.update": "/api/v2/tariffs_sms/{id}",

  "tariff_speech.organization": "/api/v2/tariffs_speech/{id}",
  "tariff_speech.organization.list": "/api/v2/tariffs_speech",
  "tariff_speech.organization.create": "/api/v2/tariffs_speech/{id}",
  "tariff_speech.organization.update": "/api/v2/tariffs_speech/{id}",

  "balance.organization": "/api/v2/balance_transactions/{id}",
  "balance.organization.create": "/api/v2/balance_transactions/{id}",

  permission: "/api/v2/permissions/{id}",
  "permission.organization": "/api/v2/organizations/{id}/permissions",
  "permission.list": "/api/v2/organizations/{id}/permissions/shared",

  product: "/api/v2/products/{id}",
  "product.list": "/api/v2/products",
  "product.suggest": "/api/v2/products/suggest",

  status: "/api/v2/statuses/{id}",
  "status.list": "/api/v2/statuses",
  "status.list_by_key": "/api/v2/statuses/get_by_key/{id}",
  "status.all": "/api/v2/statuses/get_all",
  "status.tree": "/api/v2/statuses/get_tree",
  "status.list_group": "/api/v2/statuses/get_groups",

  "delivery_types.list_by_key": "/api/v2/delivery_types/order/{id}",
  delivery_types: "/api/v2/delivery_types/{id}",
  "delivery_types.list": "/api/v2/delivery_types",

  postcode_info: "/api/v2/postcode_info",
  postcode_info_reindex: "/api/v2/postcode_info/reindex",
  postcode_info_get_one: "/api/v2/postcode_info/get_one",

  project: "/api/v2/projects/{id}",
  "project.list": "/api/v2/projects",
  "project.list_with_pages": "/api/v2/projects_with_pages",
  "project.suggest": "/api/v2/projects/suggest",
  project_product: "/api/v2/projects/products/{id}",

  "get_address.list": "/api/v2/get_address_list",
  "get_warehouse.list": "/api/v2/get_warehouse_list",

  black_list: "/api/v2/black_list",
  "black_list.delete": "/api/v2/black_list/{id}",

  order: "/api/v2/orders/{id}",
  "order.list": "/api/v2/orders",
  "order.doubles": "/api/v2/order_doubles/{id}",
  "order.change_m": "/api/v2/orders/change_m/{id}",
  "order.update_phones": "/api/v2/orders/update_phones/{id}",
  "order.get_phones_by_key": "/api/v2/orders/get_phones_by_key/{id}",

  unload: "/api/v2/unloads/{id}",
  "unload.list": "/api/v2/unloads",

  copy: "/api/v2/copies/{id}",
  "copy.info": "/api/v2/copies/info/{id}",
  "copy.list": "/api/v2/copies",

  geo: "/api/v2/geo/{id}",
  "geo.phone": "/api/v2/geo/phone/{id}",
  "geo.list": "/api/v2/geo",

  sms: "/api/v2/sms/{id}",
  "sms.list": "/api/v2/sms",
  "sms.list_mass": "/api/v2/sms/mass",

  "accord.create":"/api/v2/accord_create",
  "send_dpd_create":"/api/v2/send_dpd",
  "order_load":"/api/v2/order_load",
  "order_load_stop":"/api/v2/order_load_stop/{id}",

  sms_provider: "/api/v2/sms_provider/{id}",
  "sms_provider.list": "/api/v2/sms_provider",

  sms_rules: "/api/v2/sms_rules/{id}",
  "sms_rules.list": "/api/v2/sms_rules",

  sms_templates: "/api/v2/sms_templates",
  "sms_template": "/api/v2/sms_templates/{id}",
  "sms_templates_order": "/api/v2/sms_templates/order/{id}",
  "select_sms_templates_list": "/api/v2/get_list_sms_templates",
  "select_sms_templates_list_search": "/api/v2/get_list_sms_templates/search",

  "call.list": "/api/v2/calls",
  "call.do": "/api/v2/calls/do_call",
  "call.getbycall": "/api/v2/calls/getbycall/{id}",

  project_category: "/api/v2/project_category/{id}",
  "project_category.list": "/api/v2/project_category",

  user: "/api/v2/users/{id}",
  user_password: "/api/v2/users/{id}/password_update",
  set_show_is_work: "/api/v2/users/{id}/set_show_is_work",
  // "user.list": " /api/v2/users",

  "user.list": "/api/v2/organizations/{id}/users",
  "user.search": "/api/v2/users/{id}/search_users",
  "user.logout_by_role": "/api/v2/users/{id}/logout_by_role",

  traffic: "/api/v2/traffics/{id}",
  "traffic.list": "/api/v2/traffics",

  page: "/api/v2/project_page/{id}",
  "page.list": "/api/v2/project_page",

  page_phone: "/api/v2/project_page_phone/{id}",
  "page_phone.list": "/api/v2/project_page_phone",

  product_category: "/api/v2/product_category/{id}",
  "product_category.list": "/api/v2/product_category",
  "product_category.active": "/api/v2/product_category_active",

  gasket: "/api/v2/project_gasket/{id}",
  "gasket.list": "/api/v2/project_gasket",

  call_center: "/api/v2/call_center/{id}",
  "call_center.list": "/api/v2/call_center",

  currency: "/api/v2/currency/{id}",
  "currency.list": "/api/v2/currency",

  goal: "/api/v2/project_goal/{id}",
  "goal.list": "/api/v2/project_goal",

  project_goal_script: "/api/v2/project_goal_script/{id}",
  "project_goal_script.list": "/api/v2/project_goal_script",

  delivery_type_projects: "/api/v2/delivery_type_projects/{id}",
  "delivery_type_projects.list": "/api/v2/delivery_type_projects",

  entity: "/api/v2/entities/{id}",
  "entity.list": "/api/v2/entities",

  entity_params: "/api/v2/entity_params/{id}",
  "entity_params.list": "/api/v2/entity_params",
  "entity_params.children": "/api/v2/entity_params/{id}/children",
  "entity_params.by_entity": "/api/v2/entity_params/{id}/by_entity",
  "entity_params.by_entity_role": "/api/v2/entity_params/by_entity_role",
  "entity_params.by_role_permitted": "/api/v2/entity_params/by_role_permitted",

  role_group: "/api/v2/role_groups/{id}",
  "role_group.list": "/api/v2/role_groups",

  role: "/api/v2/roles/{id}",
  "role.list": "/api/v2/roles",
  "role.by_group": "/api/v2/roles/{id}/by_group",
  "role.organizations_projects": "/api/v2/roles/{id}/organizations_projects",
  "role.geo": "/api/v2/roles/{id}/geos",
  "role.attach_geo": "/api/v2/roles/attachGeos",
  "role.attach_params": "/api/v2/roles/attach_params",
  "role.attach_status": "/api/v2/roles/attach_status",
  "role.attach_all_child_status": "/api/v2/roles/attach_all_child_status",
  "role.attach_organizations_projects":
    "/api/v2/roles/attach_organizations_projects",
  "role.groups_by_access": "/api/v2/roles/{id}/groups_by_access",
  "role.by_access": "/api/v2/roles/{id}/by_access",
  "role.by_access_for_list": "/api/v2/roles/{id}/by_access_for_list",
  "role.copy_settings": "/api/v2/roles/copy_settings",
  "role.add_permissions": "/api/v2/roles/add_permissions",
  "role.get_permissions": "/api/v2/roles/get_permissions/{id}",

  ats: "/api/v2/ats/{id}",
  "ats.list": "/api/v2/ats",
  "ats.get_list_for_select": "/api/v2/get_list_ats",

  ats_groups: "/api/v2/ats_groups/{id}",
  "ats_groups.list": "/api/v2/ats_groups",
  "ats_groups.list_to_select": "/api/v2/ats_groups_to_select",
  "ats_groups.get_list_by_condition": "/api/v2/ats_groups_by_condition",
  "ats_groups.by_organization": "/api/v2/ats_groups/by_organization/{id}",
  "ats_groups.get_organizations": "/api/v2/ats_groups/{id}/get_organizations",
  "ats_groups.attach_organizations": "/api/v2/ats_groups/{id}/attach_organizations",


  "ats_users": "/api/v2/ats_users/{id}",
  "ats_users_upd_vpn": "/api/v2/ats_users_upd_vpn/{id}",
  "ats_user_password": "/api/v2/ats_users/{id}/ats_password_update",
  "user_get_list_ats": "/api/v2/user_get_list_ats",
  "user_get_list_ats_group": "/api/v2/dx_user_list_ats_group",
  "get_sip_registration_data": "/api/v2/get_sip_registration_data/{id}",
  "ats_users.list": "/api/v2/ats_users",
  "ats_users.get_login": "/api/v2/caller_ids/free_private",
  "ats_users.get_login_v2": "/api/v2/caller_ids/free_private_v2",
  "ats_users.get_caller_ids": "/api/v2/ats_users/{id}/caller_ids",

  sips: "/api/v2/sips/{id}",
  "sips.list": "/api/v2/sips",
  "sips.get_caller_ids": "/api/v2/sips/{id}/caller_ids",
  "sips.get_all_caller_ids": "/api/v2/sips/caller_ids",

  caller_ids: "/api/v2/caller_ids/{id}",
  "caller_ids.list": "/api/v2/caller_ids",
  "caller_ids.rebind": "/api/v2/caller_ids/rebind",
  "caller_ids.get_operators": "/api/v2/caller_ids/get_operators/{id}",
  "caller_ids.get_for_in": "/api/v2/caller_ids/get_for_in",
  "caller_ids.get_by_value": "/api/v2/caller_ids/get_by_value/{id}",

  providers: "/api/v2/providers/{id}",
  "providers.list": "/api/v2/providers",
  "providers.all": "/api/v2/providers_all",

  out_routes: "/api/v2/out_routes/{id}",
  "out_routes.list": "/api/v2/out_routes",

  order_senders: "/api/v2/order_senders/{id}",
  'order_senders.list': "/api/v2/order_senders",

  "log_activities.add_to_log" : "/api/v2/log_activities/add_to_log",

  ats_queues: "/api/v2/ats_queues/{id}",
  "ats_queues.list": "/api/v2/ats_queues",
  "ats_queues.in_list": "/api/v2/ats_queues/in",
  "ats_queues.in_list_filter": "/api/v2/ats_queues/list_by_type/0",
  "ats_queues.by_company": "/api/v2/ats_queues/by_company/{id}",
  "ats_queues.attach_operators": "/api/v2/ats_queues/{id}/attach_operators",
  "ats_queues.attach_trunks": "/api/v2/ats_queues/{id}/attach_trunks",
  "ats_queues.attach_companies": "/api/v2/ats_queues/{id}/attach_companies",
  "ats_queues.weight_adjusting_log": "/api/v2/ats_queues/{id}/weight_adjusting_log",
  "ats_queue_groups.list": "/api/v2/ats_queue_groups",
  "ats_queue_groups.create": "/api/v2/ats_queue_groups/create",
  "ats_queue_groups.load": "/api/v2/ats_queue_groups/load/{id}",
  "ats_queue_groups.update": "/api/v2/ats_queue_groups/update/{id}",
  "caller_ids.get_for_ats_queue_group": "/api/v2/caller_ids/get_for_ats_queue_groups/{id}",

  "ats_monitoring.dial_coeff": "/api/v2/ats_monitoring/get_dial_coeff",
  "ats_monitoring.current_calls": "/api/v2/ats_monitoring/get_current_calls",

  profile: "/api/v2/profile/me",
  "profile.operator_time" : "/api/v2/profile/operator_time",
  "profile.integrations": "/api/v2/profile/integrations",

  custom_integrations: "/api/v2/custom_integrations",
  "custom_integrations.update": "/api/v2/custom_integrations/{id}",
  "custom_integrations.event_list": "/api/v2/custom_integrations/event_list",
  "custom_integrations.event_field_list": "/api/v2/custom_integrations/event_field_list/{id}",
  "custom_integrations.delete": "/api/v2/custom_integrations/{id}",

  "ats_monitoring.oper_states": "/api/v2/ats_monitoring/get_oper_states",
  "ats_monitoring.mini_analytics": "/api/v2/ats_monitoring/mini_analytics",
  "ats_monitoring.get_lags": "/api/v2/ats_monitoring/get_lags/{id}",
  "ats_monitoring.move_up_orders": "/api/v2/ats_monitoring/move_up_orders",
  "ats_monitoring.move_down_orders": "/api/v2/ats_monitoring/move_down_orders",

  order_advert_sources: "/api/v2/order_advert_sources",
  "order_advert_source": "/api/v2/order_advert_sources/{id}",

  order_validators: "/api/v2/orders/validators/{id}",
  "order_validators.list": "/api/v2/orders/validators",
  "order_validators.by_org": "/api/v2/orders/validators/by_org/{id}",

  pimpay_invoices: "/api/v2/pimpay/invoices/{id}",
  "pimpay_invoices.list": "/api/v2/pimpay/invoices",
  "pimpay_invoices.list_by_order": "/api/v2/pimpay/invoices/by_order/{id}",

  owners: "/api/v2/owners",
  "owner": "/api/v2/owners/{id}",
  "owners_by_company": "/api/v2/owners/by_company/{id}",
  "owners.get_api_key": "/api/v2/owners/get/api_key",

  test_question: "/api/v2/test_questions/{id}",
  "test_questions": "/api/v2/test_questions",

  question_group: "/api/v2/question_groups/{id}",
  question_group_is_question: "/api/v2/question_group_is_question/{id}",
  "question_groups": "/api/v2/question_groups",

  wholesaler_services: "/api/v2/optovichok/services",
  "wholesaler_service": "/api/v2/optovichok/services/{id}",

  wholesale_stocks: "/api/v2/optovichok/stocks",
  "wholesale_stock": "/api/v2/optovichok/stocks/{id}",

  wholesaler_orders: "/api/v2/optovichok/orders",
  "wholesaler_order": "/api/v2/optovichok/orders/{key}",
  "wholesaler_order.change_c": "/api/v2/optovichok/orders/change_c/{id}",
  "wholesaler_order.change_m": "/api/v2/optovichok/orders/change_m/{id}",

  wholesaler_purchases: "api/v2/optovichok/purchases",
  "wholesaler_purchase": "/api/v2/optovichok/purchases/{id}",

  closing_day: "/api/v2/optovichok/closing_day",
  "closing_day_create": "/api/v2/optovichok/closing_day/{id}",

  wholesaler_unload: "/api/v2/optovichok/unloads/{id}",
  "wholesaler_unload.list": "/api/v2/optovichok/unloads",

  wholesale_clients: "/api/v2/optovichok/clients",
  "wholesale_client": "/api/v2/optovichok/clients/{id}",

  opt_traffic_sources: "/api/v2/optovichok/opt_traffic_sources",
  "opt_traffic_source": "/api/v2/optovichok/opt_traffic_sources/{id}",

  sale_statuses: "/api/v2/optovichok/sale_statuses",
  "sale_status": "/api/v2/optovichok/sale_statuses/{id}",

  ats_queue_actions: "/api/v2/ats_queue_actions/{id}",
  "ats_queue_actions.list": "/api/v2/ats_queue_actions",

  page_settings: "/api/v2/page_setting",
  competence_cat: "/api/v2/competence_cats/{id}",
  "competence_cats": "/api/v2/competence_cats",

  competence: "/api/v2/competences/{id}",
  "competences": "/api/v2/competences",
  "competences.by_user": "/api/v2/competences/by_user/{id}",

  points_with_grade: "/api/v2/points_with_grades/{id}",
  "points_with_grades": "/api/v2/points_with_grades",

  grade_option: "/api/v2/grade_options/{id}",
  "grade_options": "/api/v2/grade_options",

  interpretation: "/api/v2/interpretations/{id}",
  "interpretations": "/api/v2/interpretations",

  "otsledi.list": "/api/v2/otsledi",
  "otsledi.run": "/api/v2/otsledi/run",

  "quantity_products.list": "/api/v2/quantity-products",

  fine_cats: "/api/v2/audit/fine-cats/{id}",
  "fine_cats.list": "/api/v2/audit/fine-cats",
  "fine_cats.by_fine_cat": "/api/v2/audit/fine-cats/{id}/by_fine_cat",

  reason_fines: "/api/v2/audit/reason-fines/{id}",
  "reason_fines.list": "/api/v2/audit/reason-fines",

  test: "/api/v2/tests/{id}",
  "tests": "/api/v2/tests",

  "user_forms": "/api/v2/user_forms/{id}",
  "user_forms.list": "/api/v2/user_forms",
  "user_forms.expired": "/api/v2/user_forms/get_expired/total",
  "user_forms.doubles": "/api/v2/user_forms/get_doubles/{id}",
  "user_forms.simple_get_by_phone_number": "/api/v2/user_forms/simple_get_by_phone_number/{id}",
  "user_forms.simple": "/api/v2/user_forms/simple_update/{id}",
  "user_forms.assign_test": "/api/v2/user_forms/assign_test/{id}",

  "reason_fines.by_reason_fines": "/api/v2/audit/reason-fines/{id}/by_reason_fines",

  fines: "/api/v2/audit/fines/{id}",
  "fines.list": "/api/v2/audit/fines",
  "fines.by_users": "/api/v2/audit/fines/list/by_users",

  "audit_orders.list": "/api/v2/audit/audit_orders",

  payment_type: "/api/v2/payment_types/{id}",
  "payment_types": "/api/v2/payment_types",

  "kassa_com.create": "/api/v2/kassa_com/payment/create",

  "packed": "/api/v2/packed",
  "packed.count": "/api/v2/packed/count",
  "packed.test": "/api/v2/packed/test",
  "packed.list": "/api/v2/packed-report",
  "packed.barcodes": "/api/v2/packed-barcodes",
  "packed.who_packed": "/api/v2/who-packed",
  "packed.geo": "/api/v2/packed-geo",
  packed_standards: "/api/v2/packed-standards/{id}",
  "packed_standards.list": "/api/v2/packed-standards",

  "hp_base": "/api/v2/hp-base/{id}",
  "hp_base.list": "/api/v2/hp-base",

  "delivery_dpd_report": "/api/v2/delivery-dpd-report",

  "unpacked.up": "/api/v2/unpacked/{id}",

  "report_settings": "/api/v2/report_settings/{id}",
  "report_settings_call_center_v2": "/api/v2/report_settings_call_center_v2/{id}",
  "report_settings_call_center_v2_orgs": "/api/v2/report_settings_call_center_v2_orgs",
  "report_settings_get_calc": "/api/v2/reports/get_calc",

  "my_targets_reports": "/api/v2/reports/get_my_targets",
  "my_targets_load": "/api/v2/my_targets/load",
  "my_targets_create_update": "/api/v2/my_targets/create_update",

  'notifications.list': '/api/v2/notifications/list',
  'notifications.unread_count': '/api/v2/notifications/unread_count',
  'notifications.load': '/api/v2/notifications/load/{id}',
  'notifications.mark_read': '/api/v2/notifications/mark_read/{id}',

  'bpm.bp.list': '/api/v2/bpm/bp/list',
  'bpm.bp.list.all': '/api/v2/bpm/bp/list/all/{id}',
  'bpm.bp.export': '/api/v2/bpm/bp/export/{id}',
  'bpm.bp.import': '/api/v2/bpm/bp/import',
  'bpm.bp':      '/api/v2/bpm/bp/{id}',
  'bpm.bp.update':      '/api/v2/bpm/bp/update/{id}',
  'bpm.bp.create':      '/api/v2/bpm/bp/create',
  'bpm.bp.copy':      '/api/v2/bpm/bp/copy/{id}',
  'bpm.bp.update_bps':  '/api/v2/bpm/bp/update_bps',
  'bpm.bp.api.update':  '/api/v2/bpm/bp/api_update/{id}',
  'bpm.bp.planner.get':  '/api/v2/bpm/bp/planner_get/{id}',
  'bpm.bp.planner.update':  '/api/v2/bpm/bp/planner_update/{id}',
  'bpm.bp.for_solution': '/api/v2/bpm/bp/for_solution/{id}',
  'bpm.bp.errors': '/api/v2/bpm/bp/errors',
  'bpm.bp.sms_providers': '/api/v2/bpm_bp_sms_providers/{id}',
  'bpm.db.list': '/api/v2/bpm/db/list',
  'bpm.db':      '/api/v2/bpm/db/{id}',
  'bpm.db.update':      '/api/v2/bpm/db/update/{id}',
  'bpm.db.create':      '/api/v2/bpm/db/create',
  'bpm.contrahen':      '/api/v2/bpm/contrahen/{id}',
  'bpm.contrahen.update':      '/api/v2/bpm/contrahen/update/{id}',
  'bpm.contrahen.create':      '/api/v2/bpm/contrahen/create',
  'bpm.actions.restore': '/api/v2/bpm/actions/restore/{id}',
  'bpm.actions.restore_jump': '/api/v2/bpm/actions/restore_jump/{id}',
  'bpm.actions.add_jump': '/api/v2/bpm/actions/add_jump/{id}',
  'bpm.bp.get_stats': '/api/v2/bpm/bp/get_stats/{id}',
  'bpm.bp.check': '/api/v2/bpm/bp/check/{id}',
  'bpm.bp.run_stuck': '/api/v2/bpm/bp/run_stuck_action/{id}',
  'bpm.bp.run_error_sms': '/api/v2/sms/run_error_sms_action/{id}',

  "operator_script": "/api/v2/bpm/operator_scripts/{id}",
  "operator_scripts": "/api/v2/bpm/operator_scripts",

  "decision_template": "/api/v2/bpm/decision_templates/{id}",
  "decision_templates": "/api/v2/bpm/decision_templates",
  "solution": "/api/v2/bpm/solutions/{id}",
  "solutions": "/api/v2/bpm/solutions",
  "solution_get_bot_scripts": "/api/v2/bpm/solutions/get_bot_scripts/{id}",
  "solution_create_setups_bot_scripts": "/api/v2/bpm/solutions/create_setup_bot_scripts/{id}",
  "get_setup_through_campaign": "/api/v2/bpm/solutions/get_setup_through_campaign/{id}",
  "copy_setup_in_new_campaign": "/api/v2/bpm/solutions/copy_setup_in_new_campaign/{id}",

  "call_center_v2_data": "/api/v2/reports/call_center_extended_report_v2_achievements",

  "profile.check_telegram_verify_code": "/api/v2/profile/check_telegram_verify_code",

  "send_tel_check":"/api/v2/tel_checked",

  "tel_check_order":"/api/v2/order_call_check",
  "report_by_calls":"/api/v2/report_by_calls",
  "get_calls_state":"/api/v2/get_calls_state",

  "send_sms_check":"/api/v2/sms_checked",
  "report_by_sms":"/api/v2/report_by_sms",
  "sms_by_phone":"/api/v2/sms_by_phone",
  "get_sms_state":"/api/v2/get_sms_state",

  "sales_report.orders_list":"/api/v2/reports/sales_report/get_orders",
  "sales_section_report.orders_list":"/api/v2/reports/sales_section_report/get_orders",
  sip_srm_property: "/api/v2/sip_crm_property",
  "robot_steps_info.list_by_key": "/api/v2/robot_steps_info/order/{id}",
  "robot_steps_info.list": "/api/v2/robot_steps_info",
  "robot_steps_info": "/api/v2/robot_steps_info/{id}",

  'robot.stats': '/api/v2/bot/get_calls_count',
  'robot.script': '/api/v2/bot/script/{id}',
  'robot.intents': '/api/v2/bot/script/{id}/intents',
  'robot.script.list': '/api/v2/bot/script',
  'robot.script.update': '/api/v2/bot/script/{id}',
  'robot.script.create': '/api/v2/bot/script',
  'robot.script.export': '/api/v2/bot/script/export/{id}',
  'robot.script.import': '/api/v2/bot/script/import',
  'robot.script.copy': '/api/v2/bot/script/copy/{id}',
  'robot.script.publish': '/api/v2/bot/script/publish/{id}',
  'robot.script.check': '/api/v2/bot/script/check/{id}',

  'robot.projects': '/api/v2/bot/robot_projects/list',
  'robot.project': '/api/v2/bot/robot_projects/{id}',
  'robot.project.update': '/api/v2/bot/robot_projects/update/{id}',
  'robot.project.create': '/api/v2/bot/robot_projects/create',
  'robot.project.from_dialogs': '/api/v2/bot/robot_projects/from_dialogs',
  'robot.dialog_markups': '/api/v2/bot/dialog_markups/{id}/list',
  'robot.dialog_markup': '/api/v2/bot/dialog_markups/{id}',
  'robot.dialog_markup.update': '/api/v2/bot/dialog_markups/{id}',
  'robot.dialog_markup.audio': '/api/v2/bot/dialog_markups/audio/{id}',

  'nlu.groups': '/api/v2/nlu/groups',
  'nlu.groups.history': '/api/v2/nlu/groups/history',
  'nlu.group': '/api/v2/nlu/groups/{id}',
  'nlu.intents': '/api/v2/nlu/intents',
  'nlu.intent': '/api/v2/nlu/intents/{id}',
  'nlu.intent_by_name': '/api/v2/nlu/intents/by_name/{id}',
  'nlu.dataset': '/api/v2/nlu/dataset',
  'nlu.servers': '/api/v2/nlu/servers',
  'nlu.servers.history': '/api/v2/nlu/servers/history',
  'nlu.server': '/api/v2/nlu/servers/{id}',

  'speech.list': '/api/v1/cloud/list/languages',

  'robot.chat.get_templates': '/api/v2/bot/chat/get_templates',
  'robot.chat.init': '/api/v2/bot/chat/init',

  'robot.template': '/api/v2/bot/templates/{id}',
  'robot.templates': '/api/v2/bot/templates',

  chat_bot_setup: "/api/v2/chat_bot_setups/{id}",
  "chat_bot_setups": "/api/v2/chat_bot_setups",
  chat_bot_setup_group: "/api/v2/chat_bot_setup_groups/{id}",
  "chat_bot_setup_groups": "/api/v2/chat_bot_setup_groups",
  "chat_bot_setup_group_list_select": "/api/v2/chat_bot_setup_group_list",

  "industry": "/api/v2/industries/{id}",
  "industries": "/api/v2/industries",

  "bpm_case": "/api/v2/bpm/bpm_cases/{id}",
  "bpm_cases": "/api/v2/bpm/bpm_cases",

  "messenger_account": "/api/v2/messenger_accounts/{id}",
  "messenger_accounts": "/api/v2/messenger_accounts",

  "telephony_number": "/api/v2/telephony_numbers/{id}",
  "telephony_numbers": "/api/v2/telephony_numbers",

  "settings.phones": "/api/v2/settings/phones",

  "bot_campaign": "/api/v2/campaigns/{id}",
  "bot_campaign.elements": "/api/v2/campaigns/{id}/elements",
  "bot_campaigns": "/api/v2/campaigns",
  "bot_campaigns.get_stat": "/api/v2/campaigns/get_stat/{id}",
  "get_bpm_db_load_list": "/api/v2/get_bpm_db_load_list/{id}",
  "get_bot_scripts": "/api/v2/campaigns/get_bot_scripts/{id}",
  "save_bot_script": "/api/v2/campaigns/save_bot_script/{id}",
  "bot_campaigns.update_load_type": "/api/v2/campaigns/update_load_type/{id}",

  "tags": "/api/v2/tags",
  "tags.industries": "/api/v2/industries/tags",
  "tags.industries.id": "/api/v2/industries/{id}/tags",
  "tag.load": "/api/v2/tags/{id}",
  "tag.create": "/api/v2/tags/create",
  "tag.update": "/api/v2/tags/{id}",

  "rb.constructor.reports.load": "/gateway/report-builder/api/v1/constructor/reports",
  "rb.constructor.reports.report.load": "/gateway/report-builder/api/v1/constructor/reports/{id}",
  "rb.constructor.reports.report.delete": "/gateway/report-builder/api/v1/constructor/reports/{id}",
  "rb.constructor.reports.report.store": "/gateway/report-builder/api/v1/constructor/reports",
  "rb.constructor.reports.report.update": "/gateway/report-builder/api/v1/constructor/reports/{id}",

  "rb.constructor.templates.load": "/gateway/report-builder/api/v1/constructor/templates",
  "rb.constructor.templates.template.load": "/gateway/report-builder/api/v1/constructor/templates/{id}",
  "rb.constructor.templates.template.delete": "/gateway/report-builder/api/v1/constructor/templates/{id}",
  "rb.constructor.templates.template.store": "/gateway/report-builder/api/v1/constructor/templates",
  "rb.constructor.templates.template.update": "/gateway/report-builder/api/v1/constructor/templates/{id}",
  "rb.constructor.templates.sources.load": "/gateway/report-builder/api/v1/constructor/templates/sources",

  "rb.constructor.columns.load": "/gateway/report-builder/api/v1/constructor/columns",
  "rb.constructor.columns.column.load": "/gateway/report-builder/api/v1/constructor/columns/{id}",
  "rb.constructor.columns.column.delete": "/gateway/report-builder/api/v1/constructor/columns/{id}",
  "rb.constructor.columns.column.store": "/gateway/report-builder/api/v1/constructor/columns",
  "rb.constructor.columns.column.update": "/gateway/report-builder/api/v1/constructor/columns/{id}",

  "rb.constructor.sources.load": "/gateway/report-builder/api/v1/constructor/sources",
  "rb.constructor.sources.source.load": "/gateway/report-builder/api/v1/constructor/sources/{id}",
  "rb.constructor.sources.source.delete": "/gateway/report-builder/api/v1/constructor/sources/{id}",
  "rb.constructor.sources.source.store": "/gateway/report-builder/api/v1/constructor/sources",
  "rb.constructor.sources.source.update": "/gateway/report-builder/api/v1/constructor/sources/{id}",
  "rb.constructor.sources.source.columns": "/gateway/report-builder/api/v1/constructor/sources/{id}/columns",

  "rb.constructor.dashboards.load": "/gateway/report-builder/api/v1/constructor/dashboards",
  "rb.constructor.dashboards.dashboard.load": "/gateway/report-builder/api/v1/constructor/dashboards/{id}",
  "rb.constructor.dashboards.dashboard.delete": "/gateway/report-builder/api/v1/constructor/dashboards/{id}",
  "rb.constructor.dashboards.dashboard.store": "/gateway/report-builder/api/v1/constructor/dashboards",
  "rb.constructor.dashboards.dashboard.update": "/gateway/report-builder/api/v1/constructor/dashboards/{id}",

  "rb.reports.load": "/gateway/report-builder/api/v1/reports",
  "rb.reports.report.info.load": "/gateway/report-builder/api/v1/reports/{id}/info",
  "rb.reports.report.data.load": "/gateway/report-builder/api/v1/reports/{id}/data",
  "rb.reports.report.configuration.store": "/gateway/report-builder/api/v1/reports/{id}/configurations",

  "amocrm.set_settings" : "/api/v2/amocrm/set_settings",
  "amocrm.data.delete": "/api/v2/amocrm/delete/{id}",

  "bitrix.set_settings": "/api/v2/bitrix/set_settings",
  "bitrix.set_secret": "/api/v2/bitrix/set_secret",
  "bitrix.delete": "/api/v2/bitrix/delete/{id}",
  "bitrix.update_stages": "/api/v2/bitrix/update_stages/{id}",
  "bitrix.update_statuses": "/api/v2/bitrix/update_statuses/{id}",

  "hh.set_settings" : "/api/v2/hh/set_settings",
  "hh.data.delete": "/api/v2/hh/delete/{id}",
  "hh.resumes": "/api/v2/hh/resumes",
  "hh.create_contrahen": "/api/v2/campaigns/create_contrahen_by_hh",

  "rb.dashboards.load": "/gateway/report-builder/api/v1/dashboards",
  "rb.dashboards.dashboard.load": "/gateway/report-builder/api/v1/dashboards/{id}",

  "bot_campaigns.create_contrahen_by_list": "/api/v2/campaigns/create_contrahen_by_list",
  "bot_campaigns.create_contrahen_by_filter": "/api/v2/campaigns/create_contrahen_by_filter",
  "bot_campaigns.exclude_contact_by_list": "/api/v2/campaigns/exclude_contact_by_list",
  "bot_campaigns.exclude_contact_by_file": "/api/v2/campaigns/exclude_contact_by_file",

  'phrases': '/api/v2/phrases',
  'phrase': '/api/v2/phrases/{id}',

  'moderation': '/api/v2/moderation/{id}',
  'moderation.first_or_create': '/api/v2/moderation/{id}/first_or_new',
};

let main_url = get_main_url.mainUrl;

/**
 * TODO: функция требует переписывания так как не обрабатывает несколько переменных в путях
 */
let format = function(endpoint, id) {
  let url = endpoints[endpoint] || endpoint;
  url = url.replace("{id}", id);
  url = main_url + url;
  return url;
};

let isNotEmpty = function(value) {
  return value !== undefined && value !== null && value !== "";
};

//https://js.devexpress.com/Documentation/Guide/Widgets/DataGrid/Data_Binding/Custom_Sources/
let api = {
  main_url: main_url,
  errors_format(items) {
    let result = "";
    let i = 1;
    for (var key in items) {
      result += i + ") " + items[key] + "<br/>";
      i++;
    }

    return result;
  },
  format: format,
  dx(url, key = "id") {
    return new CustomStore({
      key: key,
      onLoaded(result) {
        this._data = result
      },
      byKey: bkey => {
        let params = [];
        params["filter"] = JSON.stringify([key, "=", bkey]);
        return http.get(main_url + url, params).then(response => {
          let data = response.data;
          if (_.has(response.data, 'data')) {
            data = data.data;
          }
          return _.first(data) || {};
        });
      },
      load: loadOptions => {
        let method = "get";
        let params = {};
        [
          "skip",
          "take",
          "requireTotalCount",
          "requireGroupCount",
          "sort",
          "filter",
          "totalSummary",
          "group",
          "groupSummary",
          "searchValue"
        ].forEach(function(i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            switch (i) {
              case "searchValue":
                params["filter"] = JSON.stringify([
                  loadOptions["searchExpr"],
                  loadOptions["searchOperation"],
                  loadOptions["searchValue"]
                ]);
                break;
              case "take":
                params[i] = loadOptions[i];
                break;
              case "filter":
                _.each(loadOptions[i], item => {
                  if (_.has(item, 2) && _.isDate(item[2])) {
                    item[2] = moment(item[2]).format('YYYY/MM/DD HH:mm:ss');
                  }
                });
                params[i] = JSON.stringify(loadOptions[i]);
                break;
              default:
                params[i] = JSON.stringify(loadOptions[i]);
            }
          }
        });
        if (typeof params["filter"] === 'string') {
            params["filter"] = params["filter"].replace(/([\d]{4})-([\d]{2})-([\d]{2})T([\d]{2}:[\d]{2}):00\.000Z/g, "$1-$2-$3 $4:00")
        }
        if(url == "/api/v2/dx_orders" || url == "/api/v2/robot_report")
          method = "post";

        return http.request(method, main_url + url, params).then(response => {
          if (response) {
            response.data["totalCount"] = response.data["total"];
            return response.data;
          }
        });
      },
      update: function (key, values) {
          let add_url = "/" + url+"/"+encodeURIComponent(key);
          return http.put(main_url + add_url, values);
      },
      remove: function (key, values) {
        let add_url = "/" + url+"/"+encodeURIComponent(key);
        return http.delete(main_url + add_url, values);
      },
      insert: function (values) {
        let add_url = "/" + url;
        return http.post(main_url + add_url, values);
      }
    });
  },
  dxTagBox(url, key = "id") {
    return new CustomStore({
      key: key,
      byKey: bkey => {
        let params = [];
        params["filter"] = JSON.stringify([key, "=", bkey]);
        return http.get(main_url + url, params).then(response => {
          if (response) {
            return response.data.data[0];
          }
        });
      },
      load: loadOptions => {
        let params = [];
        [
          "skip",
          "take",
          "requireTotalCount",
          "requireGroupCount",
          "sort",
          "filter",
          "totalSummary",
          "group",
          "groupSummary",
          "searchValue"
        ].forEach(function(i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            switch (i) {
              case "searchValue":
                params["filter"] = JSON.stringify([
                  loadOptions["searchExpr"],
                  loadOptions["searchOperation"],
                  loadOptions["searchValue"]
                ]);
                break;
              case "take":
                params[i] = loadOptions[i];
                break;
              default:
                params[i] = JSON.stringify(loadOptions[i]);
            }
          }
        });
        return http.get(main_url + url, params).then(response => {
          if (response) {
            return response.data.data;
          }
        });
      }
    });
  },
  /**
   * Данные для компонента DxTagBox для отправки запроса в базу
   * Формат
   * search: String
   * ids: Array
   * @param {String} url
   * @param {String} key
   * @returns
   */
  dxTagBoxDB(url, key = "id") {
    return new CustomStore({
      key: key,
      byKey: bkey => {
        let params = [];
        params["filter"] = JSON.stringify([key, "=", bkey]);
        return http.get(main_url + url, params).then(response => {
          if (response) {
            return response.data.data[0];
          }
        });
      },
      load: loadOptions => {
        let params = [];
        [
          "filter",
          "searchValue"
        ].forEach(function(i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            let options = _.isArray(loadOptions[i][0]) ? loadOptions[i] : [loadOptions[i]]
            switch (i) {
              case 'searchValue':
                params['search'] = loadOptions['searchValue'];
                break;
              default:
                params['ids'] = [];
                options.forEach(filter => {
                  if (filter.length === 3) {
                    params['ids'].push(filter[2]);
                  }
                });
            }
          }
        });

        return http.get(main_url + url, params).then(response => {
          if (response) {
            return response?.data?.data?.map(item => {
              for (const [key, value] of Object.entries(item)) {
                if (typeof value === 'object') {
                  delete item[key];
                }
              }
              return item;
            });
          }
        });
      }
    });
  },
  create(
    endpoint,
    id,
    data,
    state,
    commit,
    creatingChange,
    dataChange,
    errorsChange
  ) {
    let url = format(endpoint, id);
    const tmpLang = http.getLang();

    commit(creatingChange, true);

    commit(errorsChange, {});

    return http
      .post(url, data)
      .then(response => {
        commit(creatingChange, false);

        if (response && response.data) {
          commit(dataChange, response.data);

          Vue.notify({
            group: "app",
            type: "success",
            title: lang.get(dataChange, tmpLang),
            text: lang.get('successfully_completed_event', tmpLang),
            duration: 500
          });

          return true;
        } else {
          return false;
        }
      })
      .catch(error => {

        commit(creatingChange, false);
        let errorMsg = error.message;

        if (error.errors) {
          commit(errorsChange, error.errors);
        } else if (error.data) {
          commit(errorsChange, error.data);
          errorMsg = error.data.message || '';
        }
        console.log ('error ', error);
        Vue.notify({
          group: "app",
          type: "error",
          title: errorMsg,
          text: api.errors_format(error.errors),
          duration: 10000
        });

        return false;
      });
  },
  update(
    endpoint,
    id,
    data,
    state,
    commit,
    savingChange,
    dataChange,
    errorsChange
  ) {
    let url = format(endpoint, id);
    const tmpLang = http.getLang();

    commit(savingChange, true);
    commit(errorsChange, {});

    return http
      .put(url, data)
      .then(response => {
        commit(savingChange, false);

        if (response && response.data) {
          commit(dataChange, response.data);

          Vue.notify({
            group: "app",
            type: "success",
            title: lang.get(dataChange, tmpLang),
            text: lang.get('successfully_completed_event', tmpLang),
            duration: 500
          });

          return true;
        } else {
          return false;
        }
      })
      .catch(error => {
        commit(savingChange, false);
        let errorMsg = error.message;

        if (error.errors) {
          commit(errorsChange, error.errors);
        } else if (error.data) {
          commit(errorsChange, error.data);
          errorMsg = error.data.message || '';
        }

        Vue.notify({
          group: "app",
          type: "error",
          title: errorMsg,
          text: api.errors_format(error.errors),
          duration: 10000
        });

        return false;
      });
  },
  load(endpoint, id, state, commit, loadingChange, dataChange, errorsChange = null) {
    let url = format(endpoint, id);
    const tmpLang = http.getLang();

    if (loadingChange) {
      commit(loadingChange, true);
    }

    commit(dataChange, null);

    if (errorsChange){
      commit(errorsChange, {});
    }

    return http
      .get(url)
      .then(response => {
        if (loadingChange) {
          commit(loadingChange, false);
        }

        if (response && response.data) {
          commit(dataChange, response.data);

          Vue.notify({
            group: "app",
            type: "success",
            title: lang.get(dataChange, tmpLang),
            text: lang.get('successfully_completed_event', tmpLang),
            duration: 500
          });

          return true;
        }
      })
      .catch(error => {
        let errorMsg = error.message

        if (errorsChange) {
          if (error.errors) {
            commit(errorsChange, error.errors);
          } else if (error.data) {
            commit(errorsChange, error.data);
            errorMsg = error.data.message || '';
          }
        }

        Vue.notify({
          group: "app",
          type: "error",
          title: errorMsg,
          text: api.errors_format(error.errors),
          duration: 10000
        });

        return false;
      });
  },
  /**
   * Получение элемента по Id/key с параметрами
   */
  loadByParams(endpoint, id, params, state, commit, loadingChange, dataChange, errorsChange = null) {
    let url = format(endpoint, id);
    const tmpLang = http.getLang();

    if (loadingChange) {
      commit(loadingChange, true);
    }

    commit(dataChange, null);

    if (errorsChange){
      commit(errorsChange, {});
    }

    return http
      .get(url, params)
      .then(response => {
        if (loadingChange) {
          commit(loadingChange, false);
        }

        if (response && response.data) {
          commit(dataChange, response.data);

          Vue.notify({
            group: "app",
            type: "success",
            title: lang.get(dataChange, tmpLang),
            text: lang.get('successfully_completed_event', tmpLang),
            duration: 500
          });

          return true;
        }
      })
      .catch(error => {
        let errorMsg = error.message

        if (errorsChange) {
          if (error.errors) {
            commit(errorsChange, error.errors);
          } else if (error.data) {
            commit(errorsChange, error.data);
            errorMsg = error.data.message || '';
          }
        }

        Vue.notify({
          group: "app",
          type: "error",
          title: errorMsg,
          text: api.errors_format(error.errors),
          duration: 10000
        });

        return false;
      });
  },
  list(endpoint, id, params, state, commit, loadingChange, dataChange, errorsChange = null) {
    let url = format(endpoint, id);
    const tmpLang = http.getLang();

    commit(loadingChange, true);
    commit(dataChange, null);
    if (errorsChange){
      commit(errorsChange, {});
    }

    return http
      .get(url, params)
      .then(response => {
        commit(loadingChange, false);

        if (response && response.data) {
          let dataChangePayload = {
            data: response.data.data,
            total: response.data.total
          }

          if (response.data.meta) {
            dataChangePayload.total = response.data.meta.total

            if (response.data.meta?.per_page) {
              dataChangePayload.per_page = response.data.meta.per_page
            }
            if (response.data.meta?.current_page) {
              dataChangePayload.page = response.data.meta.current_page
            }
          }

          if (response.data.per_page) {
            dataChangePayload.per_page = response.data.per_page;
          }

          if (response.data?.current_page) {
            dataChangePayload.current_page = response.data.current_page;
          }

          commit(dataChange, dataChangePayload);

          Vue.notify({
            group: "app",
            type: "success",
            title: lang.get(dataChange, tmpLang),
            text: lang.get('successfully_completed_event', tmpLang),
            duration: 500
          });

          return true;
        }
      })
      .catch(error => {
        let errorMsg = error.message

        if (errorsChange) {
          if (error.errors) {
            commit(errorsChange, error.errors);
          } else if (error.data) {
            commit(errorsChange, error.data);
            errorMsg = error.data.message || '';
          }
        }

        Vue.notify({
          group: "app",
          type: "error",
          title: errorMsg,
          text: api.errors_format(error.errors),
          duration: 10000
        });

        return false;
      });
  },
  delete(endpoint, id, state, commit, loadingChange) {
    let url = format(endpoint, id);
    const tmpLang = http.getLang();

    commit(loadingChange, id);

    return http
      .delete(url)
      .then(response => {
        if (response && response.data) {
          Vue.notify({
            group: "app",
            type: "success",
            title: lang.get('removed', tmpLang),
            text: lang.get('successfully_completed_event', tmpLang),
            duration: 500
          });

          return true;
        }
      })
      .catch(error => {
        let errorMsg = error.message

        if (error.data) {
          errorMsg = error.data.message || '';
        }

        Vue.notify({
          group: "app",
          type: "error",
          title: errorMsg,
          text: api.errors_format(error.errors),
          duration: 10000
        });

        return false;
      });
  },
};

export default api;
